import { FunctionComponent } from 'react';
import SearchFilter from './SearchFilter';
import SearchStatusFilter from './SearchStatusFilter';
import useStyles from './SavedSearchesFilters.styles';

/** This component has the filters that can be used to filter down the results */
const SavedSearchesFilters: FunctionComponent = () => {
  const classes = useStyles();

  return (
      <div className={classes.container}>
        <SearchFilter />
        <SearchStatusFilter />
      </div>
  );
};

export default SavedSearchesFilters;
