import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',

    borderRadius: '30px',
    boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',
    background: theme.palette.neutral.light,

    width: '70rem',
    maxWidth: '1120px',
    maxHeight: '700px',

  },

  'styles-and-features-container': {

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    alignContent: 'space-between',

    width: '30%',
    height: '100%',

    background: theme.palette.neutral.light,
    borderRadius: '30px 0px 0px 30px',

    marginLeft: '1rem',

  },

  'styles-container': {

    width: '100%',
    marginTop: '1.25rem',

  },

  'features-container': {

    width: '100%',
    marginTop: '1.25rem',

  },

  'home-criteria-filters-container': {

    paddingTop: '1.25rem',
    width: '70%',

    borderLeft: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '0px 30px 30px 0px',

  },

}));

export default useStyles;
