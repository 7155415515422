import { FC, useState } from 'react';
import { CreateAccountService } from '@types';
import { LandingButton } from '@components';
import { useActor } from '@xstate/react';
import * as S from './CreateAccountSignUp.styled';

interface CreateAccountSignUpProps {
  createAccountService: CreateAccountService
}

const CreateAccountSignUp: FC<CreateAccountSignUpProps> = ({ createAccountService }) => {
  const [, send] = useActor(createAccountService);
  const [email, setEmail] = useState('');

  //   <S.Button onClick={() => send({ type: 'STARTED_SIGN_UP', payload: { email } })}>
  //   Sign me up!
  // </S.Button>

  return (
        <S.Container>
          <S.Input
            placeholder='Your email address...'
            value={email}
            onChange={(event) => setEmail(event.target.value)}
            >
          </S.Input>
          <LandingButton {...{ variant: 'orange', text: 'Get Started' }} onClick={() => send({ type: 'STARTED_SIGN_UP', payload: { email } })}/>
        </S.Container>
  );
};

export default CreateAccountSignUp;
