import { ListingWithID } from '@types';
import { every } from 'lodash';

/**
 * See if the listing has the features that were selected by the user.
 * Just like the styles, if the filter list is empty, ignore the filter
 */
const filterOnFeatures = (
  /** Listing to evaluate */
  listing: ListingWithID,
  /** Current values of the features selected to eval */
  featuresFilter: string[],
): boolean => {
  let result = false;

  if (featuresFilter.length > 0) {
    const { features } = listing;
    if (features) {
      result = every(featuresFilter.map((feature) => {
        return features.includes(feature);
      }));
    }
    if (!features) { result = false; }
  }
  if (featuresFilter.length === 0) { result = true; }

  return result;
};

export default filterOnFeatures;
