import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
display: grid;
grid-auto-columns: 1fr;
gap: 2rem;
justify-items: end;
align-items: center;

width: calc(100vw - 2rem);
height: 85vh;

margin-left: 1rem;


@media (${$theme('devices.laptopAndAbove')}) {
    grid-template-areas:
    'content image';
}

@media (${$theme('devices.tabletAndBelow')}) {
    grid-template-areas:
    'content';

    place-items: center;
    width: 95vw;
    height: min-content;
    margin-left: 1rem;
    margin-bottom: 3rem
}

`;

export const ContentContainer = styled.div`
grid-area: content;
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: center;

width: 100%;
max-width: 545px;
height: 100%;
max-height: 535px;

@media (${$theme('devices.tabletAndBelow')}) {
    justify-content: flex-start;

    max-height: 850px;;
}

`;

export const LandingTextContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;

width: 100%;
height: 100%;
max-height: 385px;

@media (${$theme('devices.tabletAndBelow')}) {
    max-height: min-content;
}
`;

export const FeaturesContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;

width: 93%;

@media (${$theme('devices.tabletAndBelow')}) {
    display: none;
}
`;

export const PatentContainer = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;

justify-self: flex-start;

width: 100%;
@media (${$theme('devices.tabletAndBelow')}) {
    margin-top: 2rem;
}
`;

export const ImageContainer = styled.div`

grid-area: image;
justify-self: start;

margin-right: 1rem;

width: 100%;
max-width: 537px;
height: 100%;
max-height: 535px;

@media (${$theme('devices.tabletAndBelow')}) {
    display: none;
}
`;

export const HighlightText = styled.span`
color: ${$theme('palette.primary.main')};
`;
