import { FilterValue } from '@types';

/**
 * Helper function to filter data based on the users filters
 */
function isDataWithinRange(
  /** The data thats being evaluated */
  data: number | null | undefined,
  /** The filter range being used to filter */
  filterValue: FilterValue,
  /** Default Values for the filter */
  defaultFilterValue: FilterValue,
): boolean {
  const [min, max] = filterValue;
  const [minDefault, maxDefault] = defaultFilterValue;
  const minEqualMax = min === max;

  /**
     * If the listing has the property being filtered,
     * go through the logic to see if it falls within the range
     */
  if (data) {
    if (max === maxDefault) {
      if (data >= min) { return true; }
    } else {
      if (minEqualMax && data === min) { return true; }
      if (!minEqualMax && data >= min && data <= max) { return true; }
    }
  }

  /**
     * If the filters are set back to the default ranges
     * then show all the listings even if they have the specific filter value missing.
     */
  if (!data && min === minDefault && max === maxDefault) { return true; }

  return false;
}

export default isDataWithinRange;
