import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',

    width: '100%',
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',

    width: '100%',
    height: '100%',

  },

  input: {
    margin: '0.5rem 0rem 0.5rem 0rem',
    width: '100%',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.neutral.dark,
      },
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main,
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main,
      },
    },
  },

  error: {
    width: '100%',

    marginLeft: '13rem',
    marginBottom: '1rem',
    color: 'red',
    fontSize: '1rem',
    fontWeight: theme.typography.h2.fontWeight,

  },

  create: {
    width: '100%',
    fontSize: '0.8rem',
    color: '#202020',

    cursor: 'pointer',
  },

  highlight: {
    color: theme.palette.primary.main,
  },

  button: {
    alignSelf: 'flex-end',
    margin: '0.5rem 0rem 0.5rem 0rem',

    color: theme.palette.primary.main,

    '&:hover': {
      transform: 'scale(1.03)',
      backgroundColor: theme.palette.neutral.light,
    },

    '&.error': {
      backgroundColor: theme.palette.neutral.light,
      color: theme.palette.primary.main,

    },

    '&.submit': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral.light,

      '&:hover': {
        transform: 'scale(1.03)',
        backgroundColor: theme.palette.primary.main,
      },

    },
  },
}));

export default useStyles;
