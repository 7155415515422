import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: '9.2rem',
    height: '100%',
    fontSize: '1rem',
  },

  icon: {

    width: '0.9375rem',
    height: '0.9375rem',
    marginRight: '0.3125rem',

  },

}));

export default useStyles;
