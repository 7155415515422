import { FunctionComponent } from 'react';
import ListingFeatures from './ListingFeatures';
import ListingStyles from './ListingStyles';
import MoreHomeCriteria from './MoreHomeCriteria';
import useStyles from './MoreFilters.styles';

/**
 * Holds additional filters for the user to select from.
 * These include home styles/types, features,
 * and additional home criteria options
 */
const MoreFilters: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>

      <div className={classes['styles-and-features-container']}>

        <div className={classes['styles-container']}>
          <ListingStyles />
        </div>

        <div className={classes['features-container']}>
          <ListingFeatures />
        </div>

      </div>

      <div className={classes['home-criteria-filters-container']}>
        <MoreHomeCriteria />
      </div>
    </div>
  );
};

export default MoreFilters;
