import { FunctionComponent, useCallback } from 'react';
import { SavedSearchStatus } from 'beiytak_sdk';
import clsx from 'clsx';
import { useAppDispatch } from '@hooks';
import {
  savedSearchActionValues,
  savedSearchActionNames,
  savedSearchActionIcons,
  savedSearchActionClickEvents,
} from '@constants';

import useStyles from './ActionList.styles';

interface ActionListProps {
    searchId: string,
    currentStatus: SavedSearchStatus,
}

const ActionList: FunctionComponent<ActionListProps> = (searchData) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { searchId, currentStatus } = searchData;

  const handleOnClick = useCallback((action: SavedSearchStatus) => {
    dispatch(savedSearchActionClickEvents[action](searchId));
  }, []);

  return (

    <div className={classes.container}>
        {
            savedSearchActionValues.map((action) => {
              const name = savedSearchActionNames[action];
              const Icon = savedSearchActionIcons[action];

              if (currentStatus !== action) {
                return (
                    <div
                    className={clsx({
                      [classes.action]: true,
                      delete: action === SavedSearchStatus.Deleted,
                    })}
                    key={action}
                    aria-label={action}
                    onClick={() => handleOnClick(action)}
                    >
                        <Icon classes={{ root: classes.icon }}/>
                        <span>{name}</span>
                    </div>
                );
              }

              return <></>;
            })
        }

    </div>

  );
};

export default ActionList;
