import { makeStyles } from '@material-ui/core/styles';
import { favoriteColor } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '2.6rem',
    height: '2.6rem',

    borderRadius: '50%',

    '&:hover': {

      background: 'rgb(247, 247, 247)',
    },

    cursor: 'pointer',
  },

  'favorite-icon': {
    color: theme.palette.neutral.dark,
  },

  'favorite-icon-selected': {
    color: favoriteColor,
  },

}));

export default useStyles;
