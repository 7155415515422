import { Middleware, unwrapResult, ThunkDispatch } from '@reduxjs/toolkit';
import {
  RootState,
  saveUserSearch,
  updatingSearchNameForSearchResultSession,
  updatingSearchIdForSearchResultSession,
  updatingSearchStatusForSearchResultSession,
  userRequestingToSaveSearch,
  updatingSaveSearchRequestStatus,
  updatingSavedSearchStatus,
  filteringSavedSearchesToDisplay,
  updateSavedSearch,
  updatingSavedSearchFromSearchResultSession,
  updatedSavedSearchStatus,
  updateSavedSearchAndGetResults,
  setSavedSearches,
  updateResultSessionWithSavedSearches,
  updatingSavedSearchMetaDataAssociatedWithSearchResult,
} from '@stores';
import {
  SavedSearchStatus, SaveSearch, UpdateSavedSearch,
} from 'beiytak_sdk';
import { getSourceInfoFromFavorites, formatHomeCriteriaInput } from '@utils';
import {
  HomeCriteriaKeys,
  RequestStatus,
  StepKeys,
} from '@types';

const handlingRequestToSaveOrUpdateSearchMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /** When the users requests to save their search on the results page */
  if (userRequestingToSaveSearch.match(action)) {
    const { payload: searchName } = action;

    const { userSearchResults } = storeAPI.getState().searchResultSession;
    const typeInput = storeAPI.getState().typeInput.value;
    const locationInput = storeAPI.getState().locationInput.value;
    const homeCriteriaInput = storeAPI.getState().homeCriteriaInput.value;
    const userLocationsInput = storeAPI.getState().userLocationsInput.value;
    const favorites = storeAPI.getState().searchResultFavorites.favoriteListings;

    if (locationInput && homeCriteriaInput && userLocationsInput && userSearchResults) {
      // Update the search name
      storeAPI.dispatch(updatingSearchNameForSearchResultSession(searchName));

      /** Format the favorited listings for the format required */
      const favoritesSourceInfo = getSourceInfoFromFavorites(favorites, userSearchResults);

      const saveSearchParams: SaveSearch = {
        searchName,
        params: {
          input: {
            type: typeInput,
            location: locationInput,
            homeCriteria: {
              price: formatHomeCriteriaInput({ type: typeInput, homeCriteriaKey: HomeCriteriaKeys.PRICE, input: homeCriteriaInput[HomeCriteriaKeys.PRICE] }),
              bedrooms: formatHomeCriteriaInput({ type: typeInput, homeCriteriaKey: HomeCriteriaKeys.BEDROOMS, input: homeCriteriaInput[HomeCriteriaKeys.BEDROOMS] }),
              bathrooms: formatHomeCriteriaInput({ type: typeInput, homeCriteriaKey: HomeCriteriaKeys.BATHROOMS, input: homeCriteriaInput[HomeCriteriaKeys.BATHROOMS] }),
            },
            userLocations: userLocationsInput,
          },
          favorites: favoritesSourceInfo,
        },
      };

      // Send request to backend to save the user search
      storeAPI.dispatch(saveUserSearch(saveSearchParams));

      return storeAPI.dispatch(updatingSaveSearchRequestStatus(RequestStatus.PENDING));
    }
  }

  if (updatingSavedSearchFromSearchResultSession.match(action)) {
    const params = unwrapResult(action);

    const { userSearchResults } = storeAPI.getState().searchResultSession;
    const typeInput = storeAPI.getState().typeInput.value;
    const locationInput = storeAPI.getState().locationInput.value;
    const homeCriteriaInput = storeAPI.getState().homeCriteriaInput.value;
    const userLocationsInput = storeAPI.getState().userLocationsInput.value;
    const favorites = storeAPI.getState().searchResultFavorites.favoriteListings;
    const { searchName, searchId, searchStatus } = storeAPI.getState().searchResultSession;
    const { savedSearches } = storeAPI.getState().savedSearches;

    if (locationInput && homeCriteriaInput && userLocationsInput && userSearchResults && searchName && searchId && searchStatus && savedSearches) {
      const savedSearch = savedSearches[searchId];

      /** Format the favorited listings for the format required */
      const favoritesSourceInfo = getSourceInfoFromFavorites(favorites, userSearchResults, savedSearch);

      const updateSavedSearchParams: UpdateSavedSearch = {
        searchName,
        searchId,
        status: searchStatus,
        params: {
          input: {
            type: typeInput,
            location: locationInput,
            homeCriteria: {
              price: formatHomeCriteriaInput({ type: typeInput, homeCriteriaKey: HomeCriteriaKeys.PRICE, input: homeCriteriaInput[HomeCriteriaKeys.PRICE] }),
              bedrooms: formatHomeCriteriaInput({ type: typeInput, homeCriteriaKey: HomeCriteriaKeys.BEDROOMS, input: homeCriteriaInput[HomeCriteriaKeys.BEDROOMS] }),
              bathrooms: formatHomeCriteriaInput({ type: typeInput, homeCriteriaKey: HomeCriteriaKeys.BATHROOMS, input: homeCriteriaInput[HomeCriteriaKeys.BATHROOMS] }),
            },
            userLocations: userLocationsInput,
          },
          favorites: favoritesSourceInfo,
        },
      };

      // if the results also need to be retrieved with the updated, then dispatch the specific thunk to do both
      if (params && params.getSavedSearchResult) {
        const { navigate } = params;
        return storeAPI.dispatch(updateSavedSearchAndGetResults({ navigate, updateSavedSearch: updateSavedSearchParams }));
      }

      // Send request to backend to save the user search
      return storeAPI.dispatch(updateSavedSearch(updateSavedSearchParams));
    }
  }

  /**
   * When the user updates the status of a search from the action list,
   * re-filter the saved searches and send a request to the backend to update the search
   */
  if (updatingSavedSearchStatus.match(action)) {
    // update the state with the new filter selections
    const { searchId, updatedStatus } = action.payload;
    next(action); // update the savedSearch data locally

    const params = {
      searchId,
      status: updatedStatus,
    };

    // send request to BE to update the status in stores
    storeAPI.dispatch(updatedSavedSearchStatus(params));

    // then dispatch action to filter the saved searches based on the updated filters
    const { savedSearches } = storeAPI.getState().savedSearches;
    if (savedSearches) {
      if (updatedStatus === SavedSearchStatus.Deleted) {
        // format the saved searches to an array to mimic how they would be on initial fetch
        const searches = Object.keys(savedSearches).map((searchId) => savedSearches[searchId]);
        storeAPI.dispatch(setSavedSearches(searches));
      }

      // update the search result session to reflect the updates
      storeAPI.dispatch(updateResultSessionWithSavedSearches());

      return storeAPI.dispatch(filteringSavedSearchesToDisplay(savedSearches));
    }
  }

  /** Once the search is saved on the backend, update the state to reflect the returned searchID and update list of saved searches */
  if (saveUserSearch.fulfilled.match(action)) {
    const searchId = unwrapResult(action);

    storeAPI.dispatch(updatingSaveSearchRequestStatus(RequestStatus.FULFILLED));

    const { searchName } = storeAPI.getState().searchResultSession;

    storeAPI.dispatch(updatingSearchIdForSearchResultSession(searchId));
    storeAPI.dispatch(updatingSearchStatusForSearchResultSession(SavedSearchStatus.Active)); // new searches are saved with active status by default

    // update the meta of the search so it can be used in case the user navigate elsewhere
    return storeAPI.dispatch(updatingSavedSearchMetaDataAssociatedWithSearchResult({ searchId, searchName: searchName || undefined, searchStatus: SavedSearchStatus.Active }));
  }

  /** If the request was rejected, clear out the search name and update the status to rejected */
  if (saveUserSearch.rejected.match(action)) {
    storeAPI.dispatch(updatingSearchNameForSearchResultSession(null));
    storeAPI.dispatch(updatingSearchIdForSearchResultSession(null));
    storeAPI.dispatch(updatingSearchStatusForSearchResultSession(null));

    return storeAPI.dispatch(updatingSaveSearchRequestStatus(RequestStatus.REJECTED));
  }

  return next(action);
});

export default handlingRequestToSaveOrUpdateSearchMiddleware;
