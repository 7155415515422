import { makeStyles } from '@material-ui/core/styles';

const marginLeftFromImages = '1.75rem';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    overflow: 'hidden',
  },

  'section-tabs': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    paddingLeft: marginLeftFromImages,
    marginBottom: '1.25rem',

    width: '100%',

    borderBottom: '1px solid #E5E5E5',
    fontSize: '0.875rem',
  },

  'section-tab': {

    height: '2rem',

    borderBottom: '1px solid transparent',

    marginRight: '2rem',

    '&:hover': {
      borderBottom: '1px solid #202020',
    },

    '&.selected': {
      borderBottom: '1px solid #202020',
    },
  },

  'sections-container': {

    width: '100%',
    height: '100%',

    overflowX: 'hidden',
    overflowY: 'auto',

    '&::-webkit-scrollbar': {
      width: '0rem',
      borderRadius: '0px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.neutral.dark,
      borderRadius: '0px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '0px',
      background: theme.palette.neutral.main,
    },

  },

  sections: {

    marginLeft: '1.75rem',

    width: '100%',
    height: '100%',

  },

  section: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    marginBottom: '1.5625rem',

    width: '100%',

    fontSize: '0.875rem',

  },

  'section-title': {

    marginBottom: '1.25rem',

    width: '80%',
    height: '2rem',

    borderBottom: '1px solid #E5E5E5',

  },

  divider: {

    width: '80%',
    borderBottom: '1px solid #E5E5E5',

    marginBottom: '1.25rem',
  },

}));

export default useStyles;
