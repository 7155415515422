import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',

    overflow: 'hidden',

    width: '100%',
    height: '100%',
  },

}));

export default useStyles;
