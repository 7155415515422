import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';
import { devices } from '@styleGuide';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'grid',
    gridTemplateColumns: '34% 64% 2%',
    gridTemplateRows: `${topNavBarHeight} 40rem calc(100vh - ${topNavBarHeight}  - 40rem)`,

    gridTemplateAreas:
        `
            'header header header'
            'login . .'
            '. . .'
        `,

    width: '100vw',
    height: '100vh',

    // tablet and below
    '@media (max-width: 768px)': {
      gridTemplateColumns: 'auto',
      gridTemplateRows: `${topNavBarHeight} calc(100vh - ${topNavBarHeight})`,
      gridTemplateAreas:
      `
          'header'
          'mobile-only'
      `,

    },

  },

  mobileOnly: {
    gridArea: 'mobile-only',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '95%',
    //  height: `calc(100vh - ${topNavBarHeight}`,

    fontWeight: theme.typography.h2.fontWeight,
    fontSize: '2rem',
    textAlign: 'center',
    color: 'black',

    '@media (min-width: 768px)': {
      display: 'none',
    },
  },

  background: {

    position: 'absolute',

    width: '100%',
    height: '100%',

    objectFit: 'cover',

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },

  overlay: {
    position: 'absolute',

    maxWidth: '35rem',
    minHeight: '100vh',
    maxHeight: '100vh',

    objectFit: 'fill',
    zIndex: 1,

    '@media (max-width: 768px)': {
      display: 'none',
    },
  },

  header: {

    gridArea: 'header',
    justifySelf: 'start',
    alignSelf: 'center',

    zIndex: 1,

    cursor: 'pointer',

    '@media (max-width: 768px)': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',

      width: '100%',
      height: `${topNavBarHeight}`,
      boxShadow: '0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04)',
    },
  },

  logo: {
    objectFit: 'contain',
    maxWidth: '7rem',
    marginLeft: '1rem',
    zIndex: 2,
  },

  login: {

    gridArea: 'login',
    alignSelf: 'center',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    // To make sure texts is over the background image
    zIndex: 2,

    marginLeft: '1rem',

    '@media (max-width: 768px)': {
      display: 'none',
    },

  },

  text: {
    marginBottom: '3rem',

    // ...theme.typography.h3,
    fontWeight: theme.typography.h2.fontWeight,
    fontSize: '2.75rem',
    textAlign: 'left',
    color: 'black',

  },

  subtitle: {
    // ...theme.typography.h4,
    fontWeight: theme.typography.h2.fontWeight,
    marginTop: '0.2rem',
    fontSize: '2rem',
  },

  form: {
    width: '100%',
    maxWidth: '25rem',
  },

}));

export default useStyles;
