import React, { FC, useState } from 'react';
import { PlanOptions } from '../types';
import * as S from './PlanOption.styled';

interface PlanOptionProps {
    id: PlanOptions,
    label: string,
    updatePlan: React.Dispatch<React.SetStateAction<PlanOptions>>
    selectedPlan: PlanOptions,
}

const PlanOption: FC<PlanOptionProps> = ({ id, label, updatePlan, selectedPlan }) => {
  const isSelected = id === selectedPlan;

  return (
    <S.Container {...{ isSelected }} onClick={() => updatePlan(id)}>
        <S.PlanOption
            {...{ isSelected }}
            type={'radio'}
            id={id}
            name={'plan-option'}
            value={id}
            onChange={() => updatePlan(id)}
            checked={isSelected}
        />
        <S.PlanOptionLabel>{label}</S.PlanOptionLabel>
    </S.Container>
  );
};

export default PlanOption;
