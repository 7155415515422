import { UserLocationInfo } from 'beiytak_sdk';
import { ListingWithID } from '@types';

/**
 * Returns whether the object passes is of type user location info
 * This is to avoid having to pass an additional param of what type the data is or
 * Create the same function twice with duplicated code
 * @function
 */

const isDataUserLocationInfo = (
  data: UserLocationInfo | ListingWithID,
): data is UserLocationInfo => {
  let result = false;
  if ('userAlias' in data) { result = true; }
  if ('price' in data) { result = false; }

  return result;
};

/**
 * Checks if some data is of type T.
 * This is useful when trying to create functions/components that can take more than one type
 * and handle each type difference.
 */
export function checkType<T>(
  /** Any data */
  data: any,
  /** A key that should be part of type T */
  key: string,
): data is T {
  let result = false;
  if (key in data || data[key]) { result = true; }
  return result;
}

export function checkEnumType<T>(
  /** Any data */
  data: any,
  /** A key that should be part of type T */
  key: any,
): key is T {
  let result = false;
  if (key in data) { result = true; }
  return result;
}

export default isDataUserLocationInfo;
