import { FC } from 'react';
import { useActor, useSelector } from '@xstate/react';
import { PurchaseLocationPermission } from '@components';
import { LocationPermissionService, selectHasPermission, selectDisplayPurchaseLocationPermission, selectIsCheckingLocationPermission } from '@machines';
import * as S from './PermissionProtectedSearch.styled';

interface PermissionEnabledSearchProps {
  variant: 'oval' | 'rectangle'
  locationPermissionService: LocationPermissionService,
  /** Text to display on the button itself */
  text: string,
  /** Allow the user to click. This allows an additional level of validation like waiting for all inputs to be set */
  allowClick: boolean
  /** Action to take once its verified the user has access to that location */
  onVerified: () => void
}

/** Handles checking the location permissions for the location of interest and whether the search can be made */
const PermissionProtectedSearch: FC<PermissionEnabledSearchProps> = ({ variant, locationPermissionService, text, allowClick, onVerified }) => {
  const [, send] = useActor(locationPermissionService);
  const displayPurchaseLocationPermission = useSelector(locationPermissionService, selectDisplayPurchaseLocationPermission);
  const hasPermission = useSelector(locationPermissionService, selectHasPermission);
  const isCheckingLocationPermission = useSelector(locationPermissionService, selectIsCheckingLocationPermission);

  // Display the error if the user tries to do a search on a location they don't have permission for
  const handleOnClick = () => {
    if (!hasPermission && allowClick) { send('DISPLAY_PURCHASE_LOCATION_PERMISSION'); }

    if (hasPermission && allowClick) { onVerified(); }
  };

  return (

    <>
      {
        isCheckingLocationPermission
          ? <S.Button
            {...{ variant, allowClick: false }}
            onClick={handleOnClick}
          >
            {'Checking'}
          </S.Button>
          : <></>

      }
      {
        !isCheckingLocationPermission

          ? <S.Button
              {...{ variant, allowClick }}
              onClick={handleOnClick}
              >
              {text}
            </S.Button>
          : <></>

      }
      {
        displayPurchaseLocationPermission
          ? <PurchaseLocationPermission {...{ locationPermissionService }}/>
          : <></>
      }

    </>
  );
};

export default PermissionProtectedSearch;
