import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '3rem',
    height: '2.7rem',

    border: `1px solid ${theme.palette.neutral.dark}`,
    boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',
    borderRadius: '10px',
    backgroundColor: 'white',

    color: '#202020',
    fontSize: '0.875rem',

    '&:hover': {
      transform: 'scale(1.05)',
      backgroundColor: '#FF4879',
      boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',
      color: 'white',
    },

    '&.clicked': {
      backgroundColor: '#FF4879',
      boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',
      border: 'none',
      color: 'white',
    },
  },

  line: {

    height: '60%',
    outline: `1px solid ${theme.palette.neutral.main}`,

  },

  'icon-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',

    '&.count': {
      marginRight: '0.4rem',
    },

  },

  icon: {
    width: '1.26rem',
    height: '1.26rem',
    color: ' #FF4879',

    '&.hover': {
      color: 'white',
    },

    '&.clicked': {
      color: 'white',
    },
  },

  count: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'absolute',

    bottom: '0.55rem',
    left: '0.8rem',

    width: '0.8rem',
    height: '0.8rem',

    padding: '0.06rem',

    background: '#FF4879',
    border: '1px solid white',
    borderRadius: '50%',

    color: 'white',
    fontSize: '0.6rem',

    '&.hover': {
      background: 'white',
      color: '#FF4879',
      border: '1px solid #FF4879',
    },

    '&.clicked': {
      background: 'white',
      color: '#FF4879',
      border: '1px solid #FF4879',
    },

  },

}));

export default useStyles;
