import { makeStyles } from '@material-ui/core/styles';
import background from '../../assets/404page.png';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    gridTemplateRows: '1fr 1fr 1fr 1fr',
    overflow: 'hidden',

    width: '100vw',
    height: '100vh',

    gridTemplateAreas:
        `
            '. .'
            'text .'
            'button .'
            '. .'
        `,

    '&::before': {
      position: 'absolute',

      content: '""',
      // backgroundImage: `url(${background})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      backgroundPosition: 'center',

      height: '100%',
      width: '100%',
      mixBlendMode: 'normal',

      opacity: 1,
    },
  },

  button: {
    gridArea: 'button',
    justifySelf: 'center',

    margin: '2rem',

    minWidth: '9rem',

    border: 'none',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,

    '&:hover': {
      transform: 'scale(1.04)',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral.light,

    },
  },
}));

export default useStyles;
