import { FunctionComponent, useState, useCallback } from 'react';
import Chip from '@material-ui/core/Chip';
import clsx from 'clsx';
import { createPriceLabel } from '@utils';
import { ListingWithID } from '@types';
import { ListingType } from 'beiytak_sdk';
import SelectedListingMarker from './SelectedListingMarker';
import useStyles from './ListingMarker.styles';

interface MarkerProps {
  /** The listing data */
  listing: ListingWithID,
  /** Is the listing selected? if yes then render it with marker details */
  isSelected?: boolean,
  /** Is the listing favorited? If so color it different */
  isFavorited: boolean,
  /** Is the listing being hovered in the results list */
  isHovered: boolean,
}

/**
 * A marker that represents a listing on the map.
 * When the listing is clicked on, details are displayed about the listing
 */
const ListingMarker: FunctionComponent<MarkerProps> = ({
  listing,
  isSelected,
  isFavorited,
  isHovered,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState(isSelected || false);
  const { price, id, source } = listing;
  const { type } = source;
  const label = createPriceLabel(price, type || ListingType.Sale);

  const handleOnClick = useCallback(() => {
    const isTheListingSelected = !selected;
    setSelected(isTheListingSelected);
  }, []);

  return (
    <div
      className={classes.container}
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      role="button"
      tabIndex={0}
    >

      {selected
        ? <SelectedListingMarker {...listing} />
        : (
          <Chip
            key={id}
            label={label}
            classes={{
              root: clsx({
                [classes.marker]: true,
                [classes.favorited]: isFavorited,
                [classes.hovered]: isHovered,
              }),
            }}
          />
        )}
    </div>

  );
};

export default ListingMarker;
