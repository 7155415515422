import { ListingType, Maybe } from 'beiytak_sdk';
import { StepKeys, HomeCriteriaKeys } from '@types';
import { startCase } from 'lodash';
import { priceDefaultValue, rentPriceDefaultValue, bedroomsDefaultValue, bathroomsDefaultValue } from '@stores';

/** Get the name to display for the filter based on the type of filter */
const getFilterName = (filter: HomeCriteriaKeys): string => {
  let name = '';

  if (filter === HomeCriteriaKeys.PRICE) { name = 'Price'; }
  if (filter === HomeCriteriaKeys.BEDROOMS) { name = 'Bedrooms'; }
  if (filter === HomeCriteriaKeys.BATHROOMS) { name = 'Bathrooms'; }

  return name;
};

/**
 * Formats the price provided to a label
 */
const createPriceLabel = (
  /** Price to format */
  price: number | null | undefined,
  /** If its either a house for sale = "sale" or for rent = "rent" */
  type: ListingType,
) => {
  let label: string = '$NA';

  if (price !== null && price !== undefined) {
    if (type === ListingType.Sale) {
      const priceRounded = Math.round(price / 1000);
      if (priceRounded < 1000) { label = `$${priceRounded.toLocaleString()}K`; }
      if (priceRounded >= 1000) { label = `$${(priceRounded / 1000).toFixed(2)}M`; } // Million+
    }
    if (type === ListingType.Rent) {
      const priceRounded = Math.round(price);
      label = `$${Math.round(priceRounded).toLocaleString()}`;
    }
  }

  return label;
};

/**
 * Formats the commute time
 */
const createCommuteLabel = (
  commute: number,
  /** 'include' or 'exclude' min value from label */
  type: 'include' | 'exclude',
) => {
  const label = type === 'include' ? `${Math.round(commute)} min` : `${Math.round(commute)}`;
  return label;
};

/**
 * Create label for the filter chip
 * @function
 */
const createFilterChipLabel = (
  /** ID of the step */
  key: StepKeys | HomeCriteriaKeys | string,

  /** type of listing so its formatted correctly */
  // @TODO this should be optional fix this
  type: ListingType,

  /** Data to format */
  data: Maybe<number>[] | number[] | string | null,
): string => {
  let label: string = key;

  // Format the location detail
  if (key === StepKeys.LOCATION) { label = data?.toString() || key; }

  if (key === StepKeys.MORE) { label = 'More'; }

  if (key === StepKeys.USER_LOCATIONS) { label = 'Places of Interest'; }

  if (key === StepKeys.SAVED_SEARCHES) { label = 'Saved Searches'; }

  if (key === StepKeys.PREMIUM) { label = 'Premium'; }

  // Format details related to the home criteria
  if (key in (HomeCriteriaKeys) && data && Symbol.iterator in Object(data)) {
    // Format the results of the filter data is available
    const [min, max] = data;

    // If min and max are the same, only display one
    if (min === max) {
      if (key === HomeCriteriaKeys.PRICE) {
        label = createPriceLabel(Number(min), type);
      } else {
        label = `${min}`;
      }
    }

    // If they are not equal, display the range
    // @TODO logic below is ugly
    if (min !== max) {
      if (key === HomeCriteriaKeys.PRICE) {
        const maxPriceDefault = type === ListingType.Sale ? priceDefaultValue[1] : rentPriceDefaultValue[1];
        label = `${createPriceLabel(Number(min), type)} - ${max ? createPriceLabel(Number(max), type || ListingType.Sale) : createPriceLabel(Number(maxPriceDefault), type || ListingType.Sale)}${Number(max) === maxPriceDefault || !max ? '+' : ''}`;
      } else {
        const maxDefault = key === HomeCriteriaKeys.BEDROOMS ? bedroomsDefaultValue[1] : bathroomsDefaultValue[1];
        label = `${min} - ${max || maxDefault}${max === maxDefault || !max ? '+' : ''}`;
      }
    }
  }

  return label;
};

const createFeatureLabel = (feature: string): string => startCase(feature.replaceAll('_', ' '));

export {
  getFilterName, createFilterChipLabel,
  createPriceLabel, createCommuteLabel,
  createFeatureLabel,
};
