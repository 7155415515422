import { FC, useState } from 'react';
import { Plans } from 'beiytak_sdk';
import { useAppSelector } from '@hooks';
import { selectUserSearchPlanId } from '@stores';
import { handleContactUs } from '@utils';
import * as S from './FreeTrialWarning.styled';

const FreeTrialWarning: FC = () => {
  const [close, setClose] = useState(false);
  const planId = useAppSelector(selectUserSearchPlanId);
  const hide = close || planId !== Plans.FREE_TRIAL;

  return (
    <>
        {
        hide
          ? <></>
          : <S.Container>
                <S.PlaceHolderContainer />
                <S.ContentContainer>
                        <S.Text>
                          <S.Highlight>Results are limited to only a few listings!</S.Highlight>
                        </S.Text>
                        <S.Contact onClick={() => handleContactUs()}>
                            Contact us
                        </S.Contact>
                        <S.Text>
                            to get full access to this location 🎉
                        </S.Text>
                </S.ContentContainer>

                <S.Close onClick={() => setClose(true)}>
                    x
                </S.Close>
            </S.Container>
    }
    </>
  );
};

export default FreeTrialWarning;
