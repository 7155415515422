import { FunctionComponent } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks';
import { useActor } from '@xstate/react';
import { userRequestingToLogin, selectAuthStatus } from '@stores';
import { UserLoginInfo, CreateAccountService } from '@types';
import useStyles from './LoginForm.styles';

interface LoginFormProps {
  createAccountService: CreateAccountService,
}

const LoginForm: FunctionComponent<LoginFormProps> = ({ createAccountService }) => {
  const classes = useStyles();
  const { loginAttempted, isAuthenticated } = useAppSelector(selectAuthStatus);
  const [, send] = useActor(createAccountService);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCreateAccount = () => send({ type: 'STARTED_SIGN_UP', payload: { email: '' } });

  // Validation schema for login info
  const validationSchema = yup.object({
    email: yup.string()
      .email('Invalid email address')
      .required('Required'),
    password: yup.string()
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // prepare payload
      const payload: UserLoginInfo = {
        ...values,
        navigate,
      };
      // Make sure no requests are sent unless these a0re actually filed out
      if (values.email !== '' && values.password !== '') { dispatch(userRequestingToLogin(payload)); }
    },
  });

  // If there is an input error
  const inputError = formik.errors.email && formik.errors.password;

  // If the form is ready to submit
  const readyToSubmit = !formik.errors.email && formik.values.email && !formik.errors.password && formik.values.password;

  // If the user tried logging in but failed - adding in the formik checks since the app tries to verify the user on page load using the token
  const loginFailed = loginAttempted && !isAuthenticated && formik.touched.email && formik.touched.password;

  return (
    <div className={classes.container}>

      { loginFailed
        ? (
          <div className={classes.error}>
            Email or password is incorrect
          </div>
        )
        : <></>}

      <form
        className={classes.form}
        onSubmit={formik.handleSubmit}
      >
        <TextField
          fullWidth
          classes={{
            root: classes.input,
          }}
          id="email"
          name="email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
          variant="outlined"
        />

        <TextField
          fullWidth
          classes={{
            root: classes.input,
          }}
          id="password"
          name="password"
          label="Password"
          type="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.errors.password)}
          helperText={formik.errors.password}
          variant="outlined"
        />
        <div className={classes.create} onClick={handleCreateAccount}>
          Don&apos;t have an account?
          <span className={classes.highlight}> Create one here</span>
        </div>

        <Button
          classes={{
            root: clsx({
              [classes.button]: true,
              error: inputError,
              submit: readyToSubmit,
            }),
          }}
          fullWidth
          type="submit"
        >
          Submit
        </Button>

      </form>
    </div>
  );
};

export default LoginForm;
