import React, { FunctionComponent } from 'react';
import HomeIcon from '@material-ui/icons/Home';
import clsx from 'clsx';
import { createPriceLabel } from '@utils';
import { ListingWithID } from '@types';
import { ListingHomeCriteria } from '@components';

import { ListingType } from 'beiytak_sdk';
import useStyles from './SelectedListingMarker.styles';

/**
 * This marker is displayed when a listing selected either from the result list
 * or on the map
 */
const SelectedListingMarker: FunctionComponent<ListingWithID> = (listing) => {
  const classes = useStyles();
  const { source, address, price } = listing;
  const { type } = source;
  const priceLabel = createPriceLabel(price, type || ListingType.Sale);

  return (
      <button className={classes.container}>

  <div className={clsx(classes['icon-container'])}>
      <HomeIcon className={classes.icon} />
      <span>{priceLabel}</span>
  </div>

  <div className={classes['content-container']}>
      <div className={classes.title}>
        <ListingHomeCriteria {...listing}/>
      </div>
      <span className={classes.subtitle}>{address}</span>
  </div>

  </button>
  );
};
export default SelectedListingMarker;
