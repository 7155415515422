import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus, Df } from '@types';
import { RootState } from 'stores';
import { omit } from 'lodash';
import { SavedSearch, SavedSearchStatus } from 'beiytak_sdk';
import { getListingsFromSavedSearches, formatSavedSearchResults } from './services';
import {
  SavedSearchesSlice,
  UserRequestingToGoToSavedSearchParams,
  UpdatingSavedSearchStatusParams,
} from './types';

const initialState: SavedSearchesSlice = {
  savedSearchRequestStatus: null,
  savedSearches: null,
  listingsFromSearches: [],
  listingSelectedFromSavedSearch: null,
  savedSearchIdForListingSelected: null,
};

export const savedSearchesSlice = createSlice({
  name: 'savedSearches',
  initialState,
  reducers: {

    /** Updates the status of retrieving the user searches */
    updatingSavedSearchesRequestStatus: (state, action: PayloadAction<RequestStatus | null>) => {
      const { payload: status } = action;
      state.savedSearchRequestStatus = status;
    },

    /** Updates the saved searches returned */
    setSavedSearches: (state, action: PayloadAction<SavedSearch[] | null>) => {
      const { payload: savedSearches } = action;

      // Update the state with the listings from the saved searches if available
      if (savedSearches) {
        const listings = getListingsFromSavedSearches(savedSearches);
        const formattedSavedSearches = formatSavedSearchResults(savedSearches);

        state.savedSearches = formattedSavedSearches;
        state.listingsFromSearches = listings;
      }

      // If no results were returned, set the states back to defaults
      if (!savedSearches) {
        state.listingsFromSearches = [];
        state.savedSearches = null;
      }
    },

    /** Updates the user search status when the user selects the action from the dropdown */
    updatingSavedSearchStatus: (state, action: PayloadAction<UpdatingSavedSearchStatusParams>) => {
      const { searchId, updatedStatus } = action.payload;
      const { savedSearches } = state;

      if (savedSearches) {
        if (updatedStatus === SavedSearchStatus.Deleted) {
          const updatedSavedSearches = omit(savedSearches, searchId);
          state.savedSearches = updatedSavedSearches;
        }

        if (updatedStatus !== SavedSearchStatus.Deleted) {
          const savedSearch = savedSearches[searchId];
          const updateSavedSearch = { ...savedSearch, status: updatedStatus };

          savedSearches[searchId] = updateSavedSearch;

          state.savedSearches = savedSearches;
        }
      }
    },

    /** Updates an existing saved search that has already been retrieved from the backend */
    updatingSavedSearchInState: (state, action: PayloadAction<SavedSearch>) => {
      const userSearchEntry = action.payload;
      const { searchId } = userSearchEntry;

      // Update the data for the user search
      if (state.savedSearches) { state.savedSearches[searchId] = userSearchEntry; }
    },

    /** Stores the address of the listing the user selected from the table */
    updatingSelectedListingFromSavedSearchDataTable: (state, action: PayloadAction<string | null>) => {
      const { payload: selectedListing } = action;
      state.listingSelectedFromSavedSearch = selectedListing;
    },

    /** Stores the search id from where the selected listing came from */
    updatingSavedSearchForListingSelected: (state, action: PayloadAction<string | null>) => {
      const { payload: searchId } = action;
      state.savedSearchIdForListingSelected = searchId;
    },

    /** When the user clicks to go to see a saved search */
    userRequestingToGoToSavedSearch: (state, action: PayloadAction<UserRequestingToGoToSavedSearchParams>) => {

    },

  },
});

export const selectListingSelectedFromSavedSearch = (state: RootState) => state.savedSearches.listingSelectedFromSavedSearch;

export const selectSavedSearchIdForListingSelected = (state: RootState) => state.savedSearches.savedSearchIdForListingSelected;

export const selectedSavedSearches = (state: RootState) => state.savedSearches.savedSearches;

export const {
  setSavedSearches,
  updatingSelectedListingFromSavedSearchDataTable,
  updatingSavedSearchesRequestStatus,
  userRequestingToGoToSavedSearch,
  updatingSavedSearchStatus,
  updatingSavedSearchInState,
  updatingSavedSearchForListingSelected,
} = savedSearchesSlice.actions;

export default savedSearchesSlice.reducer;
