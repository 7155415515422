import { get } from 'lodash';
import { devices } from './mediaQueries';
import { palette } from './palette';
import { border } from './radius';
import { constants } from './constants';

const themeSelection = 'light';

const lightTheme = {
  palette,
  devices,
  constants,
  border,
};

const themes = {
  light: lightTheme,
};

// just to show support for other theme formats
export const theme = themes[themeSelection];

export type Theme = typeof theme;

type NestedKeyOf<ObjectType extends object> =
{
  [Key in keyof ObjectType & (string | number)]: ObjectType[Key] extends object
  // @ts-ignore
  ? `${Key}` | `${Key}.${NestedKeyOf<ObjectType[Key]>}`
  : `${Key}`
}[keyof ObjectType & (string | number)];

// Helper to utilize the theme in styled components
// example usage:
// color: ${$theme('palette.primary.main')}
export const $theme = (key: Exclude<NestedKeyOf<Theme>, keyof Theme>) => {
  const value = get(theme, key);

  return value;
};

export * from './mediaQueries';
export * from './palette';
export * from './constants';
export default theme;
