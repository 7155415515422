import styled from 'styled-components';
import { devices } from '@styleGuide';

export const Container = styled.div`
    display: grid;
    grid-template-areas:
    "content"
    ;
    place-items: center;
    position: relative;

    width: 100vw;
    height: 100vh;

    background: #FFFFFF;

`;

export const ContentContainer = styled.div`
    grid-area: content;

    width: 95%;
    max-width: 345px;
    height: 100%;
    max-height: 550px;

    box-shadow: 0 24px 64px #26214a1a;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: none;


    @media (${devices.laptopAndAbove}) {
        max-width: 480px;
        max-height: 550px;
    }
`;

export const LoginContainer = styled.div`
    margin-top: 2rem;
    margin-bottom: 2rem;
`;
