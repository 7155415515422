import { FunctionComponent } from 'react';
import clsx from 'clsx';
import useStyles from './ListOptions.styles';
import Favorites from './Favorites';
import SortBy from './SortBy';

interface ListingOptionsProps {
  disableFavorites?: boolean,
  disableSortBy?: boolean,
}

const ListOptions: FunctionComponent<ListingOptionsProps> = (options) => {
  const classes = useStyles();
  const { disableFavorites, disableSortBy } = options;

  return (
    <div className={clsx({
      [classes.container]: true,
      'all-options': !disableFavorites && !disableSortBy,
      'favorites-only': !disableFavorites && disableSortBy,
      'sort-only': disableFavorites && !disableSortBy,
    })}>
      {
      !disableFavorites
        ? <Favorites />
        : <></>
      }

      {
      !disableSortBy
        ? <SortBy />
        : <></>
      }
    </div>
  );
};

export default ListOptions;
