import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Routes, updateSavedSearch as updateSavedSearchSdk,
  UpdateSavedSearch,
} from 'beiytak_sdk';
import { getAPIUrl } from '@utils';
import util from 'util';

const UpdateSavedSearchAction = 'savedSearches/updateSavedSearch';

/** Updates a saved search */
const updateSavedSearch = createAsyncThunk(
  UpdateSavedSearchAction,
  async (params: UpdateSavedSearch): Promise<string> => {
    const url = getAPIUrl(Routes.GRAPHQL);

    const searchId = await updateSavedSearchSdk({ params }, url).then((res) => {
      const { data } = res;

      if (data) { return data; }

      return Promise.reject();
    });

    return searchId;
  },
);

export { updateSavedSearch, UpdateSavedSearchAction };
