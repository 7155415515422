/** Central function for contacting support/sales */
export const handleContactUs = (location?: string) => {
  if (!location) {
    window.location.href = 'mailto:support@findur.io?subject=Purchase access to a location';
  }

  if (location) {
    window.location.href = `mailto:support@findur.io?subject=Purchase access to ${location}`;
  }
};
