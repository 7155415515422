import { FunctionComponent, useState, useEffect } from 'react';
import { IconButton } from '@material-ui/core';
import { Maybe } from 'graphql/jsutils/Maybe';
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { useAppDispatch } from '@hooks';
import { selectedListingIsChanging, userScrollingThroughPhotos } from '@stores';
import useStyles from './Images.styles';
import imagePlaceholder from '../../../assets/image_placeholder.png';
import { ImageType } from './types';

enum ImageAction {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
}

interface ImagesProps {
    images: Maybe<Maybe<string>[]> | Maybe<string[]> | string[] | null | undefined,
    /** ID of the listing */
    id?: string,
    /** Is the image for a "card" view or "full" view - this helps place the arrows correctly */
    type: ImageType
}

/**
 * This component is used as an image reel to show the images of the listings
 * @component
 */
const Images: FunctionComponent<ImagesProps> = ({ images, id, type }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [imageIndex, setImageIndex] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [hover, setHover] = useState(false);
  const [requestedMorePhotos, setRequestedMorePhotos] = useState(false);

  const imageToDisplay = images ? images[imageIndex] : null;
  const numberOfPhotos = images ? images?.length - 1 : 0; // so its in the same units as index
  const displayRightArrow = imageToDisplay && images && images.length > 0 && (hover || clicked) && (imageIndex + 1 <= numberOfPhotos);
  const displayLeftArrow = imageToDisplay && images && images.length > 0 && (hover || clicked) && (imageIndex - 1 >= 0);

  // Emit event when a specific listing is selected
  const handleOnClickListing = () => {
    setClicked(!clicked);
    if (id) { dispatch(selectedListingIsChanging(id)); }
  };

  const handleOnClickImageIndex = (action: ImageAction) => {
    if (images) {
      if (action === ImageAction.NEXT && imageIndex + 1 <= numberOfPhotos) { return setImageIndex(imageIndex + 1); }
      if (action === ImageAction.PREVIOUS && imageIndex - 1 >= 0) { return setImageIndex(imageIndex - 1); }

      return setImageIndex(0);
    }

    return null;
  };

  // Dispatch action to get more photos if there is a low photo count
  // Do this on render so user doesn't have to wait for photos or seem like there isn't any
  useEffect(() => {
    if (numberOfPhotos < 5 && id) {
      dispatch(userScrollingThroughPhotos(id));
      setRequestedMorePhotos(true);
    }
  }, [setRequestedMorePhotos, id]);

  return (

    <div
      className={clsx({
        [classes.container]: true,
        rightOnly: displayRightArrow && !displayLeftArrow,
        leftOnly: !displayRightArrow && displayLeftArrow,
      })}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >

      {displayLeftArrow
        ? (
          <IconButton
           classes={{ root: classes['arrow-button'] }}
           className={clsx({
             left: true,
             card: type === ImageType.CARD,
             full: type === ImageType.FULL,
           })}
           onClick={() => handleOnClickImageIndex(ImageAction.PREVIOUS)}
          >
            <KeyboardArrowLeftRounded aria-label="previous-image" className={classes.icon} />
          </IconButton>
        )
        : <></>}

      {displayRightArrow
        ? (
          <IconButton
          classes={{ root: classes['arrow-button'] }}
          className={clsx({
            right: true,
            card: type === ImageType.CARD,
            full: type === ImageType.FULL,
          })}
          onClick={() => handleOnClickImageIndex(ImageAction.NEXT)}
          >
            <KeyboardArrowRightRounded aria-label="next-image" className={classes.icon} />
          </IconButton>

        )
        : <></>}

      <img
        src={imageToDisplay || imagePlaceholder}
        alt=""
        aria-label="image"
        className={classes.image}
        onClick={handleOnClickListing}
        onKeyPress={handleOnClickListing}
        role="presentation"
      >
      </img>

    </div>

  );
};

export default Images;
