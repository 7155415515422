import {
  Favorite, SavedSearch, HomeCriteria, Maybe, Listing,
} from 'beiytak_sdk';
import {
  Df, HomeCriteriaFilters, HomeCriteriaKeys, FilterValue,
} from '@types';
import {
  priceDefaultValue,
  bathroomsDefaultValue,
  bedroomsDefaultValue,
} from '@constants';

/** Sorts the saved searches by alphabetical order */
const sortSavedSearchesByAlphabeticalOrder = (list: SavedSearch[]): SavedSearch[] => {
  const sortedList = list.sort((a, b) => a.searchName.toLocaleLowerCase().localeCompare(b.searchName.toLowerCase()));

  return sortedList;
};

/**
 * Gets the sources from a users favorites in to save the search
 * @TODO hate the naming of all the variables below
 */
const getSourceInfoFromFavorites = (favorites: string[], userSearchResults: Df, savedSearch?: SavedSearch): Favorite[] => {
  const favoriteSourceInfo: Favorite[] = [];
  const favoriteListings: {[favorite: string]: Listing} = {};

  if (savedSearch) {
    const { favorites: savedSearchFavorites } = savedSearch;

    // create an object to easily get favorite search detail if needed so we don't loop over this multiple times below
    savedSearchFavorites.forEach((savedSearchFavorite) => {
      const { listing } = savedSearchFavorite;
      const { address } = listing;

      favoriteListings[address] = listing;
    });
  }

  favorites.forEach((favoriteListing) => {
    // If the favorite is part of the search result, use that data
    if (userSearchResults[favoriteListing]) {
      const { listing } = userSearchResults[favoriteListing];
      const { address, source: { modelId, propertyId } } = listing;

      const favorite: Favorite = {
        address,
        sources: [{ modelId, propertyId }],
      };

      favoriteSourceInfo.push(favorite);
    }

    // if note then ref the  data from the saved search
    if (!userSearchResults[favoriteListing] && savedSearch) {
      const listing = favoriteListings[favoriteListing];
      const { address, source } = listing;
      const favorite: Favorite = {
        address,
        sources: [source],
      };

      favoriteSourceInfo.push(favorite);
    }
  });

  return favoriteSourceInfo;
};

/**
 * Takes the array used to save a search and converts it to a filter value
 * @TODO pretty sure I have this somewhere else
 */
const createHomeCriteriaFilter = (value: Maybe<number>[], defaultValue: number[]): FilterValue => {
  const minValue = value[0];
  const maxValue = value[1];

  const filterValue: FilterValue = [minValue || defaultValue[0], maxValue || defaultValue[1]];

  return filterValue;
};

/**
 * Converts the homeCriteria input from a saved search into a homeCriteria Filter object
 */
const getHomeCriteriaFiltersFromSavedSearch = (homeCriteria: HomeCriteria): HomeCriteriaFilters => {
  const { price, bathrooms, bedrooms } = homeCriteria;

  const homeCriteriaFilters: HomeCriteriaFilters = {
    [HomeCriteriaKeys.PRICE]: createHomeCriteriaFilter(price, priceDefaultValue),
    [HomeCriteriaKeys.BEDROOMS]: createHomeCriteriaFilter(bedrooms, bedroomsDefaultValue),
    [HomeCriteriaKeys.BATHROOMS]: createHomeCriteriaFilter(bathrooms, bathroomsDefaultValue),
  };

  return homeCriteriaFilters;
};

// eslint-disable-next-line
export {
  sortSavedSearchesByAlphabeticalOrder,
  getSourceInfoFromFavorites,
  getHomeCriteriaFiltersFromSavedSearch,
};
