import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin-top: 1rem;

    padding-top: 0.375rem;
    padding-right: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.8125rem;

    width: 93%;
    height: 3.75rem;
    max-height: 68px;

    border-radius: 8px;
    background-color: #F4F4F4;
`;

export const Input = styled.input`

    width: 100%;
    max-width: 350px;

    border: none;
    outline: none;
    background-color: transparent;

    font-weight: 500;
    font-size: 1rem;
    line-height: 1.875rem;

    color: #202020;

    &::placeholder {
        color: #888888;
    }
`;

export const Button = styled.button`
    width: 9.375rem;
    max-width: 150px;
    height: 3rem;
    max-height: 48px;

    border: none;
    border-radius: 9px;
    box-shadow: 0 10px 25px rgb(255 115 72 / 30%);
    color: white;
    background: #FF7348;

    color: white;
    font-weight: 600;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.875rem;

    &:hover {
        background: #cc5c3a
    }

    @media (${$theme('devices.tabletAndBelow')}) {
        font-size: 0.9rem;
        white-space: nowrap;
    }
`;
