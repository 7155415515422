import { SavedSearchStatus } from 'beiytak_sdk';

/** Returns whether the search status is present in the status requests */
const filterSearchStatus = (searchStatus: SavedSearchStatus, statuses: SavedSearchStatus[]): boolean => {
  // If no statuses are selected, filter everything out
  if (statuses.length === 0) { return false; }

  return statuses.includes(searchStatus);
};

export default filterSearchStatus;
