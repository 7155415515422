import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks';
import { ErrorLayout } from '@layouts';
import { updatingInterScreenState, selectDisplayRetryScreen } from '@stores';
import { Routes } from '@types';
import useStyles from './RetryScreen.styles';

// @TODO Make retry its own screen when there is time
const RetryScreen: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const displayScreen = useAppSelector(selectDisplayRetryScreen);

  useEffect(() => {
    return () => {
      dispatch(updatingInterScreenState({ route: Routes.RETRY, value: false }));
    };
  }, []);

  return (
    displayScreen
      ? (
        <div className={classes.container}>
          <ErrorLayout />
        </div>
      )
      : <></>
  );
};

export default RetryScreen;
