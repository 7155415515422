import { useState, useEffect, useCallback } from 'react';
import { throttle } from 'lodash';
import { getGooglePredictions } from '@services';
import { userChangedUserLocation, locationInputChanged } from '@stores';
import { AutoCompleteOptions, PredictionType } from '@types';
import { preparePayloadFromAutoCompleteSelection } from '@utils';
import { useAppDispatch } from '.';

enum InputChangeType {
  INPUT = 'input',
  SELECTION = 'selection'
}

interface Values {
  autoCompleteInput: string,
  autoCompleteOptions: AutoCompleteOptions[]
}

interface Handlers {
  /** Updates the autocomplete predictions for a user input change */
  update: (input: string) => void,
  /**  Handles when the user selections one of the options from the dropdown */
  selected: (input: string) => void,
  /** Handles the action when the user clears out their search or selection */
  reset: () => void,
  /** handles when the user input changes */
  onChange: (event: any, newInputValue: any) => void,
  /** Handles when a selection is made  */
  onSelection: (event: any, newInputValue: any) => void,
}

/**
 * Custom hook to handle local state for the autocomplete mui dropdown's
 * @param location narrow down the predictions to a specific area/location
 */
const useAutoCompletePredictions = (type: PredictionType, location?: string | null): [Values, Handlers] => {
  const dispatch = useAppDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const [autoCompleteInput, setAutoCompleteInput] = useState('');
  const [previousInput, setPreviousInput] = useState('');
  const [optionSelected, setOptionSelected] = useState(false);
  const [autoCompleteOptions, setAutoCompleteOptions] = useState<AutoCompleteOptions[]>([]);
  const predictionCountLimit = 5;

  const action = type === PredictionType.LOCATION ? locationInputChanged : userChangedUserLocation;
  const getOptions = autoCompleteInput !== ''
  && autoCompleteInput.length >= 3
  && typeof autoCompleteInput !== 'undefined'
  && previousInput !== autoCompleteInput
  && isMounted
  && !optionSelected;

  const update = (input: string) => {
    setPreviousInput(autoCompleteInput);
    setAutoCompleteInput(input);
    setOptionSelected(false);
  };

  const selected = (input: string) => {
    setPreviousInput(autoCompleteInput);
    setAutoCompleteInput(input);
    setOptionSelected(true);
  };

  const reset = () => {
    setPreviousInput(previousInput);
    setAutoCompleteInput('');
    setAutoCompleteOptions([]);
    setOptionSelected(false);
  };

  function handleChange(inputChangeType: InputChangeType) {
    function handle(event: any, newInputValue: any) {
      const input = preparePayloadFromAutoCompleteSelection(newInputValue);

      // If the user deletes there selection or clears it out update the global state to reflect that
      if (input === '') {
        reset();
        dispatch(action(input));
      }

      // Update the value if the input changed
      if (input !== '') { update(input); }

      // If the user made a selection, updated the state as well
      if (inputChangeType === InputChangeType.SELECTION) {
        selected(input);
        dispatch(action(input));
      }
    }

    return handle;
  }

  const requestPredictions = useCallback(throttle(async (autoCompleteInput: string) => {
    const predictions = await getGooglePredictions(autoCompleteInput.trim(), type, location);

    if (predictions) {
      // Remove this option from the search so that users click on "coffee shop" instead of "coffee shop in pearland,tx"
      const filteredPredictions = predictions
        .filter((prediction) => !prediction.value.includes('near'))
        .filter((prediction) => prediction.value !== location)
        .slice(0, predictionCountLimit);

      setAutoCompleteOptions(filteredPredictions);
    }
  }, 500), []);

  const handlers: Handlers = {
    update,
    selected,
    reset,
    onChange: handleChange(InputChangeType.INPUT),
    onSelection: handleChange(InputChangeType.SELECTION),
  };

  const values: Values = {
    autoCompleteInput,
    autoCompleteOptions,
  };

  /**
   * Tracks if the component is mounted or not so that the other side effects only
   * attempt to update local state if the component is mounted
   */
  useEffect(() => {
    setIsMounted(true);
    return () => { setIsMounted(false); };
  }, [isMounted, setIsMounted]);

  /**
   * Gets the Google predictions when the user input changes
   * The function checks whether the user input is in the list
   * If its not, it will add it to the results.
   * This handles the case when Google doesn't pick up on the name of the place exactly
   * as specified by the user.
   */
  useEffect(() => {
    if (getOptions) { requestPredictions(autoCompleteInput); }
  }, [autoCompleteInput, getOptions, isMounted]);

  return [values, handlers];
};

export default useAutoCompletePredictions;
