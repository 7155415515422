import React from 'react';
import clsx from 'clsx';
import useStyles from './SliderThumb.styles';

/** Thumb for the slider */
const SliderThumb: React.FC = (muiProps: any) => {
  const classes = useStyles();

  return (
    <span {...muiProps}>
    <div className={classes.thumb}>
      <span className={clsx(classes.bar, 'left')} />
      <span className={clsx(classes.bar, 'right')} />
    </div>
  </span>
  );
};

export default SliderThumb;
