import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '../../../constants';

const offsetFromTopNavBar = '6rem';

const useStyles = makeStyles((theme) => ({

  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: topNavBarHeight,
  },

  'filter-chip-container': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    whiteSpace: 'nowrap',

    height: '100%',
  },

  location: {
    margin: '1.25rem 0.625rem 1.25rem 0.625rem',
  },

  'filter-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    position: 'absolute',
    top: offsetFromTopNavBar,
    zIndex: 2,

    width: '47em',
    maxWidth: '784px',

    borderRadius: '15px',
    boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',

    '&.homeCriteriaFilter': {
      backgroundColor: theme.palette.neutral.light,
      height: '8rem',
    },

    '&.userLocationsFilter': {
      top: offsetFromTopNavBar,
    },

    '&.moreFilter': {
      top: offsetFromTopNavBar,
    },

  },

  tint: {
    position: 'fixed',
    zIndex: 1,
    width: '100%',
    // top: '5.9rem',
  },

}));

export default useStyles;
