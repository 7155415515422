import { FC, ButtonHTMLAttributes } from 'react';
import { theme } from '@styleGuide';
import * as S from './LandingButton.styled';

export type Variant = keyof typeof theme.palette.neon
interface LandingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
variant: Variant,
text?: string
}

const LandingButton: FC<LandingButtonProps> = ({ variant, text, ...props }) => {
  return (
        <S.Button {...props} variant={variant}>
            {text}
        </S.Button>
  );
};

export default LandingButton;
