import { FeatureOptions } from '@types';
import locationsGif from '../assets/locations.gif';
import filteringGif from '../assets/filtering-results.gif';
import savedSearchesGif from '../assets/saved-searches.gif';

export const featureOptions: FeatureOptions = {
  search: 'Super Easy Search',
  filter: 'No More Copying and Pasting',
  savedSearches: 'Saved Searches',
};

export const searchFeature = {
  gif: locationsGif,
  feature: 'Super Easy Search',
  title: 'Add all your places of interest',
  description: 'Add all your places of interest with your desired commute type. Anything you enter in Google Maps can be entered here from a general place to a specific address.',
};

export const filterResultsFeature = {
  gif: filteringGif,
  feature: 'No More Copying and Pasting',
  title: 'Filter in seconds',
  description: 'Filter listings based on commute times to all your places of interest in seconds. Long gone are the days of manually copying and pasting addresses! Findur saves weeks of manual effort, allowing you to find your next home that much faster.',
};

export const savedSearchesFeature = {
  gif: savedSearchesGif,
  feature: 'Unlimited Saved Searches',
  title: 'Save your searches',
  description: 'Save all your searches and access their details including favorites and commute times in your dashboard at anytime.  You can mix and match buy, rent, and investment searches all in one place. If you are a realtor, you can save a search for each one of your clients and manage them seamlessly.',
};
