import { ListingDetails, SavedSearch } from 'beiytak_sdk';
import { Row, QueryUserSearchInfo, ListingWithID } from '@types';

/**
 * Extracts the listing details and converts them into a row format from the saved searches.
 * If the same listing is found in multiple searches, the results are merged including the available user locations
 */
const getRowsFromSavedSearches = (savedSearches: SavedSearch[]): Row[] => {
  // Object to hold the listings available
  const listings: {[address: string]: Row} = {};

  savedSearches.forEach((savedSearch) => {
    const { favorites } = savedSearch;

    favorites.forEach((favorite) => {
      const { listing, userLocations } = favorite;
      const { address } = listing;
      const existingListing = listings[address];

      // Format the listing to follow the required convention
      const listingWithID: ListingWithID = {
        ...listing,
        id: address,
      };

      // If the existing already exists, merge the user locations
      if (existingListing) {
        const { userLocations: existingUserLocations } = existingListing;
        const updatedUserLocations = existingUserLocations;

        // Add the user locations to the existing listing
        userLocations.forEach((userLocation) => {
          const { userAlias } = userLocation;
          updatedUserLocations[userAlias] = userLocation;
        });

        // Update the listing data with the merged data
        listings[address] = {
          listing: { ...listingWithID },
          userLocations: updatedUserLocations,
        };
      }

      // If the listing isn't already added, add it to the object
      if (!existingListing) {
        // Convert the user locations to be the query type for quicker searching
        const queryUserSearchInfo: QueryUserSearchInfo = {};

        // Convert the user locations to the required query object
        userLocations.forEach((userLocation) => {
          const { userAlias } = userLocation;
          queryUserSearchInfo[userAlias] = userLocation;
        });

        listings[address] = {
          listing: listingWithID,
          userLocations: queryUserSearchInfo,
        };
      }
    });
  });

  const listingRows = Object.keys(listings).map((address) => {
    return listings[address];
  });

  return listingRows;
};

/**
 * Extracts the listing details from the saved searches.
 * If the same listing is found in multiple searches, the results are merged including the available user locations
 */
const getListingsFromSavedSearches = (savedSearches: SavedSearch[]): ListingDetails[] => {
  // Object to hold the listings available
  const listings: {[address: string]: ListingDetails} = {};

  savedSearches.forEach((savedSearch) => {
    const { favorites } = savedSearch;

    favorites.forEach((favorite) => {
      const { listing, userLocations } = favorite;
      const { address } = listing;
      const existingListing = listings[address];

      // If the existing already exists, merge the user locations
      if (existingListing) {
        const { userLocations: existingUserLocations } = existingListing;

        // Update the listing data with the merged data
        listings[address] = {
          listing,
          userLocations: [...existingUserLocations, ...userLocations],
        };
      }

      // If the listing isn't already added, add it to the object
      if (!existingListing) {
        listings[address] = {
          listing,
          userLocations,
        };
      }
    });
  });

  const listingDetails = Object.keys(listings).map((address) => {
    return listings[address];
  });

  return listingDetails;
};

export default getListingsFromSavedSearches;
