import { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Lottie from 'react-lottie';
import { Input, SectionInfo } from '@components';
import { selectIsSigningUpForAccount, selectedFailedToSignUpForAccount, selectEmailForAccount } from '@machines';
import { useActor, useSelector } from '@xstate/react';
import { CreateAccountService } from '@types';
import loadingAnimation from '../../../assets/whiteLoader.json';
import * as S from './CreateAccountForm.styled';

interface CreateAccountFormProps {
  createAccountService: CreateAccountService,
}

const CreateAccountForm: FC<CreateAccountFormProps> = ({ createAccountService }) => {
  const [, send] = useActor(createAccountService);
  const isSigningUp = useSelector(createAccountService, selectIsSigningUpForAccount);
  const failedToSignUp = useSelector(createAccountService, selectedFailedToSignUpForAccount);
  const emailEntered = useSelector(createAccountService, selectEmailForAccount);

  const buttonLabel = failedToSignUp ? 'Try again..' : 'Sign Up';

  const validationSchema = yup.object({
    email: yup.string()
      .email('Invalid email address')
      .required('Required'),
    firstName: yup.string()
      .required('Required'),
    lastName: yup.string()
      .required('Required'),
    password: yup.string()
      .min(7, 'Must be at least 8 characters long')
      .max(20, 'Must be less than 20 characters long')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        'Must contain 1 uppercase and 1 lowercase letter',
      )
      .matches(
        /^(?=.*[!@#$%^&*])/,
        'Must contain 1 special character',
      )
      .matches(/^(?=.{6,20}$)\D*\d/, 'Must contain 1 number')
      .required('Required'),
    confirmPassword: yup.string()
      .oneOf([yup.ref('password')], 'Passwords must match')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues: {
      email: emailEntered || '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Only send the request if we are already not waiting for a response from a previous request
      if (!isSigningUp) {
        const payload = {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
          password: formik.values.password,
        };

        send({ type: 'SIGN_UP', payload });
      }
    },
  });

  return (
        <S.Container>
            <SectionInfo {...{ title: 'Almost there!' }}/>
            <S.Form onSubmit={formik.handleSubmit}>
                <Input
                  label="First Name"
                  id="firstName"
                  name="firstName"
                  placeholder=' John'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.firstName)}
                  errorMessage={formik.errors.firstName}
                />
                <Input
                  label="Last Name"
                  id="lastName"
                  name="lastName"
                  placeholder=' Doe'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.lastName)}
                  errorMessage={formik.errors.lastName}
                />
                <Input
                  label="Email"
                  id="email"
                  name="email"
                  placeholder=' example@site.com'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.email)}
                  errorMessage={formik.errors.email}
                />
                <Input
                  label="Password"
                  id="password"
                  name="password"
                  placeholder=' password'
                  type={'password'}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.password)}
                  errorMessage={formik.errors.password}
                />
                <Input
                  label="Confirm Password"
                  id="confirm-password"
                  name="confirmPassword"
                  placeholder=' password'
                  type={'password'}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.confirmPassword)}
                  errorMessage={formik.errors.confirmPassword}
                />
            </S.Form>
            <S.SubmitButton onClick={formik.submitForm}>
              {
                isSigningUp

                  ? <S.AnimationContainer>
                    <Lottie
                        speed={1}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        options={{
                          animationData: loadingAnimation,
                          autoplay: true,
                        }}
                        isClickToPauseDisabled
                      />
                    </S.AnimationContainer>
                  : `${buttonLabel}`
              }
            </S.SubmitButton>

        </S.Container>
  );
};

export default CreateAccountForm;
