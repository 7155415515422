import { makeStyles } from '@material-ui/core/styles';
import { favoriteColor } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {
    marginTop: '0.75rem',

  },

  'go-to-search-container': {

    marginTop: '0.75rem',
    marginBottom: '0.75rem',
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },

  root: {
    width: '100%',

    border: 'none',
    borderRadius: '10px',
    boxShadow: '0px 5px 15px rgba(32, 32, 32, 0.05)',

  },

  'column-header': {
    '&.MuiDataGrid-columnHeader': {
      '&:focus': {
        outline: 'none',
      },

      '&:focus-within': {
        outline: 'none',
      },
    },
  },

  row: {
    background: theme.palette.neutral.light,

  },

  cell: {
    '&.MuiDataGrid-cell:focus': {
      '&:focus': {
        outline: 'none',
      },

      '&:focus-within': {
        outline: 'none',
      },
    },
  },

  'address-cell': {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    justifyItems: 'center',
  },

  'favorite-icon': {
    marginRight: '0.7rem',
    height: '3rem',
    color: favoriteColor,
  },

  image: {

    marginTop: '0.35rem', // so its centered in the row
    marginRight: '0.75rem',

    width: '3.784rem',
    maxWidth: '60,.55px',

    height: '2.5rem',
    // height: '100%',
    maxHeight: '40px',

    objectFit: 'cover',

  },

  'user-location-column-container': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

  },

  'travel-icon': {

    marginRight: '0.25rem',

    width: '1rem',
    height: '1rem',
  },

}));

export default useStyles;
