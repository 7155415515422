import styled from 'styled-components';
import { $theme, devices } from '@styleGuide';

export const Container = styled.div`
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-template-areas:
    "section-info section-info"
    "use-cases image"
    ;
    place-self: center;
    gap: 1rem;

    width: calc(100vw - 12rem);

    padding-top: 1rem;

    background-color: ${$theme('palette.pastel.blue.light')};
    border-radius: 19px;

    text-align: center;

    @media (${devices.tabletAndBelow}) {

        grid-template-columns: 1fr;
        grid-template-areas:
        "section-info"
        "image"
        "use-cases"
        ;

        gap: 1.5rem;
       width: calc(100vw - 4rem);
        height:  max-content;
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

export const SectionInfoContainer = styled.div`
    grid-area: section-info;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 1rem;
`;

export const ImageContainer = styled.div`
    grid-area: image;
    align-self: start;
    justify-self: start;

    width: 100%;
    height: 98%;
`;

export const Image = styled.img`
    object-fit: contain;
    border-radius: 25px;

    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%
`;

export const ContentContainer = styled.div`
    grid-area: 'use-cases';
    justify-self: left;
    align-self: left;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    gap: 1rem;
    margin-left: 1rem;
`;

export const ContactUsContainer = styled.div`
    grid-area: join;
    justify-self: center;
    align-self: center;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:  center;

    margin-bottom: 1rem;
    margin-left: 2rem;

    width: 100%;

    @media (${$theme('devices.tabletAndBelow')}) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:  center;

        align-self: center;
        margin-left: 0rem;
    }
`;
