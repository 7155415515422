import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;

width: 100%;
`;

export const AdditionalInfo = styled.div`
margin-top: 1rem;
width: 90%;
font-weight: 600;
color: #202020;
`;

export const HighlightText = styled.span`
color: ${$theme('palette.primary.main')}
`;

export const AnimationContainer = styled.div`
width: 100%;
max-width: 300px;
`;
