import { FunctionComponent, useState } from 'react';
import { capitalize } from 'lodash';
import { InformationDetail as InformationDetailType } from '@types';
import useStyles from './InformationDetail.styles';

/**
 * Displays a piece of information of the listing in a formatted way
 */
const InformationDetail: FunctionComponent<InformationDetailType> = (data) => {
  const classes = useStyles();

  const { title, iconSource, details } = data;
  const displayData = details.length > 0;

  return (
    displayData
      ? <div className={classes.container}>
        <img className={classes.icon} src={iconSource} />
        <div className={classes.info}>
            <div className={classes.title}>{title}</div>
            {
              details.map((detail) => {
                const detailName = capitalize(detail.replaceAll('_', ' '));
                return (
                  <div className={classes.detail} key={detailName}>{detailName}</div>
                );
              })
            }
        </div>
    </div>
      : <></>
  );
};

export default InformationDetail;
