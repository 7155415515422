import { FunctionComponent, useState, useEffect } from 'react';
import { cloneDeep } from 'lodash';
import Slider from '@material-ui/core/Slider';
import { selectTypeInput, updateDefaultForHomeCriteriaFilter } from '@stores';
import { useAppDispatch, useAppSelector } from '@hooks';
import { rentPriceDefaultValue, priceDefaultValue } from '@constants';
import { Filter, FilterValue, HomeCriteriaKeys } from '@types';
import { ListingType } from 'beiytak_sdk';
import createMarks from './services';
import ValueHolder from './ValueHolder';
import SliderThumb from './SliderThumb';
import useStyles, { sliderStyle } from './Filter.styles';

interface FilterProps extends Filter {
  /** whether to allow users to edit min/max values by changing values manually */
  disabled: boolean,
}

/** Creates a slider that will update the correct state when the inputs change */
const Filters: FunctionComponent<FilterProps> = (props) => {
  const {
    id,
    name,
    stateRef,
    icon,
    step,
    stepLabel,
    preparePayload,
    action,
    formatter,
    defaultValues,
    disabled,
  } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const defaultValueForSlider = defaultValues();
  const [minDefault, maxDefault] = defaultValueForSlider;
  const currentFilterValues = cloneDeep(stateRef());
  const defaultForLocalState = Array.isArray(currentFilterValues) && currentFilterValues ? currentFilterValues : defaultValueForSlider;
  const [value, setValue] = useState<FilterValue>(defaultForLocalState);
  const Icon = icon();
  const type = useAppSelector(selectTypeInput);
  const [localType, setLocalType] = useState(type);

  // Update the min & max of the slider accounting for the updates the user is making
  const min = minDefault <= value[0] ? minDefault : value[0];
  const max = maxDefault >= value[1] ? maxDefault : value[1];

  const typeChanged = localType !== type;

  // When the value changes, dispatch the action to update the values
  const handleChange = (event: any, newValue: number | number[]) => {
    if (newValue && Array.isArray(newValue)) {
      setValue(newValue);
    }
  };

  /**
    * The component re-renders anytime a filter value changes
    * To avoid dispatching actions twice, its only called during cleanup
    */
  useEffect(() => {
    return () => {
      if (value) { dispatch(action(preparePayload(value, id))); }
    };
  }, [value]);

  /** If the user updates the value using the input value holder, update the state */
  useEffect(() => {
    if (value[0] !== currentFilterValues[0] || value[1] !== currentFilterValues[1]) {
      dispatch(action(preparePayload(value, id)));
    }
  }, [value, currentFilterValues]);

  /**
   * When the listing type changes, reset back to defaults specifically if its price.
   * We have to do it in the filter itself since the slider is the last to re-render after all state changes
   * and will contain the old values of the previous type
   */
  useEffect(() => {
    if (id === HomeCriteriaKeys.PRICE && typeChanged) {
      if (type === ListingType.Sale) {
        dispatch(updateDefaultForHomeCriteriaFilter({ type: HomeCriteriaKeys.PRICE, data: [...priceDefaultValue] }));
        setValue([...priceDefaultValue]);
      }
      if (type === ListingType.Rent) {
        dispatch(updateDefaultForHomeCriteriaFilter({ type: HomeCriteriaKeys.PRICE, data: [...rentPriceDefaultValue] }));
        setValue([...rentPriceDefaultValue]);
      }
    }
  }, [typeChanged, setValue]);

  return (

    <div className={classes.container}>

      <div className={classes['info-container']}>

        { Icon && typeof Icon === 'string'
          ? <img src={Icon} className={classes['filter-icon']} alt="" />
          : <></>
        }
        { Icon && typeof Icon !== 'string'
          ? <Icon className={classes['filter-icon']} />
          : <></>
        }

        <div className={classes['filter-name']}>
          <span aria-label='filter-name'>{name}</span>
        </div>

      </div>

      <div className={classes['slider-container']}>

        <Slider
          key={id}
          classes={{
            root: classes.slider,
            markLabel: classes['slider-label'],
            rail: classes.rail,
            track: classes.track,
            trackFalse: classes['track-false'],
          }}
          value={value}
          onChange={handleChange}
          ThumbComponent={SliderThumb}
          valueLabelDisplay="auto"
          aria-labelledby="discrete-slider-small-steps"
          step={step}
          marks={createMarks(formatter, [min, max], stepLabel)}
          min={min}
          max={max}
          style={sliderStyle}
        />

        <div className={classes['value-container']}>
           <ValueHolder
           {...{
             ...props,
             setFilterValue: setValue,
             currentRange: [min, max],
             disabled,
           }}
           />
        </div>

      </div>

    </div>
  );
};

export default Filters;
