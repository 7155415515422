import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  RootState,
  userNavigatingToResults,
  updatingDataForSearchResultSession,
  resetAllSearchResultFilters,
  updatingFavoriteListings,
  updatingDataForListingExpanded,
  selectedListingIsChanging,
  selectedFilterChipIsChanging,
  updatingTypeInput,
  locationInputChanged,
  bulkUpdatingHomeCriteriaFilters,
  homeCriteriaStepSelected,
  bulkUpdatingOfUserLocationInput,
  resetUserLocationInputSession,
  updatingSearchNameForSearchResultSession,
  updatingSearchIdForSearchResultSession,
  updatingSearchStatusForSearchResultSession,
  updatingSelectedListingFromSavedSearchDataTable,
  updatingSavedSearchMetaDataAssociatedWithSearchResult,
} from '@stores';
import { StepKeys } from '@types';

const navigatingToSearchResultsMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  if (userNavigatingToResults.match(action)) {
    const {
      userSearchInput, userSearchResults, favorites, savedSearch,
      searchId, searchName, searchStatus,
    } = storeAPI.getState().userSearchResult;

    // Update the session data with the user search results
    storeAPI.dispatch(updatingDataForSearchResultSession({ userSearchResults: userSearchResults || undefined }));

    // reset all the input states to reflect the one from the search result
    if (userSearchInput) {
      storeAPI.dispatch(updatingTypeInput(userSearchInput[StepKeys.TYPE]));

      storeAPI.dispatch(locationInputChanged(userSearchInput[StepKeys.LOCATION]));

      storeAPI.dispatch(bulkUpdatingHomeCriteriaFilters(userSearchInput[StepKeys.HOME_CRITERIA]));
      storeAPI.dispatch(homeCriteriaStepSelected());

      storeAPI.dispatch(bulkUpdatingOfUserLocationInput(userSearchInput[StepKeys.USER_LOCATIONS]));
      storeAPI.dispatch(resetUserLocationInputSession());
    }

    storeAPI.dispatch(resetAllSearchResultFilters());
    storeAPI.dispatch(updatingFavoriteListings(favorites));
    storeAPI.dispatch(updatingDataForListingExpanded(null));
    storeAPI.dispatch(selectedListingIsChanging(null));
    storeAPI.dispatch(selectedFilterChipIsChanging({ filterChipID: null }));

    // Clear out the selected listing incase one is selected by the user
    storeAPI.dispatch(updatingSelectedListingFromSavedSearchDataTable(null));

    if (savedSearch) {
      const { searchId, searchName, status } = savedSearch;
      storeAPI.dispatch(updatingSearchNameForSearchResultSession(searchName));
      storeAPI.dispatch(updatingSearchIdForSearchResultSession(searchId));
      storeAPI.dispatch(updatingSearchStatusForSearchResultSession(status));
    }

    // If the user saved the search -> navigated to dashboard or else where -> came back to view the results
    // make sure to update the session with the meta data so they can continue updating the search
    if (!savedSearch && searchId && searchName && searchStatus) {
      storeAPI.dispatch(updatingSearchNameForSearchResultSession(searchName));
      storeAPI.dispatch(updatingSearchIdForSearchResultSession(searchId));
      storeAPI.dispatch(updatingSearchStatusForSearchResultSession(searchStatus));
    }

    return next(action);
  }
  return next(action);
});

export default navigatingToSearchResultsMiddleware;
