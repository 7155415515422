import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  RootState,
  updatingSavedSearchesSelectedInFavorites,
  updatingDataForSearchResultSession,
  resetAllSearchResultFilters,
} from '@stores';
import { ListingDetails } from 'beiytak_sdk';

const interactingWithFavoritesInTheDashboardMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  // When the user updates the saved searches they want view in the favorites section,
  // This handles updating the all the filters and listings to display to reflect only the data
  // present in the saved searches selected
  if (updatingSavedSearchesSelectedInFavorites.match(action)) {
    // Update the selected saved searches from the filters area
    next(action);

    const { filterChipSelected } = storeAPI.getState().searchResultSession;
    const { savedSearches } = storeAPI.getState().savedSearches;

    // Only update the filter state if the user closed out of the saved searches filter chip
    // signaling they completed their selections
    if (savedSearches && !filterChipSelected) {
      const { savedSearchesSelectedInFavorites } = storeAPI.getState().savedSearchesFilters;
      let listingsFromSearches: ListingDetails[] = [];

      // Get all the listings for the searches that were selected
      savedSearchesSelectedInFavorites.forEach((selectedSearch) => {
        listingsFromSearches = [...listingsFromSearches, ...savedSearches[selectedSearch].favorites];
      });

      // Update the map and result list data with those listings in case the user navigates to their favorites
      storeAPI.dispatch(updatingDataForSearchResultSession({ savedSearchFavorites: listingsFromSearches }));

      // Update all the filters based on the favorites in the saved searches
      return storeAPI.dispatch(resetAllSearchResultFilters());
    }
  }

  return next(action);
});

export default interactingWithFavoritesInTheDashboardMiddleware;
