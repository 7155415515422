import React from 'react';
import clsx from 'clsx';
import useStyles from './FilterIsApplied.styles';

/** Thumb for the slider */
const FilterIsApplied: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.container} />
  );
};

export default FilterIsApplied;
