import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {

    gridArea: 'data',

    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },

  map: {

    // gridArea: 'map',

    width: '75%',
    height: '100vh',

    overflow: 'hidden',
  },

  listings: {

    overflow: 'hidden',

    // gridArea: 'res',
    width: '25%',
    height: '99vh',
    minWidth: '42.5rem',
    backgroundColor: '#FCFCFC',
  },

}));

export default useStyles;
