import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

}));

export default useStyles;
