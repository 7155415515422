import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from 'react-select/src/theme';

const useStyles = makeStyles((theme) => ({

  'style-option': {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    backgroundColor: theme.palette.neutral.light,
    border: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '10px',
    height: '4.5rem',
    width: '4rem',

    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',

    },

    '&:hover': {
      transform: 'scale(1.03)',
    },

  },

  'icon-container': {

    width: '100%',
    height: '40%',

    '&.selected': {
      color: 'white',
    },

  },

  icon: {
    maxWidth: '1.9rem',
    maxHeight: '1.9rem',

  },

  label: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    width: '100%',
    height: '40%',

    fontSize: '0.7rem',
  },

}));

export default useStyles;
