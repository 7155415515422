import React, { FC } from 'react';
import Lottie from 'react-lottie';
import { SectionInfo } from '@components';
import * as S from './AnimationContainer.styled';

interface AnimationContainerProps {
    title: string,
    subtitle: string,
    animation: any,
}

const AnimationContainer: FC<AnimationContainerProps> = ({ title, subtitle, animation, children }) => {
  return (
    <S.Container>
      <SectionInfo {...{ title, subtitle }}/>
      {
        children || <></>
      }
      <S.AnimationContainer>
          <Lottie
          speed={1}
          style={{ width: '100%', height: '100%', objectFit: 'contain' }}
          options={{
            animationData: animation,
            autoplay: true,
            loop: true,
          }}
          isClickToPauseDisabled
          />
      </S.AnimationContainer>
</S.Container>

  );
};

export default AnimationContainer;
