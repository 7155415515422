import { Formatter, FilterValue, FilterOption } from '@types';

/**
 * Creates marks for the sliders of the filter
 * @function
 */
const createMarks = (
  /** Formatter to pass the values to */
  formatter: Formatter,
  /** Default values for the slider */
  defaultValueForSlider: FilterValue,
  /** How often a label should be displayed */
  step: number,
) => {
  const marks: FilterOption[] = [];
  const maxMarks = 10;

  if (defaultValueForSlider.length > 0) {
    const [min, max] = defaultValueForSlider;
    const count = (max - min) / step;

    if (count <= maxMarks) {
      for (let i = 0; i <= count; i += 1) {
        const value = i === 0 ? min : min + (step * i);
        const mark: FilterOption = {
          value,
          label: formatter(value, defaultValueForSlider),
        };

        marks.push(mark);
      }
    }

    if (count > maxMarks) {
      const adjustedStep = (max - min) / maxMarks;

      for (let i = 0; i <= maxMarks; i += 1) {
        const value = i === 0 ? min : min + (adjustedStep * i);
        const mark: FilterOption = {
          value,
          label: formatter(value, defaultValueForSlider),
        };

        marks.push(mark);
      }
    }

    return marks;
  }

  return marks;
};

export default createMarks;
