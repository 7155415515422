import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  RootState,
  userLeavingFavorites,
  selectedFilterChipIsChanging,
} from '@stores';

const leavingFavoritesMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  if (userLeavingFavorites.match(action)) {
    // When leaving the favorites page, clear out any filter chip that might be selected
    storeAPI.dispatch(selectedFilterChipIsChanging({ filterChipID: null }));
  }

  return next(action);
});

export default leavingFavoritesMiddleware;
