import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`;

export const AnimationContainer = styled.div`
width: 100%;
max-width: 300px;
`;
