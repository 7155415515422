import { $theme } from '@styleGuide';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
`;

export const PlanDetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    padding-left: 2rem;

    overflow: hidden;

    width: 100%;
    height: 100%;
`;

export const Title = styled.div`
    color: #202020;
    font-size: 1rem;
    font-weight: 600;

    margin-bottom: 0.75rem;
`;

export const PlanOptionsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 5%;
`;

export const FeaturesContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    height: 75%;
`;

export const PricingContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
    height: 20%;

    border-top: 1px solid ${$theme('palette.neutral.dark')};
`;

export const Price = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;

    padding-left: 1rem;

    font-size: 2rem;
    font-weight: 800;
`;

export const Duration = styled.div`
    margin-left: 0.4rem;
    font-size: 1rem;
`;

export const Button = styled.button`
    width: 9rem;
    height: 3.5rem;

    margin-left: 1rem;
    margin-right: 1rem;

    font-size: 1.2rem;
    font-weight: 600;

    border: none;
    border-radius: 9px;
    color: white;
    background: #FF7348;

    cursor: pointer;

    &:hover {
        background: #cc5c3a;
    }
`;
