import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Location, StepKeys, LocationInputSlice } from '@types';
import { RootState } from '@stores';
import locationValidation from './services';

// Initial state for store
const initialState: LocationInputSlice = {
  value: null,
  validation: false,
};

export const locationInputSlice = createSlice({
  name: 'locationInput',
  initialState,
  reducers: {

    /**
     * Updates the location the user entered
     */
    locationInputChanged: (state, action: PayloadAction<Location>) => {
      const { payload } = action;

      state.value = payload;
    },

    /**
     * Validates the location input
     */
    validateLocationInput: (state, action: PayloadAction<Location>) => {
      const { payload } = action;

      state.validation = locationValidation(payload);
    },

    /** Resets the location in put in prep for a new search */
    resetLocationInput: (state) => {
      state.value = null;
      state.validation = false;
    },
  },
});

export const selectLocationInput = (state: RootState) => state.locationInput.value;

export const selectLocationInputValidation = (state: RootState) => state.locationInput.validation;

export const {
  locationInputChanged,
  validateLocationInput,
  resetLocationInput,
} = locationInputSlice.actions;

export default locationInputSlice.reducer;
