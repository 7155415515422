import React, { useState } from 'react';
import { freeTrialPlan, locationPlan } from '@constants';
import { OfferDetails, Plans } from 'beiytak_sdk';
import { selectLocationInput } from '@stores';
import { useAppSelector } from '@hooks';
import { LocationPermissionService, selectOffers } from '@machines';
import { useActor, useSelector } from '@xstate/react';
import { handleContactUs } from '@utils';
import { PlanOptions } from './types';

interface Values {
    selectedPlan: PlanOptions,
    offer?: OfferDetails,
    features: string[],
}

interface Handlers {
    updatePlan: React.Dispatch<React.SetStateAction<PlanOptions>>
    signUpForFreeTrial: () => void;
    handleContactUs: () => void;
}

const usePlansAndOffers = (locationPermissionService: LocationPermissionService): [Values, Handlers] => {
  const [selectedPlan, setPlan] = useState<PlanOptions>('PURCHASE');
  const location = useAppSelector(selectLocationInput);
  const offers = useSelector(locationPermissionService, selectOffers);
  const [, send] = useActor(locationPermissionService);

  let features: string[] = [];

  // Filter through the offers to find them matching ones
  const freeTrialOffer = offers?.filter((offer) => offer.planId === Plans.FREE_TRIAL)[0];
  const basicPurchaseOffer = offers?.filter((offer) => offer.planId === Plans.BASE_MONTHLY)[0];

  // Get the list of features to highlight to the user
  if (selectedPlan === 'FREE_TRIAL') { features = freeTrialPlan.features; }
  if (selectedPlan === 'PURCHASE') {
    if (basicPurchaseOffer?.planId === Plans.BASE_MONTHLY) { features = locationPlan.features; }
  }

  const handlers: Handlers = {
    updatePlan: setPlan,
    // @ts-ignore this is the right payload, its defaulting to only the location_set action
    signUpForFreeTrial: () => send({ type: 'SIGN_UP_FOR_FREE_TRIAL', payload: { params: { location } } }),
    handleContactUs: () => handleContactUs(location || undefined),
  };

  const values: Values = {
    selectedPlan,
    offer: selectedPlan === 'PURCHASE' ? basicPurchaseOffer : freeTrialOffer,
    features,
  };

  return [values, handlers];
};

export default usePlansAndOffers;
