import { FunctionComponent } from 'react';
import { useAppSelector } from '@hooks';
import { Routes } from '@types';
import { useLocation, useNavigate } from 'react-router';
import {
  userNavigatingToDashboard, userNavigatingToStartNewSearch,
  userNavigatingToUpdateSearch, userNavigatingToResults,
  selectIfUserSearchResultsAreAvailable,
  selectAuthStatus,
} from '@stores';
import NavigationOption from './NavigationOption';
import logo from '../../../assets/logo.png';
import login from '../../../assets/login.svg';
import list from '../../../assets/list.svg';
import update from '../../../assets/update.svg';
import forward from '../../../assets/forward-arrow.svg';
import search from '../../../assets/search-location.svg';
import * as S from './TopNavBar.styled';

export type Variant = 'white' | 'transparent';
interface TopNavBarProps {
  variant: Variant,
}

const TopNavBar: FunctionComponent<TopNavBarProps> = ({ variant, children }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const searchResultsAvailable = useAppSelector(selectIfUserSearchResultsAreAvailable);
  const { isAuthenticated } = useAppSelector(selectAuthStatus);

  const displayGoBackToResults = searchResultsAvailable && (location.pathname.includes(Routes.SEARCH));
  const displayUpdateSearch = location.pathname.includes(Routes.RESULTS); // Only show the icon if on the results page
  const displayNewSearch = location.pathname.includes(Routes.RESULTS); // Only show if not on the search page
  const displayGoToDashboard = (!location.pathname.includes(Routes.DASHBOARD) && location.pathname !== '/') || (location.pathname === '/' && isAuthenticated); // Only show if not on the search page
  const displayLogin = location.pathname === '/' && !isAuthenticated;
  const displayGoToSearch = location.pathname === '/' && isAuthenticated;

  return (
    <S.Container variant={variant}>
        <S.LogoContainer onClick={() => navigate(Routes.LANDING)}>
          <S.Logo src={logo} alt="" />
        </S.LogoContainer>

        <S.ChildrenContainer>
            {children}
        </S.ChildrenContainer>

        <S.Navigation>

            {
                displayUpdateSearch
                  ? <NavigationOption {...{
                    src: update,
                    route: Routes.SEARCH,
                    hoverText: 'Update Search',
                    action: userNavigatingToUpdateSearch,
                  }}
                  />
                  : <></>
            }

            {
                displayNewSearch
                  ? <NavigationOption {...{
                    src: search,
                    route: Routes.SEARCH,
                    hoverText: 'New Search',
                    action: userNavigatingToStartNewSearch,
                  }}/>
                  : <></>
            }
            {
                displayGoToSearch
                  ? <NavigationOption {...{
                    src: login,
                    route: Routes.SEARCH,
                    hoverText: 'Search',
                  }}/>
                  : <></>
            }
            {
                displayGoToDashboard
                  ? <NavigationOption {...{
                    src: list,
                    route: Routes.DASHBOARD,
                    hoverText: 'Saved Searches',
                    action: userNavigatingToDashboard,
                  }}/>
                  : <></>
            }

            {
                displayGoBackToResults
                  ? <NavigationOption {...{
                    src: forward,
                    route: Routes.RESULTS,
                    hoverText: 'Back to results',
                    action: userNavigatingToResults,
                  }}/>
                  : <></>
            }
            {
                displayLogin
                  ? <S.Login onClick={() => navigate(Routes.LOGIN)}>Login</S.Login>
                  : <></>
            }
        </S.Navigation>

        </S.Container>
  );
};

export default TopNavBar;
