import { FunctionComponent, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks';
import { getPathNameFromLocationState } from '@utils';
import { verifyingUserAuth, selectAuthStatus } from '@stores';
import { Navigation } from '@types';

/**
 * This component is the "entry way" for all redirects (general + refresh of pages)
 * This components responsibility is to present a loader and initiate a verification of the user.
 * It dispatches an action to confirm the auth of the user using the token available.
 * Redux will be responsible for redirecting the user to the appropriate page based on the verification
 */
const VerifyScreen: FunctionComponent = () => {
  const { verifiedAuth, isAuthenticated } = useAppSelector(selectAuthStatus);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const pathname = getPathNameFromLocationState(location);

  useEffect(() => {
    const navigation: Navigation = { navigate, pathname };

    dispatch(verifyingUserAuth(navigation));
  }, []);

  return (
    isAuthenticated
      ? <></>
      : <div></div>
  );
};

export default VerifyScreen;
