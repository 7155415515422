import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({

  button: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    minWidth: '8.75rem',
    maxWidth: '140px',
    minHeight: '3.125rem',
    maxHeight: '50px',

    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',

    backgroundColor: theme.palette.primary.main,
    color: 'white',

    fontSize: '0.8rem',

    '&:hover': {
      transform: 'scale(1.03)',
      backgroundColor: theme.palette.primary.main,
    },

  },

}));

export default useStyles;
