import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import { UserLoginInfo, Navigation } from '@types';
import { RootState } from '@stores';
import {
  LoginAction,
  VerifyAction,
} from './thunks';

// Initial state for store
const initialState = {
  email: '',
  loginAttempted: false,
  loginSuccess: false,
  isAuthenticated: false,
  verifiedAuth: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {

    /**
     * Action to login in the user
     */
    userRequestingToLogin: (state, action: PayloadAction<UserLoginInfo>) => {
      const { email } = action.payload;
      state.email = email;
    },

    /**
     * Action is called to verify the user auth.
     * State value is set to true so that no screen is loaded until
     * the verification results are received.
     */
    verifyingUserAuth: (state, action: PayloadAction<Navigation>) => {
      state.verifiedAuth = false;
    },

    /**
     * Once the verification is complete, update the state so no transitions are displayed
     */
    verificationComplete: (state) => {
      state.verifiedAuth = true;
    },

    /**
     * Handles the case when the verification has succeeded
     */
    verificationSucceeded: (state) => {
      state.loginSuccess = true;
      state.isAuthenticated = true;
    },

    /**
     * Handles the case when the verification failed
     */
    verificationFailure: (state) => {
      state.loginSuccess = false;
      state.isAuthenticated = false;
    },

    /**
     * Handles when the user tries to login but fails
     */
    loginFailure: (state) => {
      state.loginAttempted = true;
      state.loginSuccess = false;
      state.isAuthenticated = state.loginAttempted && state.loginSuccess;
    },

    /**
     * Handles when the user successfully logs in
     */
    loginsSucceeded: (state) => {
      state.loginAttempted = true;
      state.loginSuccess = true;
      state.isAuthenticated = state.loginAttempted && state.loginSuccess;
    },
  },

});

export const selectAuthStatus = (state: RootState) => {
  return {
    loginAttempted: state.auth.loginAttempted,
    isAuthenticated: state.auth.isAuthenticated,
    verifiedAuth: state.auth.verifiedAuth,
  };
};

// Ignore these actions because the navigate object is part of these actions to navigate the user to different pages on failure/success
export const authIgnoreSerializableCheck = [
  'auth/userRequestingToLogin',
  'auth/verifyingUserAuth',
  `${LoginAction}/fulfilled`,
  `${LoginAction}/rejected`,
  `${VerifyAction}/fulfilled`,
  `${VerifyAction}/rejected`,
];

export const {
  userRequestingToLogin,
  loginFailure,
  loginsSucceeded,
  verifyingUserAuth,
  verificationComplete,
  verificationSucceeded,
  verificationFailure,
} = authSlice.actions;

export default authSlice.reducer;
