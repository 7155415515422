import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  RootState,
  userNavigatingToStartNewSearch,
  resetHomeCriteriaInput,
  resetLocationInput,
  resetTypeInput,
  resetUserLocationInput,
  resetAllSearchResultFilters,
  statusChangeAroundIfUserIsUpdatingSavedSearch,
  updatingSelectedListingFromSavedSearchDataTable,
} from '@stores';

const navigatingToStartANewSearchMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /** Clears out the search input state when the user wants to start a new search */
  if (userNavigatingToStartNewSearch.match(action)) {
    storeAPI.dispatch(resetLocationInput());
    storeAPI.dispatch(resetHomeCriteriaInput());
    storeAPI.dispatch(resetTypeInput());

    storeAPI.dispatch(statusChangeAroundIfUserIsUpdatingSavedSearch(false));

    storeAPI.dispatch(resetAllSearchResultFilters());

    storeAPI.dispatch(updatingSelectedListingFromSavedSearchDataTable(null));

    return next(action);
  }
  return next(action);
});

export default navigatingToStartANewSearchMiddleware;
