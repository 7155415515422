import {
  FunctionComponent,
} from 'react';
import { selectListingExpanded, selectListingSelectedFromSavedSearch } from '@stores';
import { useAppSelector } from '@hooks';
import { ListingType } from 'beiytak_sdk';
import { ListingHomeCriteria, ZillowLink } from '@components';
import { ListingWithID } from '@types';
import { createPriceLabel } from '@utils';
import useStyles from './ListingDetailSummary.styles';

/**
 * Creates a summary view of the listing displaying:
 * - price
 * - home features (price, beds bath)
 * - address
 */
const ListingDetailSummary: FunctionComponent<ListingWithID> = (listing) => {
  const classes = useStyles();
  const listingExpanded = useAppSelector(selectListingExpanded);
  const listingSelectedFromSavedSearch = useAppSelector(selectListingSelectedFromSavedSearch);
  const { source, price, address } = listing;
  const type = source?.type || ListingType.Sale;

  return (

    <div className={classes.container}>

      <span className={classes.price} aria-label="price">
        {
          price
            ? `${createPriceLabel(price, type)}`
            : ' $ (Not Available)'
        }
        {
          listingExpanded?.listing.address === address || listingSelectedFromSavedSearch === address
            ? <ZillowLink {...{ address }}/>
            : <></>
        }
      </span>

        <ListingHomeCriteria {...listing} />
        <span className={classes.address} aria-label="address">{address}</span>
    </div>

  );
};

export default ListingDetailSummary;
