import clsx from 'clsx';
import { FunctionComponent, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  StepData, StepKeys,
} from '@types';
import { useAppDispatch, useAppSelector } from '@hooks';
import { selectedStepIsChanging, hoveredStepIsChanging, selectSelectedStep } from '@stores';
import { LocationInput } from '@components';
import { LocationPermissionService } from '@machines';
import StepNumber from './StepNumber';
import useStyles, { buttonVariant } from './Step.style';

interface StepProps extends StepData {
    /** Location permission service to interact with the permission verification machine */
    locationPermissionService: LocationPermissionService,
}

/**
 * Component that holds the information for a step.
 * @component
 */
const Step: FunctionComponent<StepProps> = ({
  id,
  stepNumber,
  title,
  subtitle,
  locationPermissionService,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedStep = useAppSelector(selectSelectedStep);

  const currentStepSelected = selectedStep === id; // Whether the current step is active
  const anyStepSelected = selectedStep !== undefined; // Whether any step is active
  const inputId = document.getElementById(id); // stores the input ref for the step if needed

  /** When the step is clicked, update the state  */
  const handleOnClick = () => {
    const payload = currentStepSelected ? null : id;

    dispatch(selectedStepIsChanging(payload));

    // if an input resides within a step, focus on it
    if (inputId) { inputId.focus(); }
  };

  const handleOnHoverStart = () => {
    dispatch(hoveredStepIsChanging(id));
  };

  const handleOnHoverEnd = () => {
    dispatch(hoveredStepIsChanging(null));
  };

  /**
   * If the step is selected through the state and the not the user directly
   * as in the case the user clicked next or the search button without all the steps
   * being validated, make sure to focus on the input if available just like as if the
   * user had clicked the step
   */
  useEffect(() => {
    if (currentStepSelected) {
      if (inputId) { inputId.focus(); }
    }
  }, [selectedStep]);

  return (

    <motion.button
      className={clsx({
        [classes.container]: true,
        [classes.step]: !anyStepSelected,
        [classes.differentStepSelected]: (!currentStepSelected && anyStepSelected),
        [classes.selectedStep]: currentStepSelected,
      })}
      onClick={() => handleOnClick()}
      variants={buttonVariant}
      initial="initial"
      whileFocus="whileFocus"
      animate="initial"
      onHoverStart={() => handleOnHoverStart()}
      onHoverEnd={() => handleOnHoverEnd()}
    >
      <StepNumber {...{ stepNumber, id }} />

      <div className={classes.stepInfo}>

        <div className={clsx(classes.stepInfo, 'title')}>
          {title}
        </div>

        <div className={clsx(classes.stepInfo, 'subtitle')}>
          {id === StepKeys.LOCATION ? <LocationInput {...{ id, locationPermissionService }} /> : subtitle}
        </div>

      </div>

    </motion.button>

  );
};

export default Step;
