export const baseReportPlan = {
  id: 'BASE_REPORT',
  name: 'Weekly Report',
  pricePrefix: 'Starting at',
  price: '4.00',
  duration: '/ Month Per Report',
  description: 'Price is adjusted based on the number of locations, their size, and the number of places of interest',
  actionText: 'Contact Us',
  features: [
    'Frequency from multiple times a Day to Weekly',
    'Excel sheet sent directly to your email',
    'Commute times to all your places of interest',
    'Zillow links for every listing',
    'Up to 250 listings that fit your criteria',
  ],
  highlight: false,
};

export const weeklyReportPlan = {
  id: 'WEEKLY_REPORT',
  name: 'Weekly Report',
  duration: '/ Month Per Report',
  description: 'Sent out Weekly',
  actionText: 'Contact Us',
  features: [
    'Sent every Monday morning',
    'Excel sheet sent directly to you with all the info you need',
    'Commute times to all your places of interest',
    'Zillow links for every listing',
    'Interact with the results from your dashboard',
  ],
  highlight: false,
};

export const dailyReportPlan = {
  id: 'DAILY_REPORT',
  name: 'Custom Reports',
  duration: '/ Month Per Report',
  description: 'Reports to always keep you in the know. Just let us know your specific criteria and we will only send you listings that match it.',
  actionText: 'Contact Us',
  features: [
    'Can be sent at any frequency from hourly to daily',
    'Specify the criteria for you or your clients',
    'Commute times to all your places of interest',
    'Zillow links for every listing',
    'Interact with the results in your dashboard',
  ],
  highlight: false,
};

export const businessReportPlan = {
  id: 'BUSINESS_REPORT',
  name: 'Business Report',
  description: 'Reports generated from your private listing database and sent to your entire team',
  actionText: 'Contact Us',
  features: [
    'Tie in your private listing database directly into Findur',
    'Sent out at any frequency',
    'Send reports to your entire team tailored for each member',
    'Unlimited places of interest',
    'Zillow links for every listing',
  ],
  highlight: false,
};
