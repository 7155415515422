import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { FilterChip, Filter } from '@components';
import { useAppSelector } from '@hooks';
import { useLocation } from 'react-router-dom';
import { checkEnumType, homeCriteriaResultFilterFactory } from '@utils';
import {
  StepKeys, HomeCriteriaKeys, IndexableObject, Filter as FilterType,
  Routes,
} from '@types';
import {
  selectFilterChipSelected,
  selectLocationInput,
  selectTypeInput,
  selectIfUserLocationFilterIsApplied,
  selectIfMoreFilterIsApplied,
} from '@stores';
import MoreFilters from './MoreFilters';
import UserLocationsFilters from './UserLocationsFilters';
import SavedSearchesListFilter from './SavedSearchesListFilter';
import PremiumFilter from './PremiumFilter';
import useStyles from './ResultsFilters.styles';

/** This component has the filters that can be used to filter down the results */
const ResultsFilters: FunctionComponent = () => {
  const classes = useStyles();
  const { pathname } = useLocation();
  const filterChipSelected = useAppSelector(selectFilterChipSelected);
  const location = useAppSelector(selectLocationInput);
  const type = useAppSelector(selectTypeInput);

  const filterChipSelectedIsHomeCriteria = checkEnumType<HomeCriteriaKeys>(HomeCriteriaKeys, filterChipSelected);
  const onDashboardPage = pathname.includes(Routes.DASHBOARD);

  const priceResultsFilter = homeCriteriaResultFilterFactory(HomeCriteriaKeys.PRICE, type);
  const bedroomsResultsFilter = homeCriteriaResultFilterFactory(HomeCriteriaKeys.BEDROOMS);
  const bathroomsResultsFilter = homeCriteriaResultFilterFactory(HomeCriteriaKeys.BATHROOMS);

  const premiumResultsFilter = { id: StepKeys.PREMIUM };

  const resultsFilters: IndexableObject<FilterType> = {
    [HomeCriteriaKeys.PRICE]: priceResultsFilter,
    [HomeCriteriaKeys.BEDROOMS]: bedroomsResultsFilter,
    [HomeCriteriaKeys.BATHROOMS]: bathroomsResultsFilter,
  };

  return (
    <div
      className={classes.container}
    >
      <div className={classes['filter-chip-container']}>
        {
          !onDashboardPage
            ? <div className={classes.location}>{location}</div>
            : <></>
        }

        {
          onDashboardPage
            ? <FilterChip {...{ id: StepKeys.SAVED_SEARCHES }} />
            : <></>
        }

        {
          !onDashboardPage
            ? <FilterChip {...resultsFilters[HomeCriteriaKeys.PRICE] } />
            : <></>
        }

        {
          !onDashboardPage
            ? <FilterChip {...resultsFilters[HomeCriteriaKeys.BEDROOMS]} />
            : <></>
        }

        {
          !onDashboardPage
            ? <FilterChip {...resultsFilters[HomeCriteriaKeys.BATHROOMS]} />
            : <></>
        }

        <FilterChip {...{ id: StepKeys.USER_LOCATIONS, isFilterApplied: () => useAppSelector(selectIfUserLocationFilterIsApplied) }} />

        {
          !onDashboardPage
            ? <FilterChip {...{ id: StepKeys.MORE, isFilterApplied: () => useAppSelector(selectIfMoreFilterIsApplied) }} />
            : <></>
        }

      </div>

      <div
        className={clsx({
          [classes['filter-container']]: filterChipSelected,
          homeCriteriaFilter: filterChipSelectedIsHomeCriteria,
          userLocationsFilter: filterChipSelected === StepKeys.USER_LOCATIONS,
          moreFilter: filterChipSelected === StepKeys.MORE,
        })}
        key={filterChipSelected}
      >
        {
          filterChipSelected && filterChipSelectedIsHomeCriteria
            ? <Filter {...{ ...resultsFilters[filterChipSelected], disabled: true }} />
            : <></>
        }
        {
          filterChipSelected === StepKeys.USER_LOCATIONS
            ? <UserLocationsFilters />
            : <></>
        }
        {
          filterChipSelected === StepKeys.MORE
            ? <MoreFilters />
            : <></>
        }
        {
          filterChipSelected === StepKeys.SAVED_SEARCHES
            ? <SavedSearchesListFilter />
            : <></>
        }
        {
          filterChipSelected === StepKeys.PREMIUM
            ? <PremiumFilter />
            : <></>
        }
      </div>
    </div>
  );
};

export default ResultsFilters;
