import styled from 'styled-components';
import { devices } from '@styleGuide';

export const Container = styled.div<{highlight: boolean}>`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: left;

    padding: 1.65rem;

    width: 100%;
    max-width: 320px;
    height: 85%;
    min-height: 575px;

    border-radius: 12px;
    box-shadow: 0px 10px 40px rgba(32, 32, 32, 0.04);
    border: ${(props) => (props.highlight ? '1px solid #FF7348' : 'none')};

    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.875rem;
    color: #606060;
    background: #FFFFFF;

    @media (${devices.tabletAndBelow}) {

        max-width: 300px;

        padding: 0rem;
        padding-top: 1rem;
        padding-left: 0.75rem;
        border: ${(props) => (props.highlight ? '1px solid #FF7348' : '1px solid #888888')};
    }

`;

export const NameContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    margin-bottom: 1.5626rem;

    width: 100%;
`;

export const BestValue = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-left: 0.75rem;

    width: 5.875rem;
    height: 2.125rem;

    font-size: 1rem;
    line-height: 1.875rem;

    border-radius: 4px;
    background: linear-gradient(117.13deg, #CD4E26 -0.03%, #F47C57 99.2%);

    color: #FFFFFF
`;

export const Name = styled.h3`
    margin: 0rem;

    font-size: 1.75rem;
    font-weight: 700;
    color: #202020;
`;

export const PricePrefix = styled.h4`
    margin: 0rem;
    margin-top: 0.2rem;
    font-size: 1.4rem;
    font-weight: 500;
    color: #202020;
`;

export const PlanInfoContainer = styled.div`
    width: 100%;
    height: 25%;

    margin-bottom: 5rem;
`;

export const PriceContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
`;

export const Price = styled.span`
    font-weight: 700;
    font-size: 2rem;
    color: #202020;
`;

export const Duration = styled.span`

    margin-left: 0.5rem;

    font-weight: 600;
    font-size: 1rem;
    line-height: 1.875rem;
    color: #606060;
`;

export const Description = styled.h5`
    margin: 0rem;
    margin-top: 1.5626rem;

    font-weight: 600;
    font-size: 1rem;
    line-height: 1.375rem;
    color: #888888;
`;

export const Button = styled.button`
    width: 9rem;
    height: 3.5rem;

    font-size: 1.2rem;
    font-weight: 600;

    border: none;
    border-radius: 9px;
    box-shadow: 0 10px 25px rgb(255 115 72 / 30%);
    color: white;
    background: #FF7348;

    &:hover {
        background: #cc5c3a;
    }
`;

export const Divider = styled.div`

    margin-top: 1.25rem;
    margin-bottom: 1.25rem;

    width: 100%;
    height: 0px;
    border: solid 1px #E3E3E3;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
`;
