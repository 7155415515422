import { FunctionComponent, useState } from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import Lottie from 'react-lottie';
import FavoriteBorder from '@material-ui/icons/FavoriteBorder';
import { useAppSelector } from '@hooks';
import { RequestStatus } from '@types';
import { selectSearchName, selectSaveSearchRequestStatus } from '@stores';
import loadingAnimation from '../../../assets/three_dot_animation.json';
import SaveSearchInput from './SaveSearchInput';
import useStyles from './SaveSearch.styles';

const SaveSearch: FunctionComponent = () => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const searchName = useAppSelector(selectSearchName);
  const saveSearchRequestStatus = useAppSelector(selectSaveSearchRequestStatus);

  const handleOnClick = () => { setClicked(!clicked); };

  return (
    <div className={classes.container}>
      {
        searchName && saveSearchRequestStatus !== RequestStatus.PENDING
          ? <span className={classes['search-name']}>  {`${searchName}`} </span>
          : <></>
      }

      {
        !searchName && saveSearchRequestStatus !== RequestStatus.PENDING
          ? (<Button
              fullWidth
              classes={{
                root: clsx({
                  [classes.button]: true,
                  clicked,
                }),
              }}
              TouchRippleProps={{ className: classes.ripple }}
              onClick={handleOnClick}
          >
              <FavoriteBorder className={
                clsx({
                  [classes.icon]: true,
                  clicked,
                })} />
              <span className={classes.label}>Save Search</span>
          </Button>)
          : <></>
      }

      {
        saveSearchRequestStatus === RequestStatus.PENDING
          ? <Lottie
              speed={1}
              options={{
                animationData: loadingAnimation,
                autoplay: true,
              }}
              isClickToPauseDisabled
            />
          : <></>
      }

      {
        clicked
          ? (<div className={classes['search-input-container']}>
              <SaveSearchInput />
              </div>)
          : <></>
      }
    </div>
  );
};
export default SaveSearch;
