import config from '@config';
import { Routes } from 'beiytak_sdk';

/**
 * Gets the url to use to communicate with the backend
 */
const getAPIUrl = (
  /** Optional route to append to the url */
  route?: Routes,
): string => {
  const { API_ENDPOINT: api } = config;
  let url = '';

  // If using nginx use http://localhost for dev
  // If running local completely use http://localhost:4000 for dev
  if (api && route) { url = `${api}${route}`; }
  if (api && !route) { url = `${api}`; }

  return url;
};

export default getAPIUrl;
