import email from '../assets/email.png';
import placeOfInterest from '../assets/place-of-interest.png';
import pin from '../assets/pin.png';

export const reportSentToYourEmail = {
  title: 'Sent Directly to Your Email',
  description: 'We\'ll compile the results and send it right you. Are you a realtor managing a number of clients? We\'ll also send them a copy on your behalf!',
  icon: email,
};

export const excelSheet = {
  title: 'All the Details You Need',
  description: 'We \'ll provide details around commute times to all your places of interest, Zillow links for each listing, and more as part of the report.',
  icon: placeOfInterest,
};

export const customDashboard = {
  title: 'Automate Your Investment Playbook',
  description: 'Apply your Real Estate experience to craft the perfect report that can pin point the best investment opportunities.',
  icon: pin,
};

export const interactWithResults = {
  title: 'Interact With Your Report',
  description: 'You can access and interact with the compiled results directly in your dashboard at anytime.',
  icon: pin,
};
