import React, { FunctionComponent, useEffect, useState } from 'react';
import { FilterValue, Filter } from '@types';
import { cloneDeep } from 'lodash';
import * as S from './ValueHolder.styled';

interface ValueHolderProps extends Filter {
  /** The current value */
  currentRange: FilterValue,

  /** Method to update the displayed value of the slider */
  setFilterValue: React.Dispatch<React.SetStateAction<FilterValue>>,

  /** disable the input and only show the value? */
  disabled: boolean,
}

/** Component to store filter values. This is used in the home criteria step */
const ValueHolder: FunctionComponent<ValueHolderProps> = ({
  setFilterValue,
  stateRef,
  valueFormatter,
  formatter,
  currentRange,
  disabled,
}) => {
  const currentFilterValues = stateRef();
  const valueLabelFormatter = valueFormatter || formatter;
  const [focused, setFocused] = useState(false);
  const [localValue, setLocalValue] = useState<string[]>(
    [
      valueLabelFormatter(currentFilterValues[0], currentRange),
      valueLabelFormatter(currentFilterValues[1], currentRange),
    ],
  );
  const currentMin = currentFilterValues[0];
  const currentMax = currentFilterValues[1];
  const localMin = localValue[0];
  const localMax = localValue[1];
  const isMinAndMaxEqual = currentMin === currentMax;

  const formatValueEntered = (newValue: string): number => {
    const value = newValue
      .replaceAll('$', '')
      .replaceAll(',', '')
      .replaceAll('+', '')
      .replaceAll('minutes', '')
      .replaceAll('min', '')
      .replaceAll('sqft', '')
      .replaceAll('+', '')
      .replaceAll('acres', '')
      .replaceAll('acre', '')
      .toLowerCase();
    if (value.includes('k')) { return Number(value.replaceAll('k', '')) * 1000; }
    if (value.includes('m')) { return Number(value.replaceAll('m', '')) * 1000000; }

    return Number(value);
  };

  /** Update the local state for the input so changes are displayed right away */
  const handleLocaleChange = (newValue: string, type: 'min' | 'max') => {
    if (type === 'min') { setLocalValue([newValue, localValue[1]]); }
    if (type === 'max') { setLocalValue([localValue[0], newValue]); }
  };

  /** Make the official update the the state with the value so that the filter slide also has access to it */
  const updateFilterValues = (newValue: string, type: 'min' | 'max') => {
    /** If the user deleted the value and clicked away, re-set the values to the current ones */
    if (newValue === '' || !newValue) {
      const labelsForCurrentValues = [
        valueLabelFormatter(currentFilterValues[0], currentRange),
        valueLabelFormatter(currentFilterValues[1], currentRange),
      ];

      setLocalValue(labelsForCurrentValues);
    }

    if (newValue && newValue !== '') {
      if (type === 'min') {
        const newMin = formatValueEntered(newValue);
        const newFilterValue = [newMin, currentMax];

        setFilterValue(newFilterValue);
      }

      if (type === 'max') {
        const newMax = formatValueEntered(newValue);
        const newFilterValue = [currentMin, newMax];

        setFilterValue(newFilterValue);
      }
    }
  };

  /** If the user updates the values by using the slider, reflect them right away */
  useEffect(() => {
    const labelsForCurrentValues = [
      valueLabelFormatter(currentFilterValues[0], currentRange),
      valueLabelFormatter(currentFilterValues[1], currentRange),
    ];

    setLocalValue(labelsForCurrentValues);
  }, [currentFilterValues]);

  return (
    <S.Container>
      {
        isMinAndMaxEqual
          ? <S.Input
              value={focused ? localMin : valueLabelFormatter(formatValueEntered(localMin), currentRange)}
              onChange={(event) => handleLocaleChange(event.target.value, 'min')}
              disabled={true}
            />
          : <>
              <S.Input
                value={focused ? localMin : valueLabelFormatter(formatValueEntered(localMin), currentRange)}
                onChange={(event) => handleLocaleChange(event.target.value, 'min')}
                onFocus={() => setFocused(true)}
                onBlur={(event) => {
                  setFocused(false);
                  updateFilterValues(event.target.value, 'min');
                }}
                disabled={disabled}
              />

              <S.Dash> -</S.Dash>

              <S.Input
                value={focused ? localMax : valueLabelFormatter(formatValueEntered(localMax), currentRange)}
                onChange={(event) => handleLocaleChange(event.target.value, 'max')}
                onFocus={() => setFocused(true)}
                onBlur={(event) => {
                  setFocused(false);
                  updateFilterValues(event.target.value, 'max');
                }}
                disabled={disabled}
              />
            </>
      }

    </S.Container>
  );
};

export default ValueHolder;
