import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedSearch, UserSearchResults, SavedSearchStatus } from 'beiytak_sdk';
import { UserSearch } from '@types';
import { RootState } from '@stores';
import { UserSearchResultSlice } from './types';

// Initial state for store
const initialState: UserSearchResultSlice = {
  searchId: null,
  searchName: null,
  searchStatus: null,
  userSearchInput: null,
  userSearchResults: null,
  favorites: [],
};

export const userSearchResults = createSlice({
  name: 'userSearchResult',
  initialState,
  reducers: {

    /**
     * Update the state with the results that were returned
     */
    updatingStateWithUserSearchResults: (state, action: PayloadAction<UserSearchResults>) => {
      const { payload: userSearchResults } = action;

      state.userSearchResults = userSearchResults.data;
    },

    /**
     * Bulk update of the favorites associated with a user search result
     */
    updatingStateWithUserSearchResultsFavorites: (state, action: PayloadAction<string[]>) => {
      const { payload: favorites } = action;

      state.favorites = favorites;
    },

    /** Updates the input associated with the search results for reference */
    updatingUserSearchInputAssociatedWithSearchResult: (state, action: PayloadAction<UserSearch | null>) => {
      const { payload: userSearchInput } = action;

      state.userSearchInput = userSearchInput;
    },

    /** If the user just saved search, keep track of this info incase they move around to different parts of the app and wants to come back to the results */
    updatingSavedSearchMetaDataAssociatedWithSearchResult: (state, action: PayloadAction<{searchId?: string, searchName?: string, searchStatus?: SavedSearchStatus}>) => {
      const { payload: savedSearchMeta } = action;
      const { searchId, searchName, searchStatus } = savedSearchMeta;

      state.searchId = searchId;
      state.searchName = searchName;
      state.searchStatus = searchStatus;
    },

    /** Stores the saved search associated with the user search result if application */
    updatingSavedSearchAssociatedWithSearchResult: (state, action: PayloadAction<SavedSearch | undefined>) => {
      const { payload: savedSearch } = action;

      state.savedSearch = savedSearch;

      if (savedSearch) {
        const { searchName, searchId, status } = savedSearch;
        state.searchId = searchId;
        state.searchName = searchName;
        state.searchStatus = status;
      }

      if (!savedSearch) {
        state.searchId = undefined;
        state.searchName = undefined;
        state.searchStatus = undefined;
      }
    },

  },
});

export const selectUserSearchResultData = (state: RootState) => state.userSearchResult.userSearchResults;

export const {
  updatingStateWithUserSearchResults,
  updatingStateWithUserSearchResultsFavorites,
  updatingUserSearchInputAssociatedWithSearchResult,
  updatingSavedSearchAssociatedWithSearchResult,
  updatingSavedSearchMetaDataAssociatedWithSearchResult,
} = userSearchResults.actions;

export default userSearchResults.reducer;
