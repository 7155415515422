import {
  IndexableObject, HomeCriteriaKeys,
  FilterValue, MoreHomeCriteriaKeys,
} from '@types';

const priceDefaultValue: FilterValue = [0, 1000000];
const rentPriceDefaultValue: FilterValue = [0, 5000];
const bedroomsDefaultValue: FilterValue = [1, 5];
const bathroomsDefaultValue: FilterValue = [1, 5];
const storiesDefaultValue: FilterValue = [1, 4];
const bathsFullDefaultValue: FilterValue = [1, 5];
const bathsHalfDefaultValue: FilterValue = [1, 5];
const sqftDefaultValue: FilterValue = [0, 10000];
const garageCountDefaultValue: FilterValue = [0, 5];
const lotSqftDefaultValue: FilterValue = [0, 217800]; // 1 acre = 43560 sqft - max is 5 acre
const ageDefaultValue: FilterValue = [0, 100];
const saleStylesDefaultValue = ['apartment', 'townhome', 'single_family', 'multi_family', 'mobile', 'farm', 'condo', 'condop', 'coop'];
const rentStylesDefaultValue = ['apartment', 'townhome', 'single_family', 'multi_family', 'mobile', 'farm', 'condo', 'condop', 'coop'];

const saleFeaturesDefaultValue = [
  'basement',
  'community_clubhouse',
  'community_golf',
  'community_swimming_pool',
  'corner_lot',
  'cul_de_sac',
  'den_or_office',
  'fireplace',
  'hardwood_floors',
  'high_ceiling',
  'lake_view',
  'river_view',
  'senior_community',
  'spa_or_hot_tub',
  'swimming_pool',
  'tennis_court',
  'waterfront',
];

const rentFeaturesDefaultValue = [
  'basement',
  'community_clubhouse',
  'community_golf',
  'community_swimming_pool',
  'corner_lot',
  'cul_de_sac',
  'den_or_office',
  'fireplace',
  'hardwood_floors',
  'high_ceiling',
  'lake_view',
  'pets_allowed',
  'river_view',
  'senior_community',
  'spa_or_hot_tub',
  'swimming_pool',
  'tennis_court',
  'waterfront',
];

const moreHomeCriteriaFilterDefault = {
  [MoreHomeCriteriaKeys.STORIES]: storiesDefaultValue,
  [MoreHomeCriteriaKeys.BATHS_FULL]: bathsFullDefaultValue,
  [MoreHomeCriteriaKeys.BATHS_HALF]: bathsHalfDefaultValue,
  [MoreHomeCriteriaKeys.GARAGE_COUNT]: garageCountDefaultValue,
  [MoreHomeCriteriaKeys.SQFT]: sqftDefaultValue,
  [MoreHomeCriteriaKeys.LOT_SQFT]: lotSqftDefaultValue,
  [MoreHomeCriteriaKeys.AGE]: ageDefaultValue,
};

/**
 * How each filter maps to the object key for type Listing
 * This is used when filtering through listings
 */
const homeCriteriaFiltersMapping: IndexableObject<string> = {
  [HomeCriteriaKeys.PRICE]: 'price',
  [HomeCriteriaKeys.BATHROOMS]: 'bathrooms',
  [HomeCriteriaKeys.BEDROOMS]: 'bedrooms',
  [MoreHomeCriteriaKeys.STORIES]: 'stories',
  [MoreHomeCriteriaKeys.BATHS_FULL]: 'bathsFull',
  [MoreHomeCriteriaKeys.BATHS_HALF]: 'bathsHalf',
  [MoreHomeCriteriaKeys.SQFT]: 'sqft',
  [MoreHomeCriteriaKeys.GARAGE_COUNT]: 'garageCount',
  [MoreHomeCriteriaKeys.LOT_SQFT]: 'lotSqft',
  [MoreHomeCriteriaKeys.AGE]: 'age',
};

export {
  rentPriceDefaultValue,
  priceDefaultValue,
  bedroomsDefaultValue,
  bathroomsDefaultValue,
  storiesDefaultValue,
  bathsFullDefaultValue,
  bathsHalfDefaultValue,
  sqftDefaultValue,
  garageCountDefaultValue,
  lotSqftDefaultValue,
  ageDefaultValue,
  saleFeaturesDefaultValue,
  saleStylesDefaultValue,
  rentFeaturesDefaultValue,
  rentStylesDefaultValue,
  moreHomeCriteriaFilterDefault,
  homeCriteriaFiltersMapping,
};
