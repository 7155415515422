import { FunctionComponent } from 'react';
import { StepKeys } from '@types';
import { ListingType as ListingTypeSdk } from 'beiytak_sdk';
import ListingType from './ListingType';
import useStyles from './ListingTypes.styles';

/** Contains the filters for the Home Criteria step (price etc.) */
const ListingTypes: FunctionComponent = () => {
  const classes = useStyles();

  return (

    <div className={classes.container}>
        <ListingType {...{ type: ListingTypeSdk.Sale, label: 'Buy' }}/>
        <ListingType {...{ type: ListingTypeSdk.Rent, label: 'Rent' }}/>
    </div>

  );
};

export default ListingTypes;
