import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'grid',
    gridTemplateColumns: '34% 64% 2%',
    gridTemplateRows: `${topNavBarHeight} 15rem 7rem calc(100vh - ${topNavBarHeight}  - 15rem - 7rem)`,
    overflow: 'hidden',
    gridTemplateAreas:
        `
            'header header header'
            '. . .'
            'text steps .'
            '. input .'
        `,

    width: '100%',
    height: '100%',
  },

  background: {

    position: 'absolute',

    width: '100%',
    height: '100%',

    objectFit: 'cover',
  },

  overlay: {
    position: 'absolute',

    maxWidth: '35rem',
    minHeight: '100vh',
    maxHeight: '100vh',

    objectFit: 'fill',
    zIndex: 1,
  },

  header: {

    gridArea: 'header',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    zIndex: 1,
    background: 'hsl(0, 100%, 100%, 0.1)',
    boxShadow: '0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04)',

  },

  logo: {
    objectFit: 'contain',
    maxWidth: '9rem',
    marginLeft: '1rem',
    zIndex: 2,
  },

  button: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    minWidth: '8rem',
    maxWidth: '128px',
    height: '3.125rem',
    maxHeight: '50px',

    marginRight: '1rem',

    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',

    backgroundColor: theme.palette.primary.main,
    color: 'white',

    '&:hover': {
      transform: 'scale(1.03)',
      backgroundColor: theme.palette.primary.main,
    },

  },

  account: {
    marginRight: '1rem',
  },

  'text-container': {

    ...theme.typography.h3,

    gridArea: 'text',
    justifySelf: 'left',
    alignSelf: 'center',

    marginLeft: '2rem',
    maxWidth: '30rem',

    // fontSize: '3.2rem',
    fontWeight: theme.typography.h2.fontWeight,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    color: 'black',

    zIndex: 2,

  },

  'search-steps': {

    gridArea: 'steps',

    justifySelf: 'center',
    alignSelf: 'center',
    zIndex: 2,

  },

  'home-criteria-input': {

    gridArea: 'input',
    justifySelf: 'center',
    alignSelf: 'start',

    zIndex: 2,

    marginTop: '1rem',
  },

  'user-location-input': {

    gridArea: 'input',
    justifySelf: 'center',
    alignSelf: 'start',

    zIndex: 2,

    marginTop: '1rem',
  },

}));

export default useStyles;
