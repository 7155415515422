import { HomeCriteriaFilters, FilterValue, HomeCriteriaKeys } from '../../../../types';

/**
 * Validates the home filters input
 * All fields must be have values in place for the validation to pass
 * Also the step has had to be opened at least once
 * We need to check this because there are default values for the filter already
 * @param homeFilters home filters currently set
 * @param homeFiltersOpened Has the step been opened at least once
 * @returns { boolean } Whether or not the location validation passed
 */
const homeCriteriaValidation = (homeFilters: HomeCriteriaFilters, homeFiltersOpened: boolean) => {
  const {
    [HomeCriteriaKeys.PRICE]: price,
    [HomeCriteriaKeys.BEDROOMS]: bedrooms,
    [HomeCriteriaKeys.BATHROOMS]: bathrooms,
  } = homeFilters;

  const filters = [price, bedrooms, bathrooms];
  const checks: boolean[] = [];

  // Makes sure that there are value set for each filter
  filters.forEach((filter: FilterValue) => {
    if (typeof filter === 'undefined') {
      checks.push(false);
    } else {
      checks.push(true);
    }
  });

  // If all the filters have values and the step has been opened then the step is validated
  const passed = checks.every((check) => check === true) && homeFiltersOpened;

  return passed;
};

export default homeCriteriaValidation;
