import {
  FunctionComponent,
  useEffect,
} from 'react';
import {
  TextField,
  Grid,
  Popper,
  Paper,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { PredictionType } from '@types';
import { useAppSelector, useAutoCompletePredictions } from '@hooks';
import { selectNewUserLocationWillBeAdded, selectLocationInput } from '@stores';
import {
  useStyles,
  popperStyle,
  paperStyle,
  autocompleteStyle,
} from './UserLocationSearch.styles';

/**
 * Asynchronous Google place drop down selection.
 * The user selects their location of interest using this component
 * @component
 * */
const UserLocationSearch: FunctionComponent = () => {
  const classes = useStyles();
  const locationInput = useAppSelector(selectLocationInput);
  const [{ autoCompleteInput, autoCompleteOptions }, { onChange, onSelection, reset }] = useAutoCompletePredictions(PredictionType.PLACE, locationInput);
  const newUserLocationWillBeAdded = useAppSelector(selectNewUserLocationWillBeAdded);

  /**
   * If the user added a location and was successful, clear the input
   */
  useEffect(() => {
    if (newUserLocationWillBeAdded) { reset(); }
  }, [newUserLocationWillBeAdded]);

  return (

    <Autocomplete
      freeSolo
      filterSelectedOptions
      inputValue={autoCompleteInput}
      style={autocompleteStyle}
      options={autoCompleteOptions}
      classes={{ clearIndicator: classes.clearIndicator }}
      PopperComponent={(props) => (autoCompleteOptions.length > 0 ? <Popper {...props} style={popperStyle} /> : <></>)}
      PaperComponent={(props) => (autoCompleteOptions.length > 0 ? <Paper {...props} style={paperStyle} /> : <></>)}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x} // need to add this to the component for it to work correctly
      onInputChange={onChange}
      onChange={onSelection}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder='Enter a place like "Coffee Shop" or an address'
          fullWidth
          variant="standard"
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            classes: {
              input: classes.input,
            },
          }}
        />
      )}
      renderOption={(option) => {
        const { label } = option;

        return (
          <Grid container alignItems="center">
            <Grid item>
              <FavoriteIcon className={classes.icon} />
            </Grid>
            <Grid
            item
             xs
             style={{
               whiteSpace: 'nowrap',
               overflow: 'hidden',
               textOverflow: 'ellipsis',
             }}
             >
              {label}
            </Grid>
          </Grid>
        );
      }}
    />

  );
};

export default UserLocationSearch;
