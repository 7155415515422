import { UserLocationFilterData, QueryUserSearchInfo } from '@types';
import { every, includes, isEqual } from 'lodash';
import { isDataWithinRange } from '.';

/**
 * See if listing is within the range for user locations
 */
const filterOnUserLocations = (
  userLocations: QueryUserSearchInfo,
  userLocationDefaultFilters: UserLocationFilterData,
  userLocationFilters: UserLocationFilterData,
) => {
  const result = every(Object.keys(userLocationFilters).map((filter) => {
    const areDefaultsApplied = isEqual(userLocationDefaultFilters, userLocationFilters);

    // If the defaults for the user location filters are applied, return true to show all the listings
    if (areDefaultsApplied) {
      return true;
    }

    // If the defaults are not applied then make sure the locations being filtered down by the user are included and match the filter range
    if (!areDefaultsApplied) {
    // Check to make sure we have commute information.
    // Sometimes the api may have had issues retrieving the info from the api's
      if (includes(Object.keys(userLocations), filter)) {
        const { commute } = userLocations[filter];
        const currentFilterValue = userLocationFilters[filter];
        const defaultFilterValue = userLocationDefaultFilters[filter];

        return isDataWithinRange(commute, currentFilterValue, defaultFilterValue);
      }
    }

    // @TODO add null checks to filters so the user can decide to include them or not
    return false;
  }));

  return result;
};

export default filterOnUserLocations;
