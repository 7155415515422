import { FC } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Lottie from 'react-lottie';
import { Input, SectionInfo } from '@components';
import { selectIsSigningUp, selectedFailedToSignUp, selectEmail } from '@machines';
import { useActor, useSelector } from '@xstate/react';
import { WaitListService } from '@types';
import loadingAnimation from '../../../assets/whiteLoader.json';
import * as S from './WaitListForm.styled';

// If there is an input error
// const inputError = formik.errors.email && formik.errors.password;

// If the form is ready to submit
// const readyToSubmit = !formik.errors.email && formik.values.email && !formik.errors.password && formik.values.password;

// If the user tried logging in but failed - adding in the formik checks since the app tries to verify the user on page load using the token
// const loginFailed = loginAttempted && !isAuthenticated && formik.touched.email && formik.touched.password;

interface WaitListFormProps {
  waitListService: WaitListService,
}

const WaitListForm: FC<WaitListFormProps> = ({ waitListService }) => {
  const [, send] = useActor(waitListService);
  const isSigningUp = useSelector(waitListService, selectIsSigningUp);
  const failedToSignUp = useSelector(waitListService, selectedFailedToSignUp);
  const emailEntered = useSelector(waitListService, selectEmail);

  const buttonLabel = failedToSignUp ? 'Try again..' : 'Sign Up';

  const validationSchema = yup.object({
    email: yup.string()
      .email('Invalid email address')
      .required('Required'),
    firstName: yup.string()
      .required('Required'),
    lastName: yup.string()
      .required('Required'),
    comments: yup.string()
      .optional(),
  });

  const formik = useFormik({
    initialValues: {
      email: emailEntered || '',
      firstName: '',
      lastName: '',
    },
    validationSchema,
    onSubmit: (values) => {
      // Only send the request if we are already not waiting for a response from a previous request
      if (!isSigningUp) {
        const payload = {
          firstName: formik.values.firstName,
          lastName: formik.values.lastName,
          email: formik.values.email,
        };

        send({ type: 'SIGN_UP', payload });
      }
    },
  });

  return (
        <S.Container>
            <SectionInfo {...{ title: 'Almost there!' }}/>
            <S.Form onSubmit={formik.handleSubmit}>
                <Input
                  label="First Name"
                  id="firstName"
                  name="firstName"
                  placeholder='John'
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.firstName) && formik.touched.firstName}
                  errorMessage={formik.errors.firstName}
                />
                <Input
                  label="Last Name"
                  id="lastName"
                  name="lastName"
                  placeholder='Doe'
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.lastName) && formik.touched.lastName}
                  errorMessage={formik.errors.lastName}
                />
                <Input
                  label="Email"
                  id="email"
                  name="email"
                  placeholder='example@site.com'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={Boolean(formik.errors.email) && formik.touched.email}
                  errorMessage={formik.errors.email}
                />
            </S.Form>
            <S.SubmitButton onClick={formik.submitForm}>
              {
                isSigningUp

                  ? <S.AnimationContainer>
                    <Lottie
                        speed={1}
                        style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        options={{
                          animationData: loadingAnimation,
                          autoplay: true,
                        }}
                        isClickToPauseDisabled
                      />
                    </S.AnimationContainer>
                  : `${buttonLabel}`
              }
            </S.SubmitButton>

        </S.Container>
  );
};

export default WaitListForm;
