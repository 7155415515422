import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks';
import clsx from 'clsx';
import { userRequestingNextStep, selectTypeInput, updatingTypeInput } from '@stores';
import { ListingType as ListingTypes } from 'beiytak_sdk';
import useStyles from './ListingType.styles';

interface ListingTypeProps {
  type: ListingTypes
  label: string
}

/** Contains the filters for the Home Criteria step (price etc.) */
const ListingType: FunctionComponent<ListingTypeProps> = (props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentSelection = useAppSelector(selectTypeInput);
  const { type, label } = props;

  const handleOnClick = () => {
    dispatch(updatingTypeInput(type));
  };

  return (

    <div
     className={clsx({
       [classes.container]: true,
       selected: type === currentSelection,
     })}
     onClick={handleOnClick}
    >
      <span>{label}</span>
    </div>

  );
};

export default ListingType;
