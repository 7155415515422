import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    zIndex: 2,
    position: 'absolute',
    top: '2.7rem',
    left: '0.5rem',

    overflow: 'hidden',

    width: '9rem',

    borderRadius: '5px',
    border: `1px solid ${theme.palette.neutral.dark}`,

    background: theme.palette.neutral.light,

  },

  action: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    padding: '0.6rem',

    width: '100%',
    height: '100%',

    ...theme.typography.subtitle1,
    fontSize: '0.8rem',
    color: '#202020',

    '&:hover': {
      background: theme.palette.primary.main,
      color: 'white',
    },

    '&.delete': {
      '&:hover': {
        background: '#CF000F',
        color: 'white',
      },

    },

  },

  icon: {
    marginRight: '0.8rem',

    width: '1.1rem',
    height: '1.1rem',
  },

}));

export default useStyles;
