import { createMachine, InterpreterFrom, State, assign } from 'xstate';
import {
  checkLocationPermission, QueryCheckLocationPermissionArgs,
  CheckLocationPermissionResult, SignUpForFreeTrialResult,
  signUpForFreeTrial, MutationSignUpForFreeTrialArgs, TransportResponse, Routes, OfferDetails } from 'beiytak_sdk';
import { getAPIUrl } from '@utils';

const url = getAPIUrl(Routes.GRAPHQL);

  interface Context {

    /** Whether to display the permission error form (to purhcase or sign up for a free trial) */
    displayPurchaseLocationPermission: boolean,

    /** The location that the user is interested in */
    location: string | null,

    /** If the process started for checking the location permission */
    isCheckingLocationPermission: boolean,

    /** If the user has permission to that location */
    hasPermission: boolean | null,

    /** offers for the user that they can purchase depending on the location type they have selected */
    offers: OfferDetails[] | null,

    /** If an error occured when trying to verify */
    failedToVerify: boolean | null,

    /** If the user is in the process of waiting to sign up for a free trial */
    isSigningUpForFreeTrial: boolean,

    /** If the user sucessfully signed up for the free trial */
    isSignedUpForFreeTrial: boolean | null,
  }

  type Events =
  | {type: 'LOCATION_SET', payload: QueryCheckLocationPermissionArgs }
  | {type: 'LOCATION_UNSET'}
  | {type: 'DISPLAY_PURCHASE_LOCATION_PERMISSION'}
  | {type: 'HIDE_PURCHASE_LOCATION_PERMISSION'}
  | {type: 'RETRY_VERIFICATION', payload: QueryCheckLocationPermissionArgs}
  | {type: 'SIGN_UP_FOR_FREE_TRIAL', payload: MutationSignUpForFreeTrialArgs }
  | {type: 'RETRY_SIGN_UP_FOR_FREE_TRIAL', payload: MutationSignUpForFreeTrialArgs }
  | {type: 'PURCAHSE_LOCATION_PERMISSION', payload: {}}

  type Actions =
  | {type: 'displayPurchaseLocationPermission'}
  | {type: 'hidePurchaseLocationPermission'}
  | {type: 'setLocation'}
  | {type: 'unSetLocation'}
  | {type: 'setIsCheckingLocationPermission'}
  | {type: 'setPermission'}
  | {type: 'setFailedToVerify'}
  | {type: 'setIsSigningUpForFreeTrial'}
  | {type: 'setSignedUpForFreeTrial'}

  type Services =
  {

    checkLocationPermission: {
      // eslint-disable-next-line
      data: Awaited<TransportResponse<CheckLocationPermissionResult>>
    },
    signUpForFreeTrial: {
      // eslint-disable-next-line
      data: Awaited<TransportResponse<CheckLocationPermissionResult>>
    }
  }

  type Guards =
    | {type: 'hasPermission'}
    | {type: 'doesNotHavePermission'}

// eslint-disable-next-line
  export const LocationPermissionMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QBsD2BjAhgFwJaoDsBaABzACcBbXWWfAgOgNWwCUxMIBPAFVQGUO5dAAsAxKwCiAQQAiATQD6PAPKL+M1gGEAEgG0ADAF1EoEqjp5CpkAA9EATgAcARgYB2AGzuXAFgcGAMwGTv6BADQgXIgATACsvgx+DoHuDnEZgd6+AL45kWhYVsRkVDR0hAyiYOgA1rgEUAAyGDj0AAoU1LT0YhCEYAwNAG6otYPVdUSFbYSkXeX0hiZIIOaW9Db2CC5xTp4egXEOLvEO7oFO7pHRO54xDJ4OnsG+Tk6p7jEOufkgM8V5mUepVJvVGi0ih0FiCCH0BkMCKNxlURDVatNWoDSt0KgQ9C4VmYLLhiltELt9odjqdjhcrjdEMFAkkws8XM8fOk8gUsfQgbj6Kj0Q1mnzCJ1gXixBRyKhyAwSMgcAAzeWUYVTAH8nGLQjLGzrUmbVbbSkHC40s7065RWIZR5hd4pGKuX68qFzXWwhgqzC4ZCQPhaNF1SGzAiSwWEMQG1ZGsmmimhB6+GKBQK7TyeXwuFwGOKMhBPDy+TNBdxpjJ+OI8-7ikowvEMACusAoslQcAAciwdJhhmBw8Uo3q4fwAJIAcW7igAqu1FAAxFSsZdSSTKVgT6RNOPEjbWJM7D6phIZOJpAzuAwxXxF3wGA6BBw-dPpoIxPx17VeptCtsOy7WBe2wftB2HaEpV6do520aQdA0RQmhULRpB4CcVFndpJFYABZCd+EnLD9zWElE1AbYv1vBhrxcJwn18fwYncQs7QQV03CrAInmcTxXFrP5f0baDKjoKACFFOcSCXeUl3IMAwB4chcEwZB4QIQYRjGQZhIFMcGHEyTGmk2TyHkxTlNU5AEG0z18WMUiExNSjECIFw0g8HNr1Cei4gzJwiyIfwGDiJ9fMCMtMzzFxPB-Bt9J9IypJkuSFKUlS1I0rSkR0hg9O9ZtkpM1LzPSqy1Ns3L7OWAkiTIw8CHJBB3M8rxH3cXynH8y4iziV1HR+Xw4k8DzYsreL7MSorcAklKzIsjLrJlcg5QVJVVXVfKEsKoViqgUy0sszKbLsiNauMQ1yJcuw3I8hwvI6rqesC9jnFo-ygg+OJ8wMBwYkmiNpqFP0AyDARZoIQ6yuO5anOuo9XIQfynCSS8GIzfjPDCmIi1YhhAhiHNc1zdMfrvPI-mYCA4BsAr-0RhrjUZ7ZgqcB7vE-Y4Am+UJbVudzUlo8LIr2YI-F2QHsQZxhmDYDhuD4QRMGEEQrsa5rOriAnPBCdNnnPfwi3OD7gldc4jnue4pZ1GXNXBMV7NHWF1eZprjzzAJqSceIvHuY42NuGIv0GyL3nZ1wWMCG2-1ExhQcDCBg1DWpIIlGXXYo26OL+jmicvAwmP6rxcbeh7MnCgxC-NssY5E6NGEA8hOx7PsByHBtnbxTObqo3PHnzm8i5Y+4i0xhh-uODMDA8t4gjioSdrt-bocWirkB7lm3KYtxOcJ7nb2cXx+cQUIkiCEJLzSc4XhcOvgcqBPwf4SHV-Kk7N-dpHXXcR0v0rJ4oRJ4nwQCbAsZt2aWitgDReU1dqVCbv2WAadIwZ3jAjL+2cYj9ytgXYeJcgpMVojzfw5xuqukEnkIAA */
  createMachine(
    {
      tsTypes: {} as import('./LocationPermission.machine.typegen').Typegen0,
      schema: {
        context: {} as Context,
        events: {} as Events,
        actions: {} as Actions,
        services: {} as Services,
        guards: {} as Guards,
      },
      id: 'location-permission',
      initial: 'permissionNotChecked',
      context: {
        displayPurchaseLocationPermission: false,
        location: null,
        isCheckingLocationPermission: false,
        hasPermission: null,
        offers: null,
        failedToVerify: null,
        isSigningUpForFreeTrial: false,
        isSignedUpForFreeTrial: null,
      },
      states: {
        permissionNotChecked: {
          on: {
            LOCATION_SET: {
              target: 'checkingLocationPermission',
            },
            RETRY_VERIFICATION: {
              target: 'checkingLocationPermission',
            },
          },
        },
        checkingLocationPermission: {
          entry: ['setLocation', 'setIsCheckingLocationPermission'],
          invoke: {
            src: 'checkLocationPermission',
            id: 'check-location-permission',
            onDone: [
              {
                actions: ['setPermission'],
                target: 'hasPermission',
                cond: 'hasPermission',
              },
              {
                actions: ['setPermission', 'setOffers'],
                target: 'doesNotHavePermission',
                cond: 'doesNotHavePermission',
              },
            ],
            onError: {
              target: 'failedToVerify',
              actions: ['setFailedToVerify'],
            },
          },
        },
        hasPermission: {
          on: {
            LOCATION_UNSET: {
              actions: ['unSetLocation'],
              target: 'permissionNotChecked',
            },
          },
        },
        failedToVerify: {
          type: 'final',
        },
        doesNotHavePermission: {
          on: {
            LOCATION_UNSET: {
              actions: ['unSetLocation'],
              target: 'permissionNotChecked',
            },
            SIGN_UP_FOR_FREE_TRIAL: {
              target: 'signingUpForFreeTrial',
            },
            DISPLAY_PURCHASE_LOCATION_PERMISSION: {
              actions: ['displayPurchaseLocationPermission'],
            },
            HIDE_PURCHASE_LOCATION_PERMISSION: {
              actions: ['hidePurchaseLocationPermission'],
            },
          },
        },
        signingUpForFreeTrial: {
          entry: 'setIsSigningUpForFreeTrial',
          invoke: {
            src: 'signUpForFreeTrial',
            id: 'sign-up-for-free-trial',
            onDone: [
              {
                actions: ['setSignedUpForFreeTrial'],
                target: 'succesfullySignedUpForFreeTrial',
                cond: 'succesfullySignedUpForFreeTrial',
              },
              {
                actions: ['setSignedUpForFreeTrial'],
                target: 'doesNotHavePermission',
                cond: 'failedToSignUpForFreeTrial',
              },
            ],
          },
        },
        succesfullySignedUpForFreeTrial: {
          on: {
            HIDE_PURCHASE_LOCATION_PERMISSION: {
              actions: ['hidePurchaseLocationPermission'],
            },
          },
        },
      },
    },
    {
      actions: {
        displayPurchaseLocationPermission: assign({
          displayPurchaseLocationPermission: (ctx, event) => true,
        }),
        hidePurchaseLocationPermission: assign({
          displayPurchaseLocationPermission: (ctx, event) => false,
        }),
        setLocation: assign({
          location: (ctx, event) => event.payload.location,
          hasPermission: (ctx, event) => null,
          failedToVerify: (ctx, event) => null,
          offers: (ctx, event) => null,
        }),
        unSetLocation: assign({
          location: (ctx, event) => null,
          hasPermission: (ctx, event) => null,
          failedToVerify: (ctx, event) => null,
          isCheckingLocationPermission: (ctx, event) => false,
          offers: (ctx, event) => null,
          isSigningUpForFreeTrial: (ctx, event) => false,
          isSignedUpForFreeTrial: (ctx, event) => null,
        }),
        setIsCheckingLocationPermission: assign({
          isCheckingLocationPermission: (ctx, event) => true,
        }),
        setPermission: assign({
          hasPermission: (ctx, event) => event.data.data !== undefined && !('error' in event.data.data),
          isCheckingLocationPermission: (ctx, event) => false,
          failedToVerify: (ctx, event) => null,
        }),
        setOffers: assign({
          offers: (ctx, event) => {
            if (event.data.data === undefined) { return null; }
            if (event.data.data !== undefined && !('offers' in event.data.data)) { return null; }

            return event.data.data.offers;
          },
        }),
        setFailedToVerify: assign({
          isCheckingLocationPermission: (ctx, event) => false,
          failedToVerify: (ctx, event) => false,
          offers: (ctx, event) => null,
        }),
        setIsSigningUpForFreeTrial: assign({
          isSigningUpForFreeTrial: (ctx, event) => true,
          isSignedUpForFreeTrial: (ctx, event) => null,
          hasPermission: (ctx, event) => null,
          failedToVerify: (ctx, event) => null,
        }),
        setSignedUpForFreeTrial: assign({
          isSigningUpForFreeTrial: (ctx, event) => false,
          isSignedUpForFreeTrial: (ctx, event) => event.data.data !== undefined && !('error' in event.data.data),
          hasPermission: (ctx, event) => event.data.data !== undefined && !('error' in event.data.data),
          isCheckingLocationPermission: (ctx, event) => false,
          failedToVerify: (ctx, event) => null,
        }),
      },
      services: {
        // check if the user has permissiosn for the location
        checkLocationPermission: async (ctx, event) => {
          const { location } = event.payload;

          return checkLocationPermission({ location }, url);
        },

        // sign the user up for a free trial
        signUpForFreeTrial: async (ctx, event) => {
          const { params } = event.payload;

          return signUpForFreeTrial({ params }, url);
        },
      },
      guards: {
        hasPermission: (ctx, event) => event.data.data !== undefined && !('error' in event.data.data),
        doesNotHavePermission: (ctx, event) => event.data.data !== undefined && ('error' in event.data.data),
        succesfullySignedUpForFreeTrial: (ctx, event) => event.data.data !== undefined && !('error' in event.data.data),
        failedToSignUpForFreeTrial: (ctx, event) => event.data.data !== undefined && ('error' in event.data.data),
      },
    },
  );

export const selectDisplayPurchaseLocationPermission = (state: State<Context>) => state.context.displayPurchaseLocationPermission;
export const selectIsCheckingLocationPermission = (state: State<Context>) => state.context.isCheckingLocationPermission;
export const selectHasPermission = (state: State<Context>) => state.context.hasPermission;
export const selectFailedToVerify = (state: State<Context>) => state.context.failedToVerify;
export const selectOffers = (state: State<Context>) => state.context.offers;
export const selectIsSigningUpForFreeTrial = (state: State<Context>) => state.context.isSigningUpForFreeTrial;
export const selectIsSignedUpForFoFreeTrial = (state: State<Context>) => state.context.isSignedUpForFreeTrial;
