import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    overflowX: 'hidden',
    overflowY: 'scroll',

    width: '100%',
    height: '100%',

    '&::-webkit-scrollbar': {

      width: '0.25rem',
      borderRadius: '30px',

    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '30px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '30px',
      background: theme.palette.neutral.dark,
    },

    '&::-webkit-scrollbar-button': {
      height: '1.25rem',
    },
  },

  title: {
    alignSelf: 'flex-start',

    marginLeft: '1rem',
    fontSize: '1.2rem',
    fontWeight: theme.typography.h5.fontWeight,
  },

  filter: {
    width: '100%',

    minHeight: '7.5rem',
    maxHeight: '7.5rem',

    borderBottom: `1px solid ${theme.palette.neutral.dark}`,

    '&.last-filter': {
      borderBottom: 'none',
    },

  },

}));

export default useStyles;
