import { FunctionComponent, useState } from 'react';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import clsx from 'clsx';
import {
  userRequestingToDisplayFavoritesOnly,
  userRequestingToDisplayAllListings,
  selectFavoritesData,
} from '@stores';
import { useAppDispatch, useAppSelector } from '@hooks';
import useStyles from './Favorites.styles';

const Favorites: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { favoriteListings } = useAppSelector(selectFavoritesData);
  const [clicked, setClicked] = useState(false);
  const [hover, setHover] = useState(false);
  const favoriteCount = favoriteListings ? favoriteListings.length : null;

  const handleOnClick = () => {
    const updatedValue = !clicked;
    setClicked(updatedValue);

    if (updatedValue) { dispatch(userRequestingToDisplayFavoritesOnly()); }
    if (!updatedValue) { dispatch(userRequestingToDisplayAllListings()); }
  };

  return (

    <div
      className={clsx({
        [classes.container]: true,
        clicked,
      })}
      aria-label="favorites"
      onClick={handleOnClick}
      onKeyPress={handleOnClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      role="button"
      tabIndex={0}
    >
      <div className={clsx({
        [classes['icon-container']]: true,
        count: favoriteCount,
      })}
      >
        <FavoriteBorderIcon className={clsx({
          [classes.icon]: true,
          hover,
          clicked,
        })}
        />

        {favoriteCount
          ? (
            <div
              className={clsx({
                [classes.count]: true,
                hover,
                clicked,
              })}
            >
              {favoriteCount}
            </div>
          )
          : <></>}
      </div>

    </div>
  );
};

export default Favorites;
