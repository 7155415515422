import { makeStyles } from '@material-ui/core/styles';

// I have to manually specify color for mapbox
// Using theme breaks mapbox's ability to display the component
const useStyles = makeStyles(() => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start',

    // width: '15rem',
    height: '2.75rem',

    border: 'none',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 2px 4px 0px',
    borderRadius: '0.625rem',

    padding: '0rem',

    backgroundColor: '#FFFFFF',
    '&:hover': {
      transform: 'scale(1.06)',
    },
  },

  'icon-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    // Expand to fill the space provide by the parent
    minWidth: '3.2rem',
    maxWidth: '3.2rem',
    height: '100%',

    borderRadius: '0.625rem',
    color: '#FFFFFF',

    fontWeight: 'bold',
    fontSize: '0.75rem',
    lineHeight: '1rem',

    backgroundColor: '#00b3ff',

    // fontWeight: 'bold',
    // fontSize: '0.775rem',
  },

  'content-container': {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',

    overflow: 'hidden',

    minWidth: '5rem',
    height: '100%',

    marginLeft: '0.35rem',
    marginRight: '0.35rem',

    borderRadius: '0rem 0.625rem 0.625rem 0rem',
    backgroundColor: '#FFFFFF',

  },

  icon: {
    color: '#FFFFFF',

    width: '0.9375rem',
    height: '0.9375rem',

    marginTop: '0.125rem',
  },

  title: {

    overflow: 'hidden',

    fontSize: '0.75rem',
    lineHeight: '1rem',
    fontWeight: 'bold',

    textOverflow: 'clip',
    whiteSpace: 'nowrap',

    color: ' #24324f',
  },

  subtitle: {

    overflow: 'hidden',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',

    height: '0.75rem',

    fontSize: '0.7rem',
    lineHeight: '1rem',

    color: 'grey',

  },

  // Additional classes to style icons specific to the type of marker

}));

export default useStyles;
