import { ListingWithID } from '@types';

/**
 * See if the listing has the same styles that have been selected.
 * If no styles are selected, then ignore the filtering
 */
const filterOnStyles = (
  /** listing to evaluate */
  listing: ListingWithID,
  /** current values that the listing should be checked against */
  stylesFilter: string[],
): boolean => {
  let result = false;

  if (stylesFilter.length > 0) {
    const { type: style } = listing;

    if (style) { result = stylesFilter.includes(style); }
    if (!style) { result = false; }
  }
  if (stylesFilter.length === 0) { result = true; }

  return result;
};

export default filterOnStyles;
