import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin-top: 1rem;
    margin-bottom: 0.8rem;

    height: 1.375rem;
`;

export const Icon = styled.img`
    margin-right: 0.75rem;
    width: 1.3125rem;
    height: 1.3125rem;
`;

export const Text = styled.h5`
    font-size: 0.9rem;
    line-height: 1.375rem;
    font-weight: normal;
    color: #606060;
`;
