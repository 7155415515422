import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  RootState,
  userLeavingResults,
  updatingStateWithUserSearchResultsFavorites,
  updatingDataForListingExpanded,
  selectedListingIsChanging,
  selectedFilterChipIsChanging,

} from '@stores';

const leavingSearchResultsMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  if (userLeavingResults.match(action)) {
    next(action);

    const { favoriteListings } = storeAPI.getState().searchResultFavorites;

    // Update the favorites that the user selected in case they navigate back to the results
    // The example here would be if the user went to their dashboard then went back to the results
    storeAPI.dispatch(updatingStateWithUserSearchResultsFavorites(favoriteListings));

    // Clear out the selections if the user made them
    storeAPI.dispatch(selectedFilterChipIsChanging({ filterChipID: null }));
    storeAPI.dispatch(updatingDataForListingExpanded(null));
    storeAPI.dispatch(selectedListingIsChanging(null));
  }

  return next(action);
});

export default leavingSearchResultsMiddleware;
