import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  HomeCriteriaInputChangedPayload,
  HomeCriteriaKeys,
  HomeCriteriaFilters,
  HomeCriteriaInputSlice,
} from '@types';
import { RootState } from '@stores';
import { priceDefaultValue, rentPriceDefaultValue } from '@constants';
import homeFiltersValidation from './services';

// Initial state for store
const initialState: HomeCriteriaInputSlice = {

  defaults: {
    [HomeCriteriaKeys.PRICE]: [0, 1000000],
    [HomeCriteriaKeys.BEDROOMS]: [1, 5],
    [HomeCriteriaKeys.BATHROOMS]: [1, 5],
  },
  value: {
    [HomeCriteriaKeys.PRICE]: [0, 1000000],
    [HomeCriteriaKeys.BEDROOMS]: [1, 5],
    [HomeCriteriaKeys.BATHROOMS]: [1, 5],
  },
  validation: false,
  homeCriteriaStepOpened: false,

};

export const homeCriteriaInputSlice = createSlice({
  name: 'homeCriteriaInput',
  initialState,
  reducers: {

    /**
     * Updates the Home Criteria data when the user changes any of the values
     */
    homeCriteriaInputChanged: (state, action: PayloadAction<HomeCriteriaInputChangedPayload>) => {
      const { type, data } = action.payload;
      const userMax = data[1];
      const defaultMin = state.defaults[type][0];
      const defaultMax = state.defaults[type][1];
      // If the user switched from buy to run (1M/5k) or rent to buy (5k/100K+), don't update the defaults since its not a true user entered max
      const didTypeChange = userMax / defaultMax >= 10 || userMax / defaultMax <= 0.1;

      // Update the state with the new values
      state.value[type] = data;

      if (type === HomeCriteriaKeys.PRICE) {
      // If the user entered a larger max then the default, update the default so it uses the new max
        if ((userMax > defaultMax) && !didTypeChange) { state.defaults[type] = [defaultMin, userMax]; }

        // If the type did change then reset the values of the filters to the defaults
        if (didTypeChange) { state.value[type] = [defaultMin, defaultMax]; }
      }

      // For the other filters, go ahead and update the values
      if (type !== HomeCriteriaKeys.PRICE) {
        if ((userMax > defaultMax)) { state.defaults[type] = [defaultMin, userMax]; }
      }
    },

    /** Update the defaults in the case the user switches between rent/buy */
    updateDefaultForHomeCriteriaFilter: (state, action: PayloadAction<HomeCriteriaInputChangedPayload>) => {
      const { type, data } = action.payload;

      state.defaults[type] = data;
    },

    homeCriteriaStepSelected: (state) => {
      state.homeCriteriaStepOpened = true;
    },

    /**
     * Validate the home filters if the session changed.
     * Since we are tracking whether the step was opened,
     * this action was created to handle that change
     */
    validateHomeCriteriaInput: (state, action: PayloadAction<HomeCriteriaFilters>) => {
      const { payload } = action;
      const { homeCriteriaStepOpened } = state;

      state.validation = homeFiltersValidation(
        payload,
        homeCriteriaStepOpened,
      );
    },

    /** used in case the input needs to manually be invalidates (in the case of switching between listing types) */
    inValidateHomeCriteriaInput: (state) => {
      state.validation = false;
    },

    /** Resets this step when the user wants to create a new search */
    resetHomeCriteriaInput: (state) => {
      state.defaults = {
        [HomeCriteriaKeys.PRICE]: [0, 1000000],
        [HomeCriteriaKeys.BEDROOMS]: [1, 5],
        [HomeCriteriaKeys.BATHROOMS]: [1, 5],
      };
      state.value = state.defaults;
      state.homeCriteriaStepOpened = false;
      state.validation = false;
    },

    /** Bulk updates the home criteria filters in case the user is for example updating a search etc. */
    bulkUpdatingHomeCriteriaFilters: (state, action: PayloadAction<HomeCriteriaFilters>) => {
      const { payload: homeCriteriaFilters } = action;

      state.value = homeCriteriaFilters;
      state.validation = true;

      // Update the defaults for max values if greater than original defaults
      Object.keys(HomeCriteriaKeys).forEach((key) => {
        const userMax = state.value[key][1];
        const defaultMin = state.defaults[key][0];
        const defaultMax = state.defaults[key][1];

        // If the user entered a larger max then the default, update the default so it uses the new max
        if (userMax > defaultMax) { state.defaults[key] = [defaultMin, userMax]; }
      });
    },
  },
});

export const selectHomeCriteriaInputValidation = (state: RootState) => state.homeCriteriaInput.validation;

export const {
  homeCriteriaInputChanged,
  homeCriteriaStepSelected,
  validateHomeCriteriaInput,
  resetHomeCriteriaInput,
  bulkUpdatingHomeCriteriaFilters,
  inValidateHomeCriteriaInput,
  updateDefaultForHomeCriteriaFilter,
} = homeCriteriaInputSlice.actions;

export default homeCriteriaInputSlice.reducer;
