import { FunctionComponent, useState } from 'react';
import Select from '@material-ui/core/Select';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import MenuItem from '@material-ui/core/MenuItem';
import { sortByIsChanging, selectSortBy } from '@stores';
import { useAppDispatch, useAppSelector } from '@hooks';
import { SortByKeysLabel, SortByKeys } from '@types';
import { useStyles, CustomMenuProps } from './SortBy.styles';

const SortBy: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector(selectSortBy);
  const [value, setValue] = useState(sortBy);

  const handleOnChange = (event: any) => {
    const sortByKey: SortByKeys = event.target.value;
    dispatch(sortByIsChanging(sortByKey));
    setValue(sortByKey);
  };

  return (
    <button
    className={classes.container}
    >

      <Select
        value={value}
        onChange={handleOnChange}
        classes={{
          root: classes['drop-down'],
        }}
        disableUnderline
        MenuProps={CustomMenuProps}
        IconComponent={KeyboardArrowDown}
      >
        {Object.keys(SortByKeysLabel).map((key) => (
          <MenuItem
            value={key}
            key={key}
            classes={{
              root: classes.option,
              selected: classes.selected,
            }}
          >
            {SortByKeysLabel[key]}
          </MenuItem>
        ))}
      </Select>
    </button>
  );
};

export default SortBy;
