import {
  Filter,
  StepKeys,
  Formatter,
  FilterValue,
  FilterUsingUserLocationsPayload,
  FilterUsingMoreHomeCriteriaFiltersPayload,
  Icon,
  MoreFilterKeys,
  HomeCriteriaKeys,
  HomeCriteriaInputChangedPayload,
  FilterUsingHomeCriteriaPayload,
  PreparePayload,
} from '@types';
import {
  updatingUserLocationsResultsFilter, updatingMoreResultsFiltersForHomeCriteria,
  homeCriteriaInputChanged,
  updatingHomeCriteriaResultsFilter,
} from '@stores';
import {
  useAppSelector,
} from '@hooks';
import {
  createCommuteLabel, getTravelModeIcon, getHomeCriteriaIcon, getMoreFilterIcon,
  createPriceLabel, getFilterName,
} from '@utils';
import { cloneDeep } from 'lodash';
import {
  rentPriceDefaultValue,
  priceDefaultValue as salePriceDefaultValue,
  bathroomsDefaultValue,
  bedroomsDefaultValue,
} from '@constants';
import { ListingType } from 'beiytak_sdk';

/** Creates a filter for the Home Criteria Input */
const homeCriteriaInputFilterFactory = (filter: HomeCriteriaKeys, type?: ListingType): Filter => {
  const listingType = type || ListingType.Sale;

  const getStepValue = () => {
    let step = 1;

    if (filter === HomeCriteriaKeys.PRICE && listingType === ListingType.Sale) { step = 50000; }
    if (filter === HomeCriteriaKeys.PRICE && listingType === ListingType.Rent) { step = 250; }

    return step;
  };

  const getStepLabel = () => {
    let step = 1;

    if (filter === HomeCriteriaKeys.PRICE && listingType === ListingType.Sale) { step = 100000; }
    if (filter === HomeCriteriaKeys.PRICE && listingType === ListingType.Rent) { step = 500; }

    return step;
  };

  const priceFormatter: Formatter = (value, range, type) => {
    const [min, max] = range;
    let formattedValue = '';

    if (value !== null && value !== undefined) {
    // If the value is equal to the min or max, add a +  sign to the label
      const isValueMax = value === salePriceDefaultValue[1] || value === rentPriceDefaultValue[1] ? '+' : '';
      formattedValue = `${createPriceLabel(value, listingType)}${isValueMax}`;
    }

    return formattedValue;
  };

  const bedBathFormatter: Formatter = (value, range) => {
    const [min, max] = range;
    const [minDefault, maxDefault] = bedroomsDefaultValue;
    const currentMax = maxDefault >= max ? maxDefault : max;
    let formattedValue = '';

    if (value) {
    // If the value is equal to the min or max, add a +  sign to the label
    // @TODO if defaults are ever different, this needs to be separated out
      const isValueMax = value === bedroomsDefaultValue[1] ? '+' : '';
      formattedValue = `${value}${isValueMax}`;
    }

    return formattedValue;
  };

  const prepareHomeCriteriaInputChangePayload: PreparePayload<HomeCriteriaInputChangedPayload> = (values: FilterValue, id: HomeCriteriaKeys) => {
    const payload = {
      type: id,
      // we have to create a copy of the array or else mui selector will throw an error
      data: [...values],
    };

    return payload;
  };

  const homeCriteriaFilter = {
    id: filter,

    name: getFilterName(filter),

    step: getStepValue(),

    stepLabel: getStepLabel(),

    stateRef: () => useAppSelector((state) => state.homeCriteriaInput.value[filter]),

    icon: () => getHomeCriteriaIcon(filter),

    preparePayload: prepareHomeCriteriaInputChangePayload,

    action: (payload: HomeCriteriaInputChangedPayload) => homeCriteriaInputChanged(payload),

    formatter: filter === HomeCriteriaKeys.PRICE ? priceFormatter : bedBathFormatter,

    defaultValues: () => useAppSelector((state) => state.homeCriteriaInput.defaults[filter]),

  };

  return homeCriteriaFilter;
};

/** Creates a filter for the home criteria inputs but for the results
 * This builds off of the home criteria input filter factory but
 * makes adjustments to the actions and state references
 */
const homeCriteriaResultFilterFactory = (filter: HomeCriteriaKeys, type?: ListingType): Filter => {
  const prepareFilterUsingHomeCriteriaPayload: PreparePayload<FilterUsingHomeCriteriaPayload> = (values: FilterValue, id: HomeCriteriaKeys) => {
    const payload: FilterUsingHomeCriteriaPayload = {
      homeCriteriaKey: id,
      // we have to create a copy of the array or else mui selector will throw an error
      filterValue: [...values],
    };

    return payload;
  };

  const homeCriteriaResultFilter = {
    ...homeCriteriaInputFilterFactory(filter, type),
    stateRef: () => useAppSelector((state) => state.searchResultFilters[StepKeys.HOME_CRITERIA][filter]),
    preparePayload: prepareFilterUsingHomeCriteriaPayload,
    action: (payload: FilterUsingHomeCriteriaPayload) => updatingHomeCriteriaResultsFilter(payload),
    defaultValues: () => useAppSelector((state) => state.homeCriteriaInput.value[filter]),
    icon: () => getHomeCriteriaIcon(filter),
  };

  return homeCriteriaResultFilter;
};

/** Creates a a Filter for a user location */
const userLocationFilterFactory = (userAlias: string): Filter => {
  const stateRef = () => useAppSelector((state) => state.searchResultFilters[StepKeys.USER_LOCATIONS].value[userAlias]);

  const icon = () => {
    let result: Icon = '';
    const { userLocationTravelModes } = useAppSelector((state) => state.searchResultSession);
    if (userLocationTravelModes) {
      const travelMode = userLocationTravelModes[userAlias];
      result = getTravelModeIcon(travelMode);
    }
    return result;
  };

  function preparePayload(values: FilterValue, id: string): FilterUsingUserLocationsPayload { return { userAlias: id, filterValue: [...values] }; }

  const action = (payload: FilterUsingUserLocationsPayload) => updatingUserLocationsResultsFilter(payload);

  const defaultValues = () => useAppSelector((state) => state.searchResultFilters[StepKeys.USER_LOCATIONS].default[userAlias]);

  const [minDefault, maxDefault] = defaultValues();

  const formatter: Formatter = (
    value,
    range,
    // Type here is to 'include' or 'exclude' min from the label
    type = 'include',
  ) => {
    const [min, max] = range;
    let formattedValue = '';

    if (value !== null && value !== undefined) {
      const isValueMax = value === max ? '+' : '';

      // If the value is equal to the min or max, add a +  sign to the label
      formattedValue = `${createCommuteLabel(value, type)}${isValueMax}`;
    }

    return formattedValue;
  };

  /** Return the increment of the step labels to display */
  const getStepLabelInterval = (minDefault: number, maxDefault: number) => {
  // THe max number of labels to display on the filter
    const maxStepLabels = 10;
    return maxDefault <= maxStepLabels ? 1 : Math.ceil((maxDefault - minDefault) / maxStepLabels);
  };

  const valueFormatter: Formatter = (value, range, type = 'exclude') => { return formatter(value, range, type); };

  const userLocationFilter = {
    id: userAlias,
    name: userAlias,
    stateRef,
    step: 1,
    stepLabel: getStepLabelInterval(minDefault, maxDefault),
    icon,
    preparePayload,
    action,
    formatter,
    valueFormatter,
    defaultValues,
  };

  return userLocationFilter;
};

/** Creates a Filter for additional home criteria filters */
const moreHomeCriteriaFilterFactory = (filter: string): Filter => {
  const stateRef = () => useAppSelector((state) => {
    let result: FilterValue = [0, 0];
    const filterValue = state.searchResultFilters[StepKeys.MORE][MoreFilterKeys.HOME_CRITERIA].value[filter];
    if (filterValue) { result = filterValue; }

    return result;
  });

  function preparePayload(values: FilterValue, filter: string): FilterUsingMoreHomeCriteriaFiltersPayload { return { filter, filterValue: [...values] }; }

  const action = (payload: FilterUsingMoreHomeCriteriaFiltersPayload) => updatingMoreResultsFiltersForHomeCriteria(payload);

  const getDefaultValues = () => {
    let result = [0, 0];
    const filterValue = useAppSelector((state) => state.searchResultFilters[StepKeys.MORE][MoreFilterKeys.HOME_CRITERIA].default[filter]);
    if (filterValue) { result = [...filterValue]; }

    return result;
  };

  const defaultValues = getDefaultValues();
  const [minDefault, maxDefault] = defaultValues || [0, 0];

  const formatter: Formatter = (
    value,
    range,
    // type here refers to filter
    type,
  ) => {
    const [min, max] = range;
    let formattedValue = '';

    if (value !== null && value !== undefined) {
      const adjustedValue = type === 'lotSqft' ? value * 43560 : value;
      const isValueMax = adjustedValue === max ? '+' : '';

      // labels for lot sqft in acres
      if (type === 'lotSqft' && value < 21780) { formattedValue = `${value.toLocaleString()}${isValueMax}`; }

      // Range is between 0.25 - 1 acre
      if (type === 'lotSqft' && value >= 10890 && value <= 43560) {
        formattedValue = value % 43560 === 0 ? `${(value / 43560)}${isValueMax} acre` : `${(value / 43560).toFixed(2)}${isValueMax} acre`;
      }

      // More than 1 acre
      if (type === 'lotSqft' && value > 43560) {
        formattedValue = value % 43560 === 0 ? `${(value / 43560)}${isValueMax} acres` : `${(value / 43560).toFixed(2)}${isValueMax} acres`;
      }

      if (type !== 'lotSqft')formattedValue = `${value.toLocaleString()}${isValueMax}`;
    }

    return formattedValue;
  };

  const getValueFormatter = (filter: string): Formatter => {
    const valueFormatter: Formatter = (value, range, type = filter) => { return formatter(value, range, type); };
    return valueFormatter;
  };

  const getNameForFilter = (filter: string): string => {
    let name = filter;
    if (filter === 'stories') { name = 'Stories'; }
    if (filter === 'bathsFull') { name = 'Full Baths'; }
    if (filter === 'bathsHalf') { name = 'Half Baths'; }
    if (filter === 'sqft') { name = 'Sqft'; }
    if (filter === 'garageCount') { name = 'Garage Spaces'; }
    if (filter === 'lotSqft') { name = 'Lot Sqft'; }
    if (filter === 'age') { name = 'Home Age'; }

    return name;
  };

  const getStepForFilter = (filter: string): number => {
    let step = 1;
    if (filter === 'stories') { step = 1; }
    if (filter === 'bathsFull') { step = 1; }
    if (filter === 'bathsHalf') { step = 1; }
    if (filter === 'sqft') { step = 1000; }
    if (filter === 'garageCount') { step = 1; }
    if (filter === 'lotSqft') { step = 5445; } // 0.125 acre
    if (filter === 'age') { step = 5; }
    return step;
  };

  const getStepLabelInterval = (filter: string): number => {
    let step = 1;
    if (filter === 'stories') { step = 1; }
    if (filter === 'bathsFull') { step = 1; }
    if (filter === 'bathsHalf') { step = 1; }
    if (filter === 'sqft') { step = 2000; }
    if (filter === 'garageCount') { step = 1; }
    if (filter === 'lotSqft') { step = 43560; }
    if (filter === 'age') { step = 20; }
    return step;
  };

  const moreHomeCriteriaFilter = {
    id: filter,
    name: getNameForFilter(filter),
    stateRef,
    step: getStepForFilter(filter),
    stepLabel: getStepLabelInterval(filter),
    icon: () => getMoreFilterIcon(filter),
    preparePayload,
    action,
    formatter: getValueFormatter(filter),
    valueFormatter: getValueFormatter(filter),
    defaultValues: getDefaultValues,
  };

  return moreHomeCriteriaFilter;
};

export {
  userLocationFilterFactory,
  moreHomeCriteriaFilterFactory,
  homeCriteriaInputFilterFactory,
  homeCriteriaResultFilterFactory,
};
