import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedSearches } from '@types';
import { RootState } from '@stores';
import { SavedSearchStatus, SavedSearch } from 'beiytak_sdk';
import { filterSearchNameFromText, filterSearchStatus } from './services';
import { SavedSearchesFiltersSlice } from './types';

const initialState: SavedSearchesFiltersSlice = {
  searchText: '',
  selectedStatuses: [SavedSearchStatus.Active],
  savedSearchesToDisplay: [],
  savedSearchesSelectedInFavorites: [],
};

export const savedSearchesSliceFilters = createSlice({
  name: 'savedSearchesFilters',
  initialState,
  reducers: {

    /** Updates the value of the user search text */
    updatingSearchText: (state, action: PayloadAction<string>) => {
      const { payload: searchText } = action;
      state.searchText = searchText;
    },

    /** Adds a user search status when the user makes a new selection */
    updatingSelectedSearchStatuses: (state, action: PayloadAction<SavedSearchStatus>) => {
      const { payload: status } = action;
      const { selectedStatuses } = state;
      const alreadySelected = selectedStatuses.includes(status);

      let updatedStatuses: SavedSearchStatus[] = [];

      // if the status is already selected, remove it from the selection
      if (alreadySelected) {
        updatedStatuses = selectedStatuses.filter((selectedStatus) => selectedStatus !== status);
      }

      // If the status is not selected, add it to the list
      if (!alreadySelected) {
        updatedStatuses = [...selectedStatuses, status];
      }

      state.selectedStatuses = updatedStatuses;
    },

    /** Updates the status of retrieving the user searches */
    filteringSavedSearchesToDisplay: (state, action: PayloadAction<SavedSearches>) => {
      const { payload: savedSearches } = action;
      const { searchText, selectedStatuses } = state;
      const searchIds = Object.keys(savedSearches);
      const savedSearchesToDisplay: string[] = [];

      searchIds.forEach((id) => {
        const { searchId, searchName, status: searchStatus } = savedSearches[id];
        let evalNextFilter: boolean;

        evalNextFilter = filterSearchNameFromText(searchName, searchText);
        if (evalNextFilter) { evalNextFilter = filterSearchStatus(searchStatus, selectedStatuses); }
        if (evalNextFilter) { savedSearchesToDisplay.push(searchId); }
      });

      state.savedSearchesToDisplay = savedSearchesToDisplay;
    },

    /** Updates the searches that are selected from the saved searches filter in the favorites section of the dashboard */
    updatingSavedSearchesSelectedInFavorites: (state, action: PayloadAction<string[]>) => {
      const { payload: searchIDs } = action;

      state.savedSearchesSelectedInFavorites = searchIDs;
    },
  },
});

export const selectSavedSearchesToDisplay = (state: RootState) => state.savedSearchesFilters.savedSearchesToDisplay;

export const selectSavedSearchesSelectedInFavorites = (state: RootState) => state.savedSearchesFilters.savedSearchesSelectedInFavorites;

export const selectSavedSearchesFiltersSelectedStatuses = (state: RootState) => state.savedSearchesFilters.selectedStatuses;

export const selectSearchText = (state: RootState) => state.savedSearchesFilters.searchText;

export const {
  updatingSearchText,
  updatingSelectedSearchStatuses,
  filteringSavedSearchesToDisplay,
  updatingSavedSearchesSelectedInFavorites,
} = savedSearchesSliceFilters.actions;

export default savedSearchesSliceFilters.reducer;
