import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    maxWidth: '1250px',

    // @TEMP
    marginTop: '1rem',

  },

  'search-data-container': {

    background: theme.palette.neutral.light,
    borderRadius: '10px',
    boxShadow: '0px 5px 15px rgba(32, 32, 32, 0.05)',

    width: '100%',
    height: '80px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    border: 'none',

  },

  'search-table-container': {

    marginTop: '0.25rem',
    width: '100%',

  },

  'name-and-location': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    marginLeft: '2rem',
    marginRight: '1rem',

    width: '25%',
    height: '100%',
  },

  'search-inputs': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '60%',
    height: '100%',

  },

  actions: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: '15%',

  },

  name: {

    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ...theme.typography.subtitle2,
    fontSize: '1.125rem',
    fontWeight: 200,
    lineHeight: '1.536rem',

  },

  location: {

    overflow: 'hidden',
    textOverflow: 'ellipsis',

    ...theme.typography.subtitle2,
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '1.195rem',

  },

  'user-locations': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: '9rem',
    height: '100%',
    fontSize: '1rem',
  },

  line: {
    border: 'none',
    outline: `1px solid ${theme.palette.neutral.dark}`,
    height: '50%',
  },

  expand: {
    marginRight: '2.3125rem',
    marginLeft: '2.3125rem',
  },

}));

export default useStyles;
