import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    borderRadius: '30px',
    boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',
    background: theme.palette.neutral.light,

    overflow: 'hidden',

    width: '70rem',
    maxWidth: '1120px',
    height: '40rem',
    maxHeight: '640px',

  },

  'saved-search-filters-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: '1rem',
    marginBottom: '1rem',

    width: '100%',
  },

  'saved-searches-list-container': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    overflowX: 'hidden',
    overflowY: 'scroll',

    marginTop: '1rem',

    width: '100%',
    height: '100%',

    // hide the scrollbar but keep functionality
    '&::-webkit-scrollbar': {
      width: '0rem',
      borderRadius: '30px',
    },
    listStyleType: 'none',

  },

  'selected-search-count': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    paddingBottom: '1rem',
    width: '95%',

    borderBottom: '1px solid grey',

    // Same styles as the name of the search
    ...theme.typography.subtitle2,
    fontSize: '1.125rem',
    fontWeight: 200,
    lineHeight: '1.536rem',

  },

  'all-checkbox-root': {

    padding: '0rem',
    marginRight: '1rem',

    '&.MuiCheckbox-colorSecondary': {
      '&:hover': {
        background: 'transparent',
      },
      '&.Mui-checked': {
        '&:hover': {
          background: 'transparent',
        },
      },
    },

  },

  'search-checkbox-root': {

    padding: '0rem',
    marginRight: '1rem',

    // So that the checkbox stays aligned with the search list item
    // and ignores the table when its in view
    height: '6.5rem',

    '&.MuiCheckbox-colorSecondary': {
      '&:hover': {
        background: 'transparent',
      },
      '&.Mui-checked': {
        '&:hover': {
          background: 'transparent',
        },
      },
    },

  },

  'search-item': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    width: '95%',

  },

}));

export default useStyles;
