import { FC } from 'react';
import tick from '../../../assets/tick.svg';
import * as S from './FeatureHighlight.styled';

interface FeatureHighlightProps {
  feature: string
}

const FeatureHighlight: FC<FeatureHighlightProps> = ({ feature }) => {
  return (
        <S.Container>
            <S.Icon src={tick}/>
            <S.Text>{feature}</S.Text>
        </S.Container>
  );
};

export default FeatureHighlight;
