import { createSlice, PayloadAction, unwrapResult } from '@reduxjs/toolkit';
import { remove } from 'lodash';
import { ListingID, SearchResultFavoritesSlice } from '@types';
import { RootState } from '@stores';

// Initial state for store
const initialState: SearchResultFavoritesSlice = {

  // Listings the user favorited
  favoriteListings: [],
  listingAddedToFavorites: null,
  listingRemovedFromFavorites: null,

};

export const searchResultFavoritesSlice = createSlice({
  name: 'searchResultFavorites',
  initialState,
  reducers: {

    /**
     * Adds a listing to the favorites list
     * Payload here is equal to the listing ID
     */
    addingListingToFavorites: (state, action: PayloadAction<ListingID>) => {
      const { payload: listingID } = action;
      const { favoriteListings } = state;

      favoriteListings.push(listingID);

      state.favoriteListings = favoriteListings;
      state.listingAddedToFavorites = listingID;
      state.listingRemovedFromFavorites = null;
    },

    /**
     * Bulk update to update listings to display.
     * This is mainly used to update the favorites when navigating between searches
     */
    updatingFavoriteListings: (state, action: PayloadAction<string[]>) => {
      const { payload: favorites } = action;

      state.favoriteListings = favorites;
    },

    /**
     * Removes a listing from the favorites list
     */
    removingListingFromFavorites: (state, action: PayloadAction<ListingID>) => {
      const { payload: listingID } = action;
      const { favoriteListings } = state;

      const updatedFavorites = remove(favoriteListings, (listing) => listing !== listingID);

      // If the object still has listings, return it
      // If not, set the value to null

      state.favoriteListings = updatedFavorites;

      // Update state with the listing being removed from favorites
      state.listingRemovedFromFavorites = listingID;
      state.listingAddedToFavorites = null;
    },

    // Clears out the favorites.
    // This is usually done when a new search occurs
    clearingUsersFavorites: (state) => {
      state.favoriteListings = [];
      state.listingRemovedFromFavorites = null;
      state.listingAddedToFavorites = null;
    },

  },

});

export const selectFavoritesData = (state: RootState) => {
  return {
    favoriteListings: state.searchResultFavorites.favoriteListings,
    listingAddedToFavorites: state.searchResultFavorites.listingAddedToFavorites,
    listingRemovedFromFavorites: state.searchResultFavorites.listingRemovedFromFavorites,
  };
};

export const {
  addingListingToFavorites,
  removingListingFromFavorites,
  clearingUsersFavorites,
  updatingFavoriteListings,
} = searchResultFavoritesSlice.actions;

export default searchResultFavoritesSlice.reducer;
