import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexWrap: 'nowrap',

    overflowX: 'auto',
    overflowY: 'hidden',

    borderRadius: '30px',

    '&::-webkit-scrollbar': {
      height: '5px',
      background: theme.palette.neutral.dark,
      borderRadius: '100px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '100px',
    },

  },

  chip: {

    margin: '0.625rem',

    height: '2.5rem',
    maxHeight: '40px',

    border: '1px solid black',
    background: theme.palette.neutral.light,

    '&&:focus': {
      backgroundColor: theme.palette.neutral.light,
    },

  },

  deleteIcon: {

    color: 'black',

    '&:hover': {
      color: theme.palette.primary.main,
    },

  },

}));

export default useStyles;
