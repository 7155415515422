import { FunctionComponent } from 'react';
import { Routes } from '@types';
import { userNavigatingToStartNewSearch } from '@stores';
import { useAppDispatch } from '@hooks';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router';
import useStyles from './NewSearchButton.styles';

/** The user search screen aka the landing page of the app */
const NewSearchButton: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOnClick = () => {
    dispatch(userNavigatingToStartNewSearch());
    navigate(Routes.SEARCH);
  };

  return (

    <Button
        variant="contained"
        disableElevation
        classes={{
          root: classes.button,
        }}
        onClick={handleOnClick}
    >
    + New Search
    </Button>

  );
};

export default NewSearchButton;
