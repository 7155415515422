import { FC } from 'react';
import Lottie from 'react-lottie';
import { SectionInfo } from '@components';
import * as S from './WaitListFormSuccess.styled';
import popperAnimation from '../../../assets/celebration-popper.json';

const info = {
  title: 'You\'re in!',
  subtitle: 'You\'ll receive an email on next steps',
};

const WaitListFormSuccess: FC = () => {
  return (
        <S.Container>
            <SectionInfo {...info}/>
            <S.AnimationContainer>
                <Lottie
                speed={1}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                options={{
                  animationData: popperAnimation,
                  autoplay: true,
                  loop: true,
                }}
                isClickToPauseDisabled
                />
            </S.AnimationContainer>
        </S.Container>
  );
};

export default WaitListFormSuccess;
