import styled, { css } from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    height: 100%;
`;

export const Break = styled.div`
    width: 100vw;
    height: 10vh;
`;

export const SectionBreak = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-top: 2rem;
    margin-bottom: 2rem;

    width: 100vw;
    height: 25vh;


    font-size: 2.5rem;
    text-align: center;
    // text-decoration: underline;
`;
