import styled from 'styled-components';

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;

width: 100%;
height: 100%;
`;

export const Title = styled.h1`
margin: 0rem;
margin-bottom: 0.75rem;

font-size: 2.8rem;
font-weight: 800;
// line-height: 4.75rem;
color: #202020;
`;

export const SubTitle = styled.h4`
margin: 0rem;

font-size: 1.25rem;
font-weight: 400;
font-style: normal;
line-height: 1.875rem;
color: #606060;
`;

export const Highlight = styled.span<{color: string}>`
color: ${(p) => p.color};
`;
