import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Routes,
  getSavedSearches as getSavedSearchesSdk, SavedSearch,
  GetSavedSearchesFilters,
} from 'beiytak_sdk';
import { getAPIUrl } from '@utils';

const GetSavedSearchesAction = 'savedSearches/getSavedSearches';

/** Gets saved searches for the user */
const getSavedSearches = createAsyncThunk(
  GetSavedSearchesAction,
  async (filters: GetSavedSearchesFilters): Promise<SavedSearch[]> => {
    const url = getAPIUrl(Routes.GRAPHQL);

    const { data: savedSearches } = await getSavedSearchesSdk({ filters }, url);

    return savedSearches || [];
  },
);

export { getSavedSearches, GetSavedSearchesAction };
