import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    overflowX: 'hidden',
    overflowY: 'auto',

    width: '47rem',
    maxWidth: '784px',
    minHeight: '8rem',
    maxHeight: '410px',

    border: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '15px',

    boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',
    background: theme.palette.neutral.light,

    '&::-webkit-scrollbar': {
      width: '0.25rem',
      borderRadius: '30px',
    },

    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '30px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '30px',
      background: theme.palette.neutral.dark,
    },

    '&::-webkit-scrollbar-button': {
      height: '1.25rem',
    },
  },

  filter: {
    width: '100%',
    height: '7rem',

    borderBottom: `1px solid ${theme.palette.neutral.dark}`,

    '&.last-filter': {
      borderBottom: 'none',
    },
  },

}));

export default useStyles;
