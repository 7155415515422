import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { TintSlice, DisplayTintPayload } from './types';

const initialState: TintSlice = {
  display: false,
  styles: undefined,
  onClickAction: undefined,
};

export const tintSlice = createSlice({
  name: 'tint',
  initialState,
  reducers: {

    displayTint: (state, action: PayloadAction<DisplayTintPayload>) => {
      const { styles, onClickAction } = action.payload;

      state.display = true;
      state.styles = styles;
      state.onClickAction = onClickAction;
    },

    closeTint: (state) => {
      state.display = false;
      state.styles = undefined;
      state.onClickAction = undefined;
    },

  },
});

export const selectTintParams = (state: RootState) => {
  return {
    display: state.tint.display,
    styles: state.tint.styles,
    onClickAction: state.tint.onClickAction,
  };
};

export const tintIgnoreSerializableCheck = [
  'tint/displayTint',
];

export const {
  displayTint,
  closeTint,
} = tintSlice.actions;

export default tintSlice.reducer;
