import { FunctionComponent, useState, useCallback } from 'react';
import { IconButton } from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import clsx from 'clsx';
import {
  FavoriteListing, Images, ListingDetailSummary,
} from '@components';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  selectedListingIsChanging,
  userExpandingListing,
  listingBeingHovered,
  listingNoLongerBeingHovered,
  selectListingSelected,
} from '@stores';
import { Row, ImageType } from '@types';
import useStyles from './ListingCard.styles';

interface ListingCardProps {
  data: Row,
  disableFavorites?: boolean,
}

/**
 * This is a card that contains all the listing information thats displayed
 * in the results list area
 */
const ListingCard: FunctionComponent<ListingCardProps> = ({ data, disableFavorites }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [hover, setHover] = useState(false);

  const listingSelected = useAppSelector(selectListingSelected);
  const { listing } = data;
  const { photos, id } = listing;
  const currentListingSelected = listingSelected === id;

  // Emit event when a specific listing is selected if an id is provided.
  // This is used primarily when in the results list
  const handleOnClickListing = useCallback(() => {
    dispatch(selectedListingIsChanging(id));
  }, []);

  // Emit event that the user wants to see a listing in full view
  const handleOnClickExpand = useCallback(() => {
    dispatch(userExpandingListing(id));
  }, []);

  const handleOnMouseEnter = useCallback(() => {
    setHover(true);
    dispatch(listingBeingHovered(id));
  }, []);

  const handleOnMouseLeave = useCallback(() => {
    setHover(false);
    dispatch(listingNoLongerBeingHovered(id));
    dispatch(listingBeingHovered(null));
  }, []);

  return (
    <div
      className={clsx({
        [classes.container]: true,
        selected: currentListingSelected,
      })}
      id={`listing-${id}`}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      {hover
        ? (
          <IconButton
            classes={{ root: classes.expand }}
            onClick={handleOnClickExpand}
            aria-label="expand"
          >
            <ArrowForward className={classes['expand-icon']} />
          </IconButton>
        )
        : <></>}

      {!disableFavorites
        ? <div className={classes.favorite} aria-label="favorite">
            <FavoriteListing {...{ id }} />
          </div>
        : <></>
      }

      <div className={classes.images} aria-label="images">
        <Images {...{ images: photos, id, type: ImageType.CARD }} />
      </div>

      <div
        className={classes.listingContainer}
        onClick={handleOnClickListing}
        onKeyPress={handleOnClickListing}
        role="button"
        tabIndex={0}
      >

      <ListingDetailSummary {...listing}/>

      </div>
    </div>
  );
};

export default ListingCard;
