import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import {
  RootState,
  userNavigatingToUpdateSearch,
  resetUserLocationInputSession,
  bulkUpdatingHomeCriteriaFilters,
  locationInputChanged,
  homeCriteriaStepSelected,
  bulkUpdatingOfUserLocationInput,
  updatingTypeInput,
  statusChangeAroundIfUserIsUpdatingSavedSearch,
  resetAllSearchResultFilters,
} from '@stores';
import { StepKeys } from '@types';

const navigatingToUpdateSearchMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /** Updates all the inputs based on the inputs that were used during the search */
  if (userNavigatingToUpdateSearch.match(action)) {
    const { userSearchInput } = storeAPI.getState().userSearchResult;

    if (userSearchInput) {
      storeAPI.dispatch(updatingTypeInput(userSearchInput[StepKeys.TYPE]));

      storeAPI.dispatch(locationInputChanged(userSearchInput[StepKeys.LOCATION]));

      storeAPI.dispatch(bulkUpdatingHomeCriteriaFilters(userSearchInput[StepKeys.HOME_CRITERIA]));
      storeAPI.dispatch(homeCriteriaStepSelected());

      storeAPI.dispatch(bulkUpdatingOfUserLocationInput(userSearchInput[StepKeys.USER_LOCATIONS]));
      storeAPI.dispatch(resetUserLocationInputSession());

      storeAPI.dispatch(statusChangeAroundIfUserIsUpdatingSavedSearch(true));

      storeAPI.dispatch(resetAllSearchResultFilters());

      return next(action);
    }
  }
  return next(action);
});

export default navigatingToUpdateSearchMiddleware;
