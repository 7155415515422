import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { Routes, verifyAuth as verifyAuthRequest } from 'beiytak_sdk';
import { VerifyAuthResult, Navigation } from './types';
import { getAPIUrl } from '../../../../utils';

/**
 * So this can be referenced later to ignore the action from serialization because
 * the history object is part of the payload
 */
const VerifyAction = 'auth/verifyAuth';

/**
 * Sends a request to login the user.
 * If successful, a JWT will be saved in an httpOnly cookie
 */
const verifyAuth = createAsyncThunk<VerifyAuthResult, Navigation, { rejectValue: VerifyAuthResult }>(
  VerifyAction, async (navigation, { rejectWithValue }) => {
    const url = getAPIUrl(Routes.VERIFY);
    const { navigate, pathname } = navigation;

    try {
      const status = await verifyAuthRequest(url)
        .then((res) => res.status) // on success
        .catch((err) => StatusCodes.BAD_GATEWAY);

      const result = {
        status,
        navigation: {
          navigate,
          pathname,
        },
      };

      return result;
    } catch {
      // Notify redux of rejected request
      const status = StatusCodes.UNAUTHORIZED;

      const result: VerifyAuthResult = {
        status,
        navigation: {
          navigate,
          pathname,
        },
      };

      return rejectWithValue(result);
    }
  },
);

export {
  verifyAuth,
  VerifyAction,
};
