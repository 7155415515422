import { FC } from 'react';
import * as S from './LandingImage.styled';
import src from '../../../assets/home_background.png';

const LandingImage: FC = () => {
  return (
        <S.Container>
            <S.Image src={src} />
        </S.Container>
  );
};

export default LandingImage;
