import { FC } from 'react';
import { ThankYouForSigningUp } from '@components';
import { useNavigate } from 'react-router';
import * as S from './ThankyouScreen.styled';

const ThankyouScreen: FC = () => {
  return (
        <S.Container>
          <S.ThankYouContainer>
            <ThankYouForSigningUp />
          </S.ThankYouContainer>
        </S.Container>
  );
};

export default ThankyouScreen;
