import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    alignContent: 'center',

    width: '100%',
    height: '1.1875rem',

  },

  divider: {
    height: '0.625rem',
    outline: '1px solid #E5E5E5',

    marginRight: '0.6rem',
    marginLeft: '0.6rem',
  },

  label: {
    // Space between the icon and text
    marginLeft: '0.25rem',
  },

  icon: {
    minWidth: '0.7rem',
    maxWidth: '0.7rem',

    minHeight: '0.7rem',
    maxHeight: '0.7rem',
  },

}));

export default useStyles;
