import React, { FunctionComponent, useState } from 'react';
import { SavedSearchStatus } from 'beiytak_sdk';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import ActionList from './ActionList';
import useStyles from './Actions.styles';

interface ActionsProps {
  searchId: string,
  currentStatus: SavedSearchStatus,
}

const Actions: FunctionComponent<ActionsProps> = (props) => {
  const classes = useStyles();
  const { searchId, currentStatus } = props;
  const [displayActions, setDisplayActions] = useState(false);

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // So the saved search item doesn't expand when this is clicked
    setDisplayActions(!displayActions);
  };

  return (

    <div
    className={classes.container}
    onClick={(event) => handleOnClick(event)}
    >
        <IconButton
        disableRipple
        >
            <MoreHorizIcon />
        </IconButton>
        {
          displayActions
            ? <ActionList {...{ searchId, currentStatus }}/>
            : <></>
        }
    </div>

  );
};

export default Actions;
