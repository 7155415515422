import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
`;

export const IconContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-right: 1rem;

    width: 3.75rem;
    height: 3.75rem;

    background-color: #FFFFFF;
    border: none;
    border-radius: 15px;
    box-shadow: rgba(73, 204, 249, 0.1) 0px 10px 25px 0px;
`;

export const Icon = styled.img`
    width: 1.8rem;
    height: 1.8rem;
    object-fit: contain;
`;

export const UseCaseContentCont = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
`;

export const UseCaseTitle = styled.div`
    font-size: 1rem;
    font-weight: 600;
    text-align: left;
`;

export const UseCaseDescription = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    text-align: left;
`;
