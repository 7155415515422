import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    align-content: center;

    width: 100%;
    height: 100%;
`;

export const Input = styled.input<{disabled: boolean}>`

    width: 4rem;
    height: 2rem;


    border-radius: 5px;
    border: ${({ disabled }) => `1px solid ${disabled ? $theme('palette.neutral.dark') : $theme('palette.primary.main')}`} ;
    background: ${({ disabled }) => `${disabled ? $theme('palette.neutral.main') : '#FCFCFC'}`} ;

    text-align: center;
    font-size: 0.9rem;
    white-space: nowrap;

    &:focus-visible {
        outline: 1px solid ${$theme('palette.primary.main')} ;
    }
`;

export const Dash = styled.span``;
