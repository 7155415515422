import styled from 'styled-components';
import { devices } from '@styleGuide';

export const Container = styled.div`
display: grid;
grid-template-areas:
"content"
;
place-items: center;
position: relative;

width: 100vw;
height: 100vh;

background: #fff7f5;
`;

export const ThankYouContainer = styled.div`
grid-area: content;

width: 90%;
max-width: 300px;
height: 100%;
max-height: 600px;

padding: 1rem;

box-shadow: 0 24px 64px #26214a1a;
background-color: #FFFFFF;
border-radius: 10px;
border: none;


@media (${devices.laptopAndAbove}) {
    max-width: 480px;
    max-height: 550px;
}
`;
