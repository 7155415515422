import { FC } from 'react';
import * as S from './UseCase.styled';

interface UseCaseProps {
  icon: any,
  title: string,
  description: string,
}

const UseCase: FC<UseCaseProps> = ({ icon, title, description }) => {
  return (

        <S.Container>
            <S.IconContainer>
                <S.Icon src={icon}/>
            </S.IconContainer>

            <S.UseCaseContentCont>
                <S.UseCaseTitle>{title}</S.UseCaseTitle>
                <S.UseCaseDescription>{description}</S.UseCaseDescription>
            </S.UseCaseContentCont>

        </S.Container>

  );
};

export default UseCase;
