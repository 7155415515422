import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  button: {

    maxWidth: '100%',
    minWidth: '100%',

    color: theme.palette.primary.main,

    '&:hover': {
      backgroundColor: theme.palette.neutral.light,
    },

    '&.submit': {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
  },
}));

export default useStyles;
