import { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';

import { TravelModes as TravelModeOptions } from 'beiytak_sdk';
import TravelModeOption from './TravelModeOption';
import useStyles from './TravelModes.styles';

/**
 * Input for the user to name their location
 * @component
 */
const TravelModes: FunctionComponent = () => {
  const classes = useStyles();

  return (
  <div className={classes.container}>
    <TravelModeOption {...{ travelMode: TravelModeOptions.Driving, position: 'left' }} />
    <TravelModeOption {...{ travelMode: TravelModeOptions.Walking, position: 'middle' }} />
    <TravelModeOption {...{ travelMode: TravelModeOptions.Transit, position: 'right' }} />
  </div>
  );
};

export default TravelModes;
