import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
    position: fixed;
    top: 5rem;
    z-index: 10;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas:
    ". content close"
    ;

    width: 100%;
    height: 3rem;

    background-color: ${$theme('palette.neutral.main')};

    box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%);
    font-size: 1rem;
`;

export const PlaceHolderContainer = styled.div`
    grid-area: '.';
`;

export const ContentContainer = styled.div`
    grid-area: 'content';

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const Text = styled.div`
    width: max-content;
`;

export const Highlight = styled.span`
    text-decoration: underline;
    color: ${$theme('palette.primary.main')}
`;

export const Contact = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    margin-right: 0.5rem;
    margin-left: 0.5rem;

    width: 6rem;
    height: 2.2rem;

    border-radius: ${$theme('border.radius.10')};
    border: 1px solid ${$theme('palette.neutral.dark')};
    background-color: ${$theme('palette.neutral.light')};
    box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%);

    color: #202020;

    font-size: 1rem;
    font-weight: normal;

    cursor: pointer;

    &:hover {
      background-color: ${$theme('palette.neutral.light')};
      border: 1px solid ${$theme('palette.primary.main')};
      color: ${$theme('palette.primary.main')};
      transform: scale(1);
    }
`;

export const Close = styled.button`
    grid-area: 'close';
    justify-self: right;
    align-self: center;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-right: 1rem;

    border-radius: 50%;
    border: 1px solid ${$theme('palette.neutral.dark')};
    background-color: ${$theme('palette.neutral.light')};
    box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%);

    width: 2rem;
    height: 2rem;

    cursor: pointer;

    font-size: 1.2rem;
    color: #202020;

    &:hover {
      border: 1px solid ${$theme('palette.primary.main')};
      color: ${$theme('palette.primary.main')};
    }
`;
