import { InformationDetail } from '@types';
import { ListingInfo } from '../types';

/** Formats the listing info to be displayed in the listing detail section of the card */
const formatListingInfo = (listingInfo: ListingInfo): InformationDetail => {
  const { title, iconSource, data } = listingInfo;
  const details: string[] = [];

  // loop through each of the values and only push values that are available to display
  data.forEach((info) => {
    const { label, value, suffix } = info;
    if (value) {
      const valueCheck = value.toLocaleString().toLocaleLowerCase();

      if (valueCheck !== 'null' && valueCheck !== 'undefined') {
        let detail = '';
        if (label) { detail = `${label}: `; }
        detail = `${detail}${value.toLocaleString()}`;
        if (suffix) { detail = `${detail} ${suffix}`; }

        details.push(detail);
      }
    }
  });

  const informationDetail = {
    title,
    iconSource,
    details,
  };

  return informationDetail;
};

export default formatListingInfo;
