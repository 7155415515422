import { FC } from 'react';
import {
  LandingText, CreateAccountSignUp, PatentPending, LandingImage,
  FeatureHighlight,
} from '@components';
import { CreateAccountService } from '@types';
import * as S from './LandingLayout.styled';

interface LandingLayoutProps {
  createAccountService: CreateAccountService
}

const featuresToHighlight = [
  'Automated Reports',
  'Buy & Rent Options',
  'Saved Searches',
];

const LandingLayout: FC<LandingLayoutProps> = ({ createAccountService }) => {
  return (
    <S.Container>

        <S.ContentContainer>

          <S.LandingTextContainer>
            <LandingText/>
            <CreateAccountSignUp {...{ createAccountService }}/>

            <S.FeaturesContainer>
              {
                featuresToHighlight.map((feature) => <FeatureHighlight {...{ feature }} key={feature}/>)
              }
            </S.FeaturesContainer>
          </S.LandingTextContainer>

          <S.PatentContainer>
            <PatentPending />
          </S.PatentContainer>

        </S.ContentContainer>

          <S.ImageContainer>
            <LandingImage />
          </S.ImageContainer>

    </S.Container>
  );
};

export default LandingLayout;
