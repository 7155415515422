import { FC } from 'react';
import { LandingButton, AnimationContainer } from '@components';
import { Routes } from '@types';
import { useNavigate } from 'react-router';
import supportAnimation from '../../assets/support.json';
import * as S from './ErrorLayout.styled';

const ErrorLayout: FC = () => {
  const navigate = useNavigate();
  const title = 'Hmm something seems off 🤔';
  const subtitle = 'There may not be any listings using the criteria you provided. We also only currently support US listings. Try your search again with a different set of criteria and if you still have issues, please reach out to support@findur.io';

  return (
        <S.Container>
          <AnimationContainer {...{ title, subtitle, animation: supportAnimation }}>
            <S.LoginContainer>
                <LandingButton {...{ variant: 'orange', text: 'Go Back' }} onClick={() => navigate(Routes.SEARCH)}/>
              </S.LoginContainer>
          </AnimationContainer>
        </S.Container>
  );
};

export default ErrorLayout;
