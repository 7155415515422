import {
  displayTint,
  closeTint,
  updatingDataForListingExpanded,
} from '@stores';
import { DisplayTintPayload } from '@types';
import { MiddleWareWorkflow } from '../../types';

/** If a listing card is expanded into full view, display the tint to improve focus */
const tintControlForExpandingListingWorkflow: MiddleWareWorkflow = (storeAPI, next, action): any => {
  if (updatingDataForListingExpanded.match(action)) {
    const listing = action.payload;

    if (!listing) { storeAPI.dispatch(closeTint()); }

    if (listing) {
      const displayTintPayload: DisplayTintPayload = {
        styles: { top: '0rem', zIndex: 2 },
        onClickAction: () => updatingDataForListingExpanded(null),
      };

      storeAPI.dispatch(displayTint(displayTintPayload));
    }
  }

  return null;
};

export default tintControlForExpandingListingWorkflow;
