import { FilterValue, ListingWithID, IndexableObject } from '@types';
import { every } from 'lodash';
import { isDataWithinRange } from '.';
import { homeCriteriaFiltersMapping } from '../constants';

/**
  * Loop through each home criteria filter and see if the listing falls within the range
  * "every" here checks that each home criteria filter returns true in its check
  */
const filterOnHomeCriteria = (
  /** Listing to evaluate */
  listing: ListingWithID,
  /** Default values for the filter where the key is the filter */
  homeCriteriaFilterDefaults: IndexableObject<FilterValue>,
  /** Current value for the filter where the key is the filter */
  homeCriteriaFilterValues: IndexableObject<FilterValue>,
): boolean => {
  const result = every(Object.keys(homeCriteriaFiltersMapping).map((homeCriteriaFilter) => {
    const listingObjectKey = homeCriteriaFiltersMapping[homeCriteriaFilter];
    const listingValue = listing[listingObjectKey];

    const currentFilterValue = homeCriteriaFilterValues[homeCriteriaFilter];
    const defaultFilterValue = homeCriteriaFilterDefaults[homeCriteriaFilter];

    return isDataWithinRange(listingValue, currentFilterValue, defaultFilterValue);
  }));

  return result;
};

export default filterOnHomeCriteria;
