import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
`;

export const ListingFullViewContainer = styled.div`
    position: fixed;
    top: 7rem;
    right: 10rem;
    z-index: 99;

    width: 80vw;
    height: 80vh;
`;

export const OptionsContainer = styled.div`
    width: 98%;
`;

export const ListContainer = styled.div`
    padding: 0px;

    width: 100%;
    height: calc(100vh - 10.5rem);
`;

export const ResultListContainer = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    align-content: flex-start;

    padding: 0px;

    width: 100%;

    &&::-webkit-scrollbar-thumb {
        background: ${$theme('palette.neutral.dark')};
        border-radius: 30px;
    };

    &::-webkit-scrollbar-track {
        border-radius: 30px;
        background: ${$theme('palette.neutral.main')};
    };
`;

export const ItemContainer = styled.div`
    width: calc(100% * (1/2) - 1.25rem * 2);
    min-width: 18.75rem;
    height: 17.5rem;
    min-height: 17.5rem;
    max-height: 17.5rem;

    margin-bottom: 1.25rem;
`;
