import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedSearchStatus } from 'beiytak_sdk';
import { RootState } from '@stores';
import { NavigateFunction } from 'react-router';
import { SortByKeys, SearchResultSessionSlice, RequestStatus } from '@types';
import { SelectedFilterChipIsChangingPayload, UpdatingDataForSearchResultSessionPayload } from './types';
import formatUserSearchResults from './services';

// Initial state for store
const initialState: SearchResultSessionSlice = {

  // Normalized / formatted data
  userSearchResults: null,
  planDetails: null,
  searchName: null,
  searchId: null,
  searchStatus: null,
  savedSearchRequestStatus: null,
  isUserUpdatingSavedSearch: false,

  listingBoundingBox: null,
  userLocationFilterData: null,
  userLocationTravelModes: null,

  // What the user sees on the results page
  listingsToDisplay: null,
  userLocationsToDisplay: null,

  // Listing thats displayed in expanded view
  listingExpanded: null,

  // Interaction with the listings
  listingSelected: null,
  previousListingSelected: null,
  listingHovered: null,
  previousListingHovered: null,

  // Session data related to the results list
  displayFavoritesOnly: false,
  sortBy: SortByKeys.LIST_DATE,

  // Filters
  filterChipSelected: null,
};

export const searchResultSessionSlice = createSlice({
  name: 'searchResultSession',
  initialState,
  reducers: {

    /**
     * Update the state with the results that were returned
     */
    updatingDataForSearchResultSession: (state, action: PayloadAction<UpdatingDataForSearchResultSessionPayload>) => {
      const { payload } = action;
      const { userSearchResults, savedSearchFavorites, planDetails } = payload;
      const listingsAvailable = (userSearchResults && userSearchResults.length > 0) || (savedSearchFavorites && savedSearchFavorites.length >= 0);

      if (listingsAvailable) {
        const [
          listingsToDisplay,
          listingBoundingBox,
          userLocationFilterData,
          userLocationTravelModes,
        ] = formatUserSearchResults(userSearchResults, savedSearchFavorites);

        state.userSearchResults = listingsToDisplay;
        state.planDetails = planDetails || null;
        state.listingsToDisplay = Object.keys(listingsToDisplay);
        state.listingBoundingBox = listingBoundingBox;
        state.userLocationFilterData = userLocationFilterData;
        state.userLocationTravelModes = userLocationTravelModes;
      }

      if (!listingsAvailable) {
        state.userSearchResults = null;
        state.planDetails = null;
        state.listingsToDisplay = null;
        state.listingBoundingBox = null;
        state.userLocationFilterData = null;
        state.userLocationTravelModes = null;
      }
    },

    /**
     * Updates the user locations to display based on the listing thats been selected.
     * The pay expects a string which represents the ID of the listing
     */
    updatingUserLocationsToDisplay: (state, action: PayloadAction<string | null>) => {
      const { payload: selectedListingID } = action;

      // If the listing selected is not null and there is data to display,
      // Update the user locations to display on the map
      if (selectedListingID && state.userSearchResults) {
        state.userLocationsToDisplay = state.userSearchResults[selectedListingID].userLocations;
      }

      // If the user de-selects the listing then clear out the user location data
      if (!selectedListingID) {
        state.userLocationsToDisplay = null;
      }
    },

    /**
     * Updates the listings to display
     */
    updatingListingsToDisplay: (state, action: PayloadAction<string[]>) => {
      const { payload: listingsToDisplay } = action;
      state.listingsToDisplay = listingsToDisplay;
    },

    // Clears out any previous result data before a new search takes place
    clearingResultsDataBeforeSearch: (state) => {
      state.userSearchResults = null;
      state.listingBoundingBox = null;

      state.listingsToDisplay = null;
      state.userLocationsToDisplay = null;
    },

    /**
     * Updates the data for the listing thats been expanded
     */
    updatingDataForListingExpanded: (state, action: PayloadAction<string | null>) => {
      const { payload: listingID } = action;

      if (listingID && state.userSearchResults) {
        const listing = state.userSearchResults[listingID];
        state.listingExpanded = listing;
      }

      if (!listingID) {
        state.listingExpanded = null;
      }
    },

    /**
     * Handles the users interaction when they click on a listing
     * */
    selectedListingIsChanging: (state, action: PayloadAction<string | null>) => {
      const { payload: selectedListingID } = action;
      const { listingSelected: currentListingSelectedState } = state;

      /**
            * If the marker was clicked again,
            * mean the previous selected listing state and the current are equal,
            * set the listing selected to null to reset everything
            */
      if (selectedListingID === currentListingSelectedState) {
        state.listingSelected = null;
        state.previousListingSelected = selectedListingID;
      } else {
        // Save the previous listing selected & update the new one selected
        state.previousListingSelected = state.listingSelected;
        state.listingSelected = selectedListingID;
      }
    },

    /**
     * Handles the users interaction with the chip filters
     */
    selectedFilterChipIsChanging: (state, action:PayloadAction<SelectedFilterChipIsChangingPayload>) => {
      const { filterChipID } = action.payload;
      state.filterChipSelected = filterChipID;
    },

    /**
     Action to filter the results to only display the user's favorites listing
    */
    userRequestingToDisplayFavoritesOnly: (state) => {
      state.displayFavoritesOnly = true;
    },

    /**
     * Action to display all the listings if the user was viewing only favorites
     */
    userRequestingToDisplayAllListings: (state) => {
      state.displayFavoritesOnly = false;
    },

    /**
         * The user requests to sort the results by some field
         */
    sortByIsChanging: (state, action:PayloadAction<SortByKeys>) => {
      const { payload } = action;

      state.sortBy = payload;
    },

    /**
     * Updates state with the listing ID of the listing being hovered in the results list
     */
    listingBeingHovered: (state, action: PayloadAction<string | null>) => {
      const { payload: listingID } = action;
      state.listingHovered = listingID;
    },

    /**
         * Updates state with the listing ID of the listing that was previously hovered
         */
    listingNoLongerBeingHovered: (state, action: PayloadAction<string | null>) => {
      const { payload: listingID } = action;
      state.previousListingHovered = listingID;
    },

    /**
     * Clears out any session data thats not needed before the search.
     * Doing this ensures the results are not effected by any previous searches
     */
    clearingSessionDataBeforeSearch: (state) => {
      state.listingSelected = null;
      state.previousListingSelected = null;
      state.userSearchResults = null;
      state.listingBoundingBox = null;
      state.userLocationFilterData = null;
      state.displayFavoritesOnly = false;
    },

    /**
     * Updates the state when the user expands out a listing with the id of the listing
     */
    userExpandingListing: (state, action: PayloadAction<string>) => {
    },

    /**
         * Dispatch action when user is closing the expanded listing
         */
    userCollapsingListing: (state) => {
    },

    /**
         * The user wants to update their search after viewing the results
         * This action is used to reset general session data like which filter is selected
        * to prepare for the new search
         */

    userWantsToUpdateSearch: (state, action: PayloadAction<NavigateFunction>) => {

    },

    /**
     * Dispatched when a user is scrolling through photos
     * This is currently used to fetch more photos when the user is looking at photos
     */
    userScrollingThroughPhotos: (state, action: PayloadAction<string>) => {

    },

    /** Add more photos to a listing thats been expanded */
    addMorePhotosToListing: (state, action: PayloadAction<{listingID: string, photos: string[]}>) => {
      const { listingID, photos } = action.payload;
      const { listingExpanded } = state;

      // Update the state with the updated photos so they are not fetched again.
      // In addition, if the listing is expanded, update the photos there as well.
      if (state.userSearchResults && state.userSearchResults[listingID]) {
        const { listing, userLocations } = state.userSearchResults[listingID];
        const updatedListingData = { listing: { ...listing, photos }, userLocations };

        state.userSearchResults[listingID] = updatedListingData;

        if (listingExpanded && listingExpanded.listing.id === listingID) {
          state.listingExpanded = updatedListingData;
        }
      }
    },

    /** When the user requests to save a new search from the results area */
    userRequestingToSaveSearch: (state, action: PayloadAction<string>) => {

    },

    /**
     *  Request to update a saved search on the backend from the current session
     *  The payload is whether the search results be retrieved after the saved or not
     */
    updatingSavedSearchFromSearchResultSession: (state, action: PayloadAction<{navigate: NavigateFunction, getSavedSearchResult: boolean} | undefined>) => {

    },

    /** Updates the request status for the request to save a search */
    updatingSaveSearchRequestStatus: (state, action: PayloadAction<RequestStatus | null>) => {
      const status = action.payload;

      state.savedSearchRequestStatus = status;
    },

    /** Update the search name for the session */
    updatingSearchNameForSearchResultSession: (state, action: PayloadAction<string | null>) => {
      const { payload: searchName } = action;

      state.searchName = searchName;
    },

    /** Updates the search ID with the searchID returned from the backend */
    updatingSearchIdForSearchResultSession: (state, action: PayloadAction<string | null>) => {
      const { payload: searchId } = action;

      state.searchId = searchId;
    },

    updatingSearchStatusForSearchResultSession: (state, action: PayloadAction<SavedSearchStatus | null>) => {
      const { payload: status } = action;

      state.searchStatus = status;
    },

    /** Action to dispatch to update the search result session with saved searches data */
    updateResultSessionWithSavedSearches: (state) => {

    },

    statusChangeAroundIfUserIsUpdatingSavedSearch: (state, action: PayloadAction<boolean>) => {
      const { payload: isUserUpdatingSavedSearch } = action;
      state.isUserUpdatingSavedSearch = isUserUpdatingSavedSearch;
    },

    /** Resets the result session back to defaults. Used when a new search, or new data is being analyzed */
    resetSearchResultSession: (state) => {
      state.searchName = null;
      state.searchId = null;
      state.searchStatus = null;
      state.savedSearchRequestStatus = null;
      state.isUserUpdatingSavedSearch = false;
      state.listingExpanded = null;
      state.listingSelected = null;
      state.previousListingSelected = null;
      state.listingHovered = null;
      state.previousListingHovered = null;
      state.displayFavoritesOnly = false;
      state.sortBy = SortByKeys.LIST_DATE;
      state.filterChipSelected = null;
    },

  },

});

export const selectUserSearchResults = (state: RootState) => state.searchResultSession.userSearchResults;

export const selectFilterChipSelected = (state: RootState) => state.searchResultSession.filterChipSelected;

export const selectListingExpanded = (state: RootState) => state.searchResultSession.listingExpanded;

export const selectListingSelected = (state:RootState) => state.searchResultSession.listingSelected;

export const selectSortBy = (state: RootState) => state.searchResultSession.sortBy;

export const selectSearchName = (state: RootState) => state.searchResultSession.searchName;

export const selectSaveSearchRequestStatus = (state: RootState) => state.searchResultSession.savedSearchRequestStatus;

export const selectUserSearchPlanId = (state: RootState) => state.searchResultSession.planDetails?.planId;

export const selectSearchResultSessionData = (state: RootState) => {
  return {
    listingBoundingBox: state.searchResultSession.listingBoundingBox,
    listingExpanded: state.searchResultSession.listingExpanded,
    listingHovered: state.searchResultSession.listingHovered,
    listingSelected: state.searchResultSession.listingSelected,
    listingsToDisplay: state.searchResultSession.listingsToDisplay,
    previousListingHovered: state.searchResultSession.previousListingHovered,
    previousListingSelected: state.searchResultSession.previousListingSelected,
    userLocationsToDisplay: state.searchResultSession.userLocationsToDisplay,
    userSearchResults: state.searchResultSession.userSearchResults,
  };
};

export const {
  clearingResultsDataBeforeSearch,
  clearingSessionDataBeforeSearch,
  listingBeingHovered,
  listingNoLongerBeingHovered,
  selectedFilterChipIsChanging,
  selectedListingIsChanging,
  sortByIsChanging,
  updatingDataForListingExpanded,
  updatingListingsToDisplay,
  updatingDataForSearchResultSession,
  updatingUserLocationsToDisplay,
  userRequestingToDisplayAllListings,
  userRequestingToDisplayFavoritesOnly,
  userCollapsingListing,
  userExpandingListing,
  userWantsToUpdateSearch,
  userScrollingThroughPhotos,
  addMorePhotosToListing,
  userRequestingToSaveSearch,
  updatingSearchNameForSearchResultSession,
  updateResultSessionWithSavedSearches,
  updatingSearchStatusForSearchResultSession,
  updatingSearchIdForSearchResultSession,
  resetSearchResultSession,
  updatingSaveSearchRequestStatus,
  updatingSavedSearchFromSearchResultSession,
  statusChangeAroundIfUserIsUpdatingSavedSearch,
} = searchResultSessionSlice.actions;

export default searchResultSessionSlice.reducer;
