export const palette = {
  primary: {
    main: '#FF7348',
    light: '#FFE3dA',
  },
  neutral: {
    main: '#F7F7F7',
    light: '#FFFFFF',
    dark: '#E5E5E5',
  },
  success: {
    main: '#12BE76',
  },
  favorite: {
    main: '#FF4879',
  },
  neon: {
    green: {
      main: '#00B884',
      light: 'rgb(0 284 132 / 30%)',
      dark: '#00936a',
    },
    orange: {
      main: '#FF7348',
      light: 'rgb(255 115 72 / 30%)',
      dark: '#FF7348',
    },
    purple: {
      main: '#7b68ee',
      light: 'rgb(123 104 238 / 30%)',
      dark: '#6253be',
    },
    pink: {
      main: '#FD70AF',
    },
    blue: {
      main: '#49CBF8',
    },
  },
  pastel: {
    green: {
      light: '#e6f8f399',
    },
    orange: {
      light: '#fff7f5',
    },
    purple: {
      light: '#f8f7fe',
    },
    pink: {
      light: '#fff8fb',
    },
    blue: {
      light: '#ECFAFD',
    },
  },
};
