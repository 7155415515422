import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginBottom: '0.5rem',

    width: '27.8125rem',
    maxWidth: '445px',

    height: '2.5rem',
    maxHeight: '40px',

    backgroundColor: 'transparent',
    border: 'none',

  },

  commute: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    marginRight: '0.5rem',

    width: '5rem',
    height: '2.2725rem',

    border: '1px solid #F2F2F2',
    borderRadius: '5px',

    '&.hover': {
      color: '#02B3FF',
      border: '1px solid #02B3FF',
    },

  },

  icon: {
    width: '1.125rem',
    height: '0.73875rem',
    marginRight: '0.385rem',
  },

  info: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

  },

  'user-alias': {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '1.195rem',

  },

  location: {
    fontSize: '0.6875rem',
    fontWeight: 300,
    color: '#888888',
    lineHeight: '0.939rem',
  },
}));

export default useStyles;
