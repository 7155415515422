import { FC } from 'react';
import { CreateAccountForm, CreateAccountSuccess, AnimationContainer } from '@components';
import { CreateAccountService } from '@types';
import { useActor, useSelector } from '@xstate/react';
import { selectIsSignedUpForAccount, selectedFailedToSignUpForAccount } from '@machines';
import supportAnimation from '../../assets/support.json';
import * as S from './CreateAccountLayout.styled';

interface CreateAccountLayoutProps {
  createAccountService: CreateAccountService
}

const creatingAccountFailedInfo = {
  title: 'Bummer 🙁',
  subtitle: 'There may be already an account with that email. Try logging in or  reach out to support@findur.io if you continue to experience issus.',
  animation: supportAnimation,
};

const CreateAccountLayout: FC<CreateAccountLayoutProps> = ({ createAccountService }) => {
  const [, send] = useActor(createAccountService);
  const isSignedUpForAccount = useSelector(createAccountService, selectIsSignedUpForAccount);
  const failedToSignUpForAccount = useSelector(createAccountService, selectedFailedToSignUpForAccount);

  return (
        <S.Container>
          <S.CloseButton onClick={() => send('STOPPED_SIGN_UP')}>
            X
           </S.CloseButton>
          <S.CreateAccountContainer>
            {
              !isSignedUpForAccount && failedToSignUpForAccount === false
                ? <CreateAccountForm {...{ createAccountService }}/>
                : <></>
            }
            {
              isSignedUpForAccount
                ? <CreateAccountSuccess {...{ createAccountService }}/>
                : <></>
            }
            {
              failedToSignUpForAccount
                ? <AnimationContainer {...creatingAccountFailedInfo}/>
                : <></>
            }
          </S.CreateAccountContainer>
        </S.Container>
  );
};

export default CreateAccountLayout;
