import {
  FunctionComponent, useRef, useEffect, useState, useCallback,
} from 'react';
import ReactDOM from 'react-dom';
import {
  isEqual, difference, includes, pick,
} from 'lodash';
import axios, { AxiosRequestConfig } from 'axios';
import { UserLocationInfo, Coordinates, TravelModes } from 'beiytak_sdk';
import { getBoundingBox, isDataUserLocationInfo } from '@utils';
import { useAppSelector, useAppDispatch } from '@hooks';
import { selectedListingIsChanging, selectMapData } from '@stores';
import {
  ListingWithID, LngLat, Route,
} from '@types';
import config from '@config';
import mapboxgl from 'mapbox-gl'; //eslint-disable-line
import ListingMarker from './ListingMarker';
import UserLocationMarker from './UserLocationMarker';
import useStyles from './Map.styles';

require('dotenv').config();

// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// This is an on-going issue with the latest version of mapbox and chrome
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved, global-require
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

mapboxgl.accessToken = config.MAPBOX_TOKEN || '';

/**
 * Interactive map that displays the listings and the user location details
 * @component
 */
const Map: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // This will hold the reference to the container holding the map
  const mapContainerRef = useRef(null);
  // This will hold the reference to the map itself
  const mapRef = useRef<mapboxgl.Map>();

  const {
    favoriteListings,
    listingAddedToFavorites,
    listingBoundingBox,
    listingExpanded,
    listingHovered,
    listingRemovedFromFavorites,
    listingSelected,
    listingsToDisplayList,
    previousListingHovered,
    previousListingSelected,
    userLocationsToDisplay,
    userSearchResults,
  } = useAppSelector(selectMapData);

  const listingsToDisplay = listingsToDisplayList && userSearchResults ? pick(userSearchResults, listingsToDisplayList) : null;

  // These states will have when the user is moving the map around
  const [lng, setLng] = useState(-96.6792035);
  const [lat, setLat] = useState(33.0165162);
  const [zoom, setZoom] = useState(8);

  // This keeps track of what listing are in view.
  // This helps determine what markings need to be removed/re-rendered during filtering
  const [currentListingsInView, setCurrentListingsInView] = useState<string[]>([]);

  // When a listing is selected, update the state
  const handleOnClickListingMarker = useCallback((id: string) => {
    // Dispatch event to update selected listing
    dispatch(selectedListingIsChanging(id));
  }, []);

  // Creates a unique ID for the user location marker
  // This is to avoid any duplicate errors that might happen if 2 listings have the same user location and the map might render it
  // twice. The cleanup function will only delete 1 of these leaving 1 on the map
  const createUserLocationMarkerID = (listingAddress:string, userAlias: string): string => {
    return `${listingAddress}-user-location-${userAlias}`;
  };

  // Creates a listing or userLocation marker
  const createMarker = (
    data: ListingWithID | UserLocationInfo,
    listingAddress: string,
    isSelected: boolean = false,
    isFavorited: boolean = false,
    isHovered: boolean = false,
  ): HTMLDivElement => {
    let marker;

    // If the data passed is a user location
    if (isDataUserLocationInfo(data)) {
      const { userAlias } = data;

      // Create the marker component
      marker = <UserLocationMarker {...data} />;
      const markerContainer = document.createElement('div');
      markerContainer.id = createUserLocationMarkerID(listingAddress, userAlias);
      markerContainer.addEventListener('click', (e) => {
        // Disabling this so that the map doesn't resent when a user location is clicked
        e.stopPropagation();
      });

      ReactDOM.render(marker, markerContainer);

      return markerContainer;
    }

    // If the data passed is a user location
    if (!isDataUserLocationInfo(data)) {
      const listing = data;
      const markerProps = {
        listing, isSelected, isFavorited, isHovered,
      };
      marker = <ListingMarker {...markerProps} />;

      const markerContainer = document.createElement('div');
      markerContainer.id = listing.id;
      markerContainer.addEventListener('click', (e) => {
        handleOnClickListingMarker(markerContainer.id);
        e.stopPropagation(); // This stops map from getting the click notification
      });

      ReactDOM.render(marker, markerContainer);

      return markerContainer;
    }

    return document.createElement('div');
  };

  /**
   * Gets the route driving between an origin (the listing) and
   * a destination (the user location) to add to the map
   */
  const getRoute = async (origin: Coordinates, destination: Coordinates, travelMode: string): Promise<Route | void> => {
    const mode = travelMode === TravelModes.Driving || travelMode === TravelModes.Transit ? 'driving' : 'walking';
    const options: AxiosRequestConfig = {
      method: 'GET',
      url: `https://api.mapbox.com/directions/v5/mapbox/${mode}/${origin.lng},${origin.lat};${destination.lng},${destination.lat}`,
      params: {
        access_token: mapboxgl.accessToken,
        geometries: 'geojson',
      },
    };

    const route: Route = await axios.request(options)
      .then((res) => res.data)
      .catch((err) => {
        // console.log(err);
      });

    return route;
  };

  // Function to clean up the map during unmount
  const cleanup = (map: mapboxgl.Map): void => {
    // Removes the map from the dom
    map.remove();
  };

  /**
  * Initialize map when component mounts
  */
  useEffect(() => {
    // Only init the map once
    if (mapRef.current) { return undefined; }

    // Create the map and center it to the center of the listings
    mapRef.current = new mapboxgl.Map({
      container: mapContainerRef.current || '',
      style: 'mapbox://styles/mapbox/light-v10',
      center: listingBoundingBox?.center,
      zoom,
    });

    // Clear out any previously selected listings
    dispatch(selectedListingIsChanging(null));

    // Create a variable that points to the map reference
    // Doing this will avoid a lot of if-else statements to check for null
    const map: mapboxgl.Map = mapRef.current;

    if (map) {
      // Add navigation control (the +/- zoom buttons)
      map.addControl(new mapboxgl.NavigationControl(), 'top-right');

      // Get the coordinates when the user moves the map around
      map.on('move', () => {
        setLng(map.getCenter().lng);
        setLat(map.getCenter().lat);
        setZoom(map.getZoom());
      });

      // Changes the mouse icon when a location is hovered on
      map.on('mouseenter', 'listings', (e) => {
        if (e && e.features && e.features.length > 0) {
          map.getCanvas().style.cursor = 'pointer';
        }
      });

      // If the map was clicked up, update state to reflect that no listing was selected
      map.on('click', (e) => {
        dispatch(selectedListingIsChanging(null));
      });
    }

    return undefined;
  }, []);

  /** Handles the cleanup of the map on unmount */
  useEffect(() => {
    if (!mapRef.current) { return undefined; }

    const map = mapRef.current;

    // Remove the map on unmount
    return () => {
      cleanup(map);
    };
  }, [mapRef]);

  /**
  * Adds the markers for the listings.
  * This also updates the markers on the map when the user starts filtering the data
  */
  useEffect(() => {
    const map = mapRef.current;

    if (map && listingsToDisplay) {
      const keysOfListingsToDisplay = Object.keys(listingsToDisplay);

      // Confirm first the the listings to display and the current ones in view are different
      if (!isEqual(keysOfListingsToDisplay, currentListingsInView)) {
        // Add each listing that is available
        const keysOfListingsToRemove = difference(currentListingsInView, keysOfListingsToDisplay);
        const keysOfListingsToAdd = difference(keysOfListingsToDisplay, currentListingsInView);
        setCurrentListingsInView(keysOfListingsToDisplay); // Update the local state

        // Remove the listings that have been filtered out
        if (keysOfListingsToRemove.length > 0) {
          keysOfListingsToRemove.forEach((key) => {
            // Remove the marking from the map
            const markerToRemove = document.getElementById(key);
            markerToRemove?.remove();

            // If the listing being removed is already selected, dispatch an action so
            // that the other listeners can respond to it correctly.
            if (key === listingSelected) { dispatch(selectedListingIsChanging(null)); }
          });
        }

        // Add the listings that have been
        if (keysOfListingsToAdd.length > 0) {
          keysOfListingsToAdd.forEach((key) => {
            const { listing } = listingsToDisplay[key];
            const { address } = listing;
            const isSelected = false; // If the listing is selected, another useEffect will handle it
            const isFavorited = favoriteListings ? includes(favoriteListings, listing.id) : false;
            const isHovered = listing.id === listingHovered;

            const markerContainer = createMarker(listing, address, isSelected, isFavorited, isHovered);

            // Add the marker to the map
            new mapboxgl.Marker(markerContainer)
              .setLngLat(listing.coordinates)
              .addTo(map);
          });
        }
      }
    }
  }, [listingsToDisplay]);

  /**
  * Adds the markers & direction layer for the user locations of the selected listing
  */
  useEffect(() => {
    const map = mapRef.current;

    async function addRouteForUserLocation(
      map: mapboxgl.Map,
      origin: Coordinates,
      originAddress: string,
      userLocation: UserLocationInfo,
    ): Promise<void> {
      const {
        userAlias, coordinates, commute, travelMode,
      } = userLocation;

      // Only add a route layer if the user location is within 60 minutes
      // This is to avoid unnecessary lines on the map
      if (commute && coordinates) {
        const route = await getRoute(origin, coordinates, travelMode);

        if (route) {
          try {
            map.addLayer({
              id: createUserLocationMarkerID(originAddress, userAlias),
              type: 'line',
              source: {
                type: 'geojson',
                lineMetrics: true,
                data: {
                  type: 'Feature',
                  properties: {},
                  geometry: route.routes[0].geometry,
                },
              },
              layout: {
                'line-join': 'round',
                'line-cap': 'round',
              },
              paint: {
                'line-color': '#FF7348',
                'line-gradient': [
                  'interpolate',
                  ['linear'],
                  ['line-progress'],
                  0,
                  '#52796f',
                  // '#FF7348',
                  0.1,
                  // '#00b3ff',
                  ' #24C7FF',
                ],
                'line-width': 4,
              },
            });
          } catch (err) {
            // console.log(err);
          }
        }
      }
    }

    if (map && userLocationsToDisplay && listingsToDisplay && listingSelected && !listingExpanded) {
      const keys = Object.keys(userLocationsToDisplay);

      const { coordinates: origin, address } = listingsToDisplay[listingSelected].listing;

      const boundingBoxData: LngLat[] = [[origin.lng, origin.lat]];

      keys.forEach(async (key) => {
        const userLocation = userLocationsToDisplay[key];
        const { coordinates, userAlias } = userLocation;

        if (coordinates) {
          boundingBoxData.push([coordinates.lng, coordinates.lat]);

          const existingMarker = document.getElementById(createUserLocationMarkerID(address, userAlias));

          // Confirm that the marker was not already rendered
          // This fixes the issue with the same user location marker being rendered more than once
          if (!existingMarker) {
            const markerContainer = createMarker(userLocation, address);

            // Add the marker to the map
            new mapboxgl.Marker(markerContainer)
              .setLngLat(coordinates)
              .addTo(map);
          }

          addRouteForUserLocation(map, origin, address, userLocation);
        }
      });

      // returns are -> bbox extent in minX, minY, maxX, maxY order
      // Check to make sure at least 2 points are available for the bbox
      if (boundingBoxData.length > 1) {
        map.fitBounds(
          getBoundingBox(boundingBoxData).boundingBox,
          { maxZoom: 14 },
        ); // northeastern corner
      }
    }
  }, [userLocationsToDisplay, listingSelected, listingExpanded]);

  /**
   *  Handles when a listing marker is either selected or de-selected
   * 1.If a marker is selected (either from the map or the results), render the detailed marker
   * 2. When a marker is deselected, zoom the map back out
   */
  useEffect(() => {
    const map = mapRef.current;

    if (map && listingSelected && listingsToDisplay) {
      // Remove the non-selected marking from the map
      const { listing } = listingsToDisplay[listingSelected];
      const { address } = listing;

      const markerToRemove = document.getElementById(listingSelected);
      markerToRemove?.remove();

      // Re-render the marking with the detailed marker view since its selected
      const markerContainer = createMarker(listing, address, true);

      // Add the marker to the map
      new mapboxgl.Marker(markerContainer)
        .setLngLat(listing.coordinates)
        .addTo(map);
    }

    // If the map is init and the map was clicked (so listingSelected = null)
    // Then zoom the map back out to the original bounding box
    if (map && !listingSelected && listingBoundingBox) {
      // Zoom the map to the bounding box of the
      map.fitBounds(listingBoundingBox.boundingBox);
    }
  }, [listingSelected]);

  /**
   * If the user selected another marking or clicked on an empty area on the map,
   * re-render the previously selected marker to make it look like it was de-selected.
   */
  useEffect(() => {
    const map = mapRef.current;

    // Here we reference userSearchResults (the full data set)
    // Thats because if the user filters out a selected listing,
    // Then the only way to get info about it is by using the full results object
    if (map && previousListingSelected && userSearchResults) {
      const key = previousListingSelected;
      const result = userSearchResults[key];

      // check to make sure the user didn't leave the page/started a new search and the results are still applicable
      if (result) {
        const { listing, userLocations } = userSearchResults[key];
        const { address } = listing;
        const isSelected = false;
        const isFavorited = favoriteListings ? includes(favoriteListings, listing.id) : false;
        const isHovered = listing.id === listingHovered;

        // Remove the marking from the map
        const markerToRemove = document.getElementById(key);
        markerToRemove?.remove();

        // Remove the user locations that were displayed on the map
        // Also remove the directions layer for each location
        Object.keys(userLocations).forEach((key) => {
          const userLocationToRemove = document.getElementById(createUserLocationMarkerID(address, key));
          userLocationToRemove?.remove();

          try {
            // Try removing the the direction layer if it already exists
            // We first remove the layer then the underlying data source
            map.removeLayer(createUserLocationMarkerID(address, key));
            map.removeSource(createUserLocationMarkerID(address, key));
          } catch (err) {
            // console.log(`No map layer found: ${err}`);
          }
        });

        // Re-render the marking so it looks like it wasn't clicked
        const markerContainer = createMarker(listing, address, isSelected, isFavorited, isHovered);

        // Add the marker to the map
        new mapboxgl.Marker(markerContainer)
          .setLngLat(listing.coordinates)
          .addTo(map);
      }
    }
  }, [previousListingSelected]);

  /**
   * If the user removes the last listing from favorites while they are looking only at favorites,
   * make sure to remove that marker from the map. Since the rest of the effects are tracking changes to
   * listingsToDisplay, when its changed to null (all favorite are removed), it doesn't trigger the removal
   * of the last marker.
   */
  useEffect(() => {
    const map = mapRef.current;

    if (map && !listingsToDisplay && listingRemovedFromFavorites) {
      // Remove the marking from the map
      const markerToRemove = document.getElementById(listingRemovedFromFavorites);
      markerToRemove?.remove();

      // If the user clicked on the listing before removing it from favorites
      // remove the user location markers and the routes
      if (userLocationsToDisplay) {
        Object.keys(userLocationsToDisplay).forEach((key) => {
          const userLocationToRemove = document.getElementById(key);
          userLocationToRemove?.remove();

          try {
            map.removeLayer(key);
            map.removeSource(key);
          } catch (err) {
            // console.log(`No map layer found: ${err}`);
          }
        });
      }
    }
  }, [listingsToDisplay, listingRemovedFromFavorites, userLocationsToDisplay]);

  /**
   * If the user adds a listing to favorites, re-render it so its colored different
   */
  useEffect(() => {
    const map = mapRef.current;

    // Make sure the listing is not selected.
    // If it is, it doesn't need to be re-rendered because the marker details is in view
    if (map && listingsToDisplay && listingAddedToFavorites && !listingSelected) {
      const { listing } = listingsToDisplay[listingAddedToFavorites];
      const { address } = listing;
      const isSelected = false;
      const isFavorited = true;
      const isHovered = listing.id === listingHovered;

      // Remove the marking from the map
      const markerToRemove = document.getElementById(listingAddedToFavorites);
      markerToRemove?.remove();

      // Re-render the marking with the detailed marker view since its selected
      const markerContainer = createMarker(listing, address, isSelected, isFavorited, isHovered);

      // Add the marker to the map
      new mapboxgl.Marker(markerContainer)
        .setLngLat(listing.coordinates)
        .addTo(map);
    }
  }, [favoriteListings]);

  /**
   * If the user removes a listing from favorites, re-render it so its a normal marker
   */
  useEffect(() => {
    const map = mapRef.current;

    // Make sure the listing is not selected.
    // If it is, it doesn't need to be re-rendered because the marker details is in view
    if (map && listingsToDisplay && listingRemovedFromFavorites && !listingSelected) {
      // Remove the marking from the map
      const markerToRemove = document.getElementById(listingRemovedFromFavorites);
      markerToRemove?.remove();

      // If the listing is part of the listings to display (it hasn't been filtered out or not in only favorites view)
      // Re-render the marking with the detailed marker view since its selected
      if (includes(Object.keys(listingsToDisplay), listingRemovedFromFavorites)) {
        const { listing } = listingsToDisplay[listingRemovedFromFavorites];
        const { address } = listing;
        const isSelected = false;
        const isFavorited = false;
        const isHovered = listing.id === listingHovered;

        const markerContainer = createMarker(listing, address, isSelected, isFavorited, isHovered);

        // Add the marker to the map
        new mapboxgl.Marker(markerContainer)
          .setLngLat(listing.coordinates)
          .addTo(map);
      }
    }
  }, [favoriteListings]);

  /**
   * If the user hover over a listing, re-render it so its colored differently.
   */
  useEffect(() => {
    const map = mapRef.current;

    // Handle when a listing is hovered
    if (map && listingsToDisplay && listingHovered && !listingSelected) {
      // Remove the marking from the map
      const markerToRemove = document.getElementById(listingHovered);
      markerToRemove?.remove();

      if (includes(Object.keys(listingsToDisplay), listingHovered)) {
        const { listing } = listingsToDisplay[listingHovered];
        const { address } = listing;
        const isSelected = false;
        const isFavorited = favoriteListings ? includes(favoriteListings, listing.id) : false;
        const isHovered = true;

        const markerContainer = createMarker(listing, address, isSelected, isFavorited, isHovered);

        // Add the marker to the map
        new mapboxgl.Marker(markerContainer)
          .setLngLat(listing.coordinates)
          .addTo(map);
      }
    }
  }, [listingHovered]);

  /**
   * If the user hovers over a different listing, re-render the previous hovered listing so its not colored differently
   */
  useEffect(() => {
    const map = mapRef.current;

    // Handle when the user moves to another listing (the listing isn't hovered anymore)
    if (map && listingsToDisplay && previousListingHovered && !listingSelected) {
      // Remove the marking from the map
      const markerToRemove = document.getElementById(previousListingHovered);
      markerToRemove?.remove();

      if (includes(Object.keys(listingsToDisplay), previousListingHovered)) {
        const { listing } = listingsToDisplay[previousListingHovered];
        const { address } = listing;
        const isSelected = false;
        const isFavorited = favoriteListings ? includes(favoriteListings, listing.id) : false;
        const isHovered = false;

        const markerContainer = createMarker(listing, address, isSelected, isFavorited, isHovered);

        // Add the marker to the map
        new mapboxgl.Marker(markerContainer)
          .setLngLat(listing.coordinates)
          .addTo(map);
      }
    }
  }, [previousListingHovered]);

  return (
    <div className={classes.mapContainer} ref={mapContainerRef} />
  );
};

export default Map;
