import styled from 'styled-components';
import { devices } from '@styleGuide';

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;

width: 100%;
`;

export const Title = styled.h2`
margin: 0rem;
margin-bottom: 0.75rem;

font-size: 2rem;
line-height: 3.625rem;
font-weight: 800;

color: #202020;
text-align: center;
`;

export const SubTitle = styled.h4`
width: 80%;
max-width: 40rem;
margin: 0rem;

font-size: 1rem;
font-weight: 400;
line-height: 1.875rem;
color: #202020;

text-align: center;

@media (${devices.tabletAndBelow}) {
    text-align: center;
    max-width: 70vw;
}

`;
