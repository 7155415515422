import styled, { css } from 'styled-components';
import { $theme } from '@styleGuide';

const ErrorColor = css`color: #e04f44;`;

export const Container = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: flex-start;

width: 100%;
max-width: 20rem;
height: 5rem;

@media (${$theme('devices.tabletAndBelow')}) {
    width: 85%;
    max-width: 85%;
}
`;

export const InputContainer = styled.div`
display: flex;
flex-direction: column;
justify-content: space-between;
align-items: flex-start;

width: 100%;
`;

export const Label = styled.div`
font-size: 0.68rem;
margin-bottom: 0.4rem;
margin-left: 0.1rem;
`;

export const Input = styled.input<{error?: boolean}>`
width: 100%;

border: none;
outline: none;
border-radius: 6px;
border: ${(props) => (props.error ? 'solid 2px #e04f44' : 'solid 2px #F4F4F4')};
background-color: transparent;

font-weight: 500;
font-size: 1rem;
line-height: 1.875rem;

color: #202020;

&::placeholder {
    color: #888888;
}
`;

export const Error = styled.div`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;

font-weight: 500;
font-size: 0.7rem;
${ErrorColor}
`;

export const ErrorIcon = styled.img`
width: 15px;
height: 15px;
${ErrorColor}
`;

export const Form = styled.form`
`;

export const SubmitButton = styled.button`
width: 10.625rem;
height: 3.5rem;

font-size: 1.2rem;
font-weight: 600;

border: none;
border-radius: 9px;
box-shadow: 0 10px 25px rgb(0 284 132 / 30%);
color: white;
background: #00B884;
`;
