import {
  selectedFilterChipIsChanging,
  displayTint,
  closeTint,
} from '@stores';
import { DisplayTintPayload, Routes } from '@types';
import { topNavBarHeight } from '@constants';
import { MiddleWareWorkflow } from '../../types';

/** If the filter chip is selected when analyzing the results, display the tint */
const tintControlForFilterChipWorkflow: MiddleWareWorkflow = (storeAPI, next, action): any => {
  if (selectedFilterChipIsChanging.match(action)) {
    const { filterChipID, dispatchedFrom } = action.payload;

    if (!filterChipID) { storeAPI.dispatch(closeTint()); }

    if (filterChipID) {
      // top offset is different for the filter chip depending on where it is being used
      const top = topNavBarHeight;

      const displayTintPayload: DisplayTintPayload = {
        styles: { top },
        onClickAction: () => selectedFilterChipIsChanging({ filterChipID: null }),
      };

      storeAPI.dispatch(displayTint(displayTintPayload));
    }

    return next(action);
  }

  return null;
};

export default tintControlForFilterChipWorkflow;
