import { FC } from 'react';
import * as S from './LandingText.styled';

const LandingText: FC = () => {
  return (
    <S.Container>
      <S.Title>Find a Home, Apartment, or Investment Property in <S.Highlight color='#FF7348'>Half the Time</S.Highlight></S.Title>
        <S.SubTitle>
          Findur allows you to filter listings based on commute times to all your places of interest in seconds, saving you weeks of manual work.
          Put the days of copying and pasting addresses into Google Maps behind you!
        </S.SubTitle>

    </S.Container>
  );
};

export default LandingText;
