import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';
import {
  SearchResultSortBySlice,
  SortByKeys,
  SortByKeysListingMap,
  SortingListingsPayload,
} from '@types';

// Initial state for store
const initialState: SearchResultSortBySlice = {

  sortedListings: [],

};

export const searchResultSortBySlice = createSlice({
  name: 'searchResultSortBy',
  initialState,
  reducers: {

    /**
     * Sorts the listings to display by the users sort by selection
     */
    sortingListingsToDisplay: (state, action: PayloadAction<SortingListingsPayload>) => {
      const { sortByKey, listings } = action.payload;
      const listingKey = SortByKeysListingMap[sortByKey];
      const sortOrder = sortByKey === SortByKeys.PRICE_LOW_TO_HIGH || sortByKey === SortByKeys.SQFT_LOW_TO_HIGH ? 'asc' : 'desc';
      const sorted: string[] = [];

      const sortedList = orderBy(listings,
        (listingToDisplay) => listingToDisplay.listing[listingKey],
        sortOrder);

      // Format the list back to a Df object with the correct keys
      sortedList.forEach((listingToDisplay) => { sorted.push(listingToDisplay.listing.id); });

      state.sortedListings = sorted;
    },

  },

});

export const {
  sortingListingsToDisplay,
} = searchResultSortBySlice.actions;

export default searchResultSortBySlice.reducer;
