import { makeStyles } from '@material-ui/core/styles';
import { theme } from '@constants';

const useStyles = makeStyles((theme) => ({

  icon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },

  input: {
    ...theme.typography.subtitle1,
    fontWeight: 300,
  },

  clearIndicator: {
    right: '0.5rem',
  },

}));

/**
 * Styles the popper for the LocationInput component.
 * We have to pass these to the style prop of the component in order to
 * style the popper correctly.
 */
const popperStyle = {
  marginTop: '1rem',

  // So that it aligns correctly under the user location search area
  right: '80%',
};

const paperStyle = {
  overflow: 'hidden',

  minWidth: '32rem',
  maxWidth: '32rem',

  background: theme.palette.neutral.light,
  borderRadius: '0px 0px 30px 30px',
  // border: 'none',
  border: '1px solid #CCCCCC',
  boxShadow: 'none',
};

const autocompleteStyle = {
  width: '100%',
  height: '1.875rem',

  marginLeft: '0.625rem',

  overflow: 'hidden',
};

export {
  useStyles, popperStyle, paperStyle, autocompleteStyle,
};
