import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';

declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    neutral: Palette['primary'];
  }
  interface PaletteOptions {
    neutral: PaletteOptions['primary'];
  }
}

const primaryTheme = createTheme({

  palette: {
    primary: {
      main: '#FF7348',
      light: '#FFE3dA',
    },

    success: {
      main: '#12BE76',
    },

    neutral: {
      main: '#F7F7F7',
      light: '#FFFFFF',
      dark: '#E5E5E5',
    },

  },

});

/** Making font size dynamic based on screen size */
const theme = responsiveFontSizes(primaryTheme);

// Used for the step titles
theme.typography.h6 = {

  fontSize: '1rem',

  '@media (max-width:1900px)': {
    fontSize: '1rem',
  },

  '@media (max-width:1280px)': {
    fontSize: '0.9rem',
  },

};

// subtitle1 is used for subtitles
theme.typography.subtitle1 = {

  fontSize: '0.75rem',

  '@media (max-width:1900px)': {
    fontSize: '0.75rem',
  },

  '@media (max-width:1280px)': {
    fontSize: '0.675rem',
  },

};

// Overrides for specific components to keep consistency
theme.overrides = {

  MuiButton: {
    root: {

      width: '6.25rem',
      maxWidth: '100px',
      height: '3.125rem',
      maxHeight: '50px',

      borderRadius: '25px',
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        transform: 'scale(1.03)',
      },

    },

    outlined: {
      border: `1px solid ${theme.palette.primary.main}`,
    },
  },
};

// Additional colors / shades that are used in multiple places of the application
export const favoriteColor = '#FF4879';
export const topNavBarHeight = '5rem';

export default theme;
