import React, { FC, InputHTMLAttributes } from 'react';
import warning from '../../../assets/warning.svg';
import * as S from './Input.styled';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string,
    error?: boolean,
    errorMessage?: string,
}

const Input: FC<InputProps> = ({
  label, error, errorMessage, ...props
}) => {
  return (
    <S.Container>
        <S.InputContainer>
            <S.Label> {label} </S.Label>
            <S.Input {...props} />
        </S.InputContainer>

        {
            error
              ? <S.Error >
                  <S.ErrorIcon src={warning}/>
                  {errorMessage}
               </S.Error>
              : <></>
        }
    </S.Container>
  );
};

export default Input;
