import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({

  'saved-searches-container': {

    gridArea: 'data',

    overflowX: 'hidden',
    overflowY: 'scroll',

    // hide the scrollbar but keep functionality
    '&::-webkit-scrollbar': {
      width: '0rem',
      borderRadius: '30px',
    },

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    width: '100%',
    // subtract the height of the top nav bar
    height: 'calc(100vh - 9.5rem)',

  },

  'filters-and-new-search-container': {

    marginTop: '1.2rem',
    marginBottom: '1.2rem',

    // To match the width fo the saved search items
    width: '100%',
    maxWidth: '1250px',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },

}));

export default useStyles;
