import { makeStyles } from '@material-ui/core/styles';

const imageHeight = '66%';

const useStyles = makeStyles((theme) => ({

  container: {

    position: 'relative',
    overflow: 'hidden',

    marginBottom: '1.25rem',

    width: 'calc(100% * (1/2) - 1.25rem * 2)',
    minWidth: '18.75rem',
    height: '17.5rem',
    minHeight: '17.5rem',
    maxHeight: '17.5rem',

    background: theme.palette.neutral.light,
    boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',
    borderRadius: '10px',
    border: `1px solid ${theme.palette.neutral.main}`,

    '&:hover': {
      transform: 'scale(1.02)',
    },

    '&.selected': {
      boxShadow: `0px 5px 15px ${theme.palette.neutral.dark}`,
      border: '1px solid #202020',
    },

  },

  favorite: {

    position: 'absolute',

    top: '11.6rem',
    left: '16.1rem',

  },

  expand: {
    position: 'absolute',
    top: '0rem',
    right: '0rem',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    zIndex: 1,

    marginTop: '0.45rem',
    marginRight: '0.45rem',

    width: '1.5rem',
    height: '1.5rem',

    borderRadius: '5px',
    backgroundColor: '#FFFFFFE6',

    '&:hover': {
      transform: 'scale(1.04)',
      background: theme.palette.neutral.light,
    },

  },

  'expand-icon': {

    transform: 'rotate(-45deg)',
    width: '0.9rem',
    height: '0.9rem',
    color: 'black',

  },

  images: {

    overflow: 'hidden',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: imageHeight,
    maxHeight: imageHeight,

  },

  listingContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    overflow: 'hidden',

    width: '100%',
    height: 'calc(100% - imageHeight)',

  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    width: '100%',
    height: '100%',

    marginLeft: '1rem',

    fontSize: '0.875rem',
    color: '#202020',

  },

  price: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    width: '100%',
    height: '1.6875rem',
    marginTop: '0.625rem',
    marginBottom: '0.25rem',

    fontSize: '1.25rem',
    fontWeight: 'bold',
  },

  address: {

    overflow: 'hidden',
    textOverflow: 'clip',
    whiteSpace: 'nowrap',

    width: '17rem',

    marginTop: '0.5rem',
    marginBottom: '0.75rem',

    color: '#888888',
    fontSize: '0.875rem',
    lineHeight: '1.1875rem',

  },

}));

export default useStyles;
