import { isEqual, every } from 'lodash';
import util from 'util';
import { ResultFilter } from '../stores/slices/searchResultFilters/types';

/** checks if the default and current value of the user locations filter is equal */
const isValueEqualToDefault = (defaultValue: any, value: any) => {
  return isEqual(defaultValue, value);
};

/**
 * Check to see if the default and value applied by the user are equal
 * If they are that means no filter are applied
 * If they are not then filters are applied by the user
 */
const areFiltersApplied = (data: ResultFilter) => {
  const keys = Object.keys(data);
  const checks: boolean[] = [];

  keys.forEach((key) => {
    if ('default' in data[key] && 'value' in data[key]) {
      const defaultValue = data[key].default;
      const { value } = data[key];

      // for some values, an empty array means nothing is selected aka equal to default
      if (Array.isArray(value) && value.length === 0) { checks.push(true); }

      /** Keeping arrays to handel home criteria result filters */
      if (!Array.isArray(value) || (Array.isArray(value) && value.length > 0)) {
        const check = isValueEqualToDefault(defaultValue, value);
        checks.push(check);
      }
    }
  });

  const areDefaultAndValueEqual = every(checks);

  const result = !areDefaultAndValueEqual;

  return result;
};

export { isValueEqualToDefault, areFiltersApplied };
