import { Location } from 'react-router';
import { LocationState } from '@types';

/** Parses the path from from the location state object. Defaults back to "/" if not available */
const getPathNameFromLocationState = (location: Location) => {
  const state = location.state as LocationState | null | undefined; // @TODO figure out way to make this generic
  const from = state && typeof state === 'object' ? state?.from : null;
  const pathname = from ? from.pathname : '/';

  return pathname;
};

// eslint-disable-next-line
export { getPathNameFromLocationState };
