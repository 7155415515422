import { FunctionComponent } from 'react';
import Button from '@material-ui/core/Button';
import clsx from 'clsx';
import { useAppSelector, useAppDispatch } from '@hooks';
import { userRequestingNextStep, selectUserLocationsInput } from '@stores';
import UserLocationSearch from './UserLocationSearch';
import UserLocationName from './UserLocationName';
import TravelMode from './TravelModes';
import AddButton from './AddButton';
import Chips from './Chips';
import useStyles from './UserLocationsInput.styles';

/** User location input for one of the primary steps in the search */
const UserLocationsInput: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userLocations = useAppSelector(selectUserLocationsInput);
  const userAddedLocations = userLocations.length > 0;

  // Move onto the next step
  const handleOnClickNext = () => {
    dispatch(userRequestingNextStep());
  };

  return (
    <div className={classes.container}>
      <div className={clsx({
        [classes.placeholder]: !userAddedLocations,
        [classes['user-locations-container']]: userAddedLocations,
      })}
      >
    {userAddedLocations
      ? <Chips />
      : <span className={classes['placeholder-text']} aria-label='placeholder-text'>
          You will be able to filter listings based on the commute to any of your locations
        </span>
      }
      </div>

      <div className={classes['input-container']}>
        <div className={classes['user-location-search-container']}>
          <UserLocationSearch />
        </div>
        <div className={classes['user-location-name-container']}>
          <UserLocationName />
        </div>
        <div className={classes['travel-mode-container']}>
          <TravelMode />
        </div>
        <div className={classes['add-container']}>
          <AddButton />
        </div>
      </div>

      <Button
          variant="contained"
          classes={{
            root: classes.button,
          }}
          onClick={handleOnClickNext}
          disableElevation
        >
          Next
      </Button>
    </div>
  );
};

export default UserLocationsInput;
