import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mapContainer: {
    width: '100%',
    height: '100%',

    '&.mapbox-canvas-container': {
      width: '100%',
      height: '100%',
    },

    // This is to make sure that the controls don't display over the filters on the page when they are displayed
    '&.mapboxgl-ctrl-bottom-left, .mapboxgl-ctrl-bottom-right, .mapboxgl-ctrl-top-left, .mapboxgl-ctrl-top-right': {
      zIndex: 0,
    },
  },

}));

export default useStyles;
