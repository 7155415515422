import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { every } from 'lodash';
import {
  StepKeys,
  Routes,
  UserSearch,
  HomeCriteriaKeys,
} from '@types';
import {
  addingUserLocation,
  clearingSearchSessionDataBeforeSearch,
  getUserSearchResults,
  homeCriteriaStepSelected,
  homeCriteriaInputChanged,
  locationInputChanged,
  RootState,
  selectedStepIsChanging,
  updatingInterScreenState,
  userLocationRemoved,
  userRequestingNextStep,
  userRequestingSearchResults,
  validateHomeCriteriaInput,
  validateLocationInput,
  validateNewUserLocation,
  validateUserLocationInput,
  userChangedUserLocation,
  hoveredStepIsChanging,
  hidingLinesFromTheStepSearch,
  updatingTypeInput,
  inValidateHomeCriteriaInput,
  updatingUserSearchInputAssociatedWithSearchResult,
  updatingSearchNameForSearchResultSession,
  updatingSearchIdForSearchResultSession,
  updatingSearchStatusForSearchResultSession,
  clearingUsersFavorites,
  updatingSavedSearchFromSearchResultSession,
  priceDefaultValue as salePriceDefaultValue,
  rentPriceDefaultValue,
  updateDefaultForHomeCriteriaFilter,
  updateDefaultsForMoreFilters,
} from '@stores';
import { ListingType } from 'beiytak_sdk';

const creatingOrUpdatingASearchMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /** Hides the lines on the steps component when a step is hovered or clicked */
  if (selectedStepIsChanging.match(action) || hoveredStepIsChanging.match(action)) {
    next(action);
    storeAPI.dispatch(hidingLinesFromTheStepSearch());
  }

  // Handles the action when the user clicks on the search button
  if (userRequestingSearchResults.match(action)) {
    const { payload: navigate } = action;

    const locationStepValidation = storeAPI.getState().locationInput.validation;
    const homeCriteriaStepValidation = storeAPI.getState().homeCriteriaInput.validation;
    const userLocationsStepValidation = storeAPI.getState().userLocationsInput.validation;
    const { isUserUpdatingSavedSearch } = storeAPI.getState().searchResultSession;

    const validation = [locationStepValidation, homeCriteriaStepValidation, userLocationsStepValidation];
    // If everything is validated them move forward with the search
    if (every(validation)) {
      // Move the user to the loading page while the data is being fetched
      storeAPI.dispatch(updatingInterScreenState({ route: Routes.LOADING, value: true })); // so the loading page displays
      navigate(Routes.LOADING);

      const userSearch: UserSearch = {
        [StepKeys.TYPE]: storeAPI.getState().typeInput.value,
        [StepKeys.LOCATION]: storeAPI.getState().locationInput.value,
        [StepKeys.HOME_CRITERIA]: storeAPI.getState().homeCriteriaInput.value,
        [StepKeys.USER_LOCATIONS]: storeAPI.getState().userLocationsInput.value,
      };

      // Save the inputs that were used with this search for future reference
      storeAPI.dispatch(updatingUserSearchInputAssociatedWithSearchResult(userSearch));

      const { searchId } = storeAPI.getState().searchResultSession;

      // if th user is updating a saved search, send a request to update the search with the new params
      if (isUserUpdatingSavedSearch && searchId) {
        // First save the search with the updated params
        return storeAPI.dispatch(updatingSavedSearchFromSearchResultSession({ navigate, getSavedSearchResult: true }));
      }

      // If the user is starting a new search or updating an existing one that isn't saved, then just get the new results
      if (!isUserUpdatingSavedSearch || (isUserUpdatingSavedSearch && !searchId)) {
        const getUserSearchResultParams = { userSearch, navigate };

        storeAPI.dispatch(updatingSearchNameForSearchResultSession(null));
        storeAPI.dispatch(updatingSearchIdForSearchResultSession(null));
        storeAPI.dispatch(updatingSearchStatusForSearchResultSession(null));
        storeAPI.dispatch(clearingUsersFavorites());

        return storeAPI.dispatch(getUserSearchResults(getUserSearchResultParams));
      }
    }

    return storeAPI.dispatch(userRequestingNextStep());
  }

  /**
   * If the user clicked on search or next, make sure that all the steps are complete.
   * export type AppDispatch = typeof store.dispatch;
   * If not, navigate them to the missing step
   */
  if (userRequestingNextStep.match(action)) {
    const locationStepValidation = storeAPI.getState().locationInput.validation;
    const homeCriteriaStepValidation = storeAPI.getState().homeCriteriaInput.validation;
    const userLocationsStepValidation = storeAPI.getState().userLocationsInput.validation;

    const validation = [locationStepValidation, homeCriteriaStepValidation, userLocationsStepValidation];

    // If everything is validated then move to the search button
    if (every(validation)) {
      // Reset the session data before starting the search
      return storeAPI.dispatch(clearingSearchSessionDataBeforeSearch());
    }

    // If one of the steps are not validated, navigate the user to that one
    if (!locationStepValidation) {
      return storeAPI.dispatch(selectedStepIsChanging(StepKeys.LOCATION));
    } if (!homeCriteriaStepValidation) {
      return storeAPI.dispatch(selectedStepIsChanging(StepKeys.HOME_CRITERIA));
    } if (!userLocationsStepValidation) {
      return storeAPI.dispatch(selectedStepIsChanging(StepKeys.USER_LOCATIONS));
    }
  }

  /**
   * If the user changed the location input, dispatch a validation action to validate it.
   */
  if (locationInputChanged.match(action)) {
    next(action);
    const location = storeAPI.getState().locationInput.value;

    return storeAPI.dispatch(validateLocationInput(location));
  }

  /**
   * If the user updates any of the home criteria input data or selects that step,
   * validate the step since part of the home criteria validation is
   * for the user to have clicked the step at lease once
   */
  if (homeCriteriaInputChanged.match(action)
   || (selectedStepIsChanging.match(action) && action.payload === StepKeys.HOME_CRITERIA)
  ) {
    const { selectedStep } = storeAPI.getState().searchSession;
    // Update state value that the step was selected
    if (selectedStep === StepKeys.HOME_CRITERIA) { storeAPI.dispatch(homeCriteriaStepSelected()); }
    next(action);
    const homeCriteriaData = storeAPI.getState().homeCriteriaInput.value;
    return storeAPI.dispatch(validateHomeCriteriaInput(homeCriteriaData));
  }

  /**
   * This handles the state when the user tries to add a new user location.
   * It verifies the data is correct and if so adds it to the array
   */
  if (addingUserLocation.match(action)) {
    // Validate the data first before adding it

    storeAPI.dispatch(validateNewUserLocation());
    const { newUserLocationWillBeAdded } = storeAPI.getState().userLocationsInput;
    if (newUserLocationWillBeAdded) {
      // Clear out the user input since it will now be added
      storeAPI.dispatch(userChangedUserLocation(''));
      //  If the checks passed continue to adding the location
      next(action);

      // Validate the step overall
      const userLocationsInput = storeAPI.getState().userLocationsInput.value;

      return storeAPI.dispatch(validateUserLocationInput(userLocationsInput));
    }
  }

  /**
   * If the user removed a location, check to see if the step is still valid
   */
  if (userLocationRemoved.match(action)) {
    next(action);

    const userLocationsInput = storeAPI.getState().userLocationsInput.value;
    return storeAPI.dispatch(validateUserLocationInput(userLocationsInput));
  }

  /**
   * If the user changes the listing type also update the defaults being used for price ranges
   * so the labels can be reflective of the option selected
   */
  if (updatingTypeInput.match(action)) {
    next(action);
    const newListingType = storeAPI.getState().typeInput.value;

    // update the defaults first
    if (newListingType === ListingType.Sale) {
      storeAPI.dispatch(updateDefaultForHomeCriteriaFilter({ type: HomeCriteriaKeys.PRICE, data: salePriceDefaultValue }));
      storeAPI.dispatch(homeCriteriaInputChanged({ type: HomeCriteriaKeys.PRICE, data: salePriceDefaultValue }));
    }
    if (newListingType === ListingType.Rent) {
      storeAPI.dispatch(updateDefaultForHomeCriteriaFilter({ type: HomeCriteriaKeys.PRICE, data: rentPriceDefaultValue }));
      storeAPI.dispatch(homeCriteriaInputChanged({ type: HomeCriteriaKeys.PRICE, data: rentPriceDefaultValue }));
    }

    // In-validate the input since the type as changed and the values for this filter will as well
    storeAPI.dispatch((inValidateHomeCriteriaInput()));

    /** Update the defaults to match the new listing type */
    storeAPI.dispatch(updateDefaultsForMoreFilters(newListingType));
  }

  return next(action);
});

export default creatingOrUpdatingASearchMiddleware;
