import styled from 'styled-components';

export const Container = styled.div`

width: 100%;
height: 100%;

border-radius: 12px;
`;

export const Image = styled.img`
width: 100%;
height: 100%;
object-fit: cover;

border-radius: 12px;
`;
