import { AutoCompleteOptions, Location } from '../../../../types';

/**
 * Validates the location input
 * The user had to select one of the locations from the dropdown to be valid
 * @param location The location object to evaluate
 * @returns { boolean } Whether or not the location validation passed
 */
const locationValidation = (location: Location) => {
  let passed: boolean = false;

  if (location !== null && location !== '') { passed = true; }

  if (location == null) { passed = false; }

  return passed;
};

export default locationValidation;
