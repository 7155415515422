import styled from 'styled-components';
import { $theme, devices } from '@styleGuide';

export const Container = styled.div`
    display: grid;
    grid-template-areas:
    "section-info"
    "placeholder"
    "join"
    ;
    place-self: center;
    gap: 1rem;

    width: calc(100vw - 12rem);

    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: ${$theme('palette.pastel.green.light')};
    border-radius: 19px;

    text-align: center;

    @media (${devices.tabletAndBelow}) {
        gap: 1.5rem;
        width: calc(100vw - 2rem);
        height:  max-content;
    }
`;

export const JourneyImageContainer = styled.div`
    position: absolute;
    z-index: 1;

    @media (${devices.tabletAndBelow}) {
        display: none;
    }
`;

export const Image = styled.img`
    object-fit: contain;
`;

export const SectionInfoContainer = styled.div`
    grid-area: section-info;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 1rem;
`;

export const PlaceholderContainer = styled.div`
    grid-area: placeholder;
    align-self: start;
    justify-self: start;

    width: 100%;
    height: 100%;

    border-radius: 19px;

    cursor: pointer;
`;

export const PlaceholderImage = styled.img`
    object-fit: contain;

    width: 100%;
    max-width: 850px;
    height: 100%;
    max-height: 100%;

    border-radius: 19px;
`;

export const ContactUsContainer = styled.div`
    grid-area: join;
    justify-self: center;
    align-self: center;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:  center;

    margin-bottom: 1rem;
    margin-left: 2rem;

    width: 100%;

    @media (${$theme('devices.tabletAndBelow')}) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:  center;

        align-self: center;
        margin-left: 0rem;
    }
`;
