import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-right: 0.5rem;
    margin-left: 0.5rem;

    width: 1.75rem;
    height: 1.75rem;

    padding: 0.25rem;

    border: 1px solid ${$theme('palette.neutral.dark')};
    border-radius: ${$theme('border.radius.10')};
    box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%);
    background: ${$theme('palette.neutral.light')};

  &:hover {
    transform: scale(1.1);
  }
`;

export const NavOptionIcon = styled.img`
    width: 100%;
    height: 100%;
    opacity: 0.75;
`;

export const Tooltip = styled.div`
z-index: 5;

position: absolute;
top: 2.5rem;

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;

width: 4.2rem;
height: min-content;

border: none;
border-radius: 5px;
box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04);

background: ${$theme('palette.neutral.main')};
color: #202020;
opacity: 0.8;

text-align: center;
vertical-align: center;
font-size: 0.55rem;
`;
