import { FunctionComponent, useState } from 'react';
import { UserLocationInfo } from 'beiytak_sdk';
import clsx from 'clsx';
import { createCommuteLabel, getTravelModeIcon } from '@utils';

import useStyles from './UserLocationDetails.styles';

/**
 * Displays user location data for the listing thats in view
 */
const UserLocationDetails: FunctionComponent<UserLocationInfo> = (data) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);

  const {
    travelMode,
    userAlias,
    userLocation,
    name,
    commute,
    place_id,
  } = data;

  // Doc reference here: https://developers.google.com/maps/documentation/urls/get-started
  const url = `https://www.google.com/maps/search/?api=1&query=${userLocation.split(' ').join('+')}&query_place_id=${place_id}`;

  const Icon = getTravelModeIcon(travelMode);
  const handleOnClick = () => window.open(url, '_blank');

  return (

    commute
      ? <button
        className={classes.container}
        onClick={handleOnClick}
        onKeyPress={handleOnClick}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        aria-label={'user-location'}
        >

        <div className={clsx({
          [classes.commute]: true,
          hover,
        })}>
          <Icon className={classes.icon}/>
          <span aria-label={'commute'}>{createCommuteLabel(commute, 'include')}</span>
        </div>

        <div className={classes.info} aria-label={'user-location-info'}>
          <span className={classes['user-alias']} aria-label={'user-alias'}>{userAlias}</span>
          <span className={classes.location} aria-label={'location'}>{name}</span>
        </div>

      </button>
      : <></>
  );
};

export default UserLocationDetails;
