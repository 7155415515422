import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    position: 'relative',

    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    border: 'none',

    width: '0.8rem',
    height: '0.8rem',

    bottom: '1.1rem',
    right: '1.25rem',
  },

}));

export default useStyles;
