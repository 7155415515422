import React, { FunctionComponent, useState, useEffect } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { UserEnteredLocation, StepKeys } from '@types';
import { userChangedUserLocationName, selectNewUserLocationWillBeAdded } from '@stores';
import { useAppSelector, useAppDispatch } from '@hooks';
import useStyles from './UserLocationName.styles';

/**
 * Input for the user to name their location
 * @component
 */
const UserLocationName: FunctionComponent = () => {
  const classes = useStyles();
  const [input, setInput] = useState<UserEnteredLocation>('');
  const dispatch = useAppDispatch();
  const newUserLocationWillBeAdded = useAppSelector(selectNewUserLocationWillBeAdded);

  /**
   * Handles the on change event when the user changes the input
   * @function
   */
  const handleOnChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setInput(event.target.value);
    dispatch(userChangedUserLocationName(event.target.value));
  };

  useEffect(() => {
    if (newUserLocationWillBeAdded) {
      setInput('');
      dispatch(userChangedUserLocationName(''));
    }
  }, [newUserLocationWillBeAdded]);

  return (
    <InputBase
      placeholder="Nickname (optional)"
      classes={{ root: classes.container }}
      onChange={handleOnChange}
      value={input}
    />
  );
};

export default UserLocationName;
