import {
  Middleware,
  ThunkDispatch,
  unwrapResult,
} from '@reduxjs/toolkit';
import { SavedSearchStatus } from 'beiytak_sdk';
import {
  RootState,
  setSavedSearches,
  getSavedSearches,
  userNavigatingToDashboard,
  updateResultSessionWithSavedSearches,
  updatingSavedSearchesRequestStatus,
  updatingDataForSearchResultSession,
  clearingUsersFavorites,
  resetAllSearchResultFilters,
  updatingSelectedListingFromSavedSearchDataTable,
} from '@stores';
import { RequestStatus } from '@types';

const navigatingToTheDashboardMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  // if the user navigates to the dashboard, grab the saved user searches
  if (userNavigatingToDashboard.match(action)) {
    const statuses = [SavedSearchStatus.Active, SavedSearchStatus.Archived];
    storeAPI.dispatch(getSavedSearches({ statuses }));
    storeAPI.dispatch(updatingSavedSearchesRequestStatus(RequestStatus.PENDING));

    storeAPI.dispatch(resetAllSearchResultFilters());

    storeAPI.dispatch(updatingSelectedListingFromSavedSearchDataTable(null));

    // Set this to an empty state so if the user tries to go to favorites, it doesn't show results if they had already run a search
    storeAPI.dispatch(setSavedSearches([]));

    return next(action);
  }

  // Once the results are returned, update the state with the saved search data
  if (getSavedSearches.fulfilled.match(action)) {
    const userSavedSearches = unwrapResult(action);

    // Update the state with the results returned
    storeAPI.dispatch(setSavedSearches(userSavedSearches));

    // Update the result search session with the saved search data
    storeAPI.dispatch(updateResultSessionWithSavedSearches());

    // clear the favorites if the user was on a previous search
    storeAPI.dispatch(clearingUsersFavorites());

    return storeAPI.dispatch(updatingSavedSearchesRequestStatus(RequestStatus.FULFILLED));
  }

  /** If the saved searches couldn't be retrieved, update the status to reflect it */
  if (getSavedSearches.rejected.match(action)) {
    storeAPI.dispatch(setSavedSearches(null));
    storeAPI.dispatch(updatingDataForSearchResultSession({ userSearchResults: undefined }));
    return storeAPI.dispatch(updatingSavedSearchesRequestStatus(RequestStatus.REJECTED));
  }

  return next(action);
});

export default navigatingToTheDashboardMiddleware;
