/* eslint-disable react/react-in-jsx-scope */
import clsx from 'clsx';
import { FunctionComponent } from 'react';
import { Button } from '@material-ui/core';
import { every } from 'lodash';
import { useNavigate } from 'react-router';
import { useAppSelector, useAppDispatch } from '@hooks';
import { location, homeCriteria, userLocations } from '@constants';
import { LocationPermissionService } from '@machines';
import { userRequestingSearchResults, selectSearchStepValidation } from '@stores';
import { PermissionProtectedSearch } from '@components';
import Step from './Step';
import useStyles from './Steps.styles';

interface StepsProps {
  locationPermissionService: LocationPermissionService
}

/** Component that holds all the steps */
const Steps: FunctionComponent<StepsProps> = ({ locationPermissionService }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hideLineOne, hideLineTwo, hideLineThree } = useAppSelector(
    (state) => state.searchSession.stepLinesToHide,
  );

  const { locationStepValidation, homeCriteriaStepValidation, userLocationsStepValidation } = useAppSelector(selectSearchStepValidation);

  const allStepsValidated = every([locationStepValidation, homeCriteriaStepValidation, userLocationsStepValidation]);

  // Handles the click on the Search Icon
  const handleOnClick = () => {
    dispatch(userRequestingSearchResults(navigate));
  };

  return (

    <div className={classes.container}>

      <Step {...{ ...location, locationPermissionService }} key={location.stepNumber} />

      <div className={clsx({ [classes.line]: !hideLineOne, [classes.lineHidden]: hideLineOne })}/>

      <Step {...{ ...homeCriteria, locationPermissionService }} key={homeCriteria.stepNumber} />

      <div className={clsx({ [classes.line]: !hideLineTwo, [classes.lineHidden]: hideLineTwo })} />

      <Step {...{ ...userLocations, locationPermissionService }} key={userLocations.stepNumber} />

      <div className={clsx({ [classes.line]: !hideLineThree, [classes.lineHidden]: hideLineThree })} />

      <PermissionProtectedSearch {...{ variant: 'oval', locationPermissionService, text: 'Search', allowClick: allStepsValidated, onVerified: handleOnClick }}/>

    </div>

  );
};

export default Steps;
