import { FunctionComponent, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector } from '@hooks';
import { updatingInterScreenState, selectDisplayErrorScreen } from '@stores';
import { AnimationContainer } from '@components';
import { ErrorLayout } from '@layouts';
import { Button } from '@material-ui/core';
import { Routes } from '@types';
import supportAnimation from '../../assets/support.json';
import useStyles from './ErrorScreen.styles';

const ErrorScreen: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const displayScreen = useAppSelector(selectDisplayErrorScreen);

  // Dispatch action to reset the transition state when the component unmounts
  useEffect(() => {
    return () => {
      dispatch(updatingInterScreenState({ route: Routes.ERROR, value: false }));
    };
  }, []);

  return (
    displayScreen
      ? (
        <div className={classes.container}>
          <ErrorLayout />
        </div>
      )
      : <></>
  );
};

export default ErrorScreen;
