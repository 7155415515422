import {
  Middleware,
  ThunkDispatch,
} from '@reduxjs/toolkit';
import { priceDefaultValue, bathroomsDefaultValue, bedroomsDefaultValue } from '@constants';
import {
  RootState,
  updatingSearchText,
  updatingSelectedSearchStatuses,
  filteringSavedSearchesToDisplay,
  userRequestingToGoToSavedSearch,
  updatingInterScreenState,
  updateResultSessionWithSavedSearches,
  updatingDataForSearchResultSession,
  updatingSavedSearchesSelectedInFavorites,
  resetSearchResultSession,
  updatingHomeCriteriaResultsFilter,
  getSavedSearchResults,
  setUserLocationsResultsFilter,
  homeCriteriaInputChanged,
} from '@stores';
import { Routes, HomeCriteriaKeys } from '@types';

const interactingWithSavedSearchesMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /** This workflow handles updating the search session date using the saved searches */
  if (updateResultSessionWithSavedSearches.match(action)) {
    const { listingsFromSearches, savedSearches } = storeAPI.getState().savedSearches;

    if (savedSearches) {
      // Update which results should be displayed based on current filtering
      storeAPI.dispatch(filteringSavedSearchesToDisplay(savedSearches));

      // Update the map and result list data with those listings in case the user navigates to their favorites
      storeAPI.dispatch(updatingDataForSearchResultSession({ savedSearchFavorites: listingsFromSearches }));

      // Initially keep this in sync with with the saved searches in display
      const { savedSearchesToDisplay } = storeAPI.getState().savedSearchesFilters;
      storeAPI.dispatch(updatingSavedSearchesSelectedInFavorites(savedSearchesToDisplay));

      // result the search result session
      storeAPI.dispatch(resetSearchResultSession());

      // Since each search has different home criteria, just default it to the max ranges and the user can filter it down from there
      // this ensures that all favorites listings from all searches can be viewed even if price data isn't available
      const pricePayload = { homeCriteriaKey: HomeCriteriaKeys.PRICE, filterValue: priceDefaultValue };
      const bathroomPayload = { homeCriteriaKey: HomeCriteriaKeys.BATHROOMS, filterValue: bathroomsDefaultValue };
      const bedroomsPayload = { homeCriteriaKey: HomeCriteriaKeys.BEDROOMS, filterValue: bedroomsDefaultValue };

      // we also need to update the input itself since the filter chips referencing this on initial render
      // @TODO really not a fan of this but will make this cleaner in the future
      storeAPI.dispatch(homeCriteriaInputChanged({ type: HomeCriteriaKeys.PRICE, data: priceDefaultValue }));
      storeAPI.dispatch(homeCriteriaInputChanged({ type: HomeCriteriaKeys.BEDROOMS, data: bedroomsDefaultValue }));
      storeAPI.dispatch(homeCriteriaInputChanged({ type: HomeCriteriaKeys.BATHROOMS, data: bathroomsDefaultValue }));

      storeAPI.dispatch(updatingHomeCriteriaResultsFilter(pricePayload));
      storeAPI.dispatch(updatingHomeCriteriaResultsFilter(bathroomPayload));
      storeAPI.dispatch(updatingHomeCriteriaResultsFilter(bedroomsPayload));

      // Also reset the filters for the user locations so its also max range for commute times
      const { userLocationFilterData } = storeAPI.getState().searchResultSession;
      if (userLocationFilterData) { storeAPI.dispatch(setUserLocationsResultsFilter(userLocationFilterData)); }
    }
  }

  /** If the user:
     *  - Starts to search the list by entering text into the search field
     *  - Change the status in view
     *  - Update a user search status
     * filter the results to only show the saved searches that fit the search criteria
     */
  if (updatingSearchText.match(action)
      || updatingSelectedSearchStatuses.match(action)
  ) {
    // update the state with the new filter selections
    next(action);
    const { savedSearches } = storeAPI.getState().savedSearches;

    if (savedSearches) {
      // keep this in sync so that when the user navigates to their favorites, they see the same saved searches that were selected/filtered
      const searchIds = Object.keys(savedSearches);
      storeAPI.dispatch(updatingSavedSearchesSelectedInFavorites(searchIds));

      // then dispatch action to filter the saved searches based on the updated filters
      return storeAPI.dispatch(filteringSavedSearchesToDisplay(savedSearches));
    }
  }

  /** When the user wants to go to a saved search */
  if (userRequestingToGoToSavedSearch.match(action)) {
    const { savedSearch, navigate } = action.payload;
    const { searchId } = savedSearch;

    storeAPI.dispatch(updatingInterScreenState({ route: Routes.LOADING, value: true }));
    navigate(Routes.LOADING);

    return storeAPI.dispatch(getSavedSearchResults({ searchId, navigate }));
  }

  return next(action);
});

export default interactingWithSavedSearchesMiddleware;
