import styled, { css } from 'styled-components';

export const Button = styled.a<{isSelected: boolean}>`
    margin: 0rem;
    margin-right: 1rem;
    height: 2rem;

    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.875rem;

    border: none ;
    border-bottom: ${(props) => (props.isSelected ? '3px solid #7b68ee' : 'none')};
    background: transparent;
    color: #202020;

    cursor: pointer;
`;
