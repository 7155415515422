import { createAsyncThunk } from '@reduxjs/toolkit';
import { getListingPhotos, Routes } from 'beiytak_sdk';
import { getAPIUrl } from '@utils';
import { GetMoreListingPhotosParams, GetMoreListingPhotosResults } from './types';

const GetMoreListingPhotosAction = 'searchResultSession/getMoreListingPhotos';

/** Gets more photos for a listing */
const getMoreListingPhotos = createAsyncThunk(
  GetMoreListingPhotosAction,
  async (params: GetMoreListingPhotosParams): Promise<GetMoreListingPhotosResults> => {
    const { listingID, propertyId } = params;
    const url = getAPIUrl(Routes.GRAPHQL);

    const { data: photos } = await getListingPhotos({ params: propertyId }, url);

    const result = {
      listingID,
      propertyId,
      photos: photos || [],
    };

    return result;
  },
);

export { getMoreListingPhotos, GetMoreListingPhotosAction };
