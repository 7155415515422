import { SavedSearchStatus } from 'beiytak_sdk';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ArchiveIcon from '@material-ui/icons/Archive';
import CheckIcon from '@material-ui/icons/Check';
import { updatingSavedSearchStatus } from '@stores';

/** Action values to be used for the drop down for the saved searches */
const savedSearchActionValues: SavedSearchStatus[] = [
  SavedSearchStatus.Active,
  SavedSearchStatus.Archived,
  SavedSearchStatus.Deleted,
];

/** Ref to get the display name for the actions */
const savedSearchActionNames = {
  [SavedSearchStatus.Active]: 'Set to Active',
  [SavedSearchStatus.Archived]: 'Archive search',
  [SavedSearchStatus.Deleted]: 'Delete search',
};

/** The icons to use for each action */
const savedSearchActionIcons = {
  [SavedSearchStatus.Active]: CheckIcon,
  [SavedSearchStatus.Archived]: ArchiveIcon,
  [SavedSearchStatus.Deleted]: DeleteForeverIcon,
};

/** How to handle the on click event when an action is selected  */
const savedSearchActionClickEvents = {
  [SavedSearchStatus.Active]: (searchId: string) => updatingSavedSearchStatus({ searchId, updatedStatus: SavedSearchStatus.Active }),
  [SavedSearchStatus.Archived]: (searchId: string) => updatingSavedSearchStatus({ searchId, updatedStatus: SavedSearchStatus.Archived }),
  [SavedSearchStatus.Deleted]: (searchId: string) => updatingSavedSearchStatus({ searchId, updatedStatus: SavedSearchStatus.Deleted }),
};

export {
  savedSearchActionValues,
  savedSearchActionNames,
  savedSearchActionIcons,
  savedSearchActionClickEvents,
};
