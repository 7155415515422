import React, { FC } from 'react';
import { getZillowReference, Routes } from 'beiytak_sdk';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';
import { getAPIUrl } from '@utils';
import Lottie from 'react-lottie';
import * as S from './ZillowLink.styled';
import zillowLogo from '../../../assets/zillow_logo.png';
import skeletonAnimation from '../../../assets/skeleton.json';

interface ZillowLinkProps {
  address: string,
}

const url = getAPIUrl(Routes.GRAPHQL);

const ZillowLink: FC<ZillowLinkProps> = ({ address }) => {
  const {
    isLoading, data, error,
  } = useQuery(
    {
      queryKey: [address, 'getZillowReference'],
      queryFn: () => getZillowReference({ params: { address } }, url),
      retry: (failureCount, error: AxiosError) => {
        if (error.code === '429') { return true; }
        if (failureCount <= 3) { return true; }
        return false;
      },
    },
  );

  const handleOnClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation(); // so the listing card won't get selected
    window.open(data?.data?.url);
  };

  return (
    <S.Container>
      {
        isLoading
          ? <Lottie
              style={{ width: '5rem', height: '2rem' }}
              speed={0.7}
              options={{
                animationData: skeletonAnimation,
                autoplay: true,
              }}
              isClickToPauseDisabled
            />
          : <></>
      }

      {
        data && !error && !isLoading && data.data?.url
          ? <S.Logo src={zillowLogo} onClick={handleOnClick}/>
          : <></>
      }
    </S.Container>
  );
};

export default ZillowLink;
