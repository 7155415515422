export const sizes = {
  mobile: '320px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1280px',
  desktop: '2560px',
};

// Note: tried using width but isn't supported everywhere
export const devices = {
  laptopAndAbove: `min-width: ${sizes.laptop}`,
  tabletAndBelow: `max-width: ${sizes.laptop}`,
};
