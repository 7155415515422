import { Middleware, ThunkDispatch } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { tintControlForFilterChipWorkflow, tintControlForExpandingListingWorkflow } from './workflows';

// @TODO just testing out a concept to see if this works any good
const tintControlsMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  tintControlForFilterChipWorkflow(storeAPI, next, action);
  tintControlForExpandingListingWorkflow(storeAPI, next, action);

  return next(action);
});

export default tintControlsMiddleware;
