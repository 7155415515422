import React, {
  FunctionComponent, useState, createRef, useEffect,
} from 'react';
import clsx from 'clsx';
import { orderBy } from 'lodash';
import { Row } from '@types';
import { FactsAndFeatures, OtherPropertyInformation, UserLocationDetails } from '@components';
import useStyles from './ListingDetail.styles';

/** The sections available that display different details about the listing */
enum Section {
    YOUR_LOCATIONS = 'YOUR_LOCATIONS',
    FACTS_AND_FEATURES = 'FACTS_AND_FEATURES',
}

type ComponentRef = React.RefObject<HTMLDivElement>;

interface SectionDetails {
    [key: string]: {
        id: Section,
        title: string,
        componentRef: ComponentRef,
    }
}

const ListingDetail: FunctionComponent<Row> = ({ listing, userLocations }) => {
  const classes = useStyles();
  const [scrollPosition, setScrollPosition] = useState<number | undefined>(0);
  const [sectionInView, setSectionInView] = useState<Section>();

  // References to different parts of the details available
  const $sections = createRef<HTMLDivElement>();
  const $sectionTabs = createRef<HTMLDivElement>();
  const $yourLocationsSection = createRef<HTMLDivElement>();
  const $featuresAndFactsSection = createRef<HTMLDivElement>();

  // Details around each section
  const sectionDetails: SectionDetails = {
    [Section.YOUR_LOCATIONS]: {
      id: Section.YOUR_LOCATIONS,
      title: 'Your Locations',
      componentRef: $yourLocationsSection,
    },
    [Section.FACTS_AND_FEATURES]: {
      id: Section.FACTS_AND_FEATURES,
      title: 'Facts and Features',
      componentRef: $featuresAndFactsSection,
    },
  };

  const sections = Object.keys(sectionDetails);

  // Sorting user locations in order by commute time
  const sortedUserLocations = orderBy(userLocations, (userLocation) => userLocation.commute, 'asc');

  // When a tab is clicked, scroll to the component thats associated with the tab
  const handleSectionTabClicked = (id: Section, componentRef: ComponentRef) => {
    setSectionInView(id);
    componentRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  /**  This updates which section is in view as the user scrolls through the sections */
  useEffect(() => {
    let sectionInView: Section = Section.YOUR_LOCATIONS;

    const bottomOfTabs = $sectionTabs.current?.getBoundingClientRect().bottom;
    const bottomOffset = 25; // bottom margin of tabs

    // Loop through each of the sections and see if they are in view
    // Use the last section thats in view (under the section tab area) as the actual section in view
    sections.forEach((section) => {
      const { id, componentRef } = sectionDetails[section];
      const top = componentRef.current?.getBoundingClientRect().top;

      if (bottomOfTabs && top && top - bottomOffset <= bottomOfTabs) { sectionInView = id; }
    });

    if (sectionInView) { setSectionInView(sectionInView); } // update the section in view
  }, [scrollPosition, sectionInView]);

  return (

    <div className={classes.container}>

        <div className={classes['section-tabs']} ref={$sectionTabs}>

            {
                sections.map((section) => {
                  const { id, title, componentRef } = sectionDetails[section];

                  return (
                    <div
                      key={id}
                      className={clsx({
                        [classes['section-tab']]: true,
                        selected: sectionInView === id,
                      })}
                      onClick={() => { handleSectionTabClicked(id, componentRef); }}
                    >
                        {title}
                    </div>
                  );
                })
            }

        </div>

        <div
          className={classes['sections-container']}
          ref={$sections}
          onScroll={(event) => setScrollPosition($sections.current?.scrollTop)}
        >

            <div className={classes.sections}>

                <div id={Section.YOUR_LOCATIONS} className={classes.section}>
                    <div ref={$yourLocationsSection}></div>
                    {sortedUserLocations.map((userLocation) => <UserLocationDetails {...userLocation} key={userLocation.userLocation}/>)}
                </div>

                <div id={Section.FACTS_AND_FEATURES} className={classes.section}>
                    <div className={classes['section-title']}>{sectionDetails[Section.FACTS_AND_FEATURES].title}</div>
                    <div ref={$featuresAndFactsSection}></div>
                    <FactsAndFeatures {...listing}/>
                </div>

            </div>

        </div>

    </div>

  );
};

export default ListingDetail;
