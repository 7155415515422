import { makeStyles } from '@material-ui/core/styles';
import { theme } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    overflow: 'hidden',

    width: '100%',
    height: '100%',

    border: 'none',
    padding: '0px',

    // This removes the black outline when a user is using their keyboard to navigate
    '&:focus-visible': {
      outline: 'none',
    },

  },

  step: {

    border: 'none',
    padding: '0rem',

    background: 'hsl(0, 0%, 98%)',

  },

  differentStepSelected: {

    border: 'none',
    padding: '0rem',

    // background: theme.palette.neutral.main,
    background: 'hsl(0, 0%, 98%)',

    '&:hover': {
      borderRadius: '30px',
      backgroundColor: theme.palette.neutral.dark,

    },

  },

  selectedStep: {

    zIndex: 1,

    border: `1px solid ${theme.palette.neutral.main}`,
    borderRadius: '30px',

    boxShadow: '0px 6px 20px rgb(0 0 0 / 20%)',
    backgroundColor: theme.palette.neutral.light,

  },

  stepInfo: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    width: '100%',
    height: '50%',

    '& .title': {

      ...theme.typography.h6,

      fontWeight: 'normal',
      lineHeight: '1.375rem',

    },

    '& .subtitle': {

      display: 'block',

      // marginTop: '0.5rem',
      width: '100%',
      // maxWidth: '200px',
      maxWidth: '70%',

      ...theme.typography.subtitle1,
      lineHeight: '1.25rem',

      color: '#8C8C8C', // manually matching the color of the location input due to mui overrides
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textAlign: 'left',

      // Override mui to make sure the text field fills the width provided
      '& input': {
        width: '14rem',
      },

    },

  },

}));

export const buttonVariant = {

  initial: {
    width: '28em',

  },

  whileFocus: {
    width: '28.2em',
    transition: {
      type: 'spring',
      stiffness: 700,
      damping: 30,
    },
  },

};

export default useStyles;
