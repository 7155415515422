import { FunctionComponent } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  userCollapsingListing,
  selectListingExpanded,
} from '@stores';
import {
  FavoriteListing, Images, ListingDetailSummary,
  ListingDetail,
} from '@components';
import { Row, ImageType } from '@types';
import useStyles from './ListingCardFullView.styles';

interface ListingCardFullViewProps {
  data: Row,
  disableFavorites?: boolean,
}

const ListingCardFullView: FunctionComponent<ListingCardFullViewProps> = ({ data, disableFavorites }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { listing, userLocations } = data;
  const { photos: images, id } = listing;

  // Closes out of the full view
  const handleOnClickClose = () => { dispatch(userCollapsingListing()); };

  return (
      <div className={classes.container} aria-label="listing-full-view">

        {!disableFavorites
          ? <div className={classes.favorite}>
            <FavoriteListing {...{ id }} />
          </div>
          : <></>
        }

        <IconButton aria-label="close" classes={{ root: classes.close }} onClick={handleOnClickClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>

        <div className={classes.content}>

          <div className={classes.images}>
            <Images {...{ images, id, type: ImageType.FULL }} />
          </div>

          <div className={classes['listing-detail']}>

            <ListingDetailSummary {...listing}/>

            <ListingDetail {...{ listing, userLocations }}/>

          </div>

        </div>

      </div>

  );
};

export default ListingCardFullView;
