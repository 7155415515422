import { $theme } from '@styleGuide';
import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    border-radius: ${$theme('border.radius.15')} ${$theme('border.radius.15')} 0px 0px;
    background-image: linear-gradient(
        0deg,
        hsl(0deg 0% 100%) 0%,
        hsl(15deg 100% 99%) 11%,
        hsl(15deg 100% 98%) 22%,
        hsl(15deg 100% 98%) 33%,
        hsl(15deg 100% 97%) 44%,
        hsl(15deg 100% 96%) 56%,
        hsl(15deg 100% 95%) 67%,
        hsl(15deg 100% 94%) 78%,
        hsl(15deg 100% 94%) 89%,
        hsl(15deg 100% 93%) 100%
    );

    text-align: center;
`;

export const Title = styled.div`
    color: #202020;
    font-size: 1.4rem;
    font-weight: bold;

    margin-bottom: 0.8rem;
`;

export const Subtitle = styled.div`
    color: #606060;
    font-size: 1.1rem;
    font-weight: normal;

    width: 80%;
`;

export const Icon = styled.img`
    margin-right: 0.75rem;
    width: 1.3125rem;
    height: 1.3125rem;
`;
