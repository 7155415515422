import React, {
  FunctionComponent, useEffect, useRef, useState,
} from 'react';
import { ListingCard, ListingCardFullView } from '@components';
import { VirtuosoGrid, VirtuosoGridHandle } from 'react-virtuoso';
import { useAppSelector } from '@hooks';
import { selectSearchResultSessionData, selectAllSearchResultFilterData, selectSortBy } from '@stores';
import ListOptions from './ListOptions';
import * as S from './ResultList.styled';

interface ResultsListProps {
  disableFavorites?: boolean,
  disableSortBy?: boolean,
}

const ResultsList: FunctionComponent<ResultsListProps> = (options) => {
  const $virtuoso = useRef<VirtuosoGridHandle>(null);
  const [visibleRange, setVisibleRange] = useState({ startIndex: 0, endIndex: 0 });
  const [scrollPosition, setScrollPosition] = useState(0);
  const [wasPositionSet, setWasPositionSet] = useState<boolean>(true); // set it to true to avoid the first jump

  const { disableFavorites } = options;
  const {
    listingsToDisplay,
    listingExpanded,
    userSearchResults,
    listingSelected,
    previousListingSelected,
  } = useAppSelector(selectSearchResultSessionData);

  const sortBy = useAppSelector(selectSortBy);

  const filters = useAppSelector(selectAllSearchResultFilterData);

  // how much to offset the location of the card by so its scrolls to the top of the card
  const listOffset = 180;

  /** If the listing was selected or de-selected, reset the flag so that the listing is put into view */
  useEffect(() => {
    setWasPositionSet(false);
  }, [listingSelected, previousListingSelected]);

  /**
   * When a marker is selected on the map, the list scrolls to that listing for the user to view.
   * When the user clicks away, the list will scroll back to where the user was.
   */
  useEffect(() => {
    if (listingSelected && $virtuoso.current && listingsToDisplay) {
      const { startIndex, endIndex } = visibleRange;
      const selectedElementIndex = listingsToDisplay.indexOf(listingSelected);
      const isElementInView = selectedElementIndex >= startIndex && selectedElementIndex <= endIndex;

      if (!isElementInView && !wasPositionSet) {
        // Scroll the list to the listing location
        $virtuoso.current.scrollToIndex({ align: 'start', behavior: 'auto', index: selectedElementIndex });

        // Mark that the position was set. That way if the listing is still selected and the user wants to scroll,
        // They can do so without this effect always trying to keep the selected listing in view
        setWasPositionSet(true);
      }
    }
  }, [listingSelected, setScrollPosition, visibleRange, wasPositionSet, setWasPositionSet, listingsToDisplay]);

  /** Return the user back to where they were when a listing is not selected */
  // useEffect(() => {
  //   if (!listingSelected && $virtuoso.current) {
  //     // @TODO if range is already within the saved range, do nothing
  //     $virtuoso.current.scrollToIndex({ align: 'start', behavior: 'auto', index: scrollPosition });

  //     // Reset flag
  //     setWasPositionSet(false);
  //   }
  // }, [listingSelected, scrollPosition, setWasPositionSet]);

  /** If the user changes the sort by selection or the filters, reset the position of the list to the top */
  useEffect(() => {
    setScrollPosition(0);
    if ($virtuoso.current) {
      $virtuoso.current.scrollToIndex({ align: 'start', behavior: 'auto', index: 0 });

      // Reset flag
      setWasPositionSet(false);
    }
  }, [sortBy, filters, setScrollPosition, setWasPositionSet]);

  return (
    <>
    {
      listingExpanded
        ? <S.ListingFullViewContainer>
            <ListingCardFullView {...{ data: listingExpanded, disableFavorites }} />
          </S.ListingFullViewContainer>
        : <></>
      }

      <S.Container>

        <S.OptionsContainer>
          <ListOptions {...{ ...options }}/>
        </S.OptionsContainer>

          <S.ListContainer>
            {listingsToDisplay && userSearchResults
              ? <VirtuosoGrid
                    style={{ width: '100%', height: '100%' }}
                    totalCount={listingsToDisplay.length}
                    data={listingsToDisplay}
                    ref={$virtuoso}
                    isScrolling={() => setScrollPosition(visibleRange.startIndex)}
                    rangeChanged={setVisibleRange}
                    components={{
                      // @ts-ignore bug here this shouldn't be throwing a type error
                      List: S.ResultListContainer,
                      Item: S.ItemContainer,
                    }}
                    itemContent={(_, key) => <ListingCard {...{ data: userSearchResults[key], disableFavorites }} key={key} />}
                />
              : <></>
          }
         </S.ListContainer>

      </S.Container>

    </>
  );
};

export default React.memo(ResultsList);
