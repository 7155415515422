import {
  FunctionComponent,
  useEffect,
} from 'react';
import { Button } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from '@hooks';
import clsx from 'clsx';
import { addingUserLocation, resetUserLocationInputSession } from '@stores';
import { AddingUserLocationPayload, StepKeys } from '@types';

import useStyles from './AddButton.styles';

/**
 * Button to add a user entered location
 * @component
 */
const AddButton: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const {
    currentUserLocation,
    currentUserLocationName,
    currentTravelMode,
    newUserLocationWillBeAdded,
  } = useAppSelector(
    (state) => state.userLocationsInput,
  );

  // @TODO no need to have this state here, change the store to better handle this
  const handelOnClick = () => {
    const payload: AddingUserLocationPayload = {
      location: currentUserLocation,
      name: currentUserLocationName,
      travelMode: currentTravelMode,
    };
    dispatch(addingUserLocation(payload));
  };

  // If the dispatch to add the location was successful, resent the data
  useEffect(() => {
    if (newUserLocationWillBeAdded) {
      dispatch(resetUserLocationInputSession());
    }
  }, [newUserLocationWillBeAdded]);

  return (
    <Button
    variant="outlined"
    className={clsx({
      [classes.button]: true,
      submit: !!currentUserLocation,
    })}
    onClick={handelOnClick}>
      Add
    </Button>
  );
};

export default AddButton;
