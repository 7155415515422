import { FunctionComponent } from 'react';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  updatingSelectedListingFromSavedSearchDataTable,
  selectDataForListingSelectedFromSavedSearch,
  updatingSavedSearchForListingSelected,
} from '@stores';
import { Images, ListingDetailSummary, ListingDetail } from '@components';
import { ImageType } from '@types';
import useStyles from './ListingCardSideView.styles';

const ListingCardSideView: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const data = useAppSelector(selectDataForListingSelectedFromSavedSearch);
  const display = data !== null;

  // Closes out of the full view
  const handleOnClickClose = () => {
    dispatch(updatingSelectedListingFromSavedSearchDataTable(null));
    dispatch(updatingSavedSearchForListingSelected(null));
  };

  return (
    <>

    {
      display

        ? <div className={classes.container} aria-label="listing-full-view">

            <IconButton aria-label="close" classes={{ root: classes.close }} onClick={handleOnClickClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>

            <div className={classes.content}>

              <div className={classes.images}>
                <Images {...{ images: data.listing.photos, id: data.listing.id, type: ImageType.FULL }} />
              </div>

              <div className={classes['listing-detail']}>

                <ListingDetailSummary {...data.listing}/>

                <ListingDetail {...{ listing: data.listing, userLocations: data.userLocations }}/>

              </div>

            </div>

          </div>

        : <></>
    }

    </>

  );
};

export default ListingCardSideView;
