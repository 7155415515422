import { FunctionComponent, useCallback } from 'react';
import clsx from 'clsx';
import { UserLocationInfo } from 'beiytak_sdk';
import { createCommuteLabel, getTravelModeIcon } from '@utils';
import useStyles from './UserLocationMarker.styles';

/**
 * This marker is used to display the user location details for a listing
 * when its selected either from the result list or the map
 */
const UserLocationMarker: FunctionComponent<UserLocationInfo> = (userLocationInfo) => {
  const classes = useStyles();
  const {
    userAlias, userLocation, name, commute, place_id, travelMode,
  } = userLocationInfo;

  const Icon = getTravelModeIcon(travelMode);
  const commuteLabel = commute ? createCommuteLabel(commute, 'include') : '';
  const placeName = name || '';

  // Opens the link into a new tab when the user clicks on the marking
  const handleOnClick = useCallback(() => {
    // Doc reference here: https://developers.google.com/maps/documentation/urls/get-started
    const url = `https://www.google.com/maps/search/?api=1&query=${userLocation.split(' ').join('+')}&query_place_id=${place_id}`;
    window.open(url, '_blank');
  }, []);

  return (
    <button
        className={classes.container}
        onClick={handleOnClick}
    >

    <div className={clsx(classes['icon-container'])}>
        <Icon className={classes.icon} />
        <span>{commuteLabel}</span>
    </div>

    <div className={classes['content-container']}>
        <span className={classes.title}>{userAlias}</span>
        <span className={classes.subtitle}>{placeName}</span>
    </div>

    </button>
  );
};
export default UserLocationMarker;
