import useStyles from './NoFavorites.styles';

const NoFavorites = () => {
  const classes = useStyles();

  return (
      <span className={classes.container}>No Favorites</span>
  );
};

export default NoFavorites;
