import { makeStyles } from '@material-ui/core/styles';
import { favoriteColor } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',

    width: '100%',
    height: '100%',

    color: 'grey',
    fontSize: '0.875rem',

  },

}));

export default useStyles;
