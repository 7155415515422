import { FC } from 'react';
import * as S from './HeaderContent.styled';

const HeaderContent: FC = () => {
  return (
        <S.Container>
            <S.Title> Stop the endless Zillow Browsing! </S.Title>
            <S.Subtitle>
              Use Findur to filter listings based on commute times to all your places of interest in seconds saving you weeks of time
            </S.Subtitle>
        </S.Container>
  );
};

export default HeaderContent;
