import { FC } from 'react';
import { SectionInfo } from '@components';
import { CreateAccountService } from '@types';
import { useActor } from '@xstate/react';
import placeholder from '../../assets/journey-placeholder.png';
import journey from '../../assets/journey.svg';
import * as S from './JourneyLayout.styled';

const sectionInfo = {
  title: 'Interested in Findur\'s Pitch deck?',
  subtitle: 'Click below to learn the why, what, and how of Findur',
};

interface JourneyLayoutProps {
  createAccountService: CreateAccountService
}

const JourneyLayout: FC<JourneyLayoutProps> = ({ createAccountService }) => {
  const [, send] = useActor(createAccountService);

  const journeyFindurLandingPageLink = 'https://findur.journey.io/p/3f3e6c';

  return (
        <S.Container>

            <S.SectionInfoContainer>
                <SectionInfo {...sectionInfo} />
            </S.SectionInfoContainer>

            <S.PlaceholderContainer>
                <S.PlaceholderImage src={placeholder} onClick={() => window.open(journeyFindurLandingPageLink, '_blank')}/>
            </S.PlaceholderContainer>

        </S.Container>

  );
};

export default JourneyLayout;
