import React, { FunctionComponent } from 'react';
import { NewSearchButton, SavedSearches, SavedSearchesFilters } from '@components';
import useStyles from './SavedSearchesLayout.styles';

const SavedSearchesLayout: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes['saved-searches-container']}>
      <div className={classes['filters-and-new-search-container']}>
        <SavedSearchesFilters />
        <NewSearchButton />
      </div>
      <SavedSearches />
    </div>
  );
};

export default React.memo(SavedSearchesLayout);
