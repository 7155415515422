import { sortBy } from 'lodash';
import { ListingWithID, HomeCriteriaKeys } from '@types';
import { getMoreFilterIcon, getHomeCriteriaIcon } from '@utils';
import { formatListingInfo, parsePetPolicy } from '.';

const getDetailsFromListing = (listing: ListingWithID) => {
  const BedroomsIcon = getHomeCriteriaIcon(HomeCriteriaKeys.BEDROOMS);
  const BathroomsIcon = getHomeCriteriaIcon(HomeCriteriaKeys.BATHROOMS);
  const StoriesIcon = getMoreFilterIcon('stories');
  const GarageIcon = getMoreFilterIcon('garageCount');
  const SqftIcon = getMoreFilterIcon('sqft');
  const LotSqftIcon = getMoreFilterIcon('lotSqft');
  const AgeIcon = getMoreFilterIcon('age');

  const {
    bedrooms,
    bathrooms,
    bathsFull,
    bathsHalf,
    features,
    garageCount,
    lotSqft,
    sqft,
    age,
    stories,
    petPolicy,
  } = listing;

  const bedroomsDetails = formatListingInfo({
    title: 'Bedrooms',
    iconSource: BedroomsIcon,
    data: [
      {
        label: 'Bedrooms',
        value: bedrooms,
      },
    ],
  });

  const bathroomsDetails = formatListingInfo({
    title: 'Bathrooms',
    iconSource: BathroomsIcon,
    data: [
      {
        label: 'Bathrooms',
        value: bathrooms,
      },
      {
        label: 'Full Bathrooms',
        value: bathsFull,
      },
      {
        label: '1/2 Bathrooms',
        value: bathsHalf,
      },
    ],
  });

  const storiesDetails = formatListingInfo({
    title: 'Stories',
    iconSource: StoriesIcon,
    data: [
      {
        label: 'Stories',
        value: stories,
      },
    ],
  });

  const garageDetails = formatListingInfo({
    title: 'Garage Spaces',
    iconSource: GarageIcon,
    data: [
      {
        label: 'Garage Spaces',
        value: garageCount,
      },
    ],
  });

  const sqftDetails = formatListingInfo({
    title: 'Sqft',
    iconSource: SqftIcon,
    data: [
      {
        label: 'Sqft',
        value: sqft,
      },
    ],
  });

  const lotSqftDetails = formatListingInfo({
    title: 'Lot Sqft',
    iconSource: LotSqftIcon,
    data: [
      {
        label: 'Lot Sqft',
        value: lotSqft,
      },
    ],
  });

  const ageDetails = formatListingInfo({
    title: 'Age',
    iconSource: AgeIcon,
    data: [
      {
        label: 'Age',
        value: age,
        suffix: 'years',
      },
    ],
  });

  const featureDetails = formatListingInfo({
    title: 'Features',
    iconSource: AgeIcon,
    data: sortBy(features).map((feature) => {
      const info = { value: feature };
      return info;
    }),
  });

  const petPolicyDetails = formatListingInfo(parsePetPolicy(petPolicy));

  const data = {
    bedroomsDetails,
    bathroomsDetails,
    storiesDetails,
    garageDetails,
    sqftDetails,
    lotSqftDetails,
    ageDetails,
    featureDetails,
    petPolicyDetails,
  };

  return data;
};

export default getDetailsFromListing;
