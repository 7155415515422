import { FC } from 'react';
import Lottie from 'react-lottie';
import { SectionInfo, LandingButton } from '@components/shared';
import { Routes } from '@types';
import { useNavigate } from 'react-router';
import * as S from './ThankYouForSigningUp.styled';
import thankYouAnimation from '../../../assets/thank-you.json';

const info = {
  title: 'Thank you!',
};

const ThankYouForSigningUp: FC = () => {
  const navigate = useNavigate();

  return (
        <S.Container>
            <SectionInfo {...info}/>
            <S.AdditionalInfo>
                Lookout for an email on next steps. To skip the wait, send an email to
                <S.HighlightText>{' support@findur.io '}</S.HighlightText>
                {'with the plan you\'re interested in.'}
            </S.AdditionalInfo>
            <S.AnimationContainer>
                <Lottie
                speed={1}
                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                options={{
                  animationData: thankYouAnimation,
                  autoplay: true,
                  loop: true,
                }}
                isClickToPauseDisabled
                />
            </S.AnimationContainer>

            <LandingButton variant='orange' text='Close' onClick={() => navigate(Routes.LANDING)}/>
        </S.Container>
  );
};

export default ThankYouForSigningUp;
