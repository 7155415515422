import { UserLocation } from '../../../../types';

/**
 * Validates the users location input
 * The user must add at least 1 location to the list to be valid
 * @param userLocations entered user locations
 * @returns { boolean } Whether or not the user location validation passed
 */
const userLocationsValidation = (userLocations: UserLocation[]) => {
  const passed = userLocations.length > 0;

  return passed;
};

export default userLocationsValidation;
