import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    alignContent: 'center',

    width: '100%',
    heigh: '100%',

  },

  options: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'fle-start',
    alignItems: 'center',
    flexWrap: 'wrap',

    width: '100%',
    heigh: '100%',

  },

  option: {
    margin: '0.3rem',
  },

  title: {
    alignSelf: 'flex-start',

    marginBottom: '0.5rem',

    fontSize: '1.2rem',
    fontWeight: theme.typography.h5.fontWeight,
  },

}));

export default useStyles;
