import { FunctionComponent } from 'react';
import { useAppSelector } from '@hooks';
import { RequestStatus } from '@types';
import { sortSavedSearchesByAlphabeticalOrder } from '@utils';
import Lottie from 'react-lottie';
import { SavedSearch } from '@components';
import { selectSavedSearchesToDisplay } from '@stores';
import useStyles from './SavedSearches.styles';
import searchingAnimation from '../../../assets/searching_animation.json';

const SavedSearches: FunctionComponent = () => {
  const classes = useStyles();
  const {
    savedSearchRequestStatus, savedSearches,
  } = useAppSelector((state) => state.savedSearches);
  const savedSearchesToDisplayList = useAppSelector(selectSavedSearchesToDisplay);

  const savedSearchesToDisplay = savedSearches ? savedSearchesToDisplayList.map((searchID) => savedSearches[searchID]) : [];
  const sortedSavedSearchesToDisplay = sortSavedSearchesByAlphabeticalOrder(savedSearchesToDisplay);

  return (
    <>
    {
        savedSearchRequestStatus === RequestStatus.PENDING
          ? <div className={classes['loading-container']}>
            <Lottie
              speed={1}
              options={{
                animationData: searchingAnimation,
                autoplay: true,
              }}
              isClickToPauseDisabled
            />
          </div>
          : <></>
      }

      {
        savedSearchRequestStatus === RequestStatus.REJECTED
          ? <div>We had a problem, getting your results. Trying refreshing the page</div>
          : <></>
      }

      {
        savedSearches && savedSearchRequestStatus !== RequestStatus.PENDING
          ? sortedSavedSearchesToDisplay.map((savedSearch) => {
            const { searchName } = savedSearch;
            return <SavedSearch {...{ savedSearch }} key={searchName}/>;
          })
          : <></>
      }

    </>
  );
};

export default SavedSearches;
