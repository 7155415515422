import { createAsyncThunk } from '@reduxjs/toolkit';
import { Routes, saveSearch, SaveSearch } from 'beiytak_sdk';
import { getAPIUrl, formatUserSearchInput } from '@utils';

const SaveUserSearchAction = 'searchResultSession/saveSearch';

/** Saves the user search currently being analyzed to the backend */
const saveUserSearch = createAsyncThunk(
  SaveUserSearchAction,
  async (params: SaveSearch): Promise<string> => {
    const url = getAPIUrl(Routes.GRAPHQL);

    const searchId = await saveSearch({ params }, url).then((res) => {
      const { data } = res;
      if (data) { return data; }

      return Promise.reject();
    });

    return searchId;
  },
);

export { saveUserSearch, SaveUserSearchAction };
