import { FunctionComponent } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks';
import Checkbox from '@material-ui/core/Checkbox';
import { SavedSearchStatus } from 'beiytak_sdk';
import { theme } from '@constants';
import { updatingSelectedSearchStatuses, selectSavedSearchesFiltersSelectedStatuses } from '@stores';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import useStyles from './SearchStatusFilter.styles';

/** This component has the filters that can be used to filter down the results */
const SearchStatusFilter: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const selectedStatuses = useAppSelector(selectSavedSearchesFiltersSelectedStatuses);

  const activeSelected = selectedStatuses.includes(SavedSearchStatus.Active);
  const archivedSelected = selectedStatuses.includes(SavedSearchStatus.Archived);

  const handleOnClick = (status: SavedSearchStatus) => { dispatch(updatingSelectedSearchStatuses(status)); };

  return (
    <div className={classes.container}>
        <span className={classes.label}>Show:</span>

        <div className={classes['option-container']}>
            <Checkbox
                disableRipple
                classes={{
                  root: classes.root,
                }}
                checked={activeSelected}
                onClick={() => handleOnClick(SavedSearchStatus.Active)}
                checkedIcon={<CheckBoxIcon style={{ fill: theme.palette.primary.main }}/>}
                name='Active'
            />
            <span className={classes.label}>Active</span>
        </div>

        <div className={classes['option-container']}>
            <Checkbox
            disableRipple
            classes={{
              root: classes.root,
            }}
            checked={archivedSelected}
            onClick={() => handleOnClick(SavedSearchStatus.Archived)}
            checkedIcon={<CheckBoxIcon style={{ fill: theme.palette.primary.main }}/>}
            name='Archived'
            />
            <span className={classes.label}>Archived</span>
        </div>
    </div>
  );
};

export default SearchStatusFilter;
