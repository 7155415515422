import { FC } from 'react';
import { SectionInfo, LandingButton, UseCase } from '@components';
import { CreateAccountService } from '@types';
import { relocatingUseCase, innerCityUseCase, investmentUseCase } from '@constants';
import { useActor } from '@xstate/react';
import results from '../../assets/result-screenshot.png';
import * as S from './UseCasesLayout.styled';

const sectionInfo = {
  title: 'What used to take weeks of manual work now takes seconds',
  subtitle: 'Whether you are looking to buy, rent, or invest for yourself or a client, Findur cuts down countless hours from your research allowing you to close on a home that much faster.',
};

interface UseCaseLayoutProps {
  createAccountService: CreateAccountService
}

const UseCasesLayout: FC<UseCaseLayoutProps> = ({ createAccountService }) => {
  const [, send] = useActor(createAccountService);

  return (
        <S.Container>

            <S.SectionInfoContainer>
                <SectionInfo {...sectionInfo} />
            </S.SectionInfoContainer>

            <S.ImageContainer>
                <S.Image src={results} />
            </S.ImageContainer>

            <S.ContentContainer>

              <UseCase {...relocatingUseCase}/>
              <UseCase {...innerCityUseCase}/>
              <UseCase {...investmentUseCase}/>

              <S.ContactUsContainer>
                <LandingButton text='Join Now' variant='blue' onClick={() => send({ type: 'STARTED_SIGN_UP', payload: {} }) }/>
              </S.ContactUsContainer>

            </S.ContentContainer>

        </S.Container>

  );
};

export default UseCasesLayout;
