import { FunctionComponent } from 'react';
import { useAppSelector } from '@hooks';
import { updatingMoreResultsFiltersForFeatures, selectMoreFiltersFeatureDefault } from '@stores';
import Feature from './Feature';
import useStyles from './ListingFeatures.styles';

const ListingFeatures: FunctionComponent = () => {
  const classes = useStyles();
  const features = useAppSelector(selectMoreFiltersFeatureDefault);
  const action = updatingMoreResultsFiltersForFeatures;

  return (

    <div className={classes.container}>
        <span className={classes.title}>Features</span>
        <div className={classes.options}>
        {features.map((feature) => {
          return (
            <div className={classes.option} key={feature}>
            <Feature {...{ feature, action }} key={feature} />
            </div>
          );
        })}
        </div>
    </div>
  );
};

export default ListingFeatures;
