import styled from 'styled-components';
import { $theme } from '@styleGuide';

export const Button = styled.button<{variant: 'oval' | 'rectangle', allowClick: boolean}>`

    // For cases where the button is in the data table
    z-index: 1;

    min-width: 6.25rem;
    //max-width: 6.25rem;
    height: 3.125rem;
    max-height: 50px;

    margin: 0.75rem;

    border: 1px solid ${$theme('palette.primary.main')};
    border-radius: ${({ variant }) => (variant === 'oval' ? `${$theme('border.radius.25')}` : `${$theme('border.radius.10')}`)};
    box-shadow: ${({ variant }) => (variant === 'oval' ? 'none' : '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)')};


    background-color: ${({ allowClick }) => (allowClick ? `${$theme('palette.primary.main')}` : `${$theme('palette.neutral.main')}`)};

    color: ${({ allowClick }) => (allowClick ? `${$theme('palette.neutral.light')}` : `${$theme('palette.primary.main')}`)};

    font-size: 1rem;

    &:hover {
        transform: scale(1.02)
    }

    cursor: pointer;
`;
