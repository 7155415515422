import styled from 'styled-components';
import { $theme } from '@styleGuide';
import { Variant } from './TopNavBar.comp';

export const Container = styled.div<{variant: Variant}>`
    z-index: 2;

    position: sticky;
    top: 0rem;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100vw;
    height: ${$theme('constants.top-navbar-height')};

    background: ${({ variant }) => {
    switch (variant) {
      case 'white':
        return $theme('palette.neutral.light');
      case 'transparent':
        return 'transparent';
      default:
        return $theme('palette.neutral.light');
    }
  }};
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04);
`;

export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-right: 1rem;
  margin-left: 1rem;
  height: 100%;

  cursor: pointer;
`;

export const ChildrenContainer = styled.div`
  width: 80%;
  height: 100%;
`;

export const Logo = styled.img`
  max-width: 7rem;
`;

export const Navigation = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  margin-right: 3rem;

  width: 10%;
  height: 100%;

  @media (${$theme('devices.tabletAndBelow')}) {
    display: none;
  }
`;

export const Login = styled.button`
    width: 8rem;
    height: 3.5rem;

    font-size: 1.2rem;
    font-weight: 600;

    border: none;
    border-radius: 9px;
    color: white;
    background:${$theme('palette.neon.orange.main')};

    cursor: pointer;
`;
