import { FC, useState } from 'react';
import { Feature, DemoAndDescription, SectionInfo } from '@components';
import { searchFeature, filterResultsFeature, savedSearchesFeature } from '@constants';
import { FeatureOptions, CreateAccountService } from '@types';
import * as S from './FeatureLayout.styled';

interface FeatureLayoutProps {
  createAccountService: CreateAccountService
}

export const demoVideo = {
  video: 'https://www.loom.com/embed/57f7190985eb4239b6f3ed037af88dea?sid=733a5dc0-784d-4be0-996e-01d6d4d9658d',
  feature: 'See it in action',
  title: 'Click below to watch a full demo',
  description: '',
};

const FeatureLayout: FC<FeatureLayoutProps> = ({ createAccountService }) => {
  return (
      <S.Container>
        <DemoAndDescription {...{ variant: 'pink', ...searchFeature, createAccountService }}/>
        <S.Break />
        <DemoAndDescription {...{ variant: 'purple', ...filterResultsFeature, createAccountService }}/>
        <S.Break />
        <DemoAndDescription {...{ variant: 'green', ...savedSearchesFeature, createAccountService }}/>
      </S.Container>

  );
};

export default FeatureLayout;
