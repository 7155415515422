import {
  Middleware,
  ThunkDispatch,
  unwrapResult,
} from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import {
  userRequestingToLogin,
  loginFailure,
  loginsSucceeded,
  loginUser,
  RootState,
  verifyingUserAuth,
  verifyAuth,
  verificationComplete,
  verificationSucceeded,
  verificationFailure,
} from '@stores';
import { Routes } from '@types';

const handlingAuthAndNavigationMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /**
   * When the user clicks on the submit button on the login page
   * dispatch the thunk to validate the credentials
   */
  if (userRequestingToLogin.match(action)) {
    const { payload } = action;
    next(action);
    return storeAPI.dispatch(loginUser(payload));
  }

  /**
   * When the action is kicked off by the verification comp to verify the user,
   * dispatch the official action to check the users auth using the http only token
   */
  if (verifyingUserAuth.match(action)) {
    const { payload: navigate } = action;
    next(action);
    return storeAPI.dispatch(verifyAuth(navigate));
  }

  /**
   * Handles when the app tries to verify the user based on the cookie stored
   */
  if (
    verifyAuth.fulfilled.match(action)
    || verifyAuth.rejected.match(action)) {
    const { status, navigation } = unwrapResult(action);
    const { navigate, pathname } = navigation;

    // Ensure the verification step is set top to false
    // after receiving back results
    storeAPI.dispatch(verificationComplete());

    if (status === StatusCodes.OK) {
      storeAPI.dispatch(verificationSucceeded());

      // f the user is verified already and visited the app, this is the default route to send them to
      if (pathname === '/') { return navigate(Routes.SEARCH); }

      // If the user has already been using the app, return the user to where they were
      // This value is -1 because the verify screen is displayed while verification is taking place
      if (pathname !== '/') { return navigate(-1); }
    }

    storeAPI.dispatch(verificationFailure());
    return navigate(Routes.LOGIN);
  }

  /**
   * If the response is successful confirm the user is authorized then redirect
   * to the search page
   */
  if (
    loginUser.fulfilled.match(action)
    || loginUser.rejected.match(action)) {
    const { status, navigate } = unwrapResult(action);
    if (status === StatusCodes.OK) {
      storeAPI.dispatch(loginsSucceeded());

      return navigate(Routes.SEARCH);
    }

    if (status === StatusCodes.UNAUTHORIZED) {
      storeAPI.dispatch(loginFailure());
      return navigate(Routes.LOGIN);
    }

    if (status === StatusCodes.NOT_FOUND) {
      return navigate(Routes.ERROR);
    }

    // If a status didn't combe back that means the request failed
    storeAPI.dispatch(loginFailure());
    return navigate(Routes.LOGIN);
  }

  return next(action);
});

export default handlingAuthAndNavigationMiddleware;
