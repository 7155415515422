import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '1.5fr 1fr 2fr',
    overflow: 'hidden',
    gridTemplateAreas:
        `
            '.'
            'animation'
            'text'
            '.'
        `,

    width: '100vw',
    height: '100vh',

    // backgroundColor: theme.palette.primary.main,
  },

  animation: {
    gridArea: 'animation',
    justifySelf: 'center',
    alignSelf: 'center',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'space-between',
    alignItems: 'center',

    borderRadius: '50px',

    backgroundColor: 'white',
    width: '35rem',
    maxWidth: '35rem',
    height: '30rem',
    maxHeight: '30rem',
  },

  'text-container': {
    gridArea: 'text',

    ...theme.typography.h3,

    fontWeight: theme.typography.h2.fontWeight,
    textAlign: 'center',
    textOverflow: 'ellipsis',
    color: 'black',

    zIndex: 2,

  },

}));

export default useStyles;
