import { FunctionComponent, useState } from 'react';
import { ListingWithID } from '@types';
import { getDetailsFromListing } from './utils';
import InformationDetail from '../InformationDetail';
import useStyles from './FactsAndFeatures.styles';

/**
 * Displays user location data for the listing thats in view
 */
const FactsAndFeatures: FunctionComponent<ListingWithID> = (listing) => {
  const classes = useStyles();

  const {
    bedroomsDetails,
    bathroomsDetails,
    storiesDetails,
    garageDetails,
    sqftDetails,
    lotSqftDetails,
    ageDetails,
    featureDetails,
    petPolicyDetails,
  } = getDetailsFromListing(listing);

  return (
    <div className={classes.container} aria-label={'facts-and-features'}>
        <div className={classes.facts}>

            <InformationDetail {...bedroomsDetails}/>

            <InformationDetail {...bathroomsDetails}/>

            <InformationDetail {...storiesDetails}/>

            <InformationDetail {...garageDetails}/>

            <InformationDetail {...petPolicyDetails}/>

            <InformationDetail {...sqftDetails}/>

            <InformationDetail {...lotSqftDetails}/>

            <InformationDetail {...ageDetails}/>

            <InformationDetail {...featureDetails}/>

        </div>

    </div>
  );
};

export default FactsAndFeatures;
