import { makeStyles } from '@material-ui/core/styles';

// I have to manually specify color for mapbox
// Using theme breaks mapbox's ability to display the component
const useStyles = makeStyles(() => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    '&:hover': {
      transform: 'scale(1.06)',
    },
  },

  marker: {
    height: '1.75rem',

    borderRadius: '1.75rem',

    backgroundColor: '#FFFFFF',
    boxShadow: 'rgba(0, 0, 0, 0.04) 0px 0px 0px 1px, rgba(0, 0, 0, 0.18) 0px 2px 4px 0px',

    fontWeight: 'bold',

    '&:hover': {
      backgroundColor: '#FFFFFF',
    },
  },

  favorited: {
    backgroundColor: '#FF4879',
    color: '#FFFFFF',

    '&:hover': {
      backgroundColor: '#FF4879',
      color: '#FFFFFF',

      transform: 'scale(1.06)',
    },
  },

  hovered: {
    backgroundColor: '#FF7348',
    color: '#FFFFFF',

    '&:hover': {
      backgroundColor: '#FF7348',
      borderRadius: '1.75rem',
      color: '#FFFFFF',
    },
  },

}));

export default useStyles;
