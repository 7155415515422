import { FC } from 'react';
import patent from '../../../assets/patent.svg';
import * as S from './PatentPending.styled';

const PatentPending: FC = () => {
  return (
        <S.Container>
            <S.Icon src={patent}/>
            <S.Text>Currently Patent Pending! </S.Text>
        </S.Container>
  );
};

export default PatentPending;
