import React, { FunctionComponent, useState, useEffect } from 'react';
import clsx from 'clsx';
import { userChangedTravelMode } from '@stores';
import { useAppSelector, useAppDispatch } from '@hooks';
import { IndexableObject } from 'types';
import Button from '@material-ui/core/Button';
import { TravelModes } from 'beiytak_sdk';
import { SvgIcon } from '@material-ui/core';
import { getTravelModeIcon } from '@utils';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import useStyles from './TravelModeOption.styles';

interface TravelModeOptionProps {
  travelMode: TravelModes,
  /** left, middle, or right in the order of it being displayed */
  position: string,
}

/**
 * Option for the travel option
 */
const TravelModeOption: FunctionComponent<TravelModeOptionProps> = ({ travelMode, position }) => {
  const classes = useStyles();
  const { currentTravelMode } = useAppSelector((state) => state.userLocationsInput);
  const dispatch = useAppDispatch();
  const Icon = getTravelModeIcon(travelMode);
  const selected = currentTravelMode === travelMode;

  const handleOnClick = (mode: TravelModes) => {
    dispatch(userChangedTravelMode(mode));
  };

  return (
    <Button
    classes={{
      root: clsx(classes.button, position),
      label: classes.content,
    }}
    className={clsx({
      selected,
    })}
    onClick={() => handleOnClick(travelMode)}
    aria-label={travelMode.toLowerCase()}
    >
      <Icon className={classes.icon}/>
      <span aria-label={travelMode} className={classes.text}>{travelMode.toLocaleLowerCase()}</span>
    </Button>
  );
};

export default TravelModeOption;
