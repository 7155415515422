import { FunctionComponent, useState } from 'react';
import {
  FavoriteRounded,
  FavoriteBorder,
} from '@material-ui/icons';
import { includes } from 'lodash';
import { useAppDispatch, useAppSelector } from '@hooks';
import {
  addingListingToFavorites,
  removingListingFromFavorites,
} from '@stores';
import useStyles from './FavoriteListing.styles';

interface FavoriteListingProps {
  /** Id of the listing */
    id: string,
}

/** This component is used to favorite a listing */
const FavoriteListing: FunctionComponent<FavoriteListingProps> = ({ id }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { favoriteListings } = useAppSelector((state) => state.searchResultFavorites);
  const listingFavorited = favoriteListings && favoriteListings.length > 0 ? includes(favoriteListings, id) : false;
  const [favorite, setFavorite] = useState(listingFavorited);

  // Handles the action when the user clicks on the favorite icon for a listing
  const handleOnClickFavorite = () => {
    const updatedFavoriteValue = !favorite;
    setFavorite(updatedFavoriteValue);

    // Dispatch action to update the state
    if (updatedFavoriteValue) { dispatch(addingListingToFavorites(id)); }
    if (!updatedFavoriteValue) { dispatch(removingListingFromFavorites(id)); }
  };

  return (
    <div
      className={classes.container}
      onClick={handleOnClickFavorite}
      onKeyPress={handleOnClickFavorite}
      aria-label="favorite-listing"
      role="button"
      tabIndex={0}
    >
      { listingFavorited
        ? <FavoriteRounded className={classes['favorite-icon-selected']} />
        : <FavoriteBorder className={classes['favorite-icon']} /> }
    </div>
  );
};

export default FavoriteListing;
