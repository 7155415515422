import styled from 'styled-components';

export const Container = styled.button`
    background-color: transparent;
    border: none;

    cursor: pointer;
`;

export const Logo = styled.img`
    width: 5rem;
    height: 3rem;
`;
