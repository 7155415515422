import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPlaceDetails, GoogleFindPlaceInfo, Routes } from 'beiytak_sdk';
import { getAPIUrl } from '@utils';

const GetGooglePlaceDetailsAction = 'searchResultSession/getGooglePlaceDetails';

/**
 * Gets the google place details for an array of placeIDs
 */
const getGooglePlaceDetails = createAsyncThunk(
  GetGooglePlaceDetailsAction,
  async (params: string[]): Promise<GoogleFindPlaceInfo[]> => {
    const url = getAPIUrl(Routes.GRAPHQL);

    const googlePlaceFindInfo = await getPlaceDetails({ params }, url).then((res) => {
      const { data } = res;

      if (data) { return data; }

      return Promise.reject();
    });

    return googlePlaceFindInfo;
  },
);

export { getGooglePlaceDetails, GetGooglePlaceDetailsAction };
