import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    overflow: 'hidden',

    width: '100%',
    height: '100%',

    // background: theme.palette.neutral.light,
    boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',
    borderRadius: '10px',

  },

  favorite: {

    position: 'absolute',
    zIndex: 1,

    top: '0.85em',
    right: '1.5rem',

  },

  close: {
    position: 'absolute',
    top: '0rem',
    right: '0rem',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    marginTop: '0.45rem',
    marginRight: '0.45rem',

    width: '1rem',
    height: '1rem',

    borderRadius: '5px',
    backgroundColor: '#FFFFFFE6',

    '&:hover': {
      transform: 'scale(1.04)',
      background: theme.palette.neutral.main,
    },

  },

  closeIcon: {
    color: '#202020',

    width: '0.6rem',
    height: '0.6rem',

  },

  content: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    width: '100%',
    height: '100%',
  },

  images: {

    position: 'relative',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',

    width: '65%',
    height: '100%',

    backgroundColor: 'black',

  },

  'listing-detail': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

    width: '35%',
    height: '100%',

    background: theme.palette.neutral.light,

    borderRadius: '0px 10px 10px 0px',

  },

}));

export default useStyles;
