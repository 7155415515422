import React, { FunctionComponent, useEffect } from 'react';
import { Map, ResultsList } from '@components';
import { userLeavingFavorites } from '@stores';
import { useAppDispatch } from '@hooks';
import useStyles from './FavoritesLayout.styles';

const FavoritesLayout: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();

  useEffect(() => {
    return () => { dispatch(userLeavingFavorites()); };
  });

  return (
    <div className={classes.container}>
    <div className={classes.map}>
      <Map />
    </div>
    <div className={classes.listings}>
      <ResultsList {...{ disableFavorites: true }}/>
    </div>
</div>
  );
};

export default React.memo(FavoritesLayout);
