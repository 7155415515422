import { FunctionComponent } from 'react';
import { getHomeCriteriaIcon } from '@utils';
import { HomeCriteriaKeys, ListingWithID } from '@types';
import useStyles from './ListingHomeCriteria.styles';

const ListingHomeCriteria: FunctionComponent<ListingWithID> = (data) => {
  // Format the text for the bedrooms info
  let bedroomsLabel: string = '';
  let bathroomsLabel: string = '';
  let sqftLabel: string = '';

  const classes = useStyles();
  const { bedrooms, bathrooms, sqft } = data;
  const BedroomIcon = getHomeCriteriaIcon(HomeCriteriaKeys.BEDROOMS);
  const BathroomIcon = getHomeCriteriaIcon(HomeCriteriaKeys.BATHROOMS);

  if (bedrooms) { bedroomsLabel = bedrooms > 1 ? `${bedrooms} bds` : `${bedrooms} bd`; }
  if (bathrooms) { bathroomsLabel = `${bathrooms} ba`; }
  if (sqft) { sqftLabel = `${sqft?.toLocaleString()} sqft`; }

  return (
    <div className={classes.container}>

      {BedroomIcon ? <img src={BedroomIcon} alt="" className={classes.icon} /> : <></>}
      <span className={classes.label} aria-label="bedrooms">{bedroomsLabel}</span>

      <div className={classes.divider} />

      {BathroomIcon ? <img src={BathroomIcon} alt="" className={classes.icon} /> : <></>}
      <span className={classes.label} aria-label="bathrooms">{bathroomsLabel}</span>

      <div className={classes.divider} />

      <span className={classes.label} aria-label="sqft"> {sqftLabel} </span>
    </div>

  );
};

export default ListingHomeCriteria;
