enum ImageType {
    /** Display images accounting for a smaller height for the card view */
    CARD = 'CARD',

    /** Display images fully */
    FULL = 'FULL',
}

// eslint-disable-next-line
export { ImageType };
