import styled from 'styled-components';
import { devices } from '@styleGuide';

export const Container = styled.div`
    display: grid;
    grid-template-areas:
    "wait-list"
    ;
    place-items: center;
    position: relative;

    width: 100vw;
    height: 100vh;

    background: #fff7f5;

`;

export const CloseButton = styled.div`
    top: 1rem;
    right: 1rem;

    position: absolute;
    align-self: start;
    justify-self: right;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;

    border-radius: 50%;
    border: solid 2px #FF7348;

    font-size: 1rem;
    font-weight: 700;
    color: #FF7348;

    cursor: pointer;

    &:hover {
        background: #FF7348;
        color: #FFFFFF;
    }

    @media (${devices.laptopAndAbove}) {
        top: 1rem;
        right: 2rem;

        width: 3rem;
        height: 3rem;

        font-size: 1.5rem;
    }

`;

export const CreateAccountContainer = styled.div`
    grid-area: wait-list;

    width: 95%;
    max-width: 345px;
    height: 100%;
    max-height: 550px;

    box-shadow: 0 24px 64px #26214a1a;
    background-color: #FFFFFF;
    border-radius: 10px;
    border: none;


    @media (${devices.laptopAndAbove}) {
        max-width: 480px;
        max-height: 550px;
    }
`;
