import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
  },

  'loading-container': {
    width: '40%',
    height: '40%',
  },
}));

export default useStyles;
