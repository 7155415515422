import { FC } from 'react';
import { SectionInfo, LandingButton, UseCase } from '@components';
import { CreateAccountService } from '@types';
import { reportSentToYourEmail, excelSheet, interactWithResults } from '@constants';
import { useActor } from '@xstate/react';
import report from '../../assets/report.png';
import * as S from './ReportLayout.styled';

const sectionInfo = {
  title: 'Don\'t worry about checking in, We\'ll send you personalized reports ',
  subtitle: 'Whether you\'re in the market for a new home or a realtor managing a number of clients, just tell us what you need and we\'ll generate a personalized report based on any of your saved searches.',
};

interface ReportLayoutProps {
  createAccountService: CreateAccountService
}

const ReportLayout: FC<ReportLayoutProps> = ({ createAccountService }) => {
  const [, send] = useActor(createAccountService);

  return (
        <S.Container>

            <S.SectionInfoContainer>
                <SectionInfo {...sectionInfo} />
            </S.SectionInfoContainer>

            <S.ImageContainer>
                <S.Image src={report} />
            </S.ImageContainer>

            <S.ContentContainer>

              <UseCase {...reportSentToYourEmail}/>
              <UseCase {...excelSheet}/>
              <UseCase {...interactWithResults}/>

              <S.ContactUsContainer>
                <LandingButton text='Join Now' variant='blue' onClick={() => send({ type: 'STARTED_SIGN_UP', payload: {} }) }/>
              </S.ContactUsContainer>

            </S.ContentContainer>

        </S.Container>

  );
};

export default ReportLayout;
