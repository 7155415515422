import { filter } from 'lodash';
import {
  Routes, getQueryPredictions, getLocationPredictions, GoogleLocationPredictions,
  GoogleQueryPredictions,
} from 'beiytak_sdk';
import { getAPIUrl } from '@utils';
import { AutoCompleteOptions, PredictionType } from '@types';

/**
 * Formats the results returned from the google predictions into options
 * that can be used int he autocomplete components
 */
const createAutoCompleteOptions = (data: GoogleLocationPredictions | GoogleQueryPredictions): AutoCompleteOptions[] => {
  const { predictions } = data;
  const options: Array<AutoCompleteOptions> = [];

  if (!predictions) { return options; }

  predictions.forEach((prediction) => {
    options.push({ value: prediction.description, label: prediction.description });
  });

  return options;
};

/** Grabs predictions for either a location or place based on the users input */
// @TODO split these between location and query prediction since the payloads have changed and responses as well
const getGooglePredictions = async (
  /** The users input */
  input: string,
  /** What type of predictions should be run? Either location or place */
  type: PredictionType,
  /** Location to narrow down the results to */
  location?: string | null,
): Promise<AutoCompleteOptions[] | null> => {
  let predictions: AutoCompleteOptions[] = [];
  const url = getAPIUrl(Routes.GRAPHQL);
  const userOption: AutoCompleteOptions = { label: input, value: input };

  if (type === PredictionType.LOCATION) {
    const { data } = await getLocationPredictions({ params: input }, url);

    if (!data) { return null; }

    const googlePredictionOptions = createAutoCompleteOptions(data);
    predictions = googlePredictionOptions;
  }

  if (type === PredictionType.PLACE) {
    const { data } = await getQueryPredictions({ params: { query: input, location } }, url);

    if (!data) { return null; }

    const googlePredictionOptions = createAutoCompleteOptions(data);

    // Make sure that the users entry is the first one in the list
    predictions = [userOption, ...filter(googlePredictionOptions, (prediction) => prediction.label.toLowerCase().trim() !== userOption.label.toLowerCase().trim())];
  }

  return predictions;
};

export default getGooglePredictions;
