import { FC } from 'react';
import { CreateAccountService } from '@types';
import { useActor } from '@xstate/react';
import { FeatureHighlight, LandingButton } from '@components';
import * as S from './PricingCard.styled';

interface PricingCardProps {
  createAccountService: CreateAccountService,
    /** name of the plan */
    name: string,

    /** If you want to specify "Starting at" for example */
    pricePrefix?: string,

    price?: string,

    /** length (ex. 3 months) */
    duration?: string,

    description: string,

    /** text to display on the action button */
    actionText: string,

    /** features to highlight */
    features: string[]

    /** highlight this pricing plan to the user */
    highlight: boolean
}

const PricingCard: FC<PricingCardProps> = ({
  name, pricePrefix, price, duration, description, actionText, features, highlight, createAccountService,
}) => {
  const [, send] = useActor(createAccountService);

  const contactUsAction = actionText.toLowerCase() === 'contact us';
  const handleMailTo = () => {
    window.location.href = 'mailto:sales@findur.io?subject=Findur Inquiry';
  };

  return (
        <S.Container highlight={highlight}>
            <S.PlanInfoContainer>
                <S.NameContainer>
                    <S.Name>{name}</S.Name>
                    {
                        highlight
                          ? <S.BestValue>Best Value</S.BestValue>
                          : <></>
                    }
                    {
                        pricePrefix
                          ? <S.PricePrefix>{pricePrefix}</S.PricePrefix>
                          : <></>
                    }
                </S.NameContainer>

                {
                    price && duration
                      ? (
                        <S.PriceContainer>
                            <S.Price>{`$${price}`}</S.Price>
                            <S.Duration>{`${duration}`}</S.Duration>
                        </S.PriceContainer>
                      )
                      : <></>
                }

                <S.Description>{description}</S.Description>
            </S.PlanInfoContainer>

            {
              contactUsAction
                ? <LandingButton {...{ variant: 'orange', text: 'Contact Us' }} onClick={handleMailTo} />
                : <LandingButton {...{ variant: 'orange', text: 'Join Now' }} onClick={() => send({ type: 'STARTED_SIGN_UP', payload: {} }) } />
            }

            <S.DetailsContainer>
                <S.Divider />
                {
                    features.map((feature) => <FeatureHighlight {...{ feature }} key={feature} />)
                }
            </S.DetailsContainer>
        </S.Container>
  );
};

export default PricingCard;
