import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks';
import Lottie from 'react-lottie';
import { updatingInterScreenState, selectDisplayLoadingScreen } from '@stores';
import { Routes } from '@types';
import useStyles from './LoadingScreen.styles';
import houseAnimation from '../../assets/house_animation.json';
import caravanAnimation from '../../assets/caravan.json';
import hobbitAnimation from '../../assets/hobbit-house.json';
import apartmentAnimation from '../../assets/apartment.json';

const LoadingScreen: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const animations = [houseAnimation, apartmentAnimation, caravanAnimation, hobbitAnimation];
  const [animationIndex, setAnimationIndex] = useState(0);
  const displayScreen = useAppSelector(selectDisplayLoadingScreen);

  const onAnimationComplete = () => {
    if (animationIndex + 1 > animations.length - 1) { setAnimationIndex(0); }
    if (animationIndex + 1 <= animations.length - 1) { setAnimationIndex(animationIndex + 1); }
  };

  useEffect(() => {
    return () => {
      dispatch(updatingInterScreenState({ route: Routes.LOADING, value: false }));
    };
  }, []);

  return (
    displayScreen
      ? (
        <div className={classes.container}>

          <div className={classes.animation}>
              <Lottie
                speed={0.7}
                options={{
                  animationData: animations[animationIndex],
                  autoplay: true,
                }}
                eventListeners={[
                  {
                    eventName: 'loopComplete',
                    callback: () => onAnimationComplete(),
                  },
                ]}
                isClickToPauseDisabled
              />
              <div className={classes['text-container']}>
                Hold tight. It may take just a minute to get
              <span style={{ color: '#F55860' }}> your homes </span>

          </div>
          </div>
        </div>
      ) : <></>
  );
};

export default LoadingScreen;
