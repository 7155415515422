import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  isEqual, includes, remove, pick,
} from 'lodash';
import { ListingType } from 'beiytak_sdk';
import {
  FilterUsingHomeCriteriaPayload,
  FilterUsingUserLocationsPayload,
  HomeCriteriaKeys,
  Df,
  StepKeys,
  IndexableObject,
  SearchResultsFiltersSlice,
  UserLocationFilterData,
  MoreFilterKeys,
  FilterUsingMoreStylesPayload,
  FilterUsingMoreHomeCriteriaFiltersPayload,
  FilterValue,
  MoreHomeCriteriaKeys,
} from '@types';
import { RootState } from '@stores';
import { areFiltersApplied } from '@services';
import {
  filterOnHomeCriteria,
  filterOnStyles,
  filterOnFeatures,
  filterOnUserLocations,
} from './services';
import {
  rentPriceDefaultValue,
  priceDefaultValue,
  bedroomsDefaultValue,
  bathroomsDefaultValue,
  storiesDefaultValue,
  bathsFullDefaultValue,
  bathsHalfDefaultValue,
  sqftDefaultValue,
  garageCountDefaultValue,
  lotSqftDefaultValue,
  ageDefaultValue,
  saleFeaturesDefaultValue,
  saleStylesDefaultValue,
  rentFeaturesDefaultValue,
  rentStylesDefaultValue,
  moreHomeCriteriaFilterDefault,
} from './constants';

// Initial state for store
const initialState: SearchResultsFiltersSlice = {

  // These will be updated when the results are returned from the api
  [StepKeys.HOME_CRITERIA]: {
    [HomeCriteriaKeys.PRICE]: [...priceDefaultValue],
    [HomeCriteriaKeys.BEDROOMS]: [...bedroomsDefaultValue],
    [HomeCriteriaKeys.BATHROOMS]: [...bathroomsDefaultValue],
  },

  [StepKeys.USER_LOCATIONS]: {
    default: {},
    value: {},
    isFilterApplied: false,
  },

  [StepKeys.MORE]: {
    [MoreFilterKeys.HOME_CRITERIA]: {
      default: moreHomeCriteriaFilterDefault,
      value: moreHomeCriteriaFilterDefault,
    },
    [MoreFilterKeys.STYLES]: {
      default: saleStylesDefaultValue,
      value: [],
    },
    [MoreFilterKeys.FEATURES]: {
      default: saleFeaturesDefaultValue,
      value: [],
    },
    isFilterApplied: false,
  },

  filteredListings: [],
};

export const searchResultFiltersSlice = createSlice({
  name: 'searchResultFilters',
  initialState,
  reducers: {

    /**
      * Initializes the filter values in the state for the user locations when the results come back
      */
    setUserLocationsResultsFilter: (state, action: PayloadAction<UserLocationFilterData>) => {
      const { payload } = action;
      const userLocations = Object.keys(payload);
      const defaults: UserLocationFilterData = {};

      // Go through each user location and manually set the default to have a max of 60min
      userLocations.forEach((userLocation) => {
        defaults[userLocation] = [0, 60];
      });

      state[StepKeys.USER_LOCATIONS].default = defaults;
      state[StepKeys.USER_LOCATIONS].value = defaults;

      const data = pick(state[StepKeys.USER_LOCATIONS], ['default', 'value']);
      state[StepKeys.USER_LOCATIONS].isFilterApplied = areFiltersApplied({ data });
    },

    /**
     * Updates the filter values in the state for the home criteria
     */
    updatingHomeCriteriaResultsFilter: (state, action: PayloadAction<FilterUsingHomeCriteriaPayload>) => {
      const { homeCriteriaKey, filterValue } = action.payload;

      state[StepKeys.HOME_CRITERIA][homeCriteriaKey] = [...filterValue];
    },

    /**
      * Updates the filter values in the state for the user locations
      */
    updatingUserLocationsResultsFilter: (state, action: PayloadAction<FilterUsingUserLocationsPayload>) => {
      const { userAlias, filterValue } = action.payload;

      // update the filter with the new value
      state[StepKeys.USER_LOCATIONS].value[userAlias] = [...filterValue];

      const defaults = state[StepKeys.USER_LOCATIONS].default[userAlias];
      const defaultMin = defaults[0];
      const defaultMax = defaults[1];
      const userMax = filterValue[1];

      // update the default if the user entered a max value greater then the base default
      if (userMax > defaultMax) { state[StepKeys.USER_LOCATIONS].default[userAlias] = [defaultMin, userMax]; }

      const data = pick(state[StepKeys.USER_LOCATIONS], ['default', 'value']);

      state[StepKeys.USER_LOCATIONS].isFilterApplied = areFiltersApplied({ data });
    },

    /**
     * Updates the filter values for styles for more filters
     */
    updatingMoreResultsFiltersForHomeCriteria: (state, action: PayloadAction<FilterUsingMoreHomeCriteriaFiltersPayload>) => {
      const { filter, filterValue } = action.payload;
      state[StepKeys.MORE][MoreFilterKeys.HOME_CRITERIA].value[filter] = [...filterValue];

      const data = pick(state[StepKeys.MORE], [MoreFilterKeys.FEATURES, MoreFilterKeys.HOME_CRITERIA, MoreFilterKeys.STYLES]);
      state[StepKeys.MORE].isFilterApplied = areFiltersApplied(data);
    },

    /**
     * Updates the values for the styles
     */
    updatingMoreResultsFiltersForStyles: (state, action: PayloadAction<FilterUsingMoreStylesPayload>) => {
      const { value } = action.payload;

      const currentValues = state[StepKeys.MORE][MoreFilterKeys.STYLES].value;

      // If the value is already in the array, remove it
      const inArray = includes(currentValues, value);

      if (!inArray) { state[StepKeys.MORE][MoreFilterKeys.STYLES].value = [...currentValues, value]; }
      if (inArray) {
        state[StepKeys.MORE][MoreFilterKeys.STYLES].value = remove(currentValues, (element) => element !== value);

        const data = pick(state[StepKeys.MORE], [MoreFilterKeys.FEATURES, MoreFilterKeys.HOME_CRITERIA, MoreFilterKeys.STYLES]);
        state[StepKeys.MORE].isFilterApplied = areFiltersApplied(data);
      }
    },

    /**
     * Updates the values for the features
     */
    updatingMoreResultsFiltersForFeatures: (state, action: PayloadAction<FilterUsingMoreStylesPayload>) => {
      const { value } = action.payload;

      const currentValues = state[StepKeys.MORE][MoreFilterKeys.FEATURES].value;

      // If the value is already in the array, remove it
      const inArray = includes(currentValues, value);

      if (!inArray) { state[StepKeys.MORE][MoreFilterKeys.FEATURES].value = [...currentValues, value]; }
      if (inArray) {
        state[StepKeys.MORE][MoreFilterKeys.FEATURES].value = remove(currentValues, (element) => element !== value);

        const data = pick(state[StepKeys.MORE], [MoreFilterKeys.FEATURES, MoreFilterKeys.HOME_CRITERIA, MoreFilterKeys.STYLES]);
        state[StepKeys.MORE].isFilterApplied = areFiltersApplied(data);
      }
    },

    /**
     * Filters the listings to display based on the users filter values
     * specific to the the home criteria (price, beds etc.)
     */
    filteringListingsToDisplay: (
      state,
      action: PayloadAction<Df>,
    ) => {
      const { payload: df } = action;

      // Grab the current and default values for each filter
      const homeCriteriaFilters = state[StepKeys.HOME_CRITERIA];
      const moreHomeCriteriaFilters = state[StepKeys.MORE][MoreFilterKeys.HOME_CRITERIA].value;
      const stylesFilter = state[StepKeys.MORE][MoreFilterKeys.STYLES].value;
      const featuresFilter = state[StepKeys.MORE][MoreFilterKeys.FEATURES].value;
      const userLocationFilters = state[StepKeys.USER_LOCATIONS].value;
      const userLocationDefaultFilters = state[StepKeys.USER_LOCATIONS].default;

      // This makes it easier to loop through each filter and grab the filter value
      const homeCriteriaFilterDefaults: IndexableObject<FilterValue> = {
        [HomeCriteriaKeys.PRICE]: priceDefaultValue,
        [HomeCriteriaKeys.BATHROOMS]: bathroomsDefaultValue,
        [HomeCriteriaKeys.BEDROOMS]: bedroomsDefaultValue,
        [MoreHomeCriteriaKeys.STORIES]: storiesDefaultValue,
        [MoreHomeCriteriaKeys.BATHS_FULL]: bathsFullDefaultValue,
        [MoreHomeCriteriaKeys.BATHS_HALF]: bathsHalfDefaultValue,
        [MoreHomeCriteriaKeys.SQFT]: sqftDefaultValue,
        [MoreHomeCriteriaKeys.GARAGE_COUNT]: garageCountDefaultValue,
        [MoreHomeCriteriaKeys.LOT_SQFT]: lotSqftDefaultValue,
        [MoreHomeCriteriaKeys.AGE]: ageDefaultValue,
      };

      const homeCriteriaFilterValues: IndexableObject<FilterValue> = {
        ...homeCriteriaFilters,
        ...moreHomeCriteriaFilters,
      };

      if (df) {
        const result: string[] = [];
        const rows = Object.keys(df);

        // Filter the data based on the filter passed
        rows.forEach((row) => {
          const { listing, userLocations } = df[row];
          let evalNextFilter = false; // Keeps track of whether to continue filtering move to next listing

          // Filter on styles and features first to weed out as many listings as possible before
          // we have to loop through each of the home criteria filters
          evalNextFilter = filterOnStyles(listing, stylesFilter);

          if (evalNextFilter) { evalNextFilter = filterOnFeatures(listing, featuresFilter); }

          if (evalNextFilter) { evalNextFilter = filterOnHomeCriteria(listing, homeCriteriaFilterDefaults, homeCriteriaFilterValues); }

          if (evalNextFilter) { evalNextFilter = filterOnUserLocations(userLocations, userLocationDefaultFilters, userLocationFilters); }

          if (evalNextFilter) { result.push(row); }
        });

        // Update the listings to display based on the results
        state.filteredListings = result;
      }
    },

    /** Clears the user locations filters - used when a new search is made */
    clearUserLocationsFilters: (state) => {
      state[StepKeys.USER_LOCATIONS].default = {};
      state[StepKeys.USER_LOCATIONS].value = {};
      state[StepKeys.USER_LOCATIONS].isFilterApplied = false;
    },

    /**
     * Resets all of the filters based on the user search result being analyzed.
     * This action is handled in the middleware that will reset the home criteria filters, and user location filters
     * to only the data that is part of the user search result.
     * This is also used to filter out what saved searches are being evaluated in the favorites section of the dashboard
     */
    resetAllSearchResultFilters: (state) => {

    },

    updateDefaultsForMoreFilters: (state, action: PayloadAction<ListingType>) => {
      const { payload: type } = action;

      if (type === ListingType.Sale) {
        state[StepKeys.MORE][MoreFilterKeys.FEATURES].default = [...saleFeaturesDefaultValue];
        state[StepKeys.MORE][MoreFilterKeys.STYLES].default = [...saleStylesDefaultValue];
      }

      if (type === ListingType.Rent) {
        state[StepKeys.MORE][MoreFilterKeys.FEATURES].default = [...rentFeaturesDefaultValue];
        state[StepKeys.MORE][MoreFilterKeys.STYLES].default = [...rentStylesDefaultValue];
      }
    },

    resetMoreFilters: (state, action: PayloadAction<ListingType>) => {
      const { payload: type } = action;

      state[StepKeys.MORE][MoreFilterKeys.HOME_CRITERIA].value = moreHomeCriteriaFilterDefault;

      if (type === ListingType.Sale) {
        state[StepKeys.MORE][MoreFilterKeys.FEATURES].default = [...saleFeaturesDefaultValue];
        state[StepKeys.MORE][MoreFilterKeys.STYLES].default = [...saleStylesDefaultValue];
        state[StepKeys.MORE][MoreFilterKeys.FEATURES].value = [];
        state[StepKeys.MORE][MoreFilterKeys.STYLES].value = [];
      }

      if (type === ListingType.Rent) {
        state[StepKeys.MORE][MoreFilterKeys.FEATURES].default = [...rentFeaturesDefaultValue];
        state[StepKeys.MORE][MoreFilterKeys.STYLES].default = [...rentStylesDefaultValue];
        state[StepKeys.MORE][MoreFilterKeys.FEATURES].value = [];
        state[StepKeys.MORE][MoreFilterKeys.STYLES].value = [];
      }

      // reset whether filters are applied as well
      const data = pick(state[StepKeys.MORE], [MoreFilterKeys.FEATURES, MoreFilterKeys.HOME_CRITERIA, MoreFilterKeys.STYLES]);
      state[StepKeys.MORE].isFilterApplied = areFiltersApplied(data);
    },
  },

});

export const selectMoreFiltersFeatureDefault = (state: RootState) => state.searchResultFilters[StepKeys.MORE][MoreFilterKeys.FEATURES].default;

export const selectUserLocationsFilterDefault = (state: RootState) => state.searchResultFilters[StepKeys.USER_LOCATIONS].default;

export const selectMoreFiltersHomeCriteriaDefault = (state: RootState) => state.searchResultFilters[StepKeys.MORE][MoreFilterKeys.HOME_CRITERIA].default;

export const selectMoreFiltersStylesDefault = (state: RootState) => state.searchResultFilters[StepKeys.MORE][MoreFilterKeys.STYLES].default;

export const selectMoreFiltersFeaturesSelections = (state: RootState) => state.searchResultFilters[StepKeys.MORE][MoreFilterKeys.FEATURES].value;

export const selectMoreFiltersStylesSelections = (state: RootState) => state.searchResultFilters[StepKeys.MORE][MoreFilterKeys.STYLES].value;

export const selectAllSearchResultFilterData = (state: RootState) => state.searchResultFilters;

export const selectIfUserLocationFilterIsApplied = (state: RootState) => state.searchResultFilters[StepKeys.USER_LOCATIONS].isFilterApplied;

export const selectIfMoreFilterIsApplied = (state: RootState) => state.searchResultFilters[StepKeys.MORE].isFilterApplied;

export const {
  filteringListingsToDisplay,
  updatingHomeCriteriaResultsFilter,
  updatingUserLocationsResultsFilter,
  setUserLocationsResultsFilter,
  updatingMoreResultsFiltersForHomeCriteria,
  updatingMoreResultsFiltersForFeatures,
  updatingMoreResultsFiltersForStyles,
  clearUserLocationsFilters,
  resetAllSearchResultFilters,
  updateDefaultsForMoreFilters,
  resetMoreFilters,
} = searchResultFiltersSlice.actions;

export default searchResultFiltersSlice.reducer;
