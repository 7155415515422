import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  tint: {
    position: 'fixed',
    content: '""',
    width: '100vw',
    height: '100vh',

    backgroundColor: '#141629',
    opacity: '0.3',

    zIndex: 1,

  },

}));

export default useStyles;
