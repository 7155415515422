import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',

    // Hides the outline of the individual steps
    overflow: 'hidden',

    width: '58vw', // Based on the width of the column on the user search page
    minWidth: '650px',
    maxWidth: '1060px',

    // This scales the height of the steps component proportionally to the text size
    height: '5.06rem',
    maxHeight: '81px',

    /*
    Background is the same color as when a step is selected
    so it blends it correctly. Since the individual steps are
    curved, the background of the step container will show a little.
    */
    // background: theme.palette.neutral.main,
    background: 'hsl(0, 0%, 98%)',
    // border: `1px solid ${theme.palette.neutral.main}`,
    borderRadius: '85.5px',
    boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',

  },

  line: {
    border: 'none',
    outline: `1px solid ${theme.palette.neutral.dark}`,
    height: '50%',
  },

  lineHidden: {
    border: 'none',
    outline: 'none',
    height: '50%',
  },

  button: {

    // width: '6.25rem',
    minWidth: '6.25rem',
    maxWidth: '6.25rem',
    height: '3.125rem',
    maxHeight: '50px',

    margin: '0.75rem',

    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '25px',

    '&.notValidated': {

      backgroundColor: theme.palette.neutral.main,
      color: theme.palette.primary.main,

      '&:hover': {
        transform: 'scale(1.03)',
        backgroundColor: theme.palette.neutral.main,
      },

    },

    '&.validated': {

      backgroundColor: theme.palette.primary.main,
      color: theme.palette.neutral.light,

      '&:hover': {
        transform: 'scale(1.03)',
        backgroundColor: theme.palette.primary.main,
      },

    },

  },

}));

export default useStyles;
