import styled, { css } from 'styled-components';
import { devices } from '@styleGuide';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: calc(100vw - 10rem);
    margin-left: 5rem;

    @media (${devices.tabletAndBelow}) {

        width: calc(100vw - 4rem);
        margin-left: 2rem;
    }
`;

export const Break = styled.div`
    width: 100vw;
    height: 10vh;
`;
