import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '120px',
    height: '40px',

    borderRadius: '30px',
    border: 'none',

    background: theme.palette.neutral.main,
    color: '#202020',

    marginRight: '0.5rem',
    marginBottom: '0.4rem',

    '&.selected': {
      background: theme.palette.primary.main,
      color: theme.palette.neutral.light,
      boxShadow: 'rgba(0, 0, 0, 0.56) 0px 22px 70px 4px',
    },

  },

}));

export default useStyles;
