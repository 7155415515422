import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    overflow: 'hidden',
    width: '12rem',
    height: '2.7rem',

    border: `1px solid ${theme.palette.neutral.dark}`,
    boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',
    borderRadius: '10px',
    backgroundColor: 'white',

    '&:hover': {
      transform: 'scale(1.03)',
    },

  },

  'drop-down': {
    width: '8rem',
    marginRight: '0.5rem',

    fontSize: '0.9rem',

    '&:focus': {
      backgroundColor: 'transparent',
    },

  },

  option: {
    borderRadius: '0px',

    '&:hover': {
      background: theme.palette.neutral.main,
    },

    '&$selected': {
      color: 'white',
      background: theme.palette.primary.main,
      '&:hover': {
        background: theme.palette.primary.main,
      },
    },
  },

  // Mui bug/weird rule where you have to specify an empty selected css class
  // then use it somewhere else to color a selected option - makes no sense
  selected: {
  },
}));

// Specific to the paper component for the select component
const CustomMenuProps: Partial<MenuProps> = {
  getContentAnchorEl: null,

  anchorOrigin: {
    vertical: 0,
    horizontal: -13,
  },

  PaperProps: {
    style: {
      width: '12rem',
      marginTop: '2.75rem',

      borderRadius: '0.625rem',
      fontSize: '0.875rem',
    },
  },

};

export { useStyles, CustomMenuProps };
