import { FunctionComponent } from 'react';
import { LoginForm, CreateAccountLayout } from '@components';
import { Routes } from '@types';
import { useNavigate } from 'react-router';
import { useMachine, useSelector } from '@xstate/react';
import { CreateAccountMachine, selectStartedSignUpForAccount } from '@machines';
import useStyles from './UserLoginScreen.styles';
import logo from '../../assets/logo.png';
import background from '../../assets/home_background.png';
import overlay from '../../assets/overlay.png';

const UserLoginScreen: FunctionComponent = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [, , createAccountService] = useMachine(CreateAccountMachine);
  const startedSignUp = useSelector(createAccountService, selectStartedSignUpForAccount);

  return (
        <div className={classes.container}>
          <div className={classes.mobileOnly}>
          Findur
          is only supported on desktop while using our web app.
          We are working on a mobile version soon! Head over to a computer and come back to this page to login.

          <p style={{ color: '#FF7348' }}> Login at: https://findur.io/login</p>
          </div>
          {
            startedSignUp
              ? <CreateAccountLayout {...{ createAccountService }}/>
              : <>
                  <img src={background} alt="" className={classes.background} />;
                  <img src={overlay} alt="" className={classes.overlay} />;

                  <div className={classes.header}>
                    <img src={logo} className={classes.logo} alt="" onClick={() => navigate(Routes.LANDING)}/>
                  </div>

                  <div className={classes.login}>
                    <div className={classes.text}>
                      <div>
                        Welcome to
                        <span style={{ color: '#FF7348' }}> Findur</span>
                      </div>
                      <div className={classes.subtitle}>
                        Find your home so much faster
                      </div>
                    </div>
                    <div className={classes.form}>
                    <LoginForm {...{ createAccountService }}/>
                    </div>
                  </div>
               </>
            }
        </div>
  );
};

export default UserLoginScreen;
