import mapboxgl from 'mapbox-gl';
import bbox from '@turf/bbox';
import { lineString } from '@turf/helpers';
import { LngLat, BoundingBoxResults } from '../types';

/**
  * Returns the bounding box for a list of coordinates.
  * The result format is specific for mapbox so the results can be directly
  * passed to map.fitBounds
  */
const getBoundingBox = (boundingBoxData: LngLat[]): BoundingBoxResults => {
  // returns are -> bbox extent in minX, minY, maxX, maxY order
  const boundingBoxCoordinates = bbox(lineString(boundingBoxData));

  const boundingBox: mapboxgl.LngLatBoundsLike = [
    boundingBoxCoordinates[0], boundingBoxCoordinates[1], // southwestern corner
    boundingBoxCoordinates[2], boundingBoxCoordinates[3], // northeastern corner
  ];

  // The center that should be focused during map render
  const center: [number, number] = [
    (boundingBoxCoordinates[0] + boundingBoxCoordinates[2]) / 2,
    (boundingBoxCoordinates[1] + boundingBoxCoordinates[3]) / 2,
  ];

  const results = {
    boundingBox,
    center,
  };

  return results;
};

export default getBoundingBox;
