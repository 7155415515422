import { $theme } from '@styleGuide';
import styled from 'styled-components';

export const Container = styled.div`
    position: fixed;
    top: 15vh;
    right: 25vw;
    z-index: 10;


    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 40rem;
    height: 42rem;

    background-color: ${$theme('palette.neutral.light')};
    border-radius: ${$theme('border.radius.15')};
    border: ${$theme('palette.neutral.dark')};
    box-shadow: 0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%);
`;

export const Close = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 0rem;
    right: 0rem;

    margin-top: 0.45rem;
    margin-right: 0.45rem;

    border-radius: 50%;
    width: 2rem;
    height: 2rem;

    background-color: #FFFFFF;
    border: none;

    font-size: 1.2rem;
    color: #606060;

    cursor: pointer;

`;

export const HeaderContentContainer = styled.div`
    width: 100%;
    height: 30%;
`;

export const PurchaseOptionsContainer = styled.div`
    width: 100%;
    height: 70%;
`;
