import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks';
import TextField from '@material-ui/core/TextField';
import { updatingSearchText, selectSearchText } from '@stores';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from './SearchFilter.styles';

/** This component has the filters that can be used to filter down the results */
const SearchFilter: FunctionComponent = () => {
  const classes = useStyles();
  const searchText = useAppSelector(selectSearchText);
  const dispatch = useAppDispatch();

  return (
      <div className={classes.container}>
        <SearchIcon classes={{ root: classes['search-icon'] }}/>
        <TextField
            InputLabelProps={{
              classes: {
                root: classes.input,
              },
            }}
            InputProps={{
              classes: {
                input: classes.input,
              },
              disableUnderline: true,
            }}
            onChange={(event) => dispatch(updatingSearchText(event.target.value))}
            placeholder='Search'
            defaultValue={searchText}
        />
    </div>
  );
};

export default SearchFilter;
