import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { WaitListUser, WaitListPayload } from '../WaitList.machine';

// const waitListUser: WaitListUser = async (payload: WaitListPayload): Promise<string> => {
//   console.table(payload);
//   return new Promise((res, rej) => {
//     setTimeout(() => {
//       console.log('promise is done');
//       res('data is here!');
//     }, 3000);
//   });
// };

export interface Waiter {
  'amount_referred': number,
  'created_at': string,
  'current_priority': number,
  'email': string,
  'id':number,
  'priority': number,
  'referral_bool': boolean,
  'referral_link': string,
  'referral_token': string,
  'referred_by_waiter_token': boolean | null,
  'registered_email': string,
  'removed_date':string | null,
  'removed_from_waitlist':boolean,
  'removed_priority':boolean | null,
  'total_referrals': number,
  'user_id': string,
  'uuid': string,
  'verified':boolean,
  'waitlist_api_key': string,
  'waitlist_id':number
}

const waitListUser: WaitListUser = async (payload: WaitListPayload) => {
  const { email, firstName: first_name, lastName: last_name } = payload;

  const req: AxiosRequestConfig = {
    method: 'post',
    url: 'https://api.getwaitlist.com/api/v1/waiter',
    data: {
      api_key: '58DRGU',
      first_name,
      last_name,
      email,
    },
  };

  const waiter = await axios.request(req)
    .then((res: AxiosResponse<Waiter>) => {
      return res.data;
    });

  return waiter;
};

export default waitListUser;
