import { Plans } from 'beiytak_sdk';

// These plans are used for the landing page. Real-time offers are generated during the search process
export const freeTrialPlan = {
  id: Plans.FREE_TRIAL,
  name: 'Free Trial',
  price: '0',
  duration: 'For 7 Days',
  description: 'Any location',
  actionText: 'Join now',
  features: [
    'See how Findur can save you weeks of time',
    'Access to all premium features',
    'Upgrade anytime',
    'Unlimited saved searches',
  ],
  highlight: false,
};

export const locationPlan = {
  id: 'LOCATION',
  name: 'Per Location',
  pricePrefix: 'Starting at',
  price: '9.99',
  duration: '/ Month Per Location',
  description: 'Price is adjusted based on size of the location.',
  actionText: 'Join now',
  features: [
    'Up to 4 places of interest (upgrades available)',
    'Unlimited saved searches',
    'Always have access to your favorites',
    'Cancel anytime',
  ],
  highlight: false,
};

export const neighborhoodPlan = {
  id: Plans.BASE_MONTHLY,
  name: 'Per Zip Code',
  price: '24.99',
  duration: 'For 2 Months',
  description: 'Per neighborhood or zip code',
  actionText: 'Join now',
  features: [
    'Unlimited places of interest',
    'Unlimited saved searches',
    'Always have access to your favorites',
    'Cancel anytime',
  ],
  highlight: false,
};

export const cityPlan = {
  id: Plans.BUSINESS,
  name: 'Per City',
  price: '89.99',
  duration: 'For 2 Months',
  description: '',
  actionText: 'Join now',
  features: [
    'Unlimited places of interest',
    'Unlimited saved searches',
    'Always have access to your favorites',
    'Cancel anytime',
  ],
  highlight: false,
};

export const unlimitedPlan = {
  id: Plans.BASE_MONTHLY,
  name: 'Unlimited',
  price: '249.99',
  duration: '/Month',
  description: 'Best value for Real Estate Professionals',
  actionText: 'Join now',
  features: [
    'Unlimited locations (cities/zip codes)',
    'Unlimited places of interest',
    'Unlimited saved searches',
    'Always have access to your favorites',
    'Cancel anytime',
  ],
  highlight: false,
};

export const businessPlan = {
  id: Plans.BUSINESS,
  name: 'Business',
  description: 'Contact us for pricing',
  actionText: 'Contact us',
  features: [
    'Connect your private listing database',
    'Save hours of research for you and your team',
    'Unlimited locations (cities/zip codes)',
    'Unlimited places of interest',
    'Unlimited saved searches',
  ],
  highlight: false,
};
