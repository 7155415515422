import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    width: '42vw',
    minWidth: '740px',
    maxWidth: '800px',
    // height: '25.625rem',
    height: '45vh',
    minHeight: '350px',
    maxHeight: '410px',

    border: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '30px',

    boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',
    background: theme.palette.neutral.light,

  },

  divider: {
    width: '100%',
    borderBottom: `1px solid ${theme.palette.neutral.dark}`,
  },

  button: {

    alignSelf: 'flex-end',

    margin: '0.75rem',

    minHeight: '3.125rem',
    maxHeight: '50px',

    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },

}));

export default useStyles;
