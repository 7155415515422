import { FunctionComponent, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from '@hooks';
import { selectedFilterChipIsChanging, selectFilterChipSelected, selectTypeInput } from '@stores';
import { FilterValue, Icon, StepKeys } from '@types';
import { createFilterChipLabel } from '@utils';
import { areFiltersApplied } from '@services';
import FilterIsApplied from './FilterIsApplied';
import useStyles from './FilterChip.styles';

interface FilterChipProps {
    /** The ID of the chip. This id will be dispatched when its selected */
    id: string,
    /** Icon to use for the chip */
    icon?: () => Icon,
    /** State reference to update the label of the chip as the filter data changes */
    stateRef?: () => FilterValue,
    /** Allows for the flexibility to set default values form the store if need be */
    defaultValues?: () => FilterValue,
    /* hook to access the state on whether the filter is applied */
    isFilterApplied?: () => boolean,
}

/**
 * Displays information regarding the users search and allows the user
 * to filter down data related to the chip (ex. filter bedroom count etc.)
 */
const FilterChip: FunctionComponent<FilterChipProps> = ({
  id, icon, stateRef, defaultValues, isFilterApplied,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [clicked, setClicked] = useState(false);
  const [data, setData] = useState<FilterValue | null>(null);
  const filterChipSelected = useAppSelector(selectFilterChipSelected);
  const type = useAppSelector(selectTypeInput);
  const filterValue = stateRef ? stateRef() : null;

  const currentChipSelected = filterChipSelected === id;
  const svg = icon ? icon() : '';

  const getIfFilterIsApplied = (filterValue: FilterValue | null, defaultValues?: () => FilterValue, isFilterApplied?: () => boolean): boolean => {
    // If he hook is provided, use that
    if (isFilterApplied) {
      return isFilterApplied();
    }

    // If not then calculate it if both defaults and values are provided
    if (defaultValues && filterValue) {
      const defaultValue = defaultValues();
      const filterData = { default: defaultValue, value: filterValue };

      const result = areFiltersApplied({ filterData });

      return result;
    }

    return false;
  };

  const filterApplied = getIfFilterIsApplied(filterValue, defaultValues, isFilterApplied);

  // When the chip is clicked, dispatch out an action of which one was selected
  const handleOnClick = () => {
    const isClicked = !clicked;
    const filterChipID = isClicked ? id : null;
    setClicked(isClicked);
    dispatch(selectedFilterChipIsChanging({ filterChipID, dispatchedFrom: location.pathname }));
  };

  const updateChipLabel = () => {
    setData(filterValue);
  };

  /**  On mount, use the values in state to create the label */
  useEffect(() => { updateChipLabel(); }, []);

  /**
   * Only update the label when the user completes their filter selection and
   * either close the filter or move to another one
   */
  useEffect(() => {
    if (!currentChipSelected) { updateChipLabel(); }
  }, [currentChipSelected, updateChipLabel]);

  return (
    <>
        <button
          className={clsx({
            [classes.button]: [true],
            highlight: id === StepKeys.USER_LOCATIONS,
            selected: currentChipSelected,
          })}
          onClick={handleOnClick}
        >
          {svg
            ? (
              <div className={classes.icon}>
                <img src={svg} alt="" />
              </div>
            )
            : <></>}
          {createFilterChipLabel(id, type, data)}
        </button>
        {
          filterApplied
            ? <FilterIsApplied />
            : <></>
        }

       </>
  );
};

export default FilterChip;
