import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@stores';
import { ListingType } from 'beiytak_sdk';
import { TypeInputSlice } from './types';

const initialState: TypeInputSlice = {
  value: ListingType.Sale,
};

export const typeInputSlice = createSlice({
  name: 'typeInput',
  initialState,
  reducers: {

    /**  Updates the input type for the user search */
    updatingTypeInput: (state, action: PayloadAction<ListingType>) => {
      const { payload: type } = action;

      state.value = type;
    },

    /** Resets the value of the type input incase of a new search */
    resetTypeInput: (state) => {
      state.value = ListingType.Sale;
    },
  },
});

export const selectTypeInput = (state: RootState) => state.typeInput.value;

export const {
  updatingTypeInput,
  resetTypeInput,
} = typeInputSlice.actions;

export default typeInputSlice.reducer;
