import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    position: 'relative',

    maxWidth: '16rem',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    color: '#202020',
    fontSize: '0.9rem',

  },

  button: {

    // margin: '0rem 1.875rem 0rem 0rem',
    marginLeft: '2rem',

    minWidth: '9.8rem',
    maxWidth: '9.8rem',

    // border: 'none',
    // border: '1px solid black',
    // borderRadius: '25px',
    // backgroundColor: 'transparent',
    borderRadius: '25px',
    border: `1px solid ${theme.palette.neutral.dark}`,
    backgroundColor: theme.palette.neutral.light,
    boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',

    // color: theme.palette.primary.main,

    cursor: 'pointer',

    '&:hover': {
      backgroundColor: theme.palette.neutral.light,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      transform: 'scale(1)',
    },

    '&.clicked': {
      backgroundColor: theme.palette.neutral.light,
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },

    // '&:hover': {
    //   transform: 'scale(1.05)',
    //   backgroundColor: 'transparent',
    // },

    // '&.clicked': {
    //   backgroundColor: theme.palette.primary.main,
    //   color: theme.palette.neutral.light,
    // },

  },

  label: {
    marginRight: '0.4rem',
  },

  icon: {
    // color: '#202020',
    // color: theme.palette.primary.main,
    margin: '0rem 0.40625rem 0rem 0.40625rem',

    '&.clicked': {
      color: theme.palette.primary.main,
    },
  },

  ripple: {
    borderRadius: '25px',
    width: '9.6rem',
  },

  'search-name': {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },

  'search-input-container': {
    position: 'absolute',
    top: '5rem',
  },
}));

export default useStyles;
