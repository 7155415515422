import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  circle: {

    display: 'flex',
    flex: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flexShrink: 0,

    // Based on the text size of the steps component
    margin: '0rem 0.7rem 0rem 1rem ',

    border: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '50%',

    // The size of the circle adjusts accordingly based on the text size
    width: '2.5rem',
    minWidth: '2.5rem',
    maxWidth: '40px',

    height: '2.5rem',
    minHeight: '2.5rem',
    maxHeight: '40px',

    ...theme.typography.h6,

  },

  check: {
    color: theme.palette.success.main,
  },

  complete: {
    border: `0.09375rem solid ${theme.palette.success.main}`,
  },

}));

export default useStyles;
