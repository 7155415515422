import { FunctionComponent, useState } from 'react';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '@hooks';
import { includes, startCase } from 'lodash';
import { updatingMoreResultsFiltersForStyles, selectMoreFiltersStylesSelections } from '@stores';
import { getStylesIcon } from '@utils';
import useStyles from './Style.styles';

interface StyleProps {
  style: string,
  action: typeof updatingMoreResultsFiltersForStyles,
}

/**
 * Chip that represents a filter option. Clicking on the chip
 */
const Style: FunctionComponent<StyleProps> = ({ style, action }) => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const selections = useAppSelector(selectMoreFiltersStylesSelections);
  const selected = includes(selections, style);
  const dispatch = useAppDispatch();
  const icon = getStylesIcon(style);
  const label = startCase(style.replaceAll('_', ' '));

  return (
       <button
        key={label}
        onClick={() => dispatch(action({ value: style }))}
        className={clsx({
          [classes['style-option']]: true,
          selected,
        })}
      >
        <div className={classes['icon-container']}>
          <img
            src={icon}
            className={clsx({
              [classes.icon]: true,
            })}
            color={selected ? '#FFFFFF' : 'black'}
            alt=''
          />
        </div>
        <span className={classes.label} aria-label={label}>{label}</span>
      </button >
  );
};

export default Style;
