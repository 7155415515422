import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Routes, updateSavedSearchStatus as updateSavedSearchStatusSdk, UpdateSavedSearchStatus,
  UpdateSavedSearch,
} from 'beiytak_sdk';
import { getAPIUrl } from '@utils';

const UpdatedSavedSearchStatusAction = 'savedSearches/updatedSavedSearchStatus';

/** Updates the status of an existing saved search */
const updatedSavedSearchStatus = createAsyncThunk(
  UpdatedSavedSearchStatusAction,
  async (params: UpdateSavedSearchStatus): Promise<string> => {
    const url = getAPIUrl(Routes.GRAPHQL);

    const searchId = await updateSavedSearchStatusSdk({ params }, url).then((res) => {
      const { data } = res;

      if (data) { return data; }

      return Promise.reject();
    });

    return searchId;
  },
);

export { updatedSavedSearchStatus, UpdatedSavedSearchStatusAction };
