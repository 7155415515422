import { FunctionComponent } from 'react';
import useStyles from './PremiumFilter.styles';

/** Holds the commute time filters for the user locations from the search */
const PremiumFilter: FunctionComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <span>Coming soon! </span>
      <br></br>
      <span>You&apos;ll be able to filter listings based on school ratings, environmental factors, and more!</span>
    </div>
  );
};

export default PremiumFilter;
