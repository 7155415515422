import { createSelector } from 'reselect';
import { selectUserSearchResults, selectSearchResultSessionData } from '../slices/searchResultSession';
import { selectListingSelectedFromSavedSearch, selectedSavedSearches, selectSavedSearchIdForListingSelected } from '../slices/savedSearches';
import { selectFavoritesData } from '../slices/searchResultFavorites';
import { selectSavedSearchesToDisplay, selectSavedSearchesSelectedInFavorites } from '../slices/savedSearchesFilters';
import { selectLocationInputValidation } from '../slices/locationInput';
import { selectHomeCriteriaInputValidation } from '../slices/homeCriteriaInput';
import { selectedUserLocationsInputValidation } from '../slices/useLocationsInput';
import { selectUserSearchResultData } from '../slices/userSearchResult';

/** The data for the listing selected from the saved search */
const selectDataForListingSelectedFromSavedSearch = createSelector(
  [
    selectListingSelectedFromSavedSearch,
    selectUserSearchResults,
    selectedSavedSearches,
    selectSavedSearchIdForListingSelected,
  ],
  (listing, userSearchResults, savedSearches, searchId) => {
    if (userSearchResults && listing && savedSearches && searchId) {
      const { input: { type } } = savedSearches[searchId];
      const listingDetails = userSearchResults[listing];
      const { source } = listingDetails.listing;

      // add type to the source so the price label is formatted correctly
      const data = {
        listing: {
          ...listingDetails.listing,
          source: {
            ...source,
            type,
          },
        },
        userLocations: {
          ...listingDetails.userLocations,
        },
      };

      return data;
    }

    return null;
  },
);

/** ALl the required data to populate the map and provides its interactions */
const selectMapData = createSelector(
  [
    selectSearchResultSessionData,
    selectFavoritesData,
  ],
  (searchResultSessionData, favoritesData) => {
    return {
      ...searchResultSessionData,
      ...favoritesData,
      listingsToDisplayList: searchResultSessionData.listingsToDisplay,
    };
  },
);

/** Data to populate the saved searches filters */
const selectSavedSearchesFilterData = createSelector(
  [
    selectedSavedSearches,
    selectSavedSearchesToDisplay,
    selectSavedSearchesSelectedInFavorites,
  ],
  (savedSearches, savedSearchesToDisplay, savedSearchesSelectedInFavorites) => {
    return {
      savedSearches,
      savedSearchesToDisplay,
      savedSearchesSelectedInFavorites,
    };
  },
);

/** validation for the steps required to run a search */
const selectSearchStepValidation = createSelector(
  [
    selectLocationInputValidation,
    selectHomeCriteriaInputValidation,
    selectedUserLocationsInputValidation,
  ],
  (locationStepValidation, homeCriteriaStepValidation, userLocationsStepValidation) => {
    return {
      locationStepValidation,
      homeCriteriaStepValidation,
      userLocationsStepValidation,
    };
  },
);

/** Whether there is data returned from a user search */
const selectIfUserSearchResultsAreAvailable = createSelector(
  [
    selectUserSearchResultData,
  ],
  (userSearchResult) => {
    return userSearchResult !== null;
  },
);

export {
  selectDataForListingSelectedFromSavedSearch,
  selectMapData,
  selectSavedSearchesFilterData,
  selectSearchStepValidation,
  selectIfUserSearchResultsAreAvailable,
};
