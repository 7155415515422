import styled from 'styled-components';

export const Container = styled.div`

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    margin: 0rem;

    width: 96%;
    height: 3.75rem;
    max-height: 60px;

    border: solid 1px #E3E3E3;
    border-radius: 8px;
`;

export const Text = styled.h5`
    margin-left: 1.5rem;

    font-size: 1rem;
    font-weight: 600;
    line-height: 1.375rem;
    color:  #606060;
`;

export const Icon = styled.img`
    margin-left: 1rem;

    width: 2rem;
`;
