import { FunctionComponent } from 'react';
import { updatingMoreResultsFiltersForStyles, selectMoreFiltersStylesDefault } from '@stores';
import { useAppSelector } from '@hooks';
import Style from './Style';
import useStyles from './ListingStyles.styles';

const ListingFeatures: FunctionComponent = () => {
  const classes = useStyles();
  const styles = useAppSelector(selectMoreFiltersStylesDefault);
  const action = updatingMoreResultsFiltersForStyles;

  return (

    <div className={classes.container}>
        <span className={classes.title}>Styles</span>
        <div className={classes.options}>
        {styles.map((style) => {
          return (
            <div className={classes.option} key={style}>
            <Style {...{ style, action }} key={style} />
            </div>
          );
        })}
        </div>
    </div>
  );
};

export default ListingFeatures;
