import React, { FunctionComponent, useState } from 'react';
import { SavedSearch as SavedSearchType } from 'beiytak_sdk';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { HomeCriteriaKeys } from '@types';
import SearchInput from './SearchInput';
import SearchDataTable from './SearchDataTable';
import Actions from './Actions';
import useStyles from './SavedSearch.styles';

interface SavedSearchProps {
  savedSearch: SavedSearchType,
}

const SavedSearch: FunctionComponent<SavedSearchProps> = ({ savedSearch }) => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const {
    searchId, searchName, input, status, favorites,
  } = savedSearch;

  const {
    type, location, homeCriteria, userLocations,
  } = input;
  const { price, bedrooms, bathrooms } = homeCriteria;

  const userLocationCount = userLocations.length;

  const handleOnClick = () => {
    setClicked(!clicked);
  };

  return (
    <div className={classes.container}>

        <button
        className={classes['search-data-container']}
        onClick={handleOnClick}
        >
            <div className={classes['name-and-location']}>
                <span className={classes.name} aria-label='name'>{searchName}</span>
                <span className={classes.location} aria-label='location'>{location}</span>
            </div>

            <div className={classes['search-inputs']}>

                <SearchInput {...{ type, homeCriteriaKey: HomeCriteriaKeys.PRICE, value: price }} />

                <div className={classes.line}/>

                <SearchInput {...{ homeCriteriaKey: HomeCriteriaKeys.BEDROOMS, value: bedrooms }} />

                <div className={classes.line}/>

                <SearchInput {...{ homeCriteriaKey: HomeCriteriaKeys.BATHROOMS, value: bathrooms }} />

                <div className={classes.line}/>

                <span className={classes['user-locations']}>
                    {`Locations (${userLocationCount})`}
                </span>

                <div className={classes.line}/>

            </div>

            <div className={classes.actions}>
              <Actions {...{ searchId, currentStatus: status }}/>
              <div className={classes.expand}>
                  {
                  clicked
                    ? <ExpandLessIcon />
                    : <ExpandMoreIcon />
                  }
              </div>

            </div>

        </button>

        <div className={classes['search-table-container']}>
          {
          clicked
            ? <SearchDataTable {...{ savedSearch, listingDetails: favorites }}/>
            : <></>
          }
        </div>
    </div>
  );
};

export default React.memo(SavedSearch);
