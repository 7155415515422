import config from '@config';
import { setupMockApi, SetupServerParams } from 'beiytak_sdk';
import { getAPIUrl } from '@utils';

const { NODE_ENV } = config;

const startMockApi = (serverParams: SetupServerParams) => {
  if (NODE_ENV && NODE_ENV === 'dev') {
    console.log('starting up mock server');
    const api = setupMockApi<'worker'>('worker', serverParams);

    api.start();
    console.log('mock server started');
  }
};

export default startMockApi;
