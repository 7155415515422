import { FunctionComponent, useEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks';
import { SavedSearchesFilters, SavedSearch } from '@components';
import { sortBy, pick, every } from 'lodash';
import { updatingSavedSearchesSelectedInFavorites, selectSavedSearchesFilterData } from '@stores';
import Checkbox from '@material-ui/core/Checkbox';
import { theme } from '@constants';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import useStyles from './SavedSearchesListFilter.styles';

/**
 * Allows the user to filter the listings to display based on a specified search(es).
 * This is helpful so that a realtor can view specific clients favorites and searches
 * @TODO I'm sure there is a cleaner way to handle the below but... off to other things
 */
const SavedSearchesListFilter: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { savedSearches, savedSearchesToDisplay, savedSearchesSelectedInFavorites } = useAppSelector(selectSavedSearchesFilterData);

  const [selectedSearches, setSelectedSearches] = useState<string[]>(savedSearchesSelectedInFavorites);
  const allSearchesInViewAreSelected = selectedSearches.length === savedSearchesToDisplay.length;
  const [allOptionSelected, setAllOptionSelected] = useState(allSearchesInViewAreSelected);

  // Sorts the searches in alphabetical order
  const sortSearches = (): string[] => {
    if (savedSearches) {
      const selectedSearches = pick(savedSearches, savedSearchesToDisplay);

      const sortedSearches = sortBy(selectedSearches, (search) => search.searchName);

      const sortedSearchIDs = sortedSearches.map((search) => search.searchId);

      return sortedSearchIDs;
    }

    return [];
  };

  const handelOnClickForSearch = (searchId: string) => {
    const alreadySelected = selectedSearches.indexOf(searchId) !== -1;
    let updatedSelectedSearches: string[] = [];

    // If its already selected then remove it
    if (alreadySelected) {
      updatedSelectedSearches = selectedSearches.filter((selectedSearch) => selectedSearch !== searchId);
      setSelectedSearches(updatedSelectedSearches);
    }

    // If its not included, then add it to the list
    if (!alreadySelected) {
      updatedSelectedSearches = [...selectedSearches, searchId];
      setSelectedSearches(updatedSelectedSearches);
    }
  };

  const handleOnClickForAllOption = () => {
    const isAllOptionSelected = !allOptionSelected;
    setAllOptionSelected(isAllOptionSelected);

    // If the all option is selected, add everything in view to the selected searches array
    if (isAllOptionSelected) {
      setSelectedSearches([...selectedSearches, ...savedSearchesToDisplay]);
    }

    // If the all option is deselected, remove all the searches in view from the selected searches array
    if (!isAllOptionSelected) {
      const updatedSelectedSearches = selectedSearches.filter((search) => savedSearchesToDisplay.indexOf(search) === -1);
      setSelectedSearches(updatedSelectedSearches);
    }
  };

  // Updates the all selection button based on if all the searches in view are now selected
  useEffect(() => {
    const allSearchesInViewAreSelected = every(savedSearchesToDisplay, (search) => selectedSearches.indexOf(search) !== -1) && savedSearchesToDisplay.length > 0;

    setAllOptionSelected(allSearchesInViewAreSelected);
  }, [savedSearchesToDisplay, selectedSearches, setAllOptionSelected]);

  // When the user is done selecting their searches and closes out of the filter
  // dispatch an action to update the view with the listings that correspond to the selected searches
  useEffect(() => {
    return () => {
      dispatch(updatingSavedSearchesSelectedInFavorites(selectedSearches));
    };
  }, [selectedSearches]);

  return (
    <div className={classes.container}>
      <div className={classes['saved-search-filters-container']}>
        <SavedSearchesFilters />
      </div>

      <div className={classes['saved-searches-list-container']} >
        <div className={classes['selected-search-count']}>
          <Checkbox
            id='select-deselect-all'
            disableRipple
            classes={{
              root: classes['all-checkbox-root'],
            }}
            checked={allOptionSelected}
            onClick={() => handleOnClickForAllOption()}
            checkedIcon={<CheckBoxIcon style={{ fill: theme.palette.primary.main }}/>}
            />
          <span>{selectedSearches.length} Searches Selected</span>

        </div>
        {
          savedSearches
            ? sortSearches().map((searchId) => {
              const savedSearch = savedSearches[searchId];
              const { searchName } = savedSearch;

              return (
                <li
                className={classes['search-item']}
                key={searchId}
                >
                  <Checkbox
                    id={searchId}
                    disableRipple
                    classes={{
                      root: classes['search-checkbox-root'],
                    }}
                    checked={selectedSearches.indexOf(searchId) !== -1}
                    onClick={() => handelOnClickForSearch(searchId)}
                    checkedIcon={<CheckBoxIcon style={{ fill: theme.palette.primary.main }}/>}
                    name={searchName}
                  />
                  <SavedSearch {...{ savedSearch }}/>
                </li>
              );
            })
            : <></>
        }
      </div>
  </div>
  );
};

export default SavedSearchesListFilter;
