import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '@hooks';
import { startCase, includes } from 'lodash';
import { updatingMoreResultsFiltersForFeatures, selectMoreFiltersFeaturesSelections } from '@stores';
import useStyles from './Feature.styles';

interface FeatureProps {
  feature: string,
  action: typeof updatingMoreResultsFiltersForFeatures,
}

/**
 * Chip that represents a filter option. Clicking on the chip
 */
const Feature: FunctionComponent<FeatureProps> = ({ feature, action }) => {
  const classes = useStyles();
  const selections = useAppSelector(selectMoreFiltersFeaturesSelections);
  const selected = includes(selections, feature);
  const dispatch = useAppDispatch();
  const label = startCase(feature.replaceAll('_', ' '));

  const handleOnClick = () => {
    const payload = { value: feature };
    dispatch(action(payload));
  };

  return (
       <button
        key={label}
        onClick={handleOnClick}
        className={clsx({
          [classes.chip]: true,
          selected,
        })}
        aria-label={label}
      >
        {label}
      </button >
  );
};

export default Feature;
