import {
  ListingType,
  UserSearchInput,
} from 'beiytak_sdk';
import {
  UserSearch,
  StepKeys,
  HomeCriteriaKeys,
  FilterValue,
  Maybe,
  HomeCriteriaFilters,
} from '@types';
import {
  priceDefaultValue,
  rentPriceDefaultValue,
  bedroomsDefaultValue,
  bathroomsDefaultValue,
} from '@constants';

/**
 * Formats the home criteria input.
 * If the max of the input is equal to the max of the default value,
 * replace it with null so we get all listings above that range
 */
const formatHomeCriteriaInput = (params: {type: ListingType, homeCriteriaKey: HomeCriteriaKeys, input: FilterValue}) => {
  const { type, homeCriteriaKey, input } = params;
  const [minInput, maxInput] = input;
  let defaultFilterValue: FilterValue = [];

  if (homeCriteriaKey === HomeCriteriaKeys.PRICE) {
    defaultFilterValue = type === ListingType.Sale ? priceDefaultValue : rentPriceDefaultValue;
  }

  if (homeCriteriaKey === HomeCriteriaKeys.BEDROOMS) { defaultFilterValue = bedroomsDefaultValue; }
  if (homeCriteriaKey === HomeCriteriaKeys.BATHROOMS) { defaultFilterValue = bathroomsDefaultValue; }

  const [minDefault, maxDefault] = defaultFilterValue;

  const result = [minInput, maxInput === maxDefault ? null : maxInput];

  return result;
};

/** Format the user search input to make a request to get the results */
const formatUserSearchInput = (data: UserSearch): UserSearchInput | undefined => { // eslint-disable-line
  const type = data[StepKeys.TYPE];
  const location = data[StepKeys.LOCATION]?.toString();
  const homeCriteria = data[StepKeys.HOME_CRITERIA];
  const userLocations = data[StepKeys.USER_LOCATIONS];

  if (location && homeCriteria && userLocations) {
    const result = {
      type,
      location,
      homeCriteria: {
        price: formatHomeCriteriaInput({ type, homeCriteriaKey: HomeCriteriaKeys.PRICE, input: homeCriteria[HomeCriteriaKeys.PRICE] }),
        bathrooms: formatHomeCriteriaInput({ type, homeCriteriaKey: HomeCriteriaKeys.BATHROOMS, input: homeCriteria[HomeCriteriaKeys.BATHROOMS] }),
        bedrooms: formatHomeCriteriaInput({ type, homeCriteriaKey: HomeCriteriaKeys.BEDROOMS, input: homeCriteria[HomeCriteriaKeys.BEDROOMS] }),
      },
      userLocations,
    };

    return result;
  }
};

/** Formats the home criteria input from the backend to fit the required format */
const homeCriteriaInputFactory = (input: Maybe<number>[], defaultFilterValue: FilterValue): FilterValue => {
  const min = input[0] || defaultFilterValue[0];
  const max = input[1] || defaultFilterValue[1];
  const filterValue = [min, max];

  return filterValue;
};

/** Restructures the user search input object to user search */
const userSearchFactory = (userSearchInput: UserSearchInput): UserSearch => {
  const {
    location, homeCriteria, userLocations, type,
  } = userSearchInput;
  const { price, bedrooms, bathrooms } = homeCriteria;

  const userSearch: UserSearch = {
    [StepKeys.TYPE]: type,
    [StepKeys.LOCATION]: location,
    [StepKeys.HOME_CRITERIA]: {
      [HomeCriteriaKeys.PRICE]: homeCriteriaInputFactory(price, priceDefaultValue),
      [HomeCriteriaKeys.BEDROOMS]: homeCriteriaInputFactory(bedrooms, bedroomsDefaultValue),
      [HomeCriteriaKeys.BATHROOMS]: homeCriteriaInputFactory(bathrooms, bathroomsDefaultValue),
    },
    [StepKeys.USER_LOCATIONS]: userLocations,
  };

  return userSearch;
};

export {
  formatHomeCriteriaInput,
  homeCriteriaInputFactory,
  formatUserSearchInput,
  userSearchFactory,
};
