import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
`;

export const AnimationContainer = styled.div`
    width: 100%;
    max-width: 300px;
`;
