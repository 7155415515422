import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    marginTop: '0.9rem',
    marginRight: '1.7rem',
    marginBottom: '1.5625rem',
    marginLeft: '1.1rem',

    '&.all-options': {

      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',

    },

    '&.favorites-only': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
    },

    '&.sort-only': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
  },

}));

export default useStyles;
