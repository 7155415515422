import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Routes, getSavedSearchResult, SavedSearch,
} from 'beiytak_sdk';
import { getAPIUrl } from '@utils';
import {
  GetSavedSearchResultsParams,
  GetUserSearchResults,
} from './types';

const GetSavedSearchResultsAction = 'searchSession/getSavedSearchResults';

const getSavedSearchResults = createAsyncThunk(
  GetSavedSearchResultsAction,
  async (params: GetSavedSearchResultsParams): Promise<GetUserSearchResults> => {
    const { searchId, navigate } = params;
    const url = getAPIUrl(Routes.GRAPHQL);

    const { data } = await getSavedSearchResult({ searchId }, url);

    if (!data) { return { navigate }; }

    if (data && 'error' in data) { return { navigate }; }

    if (data && !('error' in data)) {
      const {
        searchName, status, input, favorites, data: listings, planDetails,
      } = data;

      const savedSearch: SavedSearch = {
        searchId,
        searchName,
        status,
        input,
        favorites,
      };

      const result: GetUserSearchResults = {
        savedSearch,
        userSearchResults: { data: listings, planDetails },
        navigate,
      };

      return result;
    }

    return { navigate };
  },

);

export { GetSavedSearchResultsAction, getSavedSearchResults };
