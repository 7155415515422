import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    overflow: 'hidden',

    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '100%',

    color: 'white',

  },

  // Images takes 100% of the space
  image: {
    objectFit: 'fill',
    minWidth: '100%',
    maxWidth: '100%',
    minHeight: '100%',
    maxHeight: '184.8px', // current max of the images container
  },

  'arrow-button': {
    position: 'absolute',
    zIndex: 1,

    minWidth: '1.5rem',
    maxWidth: '1.5rem',
    minHeight: '1.5rem',
    maxHeight: '1.5rem',

    borderRadius: '50%',
    backgroundColor: '#FFFFFFE6',

    '&.right': {
      marginRight: '0.45rem',
      marginLeft: 'calc(100% - 1.5rem - 0.4rem)',
    },

    '&.left': {

      marginRight: 'calc(100% - 1.5rem - 0.4rem)',
      marginLeft: '0.45rem',
    },

    // Card view, the image does not take up the full height
    // The details are under the photo so the arrow
    // placement needs to adjust for that
    '&.card': {
      top: '33%',
    },

    // In full view, the image does take up the full height
    '&.full': {
      top: '50%',
    },

    '&:hover': {
      transform: 'scale(1.04)',
      backgroundColor: '#FFFFFFE6',
    },

  },

  icon: {
    color: 'black',
  },

}));

export default useStyles;
