import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  Routes, saveSearch, SaveSearch,
  UpdateSavedSearch, updateSavedSearch, getSavedSearchResult,
  SavedSearch,
} from 'beiytak_sdk';
import { getAPIUrl } from '@utils';
import {
  GetUserSearchResults,
  UpdateSavedSearchAndGetResultsParams,
} from './types';

const UpdateSavedSearchAndGetResults = 'searchSession/updateSavedSearchAndGetResults';

/** Saves the user search currently being analyzed to the backend */
const updateSavedSearchAndGetResults = createAsyncThunk(
  UpdateSavedSearchAndGetResults,
  async (params: UpdateSavedSearchAndGetResultsParams): Promise<GetUserSearchResults> => {
    const url = getAPIUrl(Routes.GRAPHQL);
    const { updateSavedSearch: updateSavedSearchParams, navigate } = params;

    // First update the saved search
    const searchId = await updateSavedSearch({ params: updateSavedSearchParams }, url).then((res) => {
      const { data } = res;

      if (data) { return data; }

      return Promise.reject();
    });

    // Then get the search results for it
    const { data: savedSearchResult } = await getSavedSearchResult({ searchId }, url);

    if (!savedSearchResult) { return { navigate }; }

    if (savedSearchResult && 'error' in savedSearchResult) { return { navigate }; }

    if (savedSearchResult && !('error' in savedSearchResult)) {
      const {
        searchName, status, input, favorites, data, planDetails,
      } = savedSearchResult;

      const savedSearch: SavedSearch = {
        searchId,
        searchName,
        status,
        input,
        favorites,
      };

      const result: GetUserSearchResults = {
        savedSearch,
        userSearchResults: { data, planDetails },
        navigate,
      };

      return result;
    }

    return { navigate };
  },
);

export { UpdateSavedSearchAndGetResults, updateSavedSearchAndGetResults };
