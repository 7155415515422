import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { useAppSelector } from '@hooks';
import { Filter } from '@components';
import { selectUserLocationsFilterDefault } from '@stores';
import { userLocationFilterFactory } from '@utils';
import useStyles from './UserLocationsFilters.styles';

/** Holds the commute time filters for the user locations from the search */
const UserLocationsFilter: FunctionComponent = () => {
  const classes = useStyles();
  const userLocationsFilters = useAppSelector(selectUserLocationsFilterDefault);
  const filterCount = Object.keys(userLocationsFilters).length;

  return (
    <div className={classes.container}>
      {Object.keys(userLocationsFilters).map((filter, index) => {
        const isLastFilter = index === (filterCount - 1);

        return (
          <div className={clsx({
            [classes.filter]: true,
            'last-filter': isLastFilter,
          })}
          key={filter}
          >
            <Filter {...{ ...userLocationFilterFactory(filter), disabled: false }} key={filter} />
          </div>
        );
      })}
    </div>
  );
};

export default UserLocationsFilter;
