import { makeStyles } from '@material-ui/core/styles';
import { theme } from '../../../constants';

const useStyles = makeStyles((theme) => ({

  icon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },

  root: {

    height: '100%',

  },

  input: {
    position: 'relative',
    minWidth: '100%',
    maxWidth: '100',

    maxHeight: '10px',
    minHeight: '10px',

    ...theme.typography.subtitle1,
    color: '#888888',
    fontWeight: 'normal',
    fontSize: '0.8rem', // to match the text size of the other subtitles in steps

    // manually matching the color of the location input due to mui overrides
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',

    '&::placeholder': {
      opacity: 1,
    },
  },

  clearIndicator: {
    marginRight: '0.25rem',
  },

}));

/**
 * Styles the popper for the LocationInput component.
 * We have to pass these to the style prop of the component in order to
 * style the popper correctly.
 */
const popperStyle = {

  // So it sits level with the other inputs
  marginTop: '1.4rem',

  // To be aligned with the location input
  marginLeft: '11rem',

};

/**
 * This styles the drop down of options from mui
 */
const paperStyle = {

  overflow: 'hidden',

  minWidth: '32rem',
  maxWidth: '32rem',

  background: theme.palette.neutral.light,
  border: `1px solid ${theme.palette.neutral.dark}`,
  boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',
  borderRadius: '30px',

};

export { useStyles, popperStyle, paperStyle };

export default useStyles;
