import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, some } from 'lodash';
import {
  AddingUserLocationPayload,
  StepKeys,
  UserLocation,
  UserEnteredLocation,
  UserLocationName,
  UserLocationsInputSlice,
} from '@types';
import { RootState } from '@stores';
import { TravelModes } from 'beiytak_sdk';
import userLocationsValidation from './services';

// Initial state for store
const initialState: UserLocationsInputSlice = {

  value: [],
  validation: false,
  currentUserLocation: null,
  currentUserLocationName: null,
  currentTravelMode: TravelModes.Driving,
  newUserLocationWillBeAdded: false,

};

export const userLocationInputSlice = createSlice({
  name: 'userLocationsInput',
  initialState,
  reducers: {

    /**
     * Adds the user location entered by the user
     */
    addingUserLocation: (
      state,
      action: PayloadAction<AddingUserLocationPayload>,
    ) => {
      const { location: userLocation, name, travelMode } = action.payload;
      const currentUserLocations = state.value;
      const locationAlreadyAdded = some(currentUserLocations, ['location', userLocation]);

      // Only evaluate if location is filled out
      if (userLocation) {
        const userLocationData: UserLocation = {
          userLocation,
          userAlias: name || userLocation, // if the name is null, use the location
          travelMode,
        };

        // Add the user location to the list only if its not already there
        if (!locationAlreadyAdded) {
          state.value = [...currentUserLocations, userLocationData];
        }

        /**
          * If the location is already added, replace the existing one with the updated one.
          * The user may have changed the name and not notice
          */
        if (locationAlreadyAdded) {
          const locationRemovedFromCurrent = filter(
            currentUserLocations,
            (userLocations) => userLocations.userLocation !== userLocation,
          );

          state.value = [...locationRemovedFromCurrent, userLocationData];
        }
      }
    },

    /**
     * Removes a user location when a chip is deleted
     */
    userLocationRemoved: (state, action: PayloadAction<UserLocation>) => {
      const { payload } = action;

      const currentUserLocations = state.value;

      const updatedUserLocations = filter(
        currentUserLocations,
        (userLocationData) => userLocationData.userLocation !== payload.userLocation,
      );

      state.value = updatedUserLocations;
    },

    /**
      * Updates the selected location for for the users locations input
      */
    userChangedUserLocation: (state, action:PayloadAction<UserEnteredLocation>) => {
      const { payload } = action;

      state.currentUserLocation = payload;
    },

    /**
      * Updates the location name for the users locations input
     */
    userChangedUserLocationName: (state, action:PayloadAction<UserLocationName>) => {
      const { payload } = action;

      state.currentUserLocationName = payload;
    },

    /**
     * Updates the travel mode for the user location
     */
    userChangedTravelMode: (state, action:PayloadAction<TravelModes>) => {
      const { payload } = action;

      state.currentTravelMode = payload;
    },

    /**
     * Validated the new user location to make sure it can be added
     */
    validateNewUserLocation: (state) => {
      const { currentUserLocation } = state;

      // Just checks if location is not null
      // This happens if the user tries to go forward with the step
      // Without selecting something from the drop down explicity
      if (currentUserLocation) {
        state.newUserLocationWillBeAdded = true;
      }
    },

    /**
     * Validates the user location input
     */
    validateUserLocationInput: (state, action: PayloadAction<UserLocation[]>) => {
      const { payload } = action;

      state.validation = userLocationsValidation(
        payload,
      );
    },

    /**
     * Resets the session info for the user locations step in prep for another location
     */
    resetUserLocationInputSession: (state) => {
      state.currentUserLocation = null;
      state.currentUserLocationName = null;
      state.currentTravelMode = TravelModes.Driving;
      state.newUserLocationWillBeAdded = false;
    },

    /** Rests the user location input in prep for a new search */
    resetUserLocationInput: (state) => {
      state.value = [];
      state.validation = false;

      state.currentUserLocation = null;
      state.currentUserLocationName = null;
      state.currentTravelMode = TravelModes.Driving;
      state.newUserLocationWillBeAdded = false;
    },

    /**
     * Bulk updates the user location input.
     * This is used in the case where the user wants to update the user search and need to update the inputs they used for that specific search
     */
    bulkUpdatingOfUserLocationInput: (state, action: PayloadAction<UserLocation[]>) => {
      const { payload: userLocationInput } = action;

      state.value = userLocationInput;
      state.validation = true;
    },

  },
});

export const selectedUserLocationsInputValidation = (state: RootState) => state.userLocationsInput.validation;

export const selectUserLocationsInput = (state: RootState) => state.userLocationsInput.value;

export const selectNewUserLocationWillBeAdded = (state: RootState) => state.userLocationsInput.newUserLocationWillBeAdded;

export const {
  userLocationRemoved,
  addingUserLocation,
  resetUserLocationInputSession,
  resetUserLocationInput,
  userChangedUserLocation,
  userChangedUserLocationName,
  validateNewUserLocation,
  validateUserLocationInput,
  userChangedTravelMode,
  bulkUpdatingOfUserLocationInput,
} = userLocationInputSlice.actions;

export default userLocationInputSlice.reducer;
