import {
  createMachine, InterpreterFrom, State, assign,
} from 'xstate';
import { Waiter } from '@types';
import { waitListUser } from './services';

export interface WaitListPayload {
  firstName: string,
  lastName: string,
  email: string,
}

interface Context {
    /** If the user started to fill out the form (ex. clicking join) */
    startedSignUp: boolean,
    /** If a post is being sent to sign up the user */
    isSigningUp: boolean,
    failedToSignUp: boolean,
    /** If the user officially signed up */
    isWaitListed: boolean,
    firstName: string | null,
    lastName: string | null,
    email: string | null,
}

type Events =
| {type: 'STARTED_SIGN_UP', payload: {email?: string}}
| {type: 'STOPPED_SIGN_UP'}
| {type: 'SIGN_UP', payload: WaitListPayload}

type Actions =
| {type: 'setStartedSignUp'}
| {type: 'setEmail'}
| {type: 'setEndedSignUp'}
| {type: 'setIsSigningUp'}
| {type: 'setNotSigningUp'}
| {type: 'setUserAsWaitListed'}
| {type: 'setFailedToSignUp'}

type Services =
{
    waitListUser: {
        data: Waiter
    }
}

export type WaitListUser = (payload: WaitListPayload) => Promise<Waiter>

// eslint-disable-next-line
export const WaitListMachine =
/** @xstate-layout N4IgpgJg5mDOIC5QHcCGBLALgWgDbtkwDoA7Ae0wHUNMAZAzSAYgGUBJAcQDkB9AVQAKAbQAMAXUSgADmVhZ0ZEpJAAPRAEYAnAHYi6gCwiAbACYArABoQATw0BmTUTMBfV1fIQ4ytFjwNlMnKYCkpIqojYRla2CJFuID44+ISkFNRY9ISQAbLyispqCPom0RqG8Yl+KXJQJOgkUHxSOUEhBYh2JvpEInbq5qUIBiIVNFXEiZmMEC15oaCFZt3aAByaxgM2iCvqTq6uQA */
createMachine(
  {
    tsTypes: {} as import('./WaitList.machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      actions: {} as Actions,
      services: {} as Services,
    },
    id: 'wait-list',
    initial: 'notWaitListed',
    context: {
      firstName: null,
      lastName: null,
      email: null,
      startedSignUp: false,
      isSigningUp: false,
      failedToSignUp: false,
      isWaitListed: false,
    },
    states: {
      notWaitListed: {
        on: {
          STARTED_SIGN_UP: {
            actions: ['setStartedSignUp', 'setEmail'],
          },
          SIGN_UP: {
            target: 'signingUp',
          },
          STOPPED_SIGN_UP: {
            actions: ['setStoppedSignUp'],
          },
        },
      },
      signingUp: {
        entry: ['setIsSigningUp'],
        invoke: {
          src: 'waitListUser',
          id: 'wait-list-user',
          onDone: {
            target: 'waitListed',
            actions: ['setUserAsWaitListed'],
          },
          onError: {
            target: 'failedToWaitList',
          },
        },
        exit: ['setNotSigningUp'],
      },
      failedToWaitList: {
        always: [
          {
            target: 'notWaitListed',
            actions: ['setFailedToSignUp'],
          },
        ],
      },
      waitListed: {
        on: {
          STOPPED_SIGN_UP: {
            actions: ['setStoppedSignUp'],
          },
        },
      },
    },
  },
  {
    actions: {
      setStartedSignUp: assign({ startedSignUp: (ctx, event) => true }),
      setStoppedSignUp: assign({
        startedSignUp: (ctx, event) => false,
        isWaitListed: (ctx, event) => false,
        firstName: (ctx, event) => null,
        lastName: (ctx, event) => null,
        email: (ctx, event) => null,
      }),
      setEmail: assign((ctx, event) => {
        if (event.payload.email) { return { email: event.payload.email }; }

        return { email: null };
      }),
      setIsSigningUp: assign({ isSigningUp: (ctx, event) => true }),
      setNotSigningUp: assign({ isSigningUp: (ctx, event) => false }),
      setFailedToSignUp: assign({ failedToSignUp: (ctx, event) => true }),
      setUserAsWaitListed: assign({
        isWaitListed: (ctx, event) => true,
        failedToSignUp: (ctx, event) => false,
      }),
    },
    services: {
      waitListUser: async (ctx, event) => {
        return waitListUser({ ...event.payload });
      },
    },
  },
);

export const selectIsSigningUp = (state: State<Context>) => state.context.isSigningUp;
export const selectIsWaitListed = (state: State<Context>) => state.context.isWaitListed;
export const selectedFailedToSignUp = (state: State<Context>) => state.context.failedToSignUp;
export const selectStartedSignUp = (state: State<Context>) => state.context.startedSignUp;
export const selectEmail = (state: State<Context>) => state.context.email;
