import { FC } from 'react';
import { FeatureHighlight } from '@components';
import { Plans } from 'beiytak_sdk';
import { LocationPermissionService } from '@machines';
import usePlansAndOffers from './usePlansAndOffers.hook';
import PlanOption from './PlanOption';
import * as S from './PurchaseOptions.styled';

interface PurchaseOptionsProps {
  locationPermissionService: LocationPermissionService,
}

const PurchaseOptions: FC<PurchaseOptionsProps> = ({ locationPermissionService }) => {
  const [{ selectedPlan, offer, features }, { updatePlan, signUpForFreeTrial, handleContactUs }] = usePlansAndOffers(locationPermissionService);
  const planId = offer?.planId;
  const price = offer?.price;
  const duration = planId === Plans.FREE_TRIAL ? `${offer?.duration} days` : '/ month';

  return (
    <S.Container>

      <S.PlanDetailsContainer>
        <S.Title> Select your plan </S.Title>

        <S.PlanOptionsContainer>
            <PlanOption {...{ id: 'PURCHASE', label: 'Purchase', updatePlan, selectedPlan }} />
            <PlanOption {...{ id: 'FREE_TRIAL', label: 'Free Trial (7 days)', updatePlan, selectedPlan }} />
        </S.PlanOptionsContainer>

        <S.FeaturesContainer >
          <S.Title> What you get </S.Title>
          {features.map((feature) => <FeatureHighlight {...{ feature }} key={feature}/>)}
        </S.FeaturesContainer>

      </S.PlanDetailsContainer>

      <S.PricingContainer>

        {
          planId === Plans.FREE_TRIAL
            ? <S.Button onClick={signUpForFreeTrial}>Sign Up</S.Button>
            : <S.Button onClick={handleContactUs}>Contact Us</S.Button>
        }

        </S.PricingContainer>

    </S.Container>

  );
};

export default PurchaseOptions;
