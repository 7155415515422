import DriveEtaIcon from '@material-ui/icons/DriveEta';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import DirectionsTransitIcon from '@material-ui/icons/DirectionsTransit';
import { TravelModes } from 'beiytak_sdk';
import { MoreHomeCriteriaKeys, MoreStylesKeys, HomeCriteriaKeys } from '@types';

import priceIcon from '../assets/price.svg';
import bedroomsIcon from '../assets/bedrooms.svg';
import bathroomsIcon from '../assets/bathrooms.svg';
import storiesIcon from '../assets/stories.png';
import lotSqftIcon from '../assets/lotSqft.png';
import sqftIcon from '../assets/sqft.png';
import garageIcon from '../assets/garage.png';
import ageIcon from '../assets/year.svg';

import singleFamilyIcon from '../assets/single_family.svg';
import multiFamilyIcon from '../assets/multi_family.svg';
import mobileIcon from '../assets/mobile.svg';
import farmIcon from '../assets/farm.svg';
import petPolicyIcon from '../assets/pets.png';
import condoIcon from '../assets/condo.svg';
import condopIcon from '../assets/condop.svg';
import townhomeIcon from '../assets/townhome.svg';
import apartmentIcon from '../assets/apartment.svg';
import coopIcon from '../assets/coop.svg';

/** Returns the icon for the specific type of data */
const getHomeCriteriaIcon = (key: HomeCriteriaKeys | null): typeof priceIcon => {
  let icon = '';

  if (key) {
    if (key === HomeCriteriaKeys.PRICE) { icon = priceIcon; }
    if (key === HomeCriteriaKeys.BATHROOMS) { icon = bathroomsIcon; }
    if (key === HomeCriteriaKeys.BEDROOMS) { icon = bedroomsIcon; }
  }

  return icon;
};

const getTravelModeIcon = (travelMode: string) => {
  let TravelModeIcon = DriveEtaIcon;

  if (travelMode === TravelModes.Driving) { TravelModeIcon = DriveEtaIcon; }
  if (travelMode === TravelModes.Transit) { TravelModeIcon = DirectionsTransitIcon; }
  if (travelMode === TravelModes.Walking) { TravelModeIcon = DirectionsWalkIcon; }

  return TravelModeIcon;
};

// @TODO create enum for all of these so its consistent across the app
const getMoreFilterIcon = (filter: MoreHomeCriteriaKeys | string) => {
  let icon = '';

  if (filter === MoreHomeCriteriaKeys.STORIES) { icon = storiesIcon; }
  if (filter === MoreHomeCriteriaKeys.BATHS_FULL) { icon = bathroomsIcon; }
  if (filter === MoreHomeCriteriaKeys.BATHS_HALF) { icon = bathroomsIcon; }
  if (filter === MoreHomeCriteriaKeys.SQFT) { icon = sqftIcon; }
  if (filter === MoreHomeCriteriaKeys.GARAGE_COUNT) { icon = garageIcon; }
  if (filter === MoreHomeCriteriaKeys.LOT_SQFT) { icon = lotSqftIcon; }
  if (filter === MoreHomeCriteriaKeys.AGE) { icon = ageIcon; }
  if (filter === MoreHomeCriteriaKeys.PET_POLICY) { icon = petPolicyIcon; }

  return icon;
};

// @TODO change this so only enums is used
const getStylesIcon = (style: MoreStylesKeys | string) => {
  let icon = '';

  if (style === MoreStylesKeys.SINGLE_FAMILY) { icon = singleFamilyIcon; }
  if (style === MoreStylesKeys.MULTI_FAMILY) { icon = multiFamilyIcon; }
  if (style === MoreStylesKeys.MOBILE) { icon = mobileIcon; }
  if (style === MoreStylesKeys.FARM) { icon = farmIcon; }
  if (style === MoreStylesKeys.COOP) { icon = coopIcon; }
  if (style === MoreStylesKeys.CONDO) { icon = condoIcon; }
  if (style === MoreStylesKeys.CONDOP) { icon = condopIcon; }
  if (style === MoreStylesKeys.TOWNHOME) { icon = townhomeIcon; }
  if (style === MoreStylesKeys.APARTMENT) { icon = apartmentIcon; }

  return icon;
};

export {
  getHomeCriteriaIcon, getTravelModeIcon, getMoreFilterIcon, getStylesIcon,
};
