import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridTemplateRows: `${topNavBarHeight} min-content`,
    gridAutoRows: 'row',

    justifyItems: 'center',
    alignContent: 'start',
    gridTemplateAreas:
    `
        'top-nav-bar'
        'data'
    `,

    overflow: 'hidden',
    position: 'relative',

    width: '100vw',
    height: '100vh',

    backgroundColor: '#FCFCFC',
  },

  'top-nav-bar': {

    gridArea: 'top-nav-bar',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    zIndex: 1,
    height: '100%',
    width: '100%',

    background: theme.palette.neutral.light,
    boxShadow: '0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04)',
  },

  'tabs-container': {
    width: '100%',
    height: '100%',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginLeft: '2rem',

  },

  tab: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    marginRight: '0.5rem',
    marginLeft: '0.5rem',

    width: '8rem',
    minWidth: '8rem',
    height: '3rem',

    background: 'transparent',
    border: 'none',

    ...theme.typography.body2,
    fontWeight: 200,
    fontSize: '1rem',

    textDecoration: 'none',
    whitespace: 'nowrap',

    borderBottom: '3px solid transparent',

    '&.selected': {
      borderBottom: `3px solid ${theme.palette.primary.main}`,
    },

    '&:visited': {
      textDecoration: 'none',
      color: 'black',
    },
  },

}));

export default useStyles;
