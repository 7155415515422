import { IndexableObject } from '.';

export enum RequestStatus {
  FULFILLED = 'FULFILLED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING',
}

/**
 * Key values of the steps. This is used widely in the application so there is no hardcoded names
 * @enum
 */
export enum StepKeys {
    TYPE = 'TYPE',
    LOCATION = 'LOCATION',
    HOME_CRITERIA = 'HOME_CRITERIA',
    USER_LOCATIONS = 'USER_LOCATIONS',
    MORE = 'MORE',
    PREMIUM = 'PREMIUM',
    SAVED_SEARCHES = 'SAVED_SEARCHES',
  }

/**
   * Type of prediction to get from the google
   */
export enum PredictionType {
    LOCATION = 'location',
    PLACE = 'place',
}

/**
 * Keys for the Home Criteria data
 * @enum
 */
export enum HomeCriteriaKeys {
    PRICE ='PRICE',
    BEDROOMS = 'BEDROOMS',
    BATHROOMS = 'BATHROOMS'
  }

export enum MoreHomeCriteriaKeys {
  STORIES = 'stories',
  BATHS_FULL = 'bathsFull',
  BATHS_HALF = 'bathsHalf',
  SQFT = 'sqft',
  GARAGE_COUNT = 'garageCount',
  LOT_SQFT = 'lotSqft',
  AGE = 'age',
  PET_POLICY = 'petPolicy'
}

/**
 * Keys for the more filters section
 */
export enum MoreFilterKeys {
  HOME_CRITERIA = 'HOME_CRITERIA',
  STYLES = 'STYLES',
  FEATURES = 'FEATURE',
}

export enum MoreStylesKeys {
  SINGLE_FAMILY = 'single_family',
  MULTI_FAMILY = 'multi_family',
  MOBILE = 'mobile',
  FARM = 'farm',
  CONDO = 'condo',
  CONDOP ='condop',
  COOP= 'coop',
  TOWNHOME = 'townhome',
  APARTMENT = 'apartment'
}

/**
 * What the user is allowed to sort by
 */
export enum SortByKeys {
  LIST_DATE = 'LIST_DATE',
  PRICE_HIGH_TO_LOW = 'PRICE_HIGH_TO_LOW',
  PRICE_LOW_TO_HIGH = 'PRICE_LOW_TO_HIGH',
  SQFT_HIGH_TO_LOW = 'SQFT_HIGH_TO_LOW',
  SQFT_LOW_TO_HIGH = 'SQFT_LOW_TO_HIGH',
}

/**
 * How the labels should be displayed for each step key
 */
export const SortByKeysLabel: IndexableObject<string> = {
  [SortByKeys.LIST_DATE]: 'List Date (Latest)',
  [SortByKeys.PRICE_HIGH_TO_LOW]: 'Price (High to Low)',
  [SortByKeys.PRICE_LOW_TO_HIGH]: 'Price (Low to High)',
  [SortByKeys.SQFT_HIGH_TO_LOW]: 'Sqft (High to Low)',
  [SortByKeys.SQFT_LOW_TO_HIGH]: 'Sqft (Low to High)',
};

/**
 * How the sort by keys map to the key in the listing object
 */
export const SortByKeysListingMap: IndexableObject<string> = {
  [SortByKeys.LIST_DATE]: 'listDate',
  [SortByKeys.PRICE_HIGH_TO_LOW]: 'price',
  [SortByKeys.PRICE_LOW_TO_HIGH]: 'price',
  [SortByKeys.SQFT_HIGH_TO_LOW]: 'sqft',
  [SortByKeys.SQFT_LOW_TO_HIGH]: 'sqft',
};

/**
 * Routes for the application.
 * This just ensures consistency across the application incase any of the
 * route names change.
 */
export enum Routes {
  /** All requests flow through verify to kick off the user verification check */
  LANDING = '/',
  THANK_YOU='/thank-you',
  VERIFY ='/verify',
  LOGIN = '/login',
  SEARCH = '/search',
  LOADING = '/loading',
  RESULTS = '/results',
  DASHBOARD = '/dashboard',
  /** Relative path under dashboard */
  SAVED_SEARCHES = 'saved-searches',
    /** Relative path under dashboard */
  FAVORITES = 'favorites',
  ERROR = '/error',
  RETRY = '/retry',
}
