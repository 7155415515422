import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    width: '100%',
    height: '100%',

    padding: '0.625rem',

    ...theme.typography.subtitle1,
    fontWeight: 300,

    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },
}));

export default useStyles;
