import { FunctionComponent } from 'react';
import { useAppSelector } from '@hooks';
import {
  Steps,
  HomeCriteriaInput,
  UserLocationsInput,
  TopNavBar,
  ListingTypes,
} from '@components';
import { selectSelectedStep } from '@stores';
import { LocationPermissionMachine } from '@machines';
import { useMachine } from '@xstate/react';
import { StepKeys } from '@types';
import useStyles from './UserSearchScreen.styles';
import background from '../../assets/home_background.png';
import overlay from '../../assets/overlay.png';

/**
 * The user search screen aka the landing page of the app
 * @component
 */
const UserSearchScreen: FunctionComponent = () => {
  const classes = useStyles();
  const selectedStep = useAppSelector(selectSelectedStep);
  const [, , locationPermissionService] = useMachine(LocationPermissionMachine);

  return (

    <div className={classes.container}>

      <img src={background} alt="" className={classes.background} />
      <img src={overlay} alt="" className={classes.overlay} />

      <div className={classes.header}>
        <TopNavBar variant='transparent'/>
      </div>

      <div className={classes['text-container']}>
          Define what a home means
          to <span style={{ color: '#FF7348' }}> you </span> and  we&apos;ll help
          you find it

      </div>

      <div className={classes['search-steps']}>
        <ListingTypes />
        <Steps {...{ locationPermissionService }}/>
      </div>

      <div className={classes['home-criteria-input']}>
        {selectedStep === StepKeys.HOME_CRITERIA
          ? <HomeCriteriaInput />
          : <></>
        }
      </div>

      <div className={classes['user-location-input']}>
      {selectedStep === StepKeys.USER_LOCATIONS
        ? <UserLocationsInput />
        : <></>
        }
      </div>

    </div>

  );
};

export default UserSearchScreen;
