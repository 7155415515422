import { FunctionComponent } from 'react';
import { Navigate, useLocation } from 'react-router';
import { Routes, LocationState } from '@types';
import { selectAuthStatus } from '@stores';
import { useAppSelector } from '@hooks';

/** Protects routes by making sure the user is authenticated before moving forward */
const ProtectedRoute: FunctionComponent <any> = ({ children, redirectTo }) => {
  const { isAuthenticated, verifiedAuth } = useAppSelector(selectAuthStatus);
  const location = useLocation();

  const state: LocationState = {
    from: {
      pathname: location.pathname,
    },
  };

  return (

    isAuthenticated && verifiedAuth
      ? children
      : (<Navigate to={Routes.VERIFY} state={state} />)

  );
};

export default ProtectedRoute;
