import { Middleware, unwrapResult, ThunkDispatch } from '@reduxjs/toolkit';
import {
  RootState,
  getUserSearchResults,
  updatingDataForSearchResultSession,
  updatingInterScreenState,
  updatingStateWithUserSearchResults,
  updatingSearchNameForSearchResultSession,
  updatingSearchIdForSearchResultSession,
  updatingSearchStatusForSearchResultSession,
  resetAllSearchResultFilters,
  clearingUsersFavorites,
  resetSearchResultSession,
  updatingFavoriteListings,
  getSavedSearchResults,
  updatingTypeInput,
  locationInputChanged,
  bulkUpdatingHomeCriteriaFilters,
  homeCriteriaStepSelected,
  bulkUpdatingOfUserLocationInput,
  resetUserLocationInputSession,
  updatingUserSearchInputAssociatedWithSearchResult,
  clearingSearchSessionDataBeforeSearch,
  clearingResultsDataBeforeSearch,
  clearUserLocationsFilters,
  updateSavedSearchAndGetResults,
  updatingSavedSearchAssociatedWithSearchResult,
} from '@stores';
import { getHomeCriteriaFiltersFromSavedSearch } from '@utils';
import { Routes, UserSearch, StepKeys } from '@types';

const handlingUserSearchResultsMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /**
   *  if the user is requesting to get saved search results, update the state with the inputs that were used in the saved search
   *  This ensures that if the user wants to update their params later or their favorites they can
   */
  if (getSavedSearchResults.fulfilled.match(action)
  || updateSavedSearchAndGetResults.fulfilled.match(action)
  ) {
    const { savedSearch } = unwrapResult(action);

    if (savedSearch) {
      const { input } = savedSearch;
      const {
        type, location, homeCriteria, userLocations,
      } = input;

      const homeCriteriaFilters = getHomeCriteriaFiltersFromSavedSearch(homeCriteria);

      const userSearch: UserSearch = {
        [StepKeys.TYPE]: type,
        [StepKeys.LOCATION]: location,
        [StepKeys.HOME_CRITERIA]: homeCriteriaFilters,
        [StepKeys.USER_LOCATIONS]: userLocations,
      };

      storeAPI.dispatch(updatingUserSearchInputAssociatedWithSearchResult(userSearch));

      storeAPI.dispatch(updatingTypeInput(type));

      storeAPI.dispatch(locationInputChanged(location));

      storeAPI.dispatch(bulkUpdatingHomeCriteriaFilters(homeCriteriaFilters));
      storeAPI.dispatch(homeCriteriaStepSelected());

      storeAPI.dispatch(bulkUpdatingOfUserLocationInput(userLocations));
      storeAPI.dispatch(resetUserLocationInputSession());
    }
  }

  /**
    * Once the results are returned:
    * Update the state and directory the user to the results page
    * Or if no results came back, send the user to the no listings found page
    */
  if (getUserSearchResults.fulfilled.match(action)
      || getUserSearchResults.rejected.match(action)
      || getSavedSearchResults.fulfilled.match(action)
      || getSavedSearchResults.rejected.match(action)
      || updateSavedSearchAndGetResults.fulfilled.match(action)
      || updateSavedSearchAndGetResults.rejected.match(action)
  ) {
    const { savedSearch, userSearchResults, navigate } = unwrapResult(action);

    // If the result isn't void and has at least one listing then update the results
    if (userSearchResults && Array.isArray(userSearchResults.data) && userSearchResults.data.length > 0) {
      // Reset the session data before starting the search
      storeAPI.dispatch(clearingSearchSessionDataBeforeSearch());

      // Clear user search results data before the new search
      storeAPI.dispatch(clearingResultsDataBeforeSearch());
      storeAPI.dispatch(clearUserLocationsFilters());

      // Update the state with the results
      const savedSearchFavorites = savedSearch ? savedSearch.favorites : [];

      storeAPI.dispatch(updatingStateWithUserSearchResults(userSearchResults));
      storeAPI.dispatch(updatingSavedSearchAssociatedWithSearchResult(savedSearch));
      storeAPI.dispatch(updatingDataForSearchResultSession({ userSearchResults: userSearchResults.data, savedSearchFavorites, planDetails: userSearchResults.planDetails }));
      storeAPI.dispatch(clearingUsersFavorites());

      // Update all the filters based on the data received
      storeAPI.dispatch(resetAllSearchResultFilters());
      storeAPI.dispatch(resetSearchResultSession());

      // If there is saved search data then update the state with that
      if (savedSearch) {
        const {
          searchName, searchId, status, favorites,
        } = savedSearch;

        const favoritesAddresses = favorites.map((ListingDetail) => ListingDetail.listing.address);

        // Update the search related data
        storeAPI.dispatch(updatingSearchNameForSearchResultSession(searchName));
        storeAPI.dispatch(updatingSearchIdForSearchResultSession(searchId));
        storeAPI.dispatch(updatingSearchStatusForSearchResultSession(status));

        // Update the favorites that were part of the saved search
        storeAPI.dispatch(updatingFavoriteListings(favoritesAddresses));
      }

      // Push the user to the results page
      storeAPI.dispatch(updatingInterScreenState({ route: Routes.LOADING, value: false }));
      return navigate(Routes.RESULTS);
    }

    // If the result is void or no listings were found, direct the the user to the
    // listings not found page
    storeAPI.dispatch(updatingInterScreenState({ route: Routes.RETRY, value: true }));
    return navigate(Routes.RETRY);
  }

  return next(action);
});

export default handlingUserSearchResultsMiddleware;
