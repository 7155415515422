import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { useAppSelector } from '@hooks';
import { selectMoreFiltersHomeCriteriaDefault, selectTypeInput } from '@stores';
import { Filter } from '@components';
import { MoreHomeCriteriaKeys } from '@types';
import { moreHomeCriteriaFilterFactory } from '@utils';
import { ListingType } from 'beiytak_sdk';
import useStyles from './MoreHomeCriteria.styles';

const MoreHomeCriteria: FunctionComponent = () => {
  const classes = useStyles();
  const moreFiltersForHomeCriteria = useAppSelector(selectMoreFiltersHomeCriteriaDefault);
  const type = useAppSelector(selectTypeInput);
  const filters = Object.keys(moreFiltersForHomeCriteria).filter((filter) => {
    // Right now the 3rd party api doesn't provide stories for rentals so we have to filter it out not to confuse users
    if (type === ListingType.Rent) { return filter !== MoreHomeCriteriaKeys.STORIES; }

    // If this is a for sale search, show all filters
    return true;
  });

  const filterCount = Object.keys(filters).length;

  return (
      <div className={classes.container}>
        <span className={classes.title}>Home Criteria</span>
        { filters.map((filter, index) => {
          const isLastFilter = index === (filterCount - 1);

          return (
            <div
            key={filter}
            className={clsx({
              [classes.filter]: true,
              'last-filter': isLastFilter,
            })}
            >
              <Filter {...{ ...moreHomeCriteriaFilterFactory(filter), disabled: true }} key={filter} />
            </div>
          );
        })
      }
    </div>
  );
};

export default MoreHomeCriteria;
