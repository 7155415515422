import { $theme } from '@styleGuide';
import styled from 'styled-components';

export const Container = styled.div<{isSelected: boolean}>`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    padding: 0.7rem;
    margin: 0rem 0.5rem 0rem 0.5rem;
    width: 40%;

    border-radius: ${$theme('border.radius.10')};
    border:  ${({ isSelected }) => (isSelected ? `2px solid ${$theme('palette.primary.main')}` : `1px solid ${$theme('palette.neutral.dark')}`)};

    font-size: 1rem;
    font-weight: 600;

    cursor: pointer;
`;

export const PlanOption = styled.input<{isSelected: boolean}>`
    margin-right: 0.5rem;
    accent-color: ${$theme('palette.primary.main')};
    cursor: pointer;
`;

export const PlanOptionLabel = styled.div`
`;
