import { FC } from 'react';
import { LocationPermissionService, selectIsSigningUpForFreeTrial, selectIsSignedUpForFoFreeTrial } from '@machines';
import { useActor, useSelector } from '@xstate/react';
import { AnimationContainer } from '@components';
import loadingAnimation from '../../../assets/three_dot_animation.json';
import popperAnimation from '../../../assets/celebration-popper.json';
import supportAnimation from '../../../assets/support.json';
import HeaderContent from './HeaderContent';
import PurchaseOptions from './PurchaseOptions';
import * as S from './PurchaseLocationPermission.styled';

interface PurchaseLocationPermissionProps {
    locationPermissionService: LocationPermissionService
}

const processingInfo = {
  title: 'Give us just a second 🙏',
  subtitle: '',
  animation: loadingAnimation,
};

const signUpFoFreeTrialSuccessInfo = {
  title: 'You\'re in!',
  subtitle: 'You can now close out and start your search.',
  animation: popperAnimation,
};

const signUpFoFreeTrialFailedInfo = {
  title: 'Looks like we couldn\'t sign you up',
  subtitle: 'Your free trial may have already expired. Please reach out to support@findur.io to purchase access',
  animation: supportAnimation,
};

const PurchaseLocationPermission: FC<PurchaseLocationPermissionProps> = ({ locationPermissionService }) => {
  const [, send] = useActor(locationPermissionService);
  const isSigningUpForFreeTrial = useSelector(locationPermissionService, selectIsSigningUpForFreeTrial);
  const isSignedUpForFreeTrial = useSelector(locationPermissionService, selectIsSignedUpForFoFreeTrial);
  const displayPurchaseOptions = !isSigningUpForFreeTrial && isSignedUpForFreeTrial == null;

  return (
        <S.Container>
          {
            !isSigningUpForFreeTrial
              ? <S.Close onClick={() => send('HIDE_PURCHASE_LOCATION_PERMISSION')}>x</S.Close>
              : <></>
          }

          {
            displayPurchaseOptions
              ? <>
                <S.HeaderContentContainer>
                  < HeaderContent />
                </S.HeaderContentContainer>

                <S.PurchaseOptionsContainer>
                  <PurchaseOptions {...{ locationPermissionService }}/>
                </S.PurchaseOptionsContainer>
              </>
              : <></>
          }

          {
            isSigningUpForFreeTrial
              ? <AnimationContainer {...processingInfo}/>
              : <></>
          }

          {
            isSignedUpForFreeTrial
              ? <AnimationContainer {...signUpFoFreeTrialSuccessInfo}/>
              : <></>
          }

          {
            isSignedUpForFreeTrial === false
              ? <AnimationContainer {...signUpFoFreeTrialFailedInfo}/>
              : <></>
          }
        </S.Container>
  );
};

export default PurchaseLocationPermission;
