import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: `${topNavBarHeight} min-content`,
    gridAutoRows: 'row',

    justifyItems: 'center',
    alignContent: 'start',
    gridTemplateAreas:
    `
        'header header header'
        'map  map res'
    `,
    overflow: 'hidden',

    width: '100vw',
    height: '100vh',
  },

  'top-nav-bar': {

    gridArea: 'header',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    zIndex: 1,
    width: '100%',

    boxShadow: '0 2px 6px -1px rgba(0, 0, 0, 0.16), 0 1px 4px -1px rgba(0, 0, 0, 0.04)',
  },

  'logo-container': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: '1rem',

    width: '33%',

  },

  logo: {
    objectFit: 'cover',
    maxHeight: '3rem',
    maxWidth: '9rem',
  },

  'filters-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    height: '100%',
  },

  'result-filters-container': {
    width: '70%',
    maxWidth: '50rem',
  },

  divider: {
    outline: `1px solid ${theme.palette.neutral.dark}`,
    height: '2rem',
    // marginRight: '1.84375rem',
    margin: '3rem',
    opacity: '0.75',
  },

  map: {

    gridArea: 'map',

    width: '100%',
    height: '100vh',

    overflow: 'hidden',
  },

  listings: {

    overflow: 'hidden',

    gridArea: 'res',
    width: '100%',
    height: '99vh',
    minWidth: '42.5rem',
    backgroundColor: '#FCFCFC',
  },

}));

export default useStyles;
