import styled from 'styled-components';
import { $theme, devices, theme } from '@styleGuide';

export const Container = styled.div<{variant: keyof typeof theme.palette.pastel}>`
    display: grid;
    grid-template-rows: min-content;
    gap: 1rem;
    grid-template-areas:
    "feature"
    "content"
    "gif"
    "join"
    ;

    width: 100%;

    padding-top: 1rem;

    background-color: ${({ variant }) => `${$theme(`palette.pastel.${variant}.light`)}`};
    background-color: '#fff8fb';
    border-radius:  ${$theme('border.radius.25')};

    @media (${devices.tabletAndBelow}) {
        gap: 1.5rem;
        height:  max-content;
        padding-left: 1rem;
        padding-right: 1rem;
    }
`;

export const Feature = styled.div<{variant: keyof typeof theme.palette.neon}>`
    height: 2rem;

    font-size: 1.1rem;
    font-weight: 700;
    line-height: 1.875rem;

    border: none ;
    border-bottom: ${({ variant }) => `3px solid ${$theme(`palette.neon.${variant}.main`)}`};
    background: transparent;
    color: #202020;
`;

export const FeaturesContainer = styled.div`
    grid-area: 'feature';
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

export const DescriptionContainer = styled.div`
    grid-area: content;
    justify-self: center;
    align-self: center;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;

    text-align: center;
`;

export const DemoContainer = styled.div`
    grid-area: gif;
    justify-self: center;
    align-self: center;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    margin: 0rem;

    width: 100%;
    max-width: 850px;
`;

export const Gif = styled.img`
    object-fit: contain;
    width: 100%;
    max-width: 100%;
    height: 100%;
    min-height: 100%;
    max-height: 100%;

    border-radius: 16px;
;`;

export const Video = styled.iframe`
    object-fit: contain;


    width: 100%;
    max-width: 100%;
    height: 30rem;

    border-radius: 16px;
;`;

export const Title = styled.h2`
    margin: 0rem;
    margin-bottom: 0.75rem;

    font-size: 2.2rem;
    line-height: 3.625rem;
    font-weight: 800;

    color: #202020;

    @media (${$theme('devices.tabletAndBelow')}) {
        font-size: 1.8rem;
    }
`;

export const Description = styled.h4`
    margin: 0rem;

    max-width: 40rem;

    font-size: 1rem;
    font-weight: 400;
    line-height: 1.875rem;
    color: #202020;
`;

export const JoinContainer = styled.div`
    grid-area: join;
    justify-self: center;
    align-self: center;

    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items:  center;

    margin-bottom: 1rem;
    margin-left: 2rem;

    width: 100%;

    @media (${$theme('devices.tabletAndBelow')}) {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items:  center;

        align-self: center;
        margin-left: 0rem;
    }
`;
