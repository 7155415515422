import { SavedSearch } from 'beiytak_sdk';
import { SavedSearches } from '../types';

/** Formats the results returned to fit an object structure to make querying easier */
const formatSavedSearchResults = (searches: SavedSearch[]): SavedSearches => {
  const savedSearches: SavedSearches = {};

  searches.forEach((search) => {
    const { searchId } = search;
    savedSearches[searchId] = search;
  });

  return savedSearches;
};

export default formatSavedSearchResults;
