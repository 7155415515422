import { FC } from 'react';
import Lottie from 'react-lottie';
import { useNavigate, useLocation } from 'react-router';
import { LandingButton, AnimationContainer } from '@components';
import { useActor } from '@xstate/react';
import { Routes, CreateAccountService } from '@types';
import * as S from './CreateAccountSuccess.styled';
import popperAnimation from '../../../assets/celebration-popper.json';

interface CreateAccountSuccessProps {
  createAccountService: CreateAccountService
}

const CreateAccountSuccess: FC<CreateAccountSuccessProps> = ({ createAccountService }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [, send] = useActor(createAccountService);
  const isOnTheLoginPage = location.pathname.includes(Routes.LOGIN);

  const title = 'You\'re in!';
  const subtitle = 'You\'ll receive a welcome email shortly with some helpful tutorials and ways to get in contact if you need anything 👋';

  // Since these forms are just rendered on top of the page,
  // We simply need to close out the modal if we are already on the login page
  const handleOnClick = () => {
    if (isOnTheLoginPage) { return send('STOPPED_SIGN_UP'); }
    return navigate(Routes.LOGIN);
  };

  return (

        <AnimationContainer {...{ title, subtitle, animation: popperAnimation }}>
          <S.LoginContainer>
            <LandingButton {...{ variant: 'orange', text: 'Login' }} onClick={handleOnClick}/>
          </S.LoginContainer>
        </AnimationContainer>

  );
};

export default CreateAccountSuccess;
