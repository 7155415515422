import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-start',

    width: '24rem',
    height: '10rem',

    boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',
    backgroundColor: theme.palette.neutral.light,
    borderRadius: '25px',

  },

  'actions-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',

    width: '100%',
  },

  'input-root': {

    marginTop: '2rem',
    marginLeft: '2rem',
    maxWidth: '20rem',

  },

  input: {
    fontSize: '1rem',
  },

  action: {

    margin: '1rem',

    minWidth: '6rem',
    maxWidth: '6rem',

    borderRadius: '25px',
    backgroundColor: 'transparent',

    color: theme.palette.primary.main,

    '&.searchNameEntered': {
      color: theme.palette.neutral.light,
      backgroundColor: theme.palette.primary.main,
    },

    '&:hover': {
      transform: 'scale(1.05)',
      backgroundColor: 'transparent',
    },

  },

}));

export default useStyles;
