import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'center',

    marginBottom: '1.75rem',

    fontSize: '0.875rem',
  },

  icon: {
    marginTop: '0.2rem',
    marginRight: '0.5rem',

    width: '0.9375rem',
    height: '0.81rem',
  },

  info: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

  },

  title: {
    fontWeight: 500,
  },

  detail: {
    fontWeight: 300,
  },

}));

export default useStyles;
