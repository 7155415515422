import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    // height: '2rem',

  },

  button: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    margin: '1.25rem 0.4rem 1.25rem 0.4rem',
    padding: '0.875rem',

    minWidth: '5.5rem',

    borderRadius: '25px',
    border: `1px solid ${theme.palette.neutral.dark}`,
    backgroundColor: theme.palette.neutral.light,
    boxShadow: '0px 1px 2px rgb(0 0 0 / 8%), 0px 4px 12px rgb(0 0 0 / 5%)',

    fontSize: '1rem',
    whiteSpace: 'nowrap',

    cursor: 'pointer',

    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
      transform: 'scale(1)',
    },

    '&.highlight': {
      border: `1px solid ${theme.palette.primary.main}`,
      // color: theme.palette.primary.main,
    },

    '&.selected': {
      border: `1px solid ${theme.palette.primary.main}`,
      color: theme.palette.primary.main,
    },
  },

  icon: {

    width: '0.9375rem',
    height: '0.9375rem',
    marginRight: '0.3125rem',

  },

}));

export default useStyles;
