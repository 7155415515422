import { createAsyncThunk } from '@reduxjs/toolkit';
import { StatusCodes } from 'http-status-codes';
import { Routes, requestToLoginUser } from 'beiytak_sdk';
import { UserLoginInfo } from '@types';
import { getAPIUrl } from '@utils';
import { LoginUserResult } from './types';

/**
 * So this can be referenced later to ignore the action from serialization because
 * the history object is part of the payload
 */
const LoginAction = 'auth/loginUser';

/**
 * Sends a request to login the user.
 * If successful, a JWT will be saved in an httpOnly cookie
 */
const loginUser = createAsyncThunk<LoginUserResult, UserLoginInfo, { rejectValue: LoginUserResult }>(
  LoginAction,
  async (userLoginInfo: UserLoginInfo,
    { rejectWithValue }) => {
    const url = getAPIUrl(Routes.LOGIN);
    const { email, password, navigate } = userLoginInfo;

    try {
      const status = await requestToLoginUser({ email, password }, url)
        .then((res) => res.status);

      const result = {
        navigate,
        status,
      };

      return result;
    } catch {
      // Notify redux of rejected request
      const status = StatusCodes.UNAUTHORIZED;

      const result = {
        status,
        navigate,
      };

      return rejectWithValue(result);
    }
  },
);

export {
  loginUser,
  LoginAction,
};
