import { FunctionComponent, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useAppDispatch, useAppSelector } from '@hooks';
import { userRequestingNextStep, selectTypeInput } from '@stores';
import { homeCriteriaInputFilterFactory } from '@utils';
import { HomeCriteriaKeys } from '@types';
import { Filter } from '@components';
import useStyles from './HomeCriteriaInput.styles';

/** Contains the filters for the Home Criteria step (price etc.) */
const HomeCriteriaInput: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const type = useAppSelector(selectTypeInput);

  const priceFilter = homeCriteriaInputFilterFactory(HomeCriteriaKeys.PRICE, type);
  const bedroomsFilter = homeCriteriaInputFilterFactory(HomeCriteriaKeys.BEDROOMS);
  const bathroomsFilter = homeCriteriaInputFilterFactory(HomeCriteriaKeys.BATHROOMS);

  const handleOnClickNext = () => dispatch(userRequestingNextStep());

  return (

    <div className={classes.container}>
        <Filter {...{ ...priceFilter, disabled: false }} />
        <div className={classes.divider} />
        <Filter {...{ ...bedroomsFilter, disabled: false }} />
        <div className={classes.divider} />
        <Filter {...{ ...bathroomsFilter, disabled: false }} />
        <div className={classes.divider} />

        <Button
          variant="outlined"
          className={classes.button}
          onClick={handleOnClickNext}>
          Next
        </Button>

    </div>

  );
};

export default HomeCriteriaInput;
