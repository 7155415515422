import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'space-between',

    backgroundColor: 'transparent',
    border: 'none',

    width: '100%',

  },

  facts: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 47%)',
  },

  fact: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    alignContent: 'center',

    marginBottom: '1.75rem',
    width: '15rem',

    fontSize: '0.875rem',
  },

  'fact-icon': {
    marginTop: '0.2rem',
    marginRight: '0.5rem',

    width: '0.9375rem',
    height: '0.81rem',
  },

  'fact-info': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',

  },

  'fact-title': {
    fontWeight: 500,
  },

  'fact-detail': {
    fontWeight: 300,
  },

  features: {

  },

}));

export default useStyles;
