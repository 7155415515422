import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Routes,
  SessionSlice,
} from '@types';
import { RootState } from '@stores';

const initialState: SessionSlice = {
  transitionScreens: {
    [Routes.LOADING]: false,
    [Routes.RETRY]: false,
    [Routes.ERROR]: false,
  },
  previousScreen: null,
};

export const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {

    /**
     * Handles whether an intermediate screen should be displayed (loading data, error, etc.)
     */
    updatingInterScreenState: (state, action: PayloadAction<{route: Routes, value: boolean}>) => {
      const { route, value } = action.payload;

      // Update value for the route
      state.transitionScreens[route] = value;

      // If we are enabling a route, disable the remaining
      if (value) {
        // Get the remaining routes that are not being updated
        const remainingRoutes = Object.keys(state).filter((key) => key !== route);

        // Disable the rest of the routes because only 1 can be true at a time
        remainingRoutes.forEach((route) => {
          state.transitionScreens[route] = false;
        });
      }
    },

    /** Action to notify app that the user is navigation to the dashboard so that the user saved searches are retrieved */
    userNavigatingToDashboard: (state) => {
    },

    /**
     * Action to notify app that the user is navigation to the results so the state is updated accordingly with the
     * correct data to view
    */
    userNavigatingToResults: (state) => {
    },

    /**
     * Fires off when the user leaves the search result session.
     * This fires off another actions to update the saved search with the latest data
     * if the user was analyzing a saved search
     * */
    userLeavingResults: (state) => {

    },

    /** Action is used to highlight when a user is going to start a new search prompting the app the clear our the search inputs */
    userNavigatingToStartNewSearch: (state) => {
    },

    /** Action is used to highlight that the user wants to update an existing search */
    userNavigatingToUpdateSearch: (state) => {
    },

    /** Action to dispatch when the user leaves favorites */
    userLeavingFavorites: (sate) => {

    },

  },
});

export const selectDisplayLoadingScreen = (state: RootState) => state.navigation.transitionScreens[Routes.LOADING];
export const selectDisplayRetryScreen = (state: RootState) => state.navigation.transitionScreens[Routes.RETRY];
export const selectDisplayErrorScreen = (state: RootState) => state.navigation.transitionScreens[Routes.ERROR];
export const selectPreviousScreen = (state: RootState) => state.navigation.previousScreen;

export const {
  updatingInterScreenState,
  userNavigatingToDashboard,
  userNavigatingToStartNewSearch,
  userNavigatingToUpdateSearch,
  userNavigatingToResults,
  userLeavingResults,
  userLeavingFavorites,
} = navigationSlice.actions;

export default navigationSlice.reducer;
