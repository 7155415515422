import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  thumb: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'space-between',

    width: '1.25rem',
    height: '1.25rem',

    background: theme.palette.neutral.light,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '3px',

  },

  bar: {

    top: '0.375rem',
    left: '0.4375rem',

    width: '0.075rem',
    height: '0.5rem',

    border: `1px solid ${theme.palette.primary.main}`,

    '&.left': {
      margin: '0.375rem 0.1125rem 0.375rem 0.44375rem',
    },

    '&.right': {
      margin: '0.375rem 0.44375rem 0.375rem 0.1125rem',
    },

  },

}));

export default useStyles;
