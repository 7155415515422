import { FunctionComponent } from 'react';
import { ListingType } from 'beiytak_sdk';
import { createFilterChipLabel, getHomeCriteriaIcon } from '@utils';
import { HomeCriteriaKeys, Maybe } from '@types';
import useStyles from './SearchInput.styles';

interface SearchInputProps {
    type?: ListingType
    homeCriteriaKey: HomeCriteriaKeys
    value: Maybe<number>[]
}

const SearchInput: FunctionComponent<SearchInputProps> = (searchInputProps) => {
  const classes = useStyles();
  const { type, homeCriteriaKey, value } = searchInputProps;

  return (
    <div className={classes.container}>
        <div className={classes.icon}>
            <img src={getHomeCriteriaIcon(homeCriteriaKey)} alt="" />
        </div>
        {createFilterChipLabel(homeCriteriaKey, type || ListingType.Sale, value)}
    </div>
  );
};

export default SearchInput;
