import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'fex-start',

    width: '100%',
    height: '100%',

  },

  'info-container': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginTop: '1rem',
    marginLeft: '1rem',

    // maxWidth: '10rem',
    maxWidth: '95%',

  },

  'filter-icon': {

    width: '0.9375rem',
    height: '0.9375rem',

    marginRight: '0.5rem',

  },

  'filter-name': {

    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '1rem',
  },

  'slider-container': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: '100%',
    height: '100%',
    maxHeight: '5rem',

  },

  'value-container': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',

    width: '11.25rem',
  },

  range: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    alignContent: 'center',

    width: '100%',
  },

  slider: {
    color: theme.palette.neutral.dark,

    '& .MuiSlider-active': {
      boxShadow: `0 0 0 11px ${theme.palette.primary.light}`,
    },

    '& .MuiSlider-thumb:hover': {
      boxShadow: `0 0 0 11px ${theme.palette.primary.light}`,
    },

  },

  rail: {
    color: theme.palette.neutral.dark,
  },

  track: {
    color: theme.palette.primary.main,
  },

  'track-false': {
    color: theme.palette.neutral.dark,
  },

  'slider-label': {
    fontSize: '0.625rem',
    marginTop: '0.125rem',
  },

  focusVisible: {
    boxShadow: '0px 0px 0px 14px rgba(0, 0, 0, 1)',
  },

}));

/**
 * Custom style for the slider to pass directly into the component
 */
const sliderStyle = {
  maxWidth: '30rem',
  marginRight: '2rem', // space to the value holders
};

export default useStyles;
export { sliderStyle };
