import React, { FunctionComponent, useState } from 'react';
import { useAppDispatch } from '@hooks';
import clsx from 'clsx';
import Input from '@material-ui/core/Input';
import { userRequestingToSaveSearch } from '@stores';
import { Button } from '@material-ui/core';
import useStyles from './SaveSearchInput.styles';

const SaveSearchInput: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [searchName, setSearchName] = useState('');
  const [clicked, setClicked] = useState(false);
  const searchNameEntered = searchName !== '';

  const handleInputChange = (input: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSearchName(input.target.value);
  };

  const handleOnClickSaveSearch = () => {
    if (searchNameEntered) {
      dispatch(userRequestingToSaveSearch(searchName));
      setClicked(!clicked);
    }
  };

  return (
    !clicked
      ? <div className={classes.container}>
          <Input
              classes={{
                root: classes['input-root'],
                input: classes.input,
              }}
              placeholder="Name your search"
              inputProps={{ 'aria-label': 'description' }}
              fullWidth
              onChange={(input) => handleInputChange(input)}
          />

          <div className={classes['actions-container']}>
              <Button
              classes={{
                root: clsx({
                  [classes.action]: true,
                  searchNameEntered,
                }),
              }}
              onClick={handleOnClickSaveSearch}
              >
                Save
              </Button>
          </div>

        </div>
      : <></>
  );
};
export default SaveSearchInput;
