import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router';
import {
  StepKeys,
  SearchSessionSlice,
} from '@types';
import { RootState } from '@stores';
import { GetUserSearchResultsAction, GetSavedSearchResultsAction } from './thunks';
import whichLinesToHide from './services';
// Initial state for store
const initialState: SearchSessionSlice = {

  selectedStep: null,

  hoveredStep: null,

  stepLinesToHide: {
    hideLineOne: false,
    hideLineTwo: false,
    hideLineThree: false,
  },

};

export const searchSessionSlice = createSlice({
  name: 'searchSession',
  initialState,
  reducers: {

    /**  Updates what step is currently selected */
    selectedStepIsChanging: (state, action: PayloadAction<StepKeys | null>) => {
      const { payload } = action;
      state.selectedStep = payload;
    },

    /** Updates what step is current hovered */
    hoveredStepIsChanging: (state, action: PayloadAction<StepKeys | null>) => {
      const { payload } = action;
      state.hoveredStep = payload;
    },

    /**
     * Clears out any session data thats not needed before the search.
     * Doing this ensures the results are not effected by any previous searches
     */
    clearingSearchSessionDataBeforeSearch: (state) => {
      state.selectedStep = null;
    },

    /**
     * Controls what lines to hide in the search area when a step is hovered
     * @TODO THis will be re-created in purse CSS in the future
     */
    hidingLinesFromTheStepSearch: (state) => {
      const { selectedStep, hoveredStep } = state;
      const linesToHide = whichLinesToHide(selectedStep, hoveredStep);

      state.stepLinesToHide = linesToHide;
    },

    /**
     * This handles the case when the user clicks on next for either the Home Criteria step
     * or the Your Locations step.
     * This action is simply to trigger the event of going ot the next step.
     * No state change is needed. The middleware listens to this action and handles it
     */
    userRequestingNextStep: (state) => {},

    /**
          * Action to dispatch to requests the results for the search
          * The payload requires the history object to push the user the user to the loading page
          * while the results are being fetched.
          */
    userRequestingSearchResults: (state, action: PayloadAction<NavigateFunction>) => {

    },

  },
});

export const selectSelectedStep = (state:RootState) => state.searchSession.selectedStep;

export const searchSessionIgnoreSerializableCheck = [
  'searchSession/userRequestingSearchResults',
  `${GetUserSearchResultsAction}/fulfilled`,
  `${GetUserSearchResultsAction}/rejected`,
  `${GetSavedSearchResultsAction}/fulfilled`,
  `${GetSavedSearchResultsAction}/rejected`,
];

export const {
  hidingLinesFromTheStepSearch,
  selectedStepIsChanging,
  clearingSearchSessionDataBeforeSearch,
  userRequestingNextStep,
  userRequestingSearchResults,
  hoveredStepIsChanging,
} = searchSessionSlice.actions;

export default searchSessionSlice.reducer;
