import {
  FunctionComponent, useEffect,
} from 'react';
import {
  TextField,
  Grid,
  Popper,
  Paper,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useAppSelector, useAutoCompletePredictions } from '@hooks';
import { location } from '@constants';
import { LocationPermissionService } from '@machines';
import { useActor } from '@xstate/react';
import { preparePayloadFromAutoCompleteSelection } from '@utils';
import {
  AutoCompleteOptions,
  PredictionType,
} from '@types';
import { selectLocationInput } from '@stores';
import {
  useStyles,
  popperStyle,
  paperStyle,
} from './LocationInput.styles';

/**
 * Props to ass along to the LocationInput component
 * @interface
 */
interface LocationInputProps {
  /** Unique ID of the step */
  id: string,
  locationPermissionService: LocationPermissionService,
}

/**
 * Asynchronous Google place drop down selection.
 * The user selects their location of interest using this component
 * @component
 * */
const LocationInput: FunctionComponent<LocationInputProps> = ({ id, locationPermissionService }) => {
  const classes = useStyles();
  const userEnteredLocation = useAppSelector(selectLocationInput);
  const [, send] = useActor(locationPermissionService);
  const [{ autoCompleteOptions }, { onChange, onSelection }] = useAutoCompletePredictions(PredictionType.LOCATION);

  /**
   * Provides the default value for the component.
   * If the user already entered one and refreshed the page or navigated back,
   * display that selection
   */
  const getDefaultValue = (): AutoCompleteOptions | undefined => {
    let defaultValue;
    if (userEnteredLocation) {
      defaultValue = { label: userEnteredLocation, value: userEnteredLocation };
    }
    return defaultValue;
  };

  // If the user refreshed the browser or it is already set, kick off the action for the location permissions
  useEffect(() => {
    if (userEnteredLocation) {
      send({ type: 'LOCATION_SET', payload: { location: userEnteredLocation } });
    }
  }, [send, userEnteredLocation]);

  return (

    <Autocomplete
      id={id}
      freeSolo
      filterSelectedOptions
      defaultValue={getDefaultValue()}
      options={autoCompleteOptions}
      classes={{ clearIndicator: classes.clearIndicator }}
      PopperComponent={(props) => (autoCompleteOptions.length > 0 ? <Popper {...props} style={popperStyle} /> : <></>)}
      PaperComponent={(props) => (autoCompleteOptions.length > 0 ? <Paper {...props} style={paperStyle} /> : <></>)}
      getOptionLabel={(option) => option.label}
      filterOptions={(x) => x} // need to add this to the component for it to work correctly
      onInputChange={userEnteredLocation !== '' && userEnteredLocation ? () => {} : onChange} // only fire the change if the user is actively making a change and not a page refresh
      onChange={(event, value) => {
        const input = preparePayloadFromAutoCompleteSelection(value);
        // Update redux if the value is valid
        onSelection(event, value);

        // Send out an action if appropriate to kick off the verification of permissions for the location
        if (input && input !== '') {
          send({ type: 'LOCATION_SET', payload: { location: input } });
        } else {
          send({ type: 'LOCATION_UNSET' });
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={location.subtitle}
          fullWidth
          variant="standard"
          style={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          InputProps={{
            ...params.InputProps,
            disableUnderline: true,
            classes: {
              root: classes.root,
              input: classes.input,
            },
          }}
        />
      )}
      // Formats how the list options are displayed
      renderOption={(option) => {
        const { label } = option;

        return (
          <Grid container direction="row" justifyContent="flex-start" alignItems="center" aria-label={label}>
            <Grid item>
              <LocationOnIcon className={classes.icon} />
            </Grid>
            <Grid
            item
            xs
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
              {label}
            </Grid>
          </Grid>
        );
      }}
    />

  );
};

export default LocationInput;
