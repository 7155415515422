import styled from 'styled-components';
import { $theme } from '@styleGuide';
import { Variant } from './LandingButton.comp';

export const Button = styled.button<{variant: Variant}>`
    width: 10.625rem;
    height: 3.5rem;

    font-size: 1.2rem;
    font-weight: 600;

    border: none;
    border-radius: 9px;
    color: white;
    background: ${({ variant }) => $theme(`palette.neon.${variant}.main`)};

    cursor: pointer;
`;
