import { createAsyncThunk } from '@reduxjs/toolkit';
import { Routes, getUserHomes } from 'beiytak_sdk';
import { getAPIUrl, formatUserSearchInput } from '@utils';
import {
  GetUserSearchResultsParams,
  GetUserSearchResults,
} from './types';

const GetUserSearchResultsAction = 'searchSession/getUserSearchResults';

const getUserSearchResults = createAsyncThunk(
  GetUserSearchResultsAction,
  async (searchData: GetUserSearchResultsParams): Promise<GetUserSearchResults> => {
    const { userSearch, navigate } = searchData;
    const url = getAPIUrl(Routes.GRAPHQL);
    const params = formatUserSearchInput(userSearch);

    /** payload to send back if the request wasn't successful */
    const failedRequest = {
      navigate,
    };

    /**
     * If the input is formatted and all the fields are complete,
     * then send the request.
     * @TODO i'm sure there is a cleaner way to do this
     */
    if (params) {
      const { data } = await getUserHomes({ params }, url);

      if (!data) { return failedRequest; }

      // Check if its an location permission error
      if (data && 'error' in data) {
        const locationPermissionError = data;
        return failedRequest;
      }

      // If its the actual results send it back
      if (data && !('error' in data)) {
        const result = {
          userSearchResults: data,
          navigate,
        };

        return result;
      }
    }

    return failedRequest;
  },
);

export { GetUserSearchResultsAction, getUserSearchResults };
