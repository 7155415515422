import { FC } from 'react';
import { theme } from '@styleGuide';
import * as S from './SectionInfo.styled';

interface SectionInfoProps {
    title: string,
    subtitle?: string,
}

const SectionInfo: FC<SectionInfoProps> = ({ title, subtitle }) => {
  return (
        <S.Container>
            <S.Title>{title}</S.Title>
            {
              subtitle
                ? <S.SubTitle>{subtitle}</S.SubTitle>
                : <></>
            }

        </S.Container>
  );
};

export default SectionInfo;
