import {
  createMachine, State, assign,
} from 'xstate';
import { createUser, CreateUserResult, Routes, TransportResponse } from 'beiytak_sdk';
import { getAPIUrl } from '@utils';

const url = getAPIUrl(Routes.SIGN_UP);

export interface SignUpUserPayload {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
}

interface Context {
    /** If the user started to fill out the form (ex. clicking join) */
    startedSignUp: boolean,
    /** If a post is being sent to sign up the user */
    isSigningUp: boolean,
    failedToSignUp: boolean,
    /** If the user officially signed up */
    isSignedUp: boolean,
    firstName: string | null,
    lastName: string | null,
    email: string | null,
}

type Events =
| {type: 'STARTED_SIGN_UP', payload: {email?: string}}
| {type: 'STOPPED_SIGN_UP'}
| {type: 'SIGN_UP', payload: SignUpUserPayload}

type Actions =
| {type: 'setStartedSignUp'}
| {type: 'setEmail'}
| {type: 'setEndedSignUp'}
| {type: 'setIsSigningUp'}
| {type: 'setNotSigningUp'}
| {type: 'setUserAsSignedUp'}
| {type: 'setFailedToSignUp'}

type Services =
{
    signUpUser: {
        // eslint-disable-next-line
        data: Awaited<TransportResponse<CreateUserResult>>
    }
}

export type SignUpUser = (payload: SignUpUserPayload) => Promise<CreateUserResult>

// eslint-disable-next-line
export const CreateAccountMachine =
/** @xstate-layout N4IgpgJg5mDOIC5QHcCGBLALgWgDbtkwDoA7Ae0wHUNMAZAzSAYgGUBJAcQDkB9AVQAKAbQAMAXUSgADmVhZ0ZEpJAAPRAEYAnAHYi6gCwiAbACYArABoQATw0BmTUTMBfV1fIQ4ytFjwNlMnKYCkpIqojYRla2CJFuID44+ISkFNRY9ISQAbLyispqCPom0RqG8Yl+KXJQJOgkUHxSOUEhBYh2JvpEInbq5qUIBiIVNFXEiZmMEC15oaCFZt3aAByaxgM2iCvqTq6uQA */
createMachine(
  {
    tsTypes: {} as import('./CreateAccount.machine.typegen').Typegen0,
    schema: {
      context: {} as Context,
      events: {} as Events,
      actions: {} as Actions,
      services: {} as Services,
    },
    id: 'create-account',
    initial: 'notSignedUp',
    context: {
      firstName: null,
      lastName: null,
      email: null,
      startedSignUp: false,
      isSigningUp: false,
      failedToSignUp: false,
      isSignedUp: false,
    },
    states: {
      notSignedUp: {
        on: {
          STARTED_SIGN_UP: {
            actions: ['setStartedSignUp', 'setEmail'],
          },
          SIGN_UP: {
            target: 'signingUp',
          },
          STOPPED_SIGN_UP: {
            actions: ['setStoppedSignUp'],
          },
        },
      },
      signingUp: {
        entry: ['setIsSigningUp'],
        invoke: {
          src: 'signUpUser',
          id: 'sign-up-user',
          onDone: [
            {
              target: 'signedUp',
              actions: ['setUserAsSignedUp'],
              cond: 'successfullyCreatedAccount',
            },
            {
              target: 'failedToSignUp',
              actions: ['setFailedToSignUp'],
              cond: 'failedToCreateAnAccount',
            },
          ],
          onError: {
            target: 'failedToSignUp',
          },
        },
        exit: ['setNotSigningUp'],
      },
      failedToSignUp: {
        always: [
          {
            target: 'notSignedUp',
            actions: ['setFailedToSignUp'],
          },
        ],
      },
      signedUp: {
        on: {
          STOPPED_SIGN_UP: {
            actions: ['setStoppedSignUp'],
          },
        },
      },
    },
  },
  {
    actions: {
      setStartedSignUp: assign({ startedSignUp: (ctx, event) => true }),
      setStoppedSignUp: assign({
        startedSignUp: (ctx, event) => false,
        isSignedUp: (ctx, event) => false,
        firstName: (ctx, event) => null,
        lastName: (ctx, event) => null,
        email: (ctx, event) => null,
        failedToSignUp: (ctx, event) => false,
      }),
      setEmail: assign((ctx, event) => {
        if (event.payload.email) { return { email: event.payload.email }; }

        return { email: null };
      }),
      setIsSigningUp: assign({ isSigningUp: (ctx, event) => true }),
      setNotSigningUp: assign({ isSigningUp: (ctx, event) => false }),
      setFailedToSignUp: assign({ failedToSignUp: (ctx, event) => true }),
      setUserAsSignedUp: assign({
        isSignedUp: (ctx, event) => true,
        failedToSignUp: (ctx, event) => false,
      }),
    },
    services: {
      signUpUser: async (ctx, event) => {
        const params = event.payload;
        return createUser({ params }, url);
      },
    },
    guards: {
      successfullyCreatedAccount: (ctx, event) => event.data.data !== undefined && !('error' in event.data.data),
      failedToCreateAnAccount: (ctx, event) => event.data.data !== undefined && ('error' in event.data.data),
    },
  },
);

export const selectIsSigningUpForAccount = (state: State<Context>) => state.context.isSigningUp;
export const selectIsSignedUpForAccount = (state: State<Context>) => state.context.isSignedUp;
export const selectedFailedToSignUpForAccount = (state: State<Context>) => state.context.failedToSignUp;
export const selectStartedSignUpForAccount = (state: State<Context>) => state.context.startedSignUp;
export const selectEmailForAccount = (state: State<Context>) => state.context.email;
