import { FunctionComponent } from 'react';
import { useAppDispatch, useAppSelector } from '@hooks';
import { selectTintParams, closeTint } from '@stores';
import useStyles from './Tint.styles';

/**
 * This component acts as a tint when a filter type component is selected.
 * It just sits on top of the other components in the background and adds a dark mask
 * so the filter like component thats selected is in focus for the user
 */
const Tint: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { display, styles, onClickAction } = useAppSelector(selectTintParams);

  // If the user clicks in the tint area,
  // dispatch the provided onClick action and close out any filter like component.
  // Example: When the tint is selected, dispatch an additional action to close the filter chip selected
  const handleOnClick = () => {
    if (onClickAction) { dispatch(onClickAction()); }
    dispatch(closeTint());
  };

  return (
    <div
      onClick={handleOnClick}
      role="presentation"
    >
      {display
        ? <div className={classes.tint} style={styles} />
        : <></>
      }
    </div>
  );
};

export default Tint;
