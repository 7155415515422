import { makeStyles } from '@material-ui/core/styles';
import { borderRadius } from 'react-select/src/theme';

const useStyles = makeStyles((theme) => ({

  chip: {

    backgroundColor: theme.palette.neutral.light,
    border: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '10px',
    height: '2rem',
    minWidth: '2rem',

    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',

    },

    '&:hover': {
      transform: 'scale(1.03)',
    },

  },

}));

export default useStyles;
