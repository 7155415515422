import { StepData, StepKeys } from '../types';

const location: StepData = {
  stepNumber: 1,
  id: StepKeys.LOCATION,
  title: 'Location',
  subtitle: "What's your area of interest?",
};

const homeCriteria: StepData = {
  stepNumber: 2,
  id: StepKeys.HOME_CRITERIA,
  title: 'Home Criteria',
  subtitle: 'What are you looking for?',
};

const userLocations: StepData = {
  stepNumber: 3,
  id: StepKeys.USER_LOCATIONS,
  title: 'Places of Interest',
  subtitle: "What's important to you?",
};

export {
  homeCriteria,
  location,
  userLocations,
};
