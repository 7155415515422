import styled from 'styled-components';
import { devices } from '@styleGuide';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
`;

export const Form = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

export const NameContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    max-height: 3.75rem;
`;

export const SubmitButton = styled.button`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 10.625rem;
    height: 3.5rem;

    font-size: 1.2rem;
    font-weight: 600;

    border: none;
    border-radius: 9px;
    color: white;
    background: #FF7348;

    cursor: pointer;

    &:hover {
        background: #cc5c3a;
    }
`;

export const AnimationContainer = styled.div`
    width: 6rem;
`;
