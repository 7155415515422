import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },

  'option-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    marginLeft: '0.75rem',

  },

  root: {

    padding: '0rem',
    marginRight: '0.2rem',

    '&.MuiCheckbox-colorSecondary': {
      '&:hover': {
        background: 'transparent',
      },
      '&.Mui-checked': {
        '&:hover': {
          background: 'transparent',
        },
      },
    },

  },

  label: {
    ...theme.typography.subtitle2,
    fontSize: '1rem',
    fontWeight: 200,
    lineHeight: '1.536rem',
  },
}));

export default useStyles;
