import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  container: {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    overflow: 'hidden',

    width: '50vw',
    maxWidth: '1060px',

    height: '15rem',
    maxHeight: '15rem',

    border: `1px solid ${theme.palette.neutral.dark}`,
    boxShadow: '0px 30px 20px rgba(0, 0, 0, 0.2)',
    borderRadius: '30px',
    background: theme.palette.neutral.light,

  },

  placeholder: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignContent: 'center',

    width: '100%',
    // This is to keep the container from resizing when locations are added
    paddingTop: '0.5rem',

  },

  'placeholder-text': {

    ...theme.typography.subtitle2,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',

    margin: '0.625rem',

    height: '2.5rem',
    maxHeight: '40px',

    // fontSize: '0.9rem',
    fontWeight: 300,
    color: '#888888',

  },

  'user-locations-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignContent: 'center',

    width: '91.5%',
    marginBottom: '0.5rem',
  },

  'input-container': {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',

    width: '100%',

    borderTop: `1px solid ${theme.palette.neutral.dark}`,
    borderBottom: `1px solid ${theme.palette.neutral.dark}`,

    paddingTop: '1.25rem',
    paddingBottom: '1.25rem',

  },

  'user-location-search-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    width: '35%',
    height: '3.25rem',

    border: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '5px',

    background: theme.palette.neutral.light,
  },

  'user-location-name-container': {

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',

    overflow: 'hidden',

    boxSizing: 'border-box',

    width: '20%',
    height: '3.25rem',

    background: theme.palette.neutral.light,

    border: `1px solid ${theme.palette.neutral.dark}`,
    borderRadius: '5px',

  },

  'travel-mode-container': {
    width: '23%',
    maxWidth: '12rem',
  },

  'add-container': {
    width: '15%',
    maxWidth: '6.25rem',
  },

  button: {
    alignSelf: 'flex-end',

    margin: '0.75rem 0.8rem 0.75rem 0rem',

    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.light,

    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },

  },

}));

export default useStyles;
