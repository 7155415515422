import { FunctionComponent, useEffect } from 'react';
import {
  Map,
  ResultsFilters,
  ResultsList,
  SaveSearch,
  TopNavBar,
  Tint,
  FreeTrialWarning,
} from '@components';
import { userLeavingResults } from '@stores';
import { useAppDispatch } from '@hooks';
import useStyles from './UserSearchResultsScreen.styles';

const UserSearchResultsScreen: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => { dispatch(userLeavingResults()); };
  }, []);

  return (
    <>
    <Tint />

    <FreeTrialWarning />

    <div className={classes.container}>

      <div className={classes['top-nav-bar']}>

        <TopNavBar variant='white'>

          <div className={classes['filters-container']}>
            <SaveSearch />

            <div className={classes.divider}/>

            <div className={classes['result-filters-container']}>
              <ResultsFilters />
            </div>

            <div className={classes.divider}/>

          </div>

        </TopNavBar>

      </div>

      <div className={classes.map}>
        <Map />
      </div>

      <div className={classes.listings}>
        <ResultsList />
      </div>

    </div>
    </>
  );
};

export default UserSearchResultsScreen;
