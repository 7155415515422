import { PetPolicy, Maybe } from 'beiytak_sdk';
import { getMoreFilterIcon } from '@utils';
import { ListingInfo } from '../types';

const parsePetPolicy = (petPolicy?: Maybe<PetPolicy>): ListingInfo => {
  const base = {
    title: 'Pet Policy',
    iconSource: getMoreFilterIcon('petPolicy'),
  };

  if (!petPolicy) { return { ...base, data: [] }; }

  const {
    cats, dogs, dogsLarge, dogsSmall,
  } = petPolicy;

  const info: ListingInfo = {
    ...base,
    data: [
      {
        label: 'Cats',
        value: cats ? 'Yes' : 'No',
      },
      {
        label: 'Dogs',
        value: dogs ? 'Yes' : 'No',
      },
      {
        label: 'Large Dogs',
        value: dogsLarge ? 'Yes' : 'No',
      },
      {
        label: 'Small Dogs',
        value: dogsSmall ? 'Yes' : 'No',
      },
    ],
  };

  return info;
};

export default parsePetPolicy;
