import React, { FC } from 'react';
import { CreateAccountService } from '@types';
import { useActor } from '@xstate/react';
import { theme } from '@styleGuide';
import { LandingButton, Feature } from '@components';
import * as S from './DemoAndDescription.styled';

interface DemoProps {
  variant: keyof typeof theme.palette.neon,
  gif?: string,
  video?: string,
  feature: string,
  title: string,
  description: string,
  createAccountService: CreateAccountService,
}

const DemoAndDescription: FC<DemoProps> = ({
  variant, gif, video, feature, title, description, createAccountService,
}) => {
  const [, send] = useActor(createAccountService);

  return (

      <S.Container {...{ variant }}>

          <S.FeaturesContainer>
            <S.Feature {...{ variant }}>
              {feature}
            </S.Feature>
          </S.FeaturesContainer>

        <S.DescriptionContainer>
          <S.Title>{title}</S.Title>
          <S.Description>{description}</S.Description>
        </S.DescriptionContainer>

        <S.DemoContainer>
          {
            gif
              ? <S.Gif src={gif} />
              : <></>
          }
          {
            video
              ? <S.Video src={video}/>
              : <></>
          }

        </S.DemoContainer>

       <S.JoinContainer>
          <LandingButton variant={variant} text='Join Now' onClick={() => send({ type: 'STARTED_SIGN_UP', payload: {} })} />
       </S.JoinContainer>

      </S.Container>
  );
};

export default React.memo(DemoAndDescription);
