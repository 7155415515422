import { makeStyles } from '@material-ui/core/styles';
import { topNavBarHeight } from '@constants';

const useStyles = makeStyles((theme) => ({

  container: {

    marginRight: '4rem',

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    borderBottom: '1px solid #E5E5E5',
  },

  'search-icon': {
    color: '#202020',
    marginRight: '0.625rem',
  },

  input: {
    '&::placeholder': {
      opacity: 0.5,
      color: '#202020',
    },
  },

}));

export default useStyles;
