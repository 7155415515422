import React from 'react';
import { ThemeProvider as ThemeProvideMUI } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { ThemeProvider } from 'styled-components';
import { SetupServerParams, logger } from 'beiytak_sdk';
import {
  BrowserRouter as Router, Route, Routes, Navigate,
} from 'react-router-dom';
import 'mapbox-gl/dist/mapbox-gl.css';
import config from '@config';
import { Routes as AppRoutes } from '@types';
import { Verify } from '@screens';
import { ProtectedRoute } from '@components';
import { SavedSearchesLayout, FavoritesLayout } from '@layouts';
import { theme as muiTheme } from '@constants';
import { store } from '@stores';
import { startMockApi, getAPIUrl } from '@utils';
import { RecoilRoot } from 'recoil';
import theme from '@styleGuide';
import { inspect } from '@xstate/inspect';
import { QueryClient, QueryClientProvider } from 'react-query';

const { NODE_ENV } = config;
let runMockServer = false;
if ('RUN_MOCK_SERVER' in config) {
  // eslint-disable-next-line
  // @ts-ignore
  runMockServer = config.RUN_MOCK_SERVER === 'true';
}

// Hide all the logs in production mode
// bunyan FATAL is 60 so + 1 will hide all the logs
if (NODE_ENV === 'production') {
  logger.level(60 + 1);
  runMockServer = false;
} else {
  // Only run xstate devtools in dev
  inspect({ iframe: false });
}

if (runMockServer) {
  const serverParams: SetupServerParams = {
    apiEndPoint: getAPIUrl(),
    mockEmail: 'testing@testing.com',
    mockPassword: 'testing',
    mockLocation: 'Houston, TX',
    mockUserLocations: ['Coffee Shop', 'Day Care'],
    autoCompleteCount: 5,
    listingCount: 5,
    delay: 1000,
  };

  startMockApi(serverParams);
}

const UserLoginScreen = React.lazy(() => import('./screens/UserLogin'));
const UserSearchScreen = React.lazy(() => import('./screens/UserSearch'));
const UserSearchResultsScreen = React.lazy(() => import('./screens/UserSearchResults'));
const DashboardScreen = React.lazy(() => import('./screens/Dashboard'));
const LoadingScreen = React.lazy(() => import('./screens/Loading'));
const RetryScreen = React.lazy(() => import('./screens/Retry'));
const ErrorScreen = React.lazy(() => import('./screens/Error'));
const LandingScreen = React.lazy(() => import('./screens/Landing'));
const ThankyouScreen = React.lazy(() => import('./screens/ThankYou'));

const persistor = persistStore(store);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

const App = () => {
  return (
      <Provider store={store}>
        <RecoilRoot>
          <PersistGate loading={null} persistor={persistor}>
            <QueryClientProvider client={queryClient} >
              <Router>
                <ThemeProvider theme={theme}>
                  <ThemeProvideMUI theme={muiTheme}>
                    <React.Suspense fallback={<></>}>
                      <Routes>
                        <Route path={AppRoutes.LANDING} element={<LandingScreen />} />
                        <Route path={AppRoutes.THANK_YOU} element={<ThankyouScreen />} />
                        <Route path={AppRoutes.VERIFY} element={<Verify />} />
                        <Route path={AppRoutes.LOGIN} element={<UserLoginScreen />} />
                        <Route path={AppRoutes.SEARCH} element={
                            <ProtectedRoute>
                              <UserSearchScreen />
                            </ProtectedRoute>
                          }
                        />
                        <Route path={AppRoutes.RESULTS} element={
                            <ProtectedRoute>
                              <UserSearchResultsScreen />
                            </ProtectedRoute>
                          }
                        />
                        <Route path={AppRoutes.DASHBOARD} element={
                            <ProtectedRoute>
                              <DashboardScreen />
                            </ProtectedRoute>
                          }>
                            <Route index element={<SavedSearchesLayout />} />
                            <Route path="saved-searches" element={<SavedSearchesLayout />}/>
                            <Route path="favorites" element={<FavoritesLayout />}/>
                        </Route>
                        <Route path={AppRoutes.LOADING} element={<LoadingScreen />} />
                        <Route path={AppRoutes.ERROR} element={<ErrorScreen/>} />
                        <Route path={AppRoutes.RETRY} element={<RetryScreen/>} />
                        <Route element={() => <Navigate to={AppRoutes.LANDING} />} />
                      </Routes>
                    </React.Suspense>
                  </ThemeProvideMUI>
                </ThemeProvider>
              </Router>
            </QueryClientProvider>
          </PersistGate>
        </RecoilRoot>
      </Provider>
  );
};

export default App;
