import { FunctionComponent, useEffect, useState } from 'react';
import {
  ListingDetails, UserLocationInfo,
  SavedSearch,
} from 'beiytak_sdk';
import { useAppDispatch } from '@hooks';
import { updatingSelectedListingFromSavedSearchDataTable, updatingSavedSearchForListingSelected, userRequestingToGoToSavedSearch } from '@stores';
import clsx from 'clsx';
import Favorite from '@material-ui/icons/Favorite';
import {
  DataGrid, GridColDef, GridRowData, GridRowParams,
} from '@material-ui/data-grid';
import { createPriceLabel, getTravelModeIcon, createCommuteLabel } from '@utils';
import { PermissionProtectedSearch } from '@components';
import { useNavigate } from 'react-router';
import { LocationPermissionService } from '@types';
import { LocationPermissionMachine } from '@machines';
import { useMachine } from '@xstate/react';
import { SearchDataTableRow, AddressCellData, AddressGridCell } from './types';
import imagePlaceholder from '../../../../assets/image_placeholder.png';
import GoToSearch from './GoToSearch';
import NoFavorites from './NoFavorites';
import useStyles from './SearchDataTable.styles';

interface SearchDataTableProps {
  savedSearch: SavedSearch,
  listingDetails: ListingDetails[]
}

const SearchDataTable: FunctionComponent<SearchDataTableProps> = ({ savedSearch, listingDetails }) => {
  const dispatch = useAppDispatch();
  const [, send, locationPermissionService] = useMachine(LocationPermissionMachine);
  const classes = useStyles();
  const [selectedRow, setSelectedRow] = useState<string | null>(null);
  const navigate = useNavigate();
  const { input: { type, location }, searchId } = savedSearch;

  // using the first favorite as a reference to create the columns
  // if no favorites are available then just passing an empty array
  const userLocations = listingDetails.length > 0 ? listingDetails[0].userLocations : [];
  /** Creates the columns needed for the table */
  const createColumns = (userLocations: UserLocationInfo[]): GridColDef[] => {
    const baseColumns: GridColDef[] = [
      {
        field: 'id',
        headerName: 'id',
        headerAlign: 'center',
        hide: true,
        align: 'center',
      },
      {
        field: 'address',
        headerName: 'Address',
        headerAlign: 'center',
        sortable: true,
        width: 300,
        align: 'left',
        renderCell: (params) => {
          // In order to make TS happy and it nows that the value of params here is an object
          const addressGridCell = params as AddressGridCell;
          const { address, photo } = addressGridCell.value;
          return (
            typeof params.value === 'object'
              ? (
              <div className={classes['address-cell']}>
                <Favorite classes={{ root: classes['favorite-icon'] }}/>
                <img className={classes.image} src={photo} alt=""/>
                <span>{address}</span>
              </div>
              )
              : <></>
          );
        },
      },
      {
        field: 'price',
        headerName: 'Price',
        headerAlign: 'center',
        sortable: true,
        width: 150,
        align: 'center',
        valueFormatter: ((params) => {
          const price = params.value;

          if (price === null || price === undefined) { return '-'; }
          return createPriceLabel(Number(params.value), type);
        }),
      },
      {
        field: 'bedrooms',
        headerName: 'Beds',
        headerAlign: 'center',
        sortable: true,
        width: 125,
        align: 'center',
      },
      {
        field: 'bathrooms',
        headerName: 'Baths',
        headerAlign: 'center',
        sortable: true,
        width: 125,
        align: 'center',
      },
    ];

    const userLocationColumns: GridColDef[] = userLocations.map((userLocation) => {
      const { userAlias, travelMode } = userLocation;
      const TravelModeIcon = getTravelModeIcon(travelMode);

      const gridCol: GridColDef = {
        field: userAlias,
        headerName: userAlias,
        headerAlign: 'center',
        sortable: true,
        width: 175,
        align: 'center',
        renderHeader: ((params) => {
          return (
            <div className={classes['user-location-column-container']}>
              <TravelModeIcon className={classes['travel-icon']}/>
              {userAlias}
            </div>
          );
        }),
        valueFormatter: ((params) => `${createCommuteLabel(Number(params.value), 'include')}`),
      };

      return gridCol;
    });

    const columns = [...baseColumns, ...userLocationColumns];

    return columns;
  };

  /** Creates the rows for the data table based on the data provided */
  const createRows = (listings: ListingDetails[]): GridRowData[] => {
    const rows = listings.map((listingDetail) => {
      const { listing, userLocations } = listingDetail;
      const {
        type, address, price, bedrooms, bathrooms, bathsFull, photos,
      } = listing;

      const addressCellData: AddressCellData = {
        address,
        photo: photos && photos[0] ? photos[0] : imagePlaceholder,
      };

      const baseCells: SearchDataTableRow = {
        id: address,
        address: addressCellData,
        price,
        bedrooms,
        bathrooms: bathrooms || bathsFull,
      };

      const userLocationCells: SearchDataTableRow = {};

      userLocations.forEach((userLocation) => {
        const { userAlias, commute } = userLocation;
        userLocationCells[userAlias] = commute;
      });

      const row = { ...baseCells, ...userLocationCells };

      return row;
    });

    return rows;
  };

  /** When the user clicks on a row, dispatch action so that the listing card displays */
  const onRowClick = (params: GridRowParams) => {
    const { address } = params.row.address;

    // If the user clicks on the same row, unselect it
    if (address === selectedRow) {
      setSelectedRow(null);
      dispatch(updatingSelectedListingFromSavedSearchDataTable(null));
      dispatch(updatingSavedSearchForListingSelected(null));
    }

    // If the user clicks on a different row or for the first time, display the listing details
    if (address && (!selectedRow || address !== selectedRow)) {
      setSelectedRow(address);
      dispatch(updatingSelectedListingFromSavedSearchDataTable(address));
      dispatch(updatingSavedSearchForListingSelected(searchId));
    }
  };

  /** If the user has access to the location, allow them to go to the search */
  const onVerified = () => dispatch(userRequestingToGoToSavedSearch({ savedSearch, navigate }));

  const columns = createColumns(userLocations);
  const rows = createRows(listingDetails);
  const tableHeight = rows.length > 5 ? '20rem' : `${rows.length * 4.5 + 5}rem`;

  /** When the user expands out the saved search to see the table, set the location in case they plan to do the full search */
  useEffect(() => {
    send({ type: 'LOCATION_SET', payload: { location } });
  }, [send, location]);

  return (
    <div className={classes.container}>
      <DataGrid
      classes={{
        root: clsx({
          [classes.root]: true,
          'Mui-selected': false,
        }),
        columnHeader: classes['column-header'],
        row: classes.row,
        cell: classes.cell,
      }}
      style={{
        height: tableHeight,
      }}
      components={{
        NoRowsOverlay: () => <NoFavorites />,
      }}
      rows={rows}
      onRowClick={onRowClick}
      columns={columns}
      disableColumnFilter
      disableColumnMenu
      disableColumnSelector
      disableDensitySelector
      hideFooter
      hideFooterPagination
      hideFooterRowCount
      hideFooterSelectedRowCount
      />

      <div className={classes['go-to-search-container']}>
        <PermissionProtectedSearch {...{ variant: 'rectangle', locationPermissionService, text: 'Go to Search', allowClick: true, onVerified }}/>
      </div>
    </div>
  );
};

export default SearchDataTable;
