import styled from 'styled-components';
import { devices, $theme } from '@styleGuide';

export const Container = styled.div`

    display: grid;
    grid-template-columns: auto;
    grid-template-areas:
    "section-info"
    "pricing"
    ;
    place-items: center;
    gap: 1rem;

    width: calc(100vw - 10rem);
    margin-left: 5rem;

    padding-top: 1rem;
    padding-bottom: 1rem;

    border-radius: 19px;
    background: ${$theme('palette.pastel.orange.light')};

    padding-left: 1rem;
    padding-right: 1rem;


    @media (${devices.tabletAndBelow}) {
        gap: 1.5rem;

        margin-left: 1rem;

        width: calc(100vw - 4rem);
        height:  max-content;
    }
`;

export const SectionInfoContainer = styled.div`
    grid-area: section-info;
`;

export const PlansContainer = styled.div`
    grid-area: pricing;
    place-items: center;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    gap: 1rem;

    width: 100%;
    height: 100%;

    @media (${devices.tabletAndBelow}) {
       display: grid;
       grid-gap: 1rem;
    }
`;
