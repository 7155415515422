/**
 * Helper function to parse out the user selected value from the
 * mui autocomplete component as it can come back in a number of forms
 */
function preparePayloadFromAutoCompleteSelection(input: any): string {
  let payload = null;

  // If the user deleted the selection or is blank on start, pass undefined
  if (input == null || input === '') { payload = ''; }

  // If mui passes an object
  if (typeof input === 'object' && input !== null) { payload = input.value; }

  // if the component returns the string, use that as the payload
  if (typeof input === 'string') { payload = input; }

  return payload;
}

export default preparePayloadFromAutoCompleteSelection;
