import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import {
  configureStore,
  combineReducers,
} from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import {
  handlingAuthAndNavigationMiddleware,
  creatingOrUpdatingASearchMiddleware,
  interactingWithSearchResultDataMiddleware,
  navigatingToTheDashboardMiddleware,
  handlingUserSearchResultsMiddleware,
  interactingWithSavedSearchesMiddleware,
  interactingWithFavoritesInTheDashboardMiddleware,
  handlingRequestToSaveOrUpdateSearchMiddleware,
  navigatingToStartANewSearchMiddleware,
  navigatingToUpdateSearchMiddleware,
  navigatingToSearchResultsMiddleware,
  tintControlsMiddleware,
  leavingSearchResultsMiddleware,
  leavingFavoritesMiddleware,
} from './middleware';
import {
  authReducer,
  homeCriteriaInputReducer,
  locationInputReducer,
  navigationReducer,
  savedSearchesFiltersReducer,
  savedSearchReducer,
  searchResultFavoritesReducer,
  searchResultFiltersReducer,
  searchResultSessionReducer,
  searchResultSortByReducer,
  searchSessionReducer,
  tintReducer,
  userLocationsInputReducer,
  userSearchResultReducer,
  typeInputReducer,
  authIgnoreSerializableCheck,
  tintIgnoreSerializableCheck,
  searchSessionIgnoreSerializableCheck,
} from './slices';

// Combine reducers
const reducers = combineReducers({
  auth: authReducer,
  navigation: navigationReducer,
  typeInput: typeInputReducer,
  locationInput: locationInputReducer,
  homeCriteriaInput: homeCriteriaInputReducer,
  userLocationsInput: userLocationsInputReducer,
  searchSession: searchSessionReducer,
  searchResultFilters: searchResultFiltersReducer,
  searchResultFavorites: searchResultFavoritesReducer,
  searchResultSortBy: searchResultSortByReducer,
  searchResultSession: searchResultSessionReducer,
  savedSearches: savedSearchReducer,
  savedSearchesFilters: savedSearchesFiltersReducer,
  userSearchResult: userSearchResultReducer,
  tint: tintReducer,
});

// Set up persistence of state
const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  blacklist: ['auth', 'navigation'],
};

const persistedReducer = persistReducer(persistConfig, reducers);

/**
 * Helper function to create a store.
 * This is useful during testing so that a new store is created for reach test block
 */
const createStore = () => {
  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, // Related to redux-persist
          ...authIgnoreSerializableCheck,
          ...searchSessionIgnoreSerializableCheck,
          ...tintIgnoreSerializableCheck,
        ],
      },
    }).concat(
      handlingAuthAndNavigationMiddleware,
      creatingOrUpdatingASearchMiddleware,
      handlingUserSearchResultsMiddleware,
      handlingRequestToSaveOrUpdateSearchMiddleware,
      navigatingToSearchResultsMiddleware,
      leavingSearchResultsMiddleware,
      leavingFavoritesMiddleware,
      navigatingToStartANewSearchMiddleware,
      navigatingToUpdateSearchMiddleware,
      navigatingToTheDashboardMiddleware,
      interactingWithSearchResultDataMiddleware,
      interactingWithFavoritesInTheDashboardMiddleware,
      interactingWithSavedSearchesMiddleware,
      tintControlsMiddleware,
    ),
  });

  return store;
};

// Create the store
export const store = createStore();

// Had to change the reference here to avoid circular reference warning.
// I wanted to make sure the custom middleware was type checked
// Github issue: https://github.com/reduxjs/redux-toolkit/issues/324
// export type RootState = ReturnType<typeof store.getState>;
export type RootState = ReturnType<typeof persistedReducer>;

export type AppDispatch = typeof store.dispatch;

export * from './selectors';
export * from './slices';

export { createStore };
