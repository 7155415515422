import { FunctionComponent, useEffect, useState } from 'react';
import { Routes } from '@types';
import FavoriteIcon from '@material-ui/icons/Favorite';
import { useNavigate } from 'react-router';
import { ActionCreatorWithoutPayload } from '@reduxjs/toolkit';
import { useAppDispatch } from '@hooks';
import * as S from './NavigationOption.styled';

interface NavigationOptionProps {
    src: string,
    hoverText: string,
    route: Routes,
    action?: ActionCreatorWithoutPayload,
}

const NavigationOption: FunctionComponent<NavigationOptionProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [hovered, setHovered] = useState(false);
  const {
    src, hoverText, route, action,
  } = props;
  const handleOnHover = () => { setHovered(!hovered); };

  const handelOnClick = () => {
    if (action) { dispatch(action()); }
    navigate(route);
  };

  /** Make sure that when the option is not on the page, remove any tooltips */
  useEffect(() => {
    // On initial render, don't show it the tooltip if the user had his cursor on the icon/area
    setHovered(false);

    return (() => {
      setHovered(false);
    });
  }, []);

  return (
    <S.Container
        onMouseEnter={handleOnHover}
        onMouseLeave={handleOnHover}
        aria-label={hoverText}
    >
        <S.NavOptionIcon src={src} onClick={handelOnClick}/>
        {hovered
          ? <S.Tooltip>
                {hoverText}
            </S.Tooltip>
          : <></>
        }
    </S.Container>

  );
};

export default NavigationOption;
