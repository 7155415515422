import { FunctionComponent } from 'react';
import {
  LandingLayout, FeatureLayout, PlatformPricingLayout,
  JourneyLayout, CreateAccountLayout,
  UseCasesLayout, ReportPricingLayout,
  ReportLayout,
} from '@layouts';
import { TopNavBar } from '@components';
import { useMachine, useSelector } from '@xstate/react';
import { CreateAccountMachine, selectStartedSignUpForAccount } from '@machines';
import * as S from './LandingScreen.styled';

const Landing: FunctionComponent = () => {
  const [, , createAccountService] = useMachine(CreateAccountMachine);
  const startedSignUp = useSelector(createAccountService, selectStartedSignUpForAccount);

  return (
      <S.Container>
          {
            startedSignUp
              ? <CreateAccountLayout {...{ createAccountService }}/>
              : <>
                  <TopNavBar variant='white'/>
                  <LandingLayout {...{ createAccountService }}/>
                  <UseCasesLayout {...{ createAccountService }}/>
                  <S.SectionBreak >
                    Simple, Intuitive, Powerful
                  </S.SectionBreak>
                  <FeatureLayout {...{ createAccountService }}/>
                  <S.SectionBreak >
                    Let Findur be your Real Estate Assistant
                  </S.SectionBreak>
                  <ReportLayout {...{ createAccountService }}/>
                  <S.Break />
                  <JourneyLayout {...{ createAccountService }}/>
                  <S.Break />
                </>
          }
      </S.Container>

  );
};

export default Landing;
