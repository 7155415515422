import { makeStyles } from '@material-ui/core/styles';

const marginLeftFromImages = '1.75rem';

const useStyles = makeStyles((theme) => ({

  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',

    marginTop: '0.25rem',
    marginBottom: '1.5rem',
    marginLeft: marginLeftFromImages,

    width: '100%',

    fontSize: '0.875rem',
    color: '#202020',

  },

  price: {

    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',

    width: '100%',
    height: '1.6875rem',
    marginTop: '0.625rem',

    marginBottom: '0.25rem',

    fontSize: '1.25rem',
    fontWeight: 'bold',
  },

  address: {

    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    width: '95%',

    marginTop: '0.5rem',
    marginBottom: '0.75rem',

    color: '#888888',
    fontSize: '0.85rem',
    lineHeight: '1.1875rem',

  },

}));

export default useStyles;
