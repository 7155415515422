import { Middleware, unwrapResult, ThunkDispatch } from '@reduxjs/toolkit';
import { pick } from 'lodash';
import {
  RootState,
  selectedListingIsChanging,
  updatingHomeCriteriaResultsFilter,
  updatingUserLocationsToDisplay,
  updatingUserLocationsResultsFilter,
  filteringListingsToDisplay,
  sortByIsChanging,
  sortingListingsToDisplay,
  userRequestingToDisplayFavoritesOnly,
  userRequestingToDisplayAllListings,
  updatingListingsToDisplay,
  removingListingFromFavorites,
  userExpandingListing,
  userCollapsingListing,
  listingBeingHovered,
  listingNoLongerBeingHovered,
  userWantsToUpdateSearch,
  selectedFilterChipIsChanging,
  updatingMoreResultsFiltersForHomeCriteria,
  updatingMoreResultsFiltersForStyles,
  updatingMoreResultsFiltersForFeatures,
  getMoreListingPhotos,
  addMorePhotosToListing,
  userScrollingThroughPhotos,
  resetAllSearchResultFilters,
  setUserLocationsResultsFilter,
  addingListingToFavorites,
  updatingSavedSearchFromSearchResultSession,
  updatingDataForListingExpanded,
  resetMoreFilters,
} from '@stores';
import { Routes, Df, HomeCriteriaKeys } from '@types';

// eslint-disable-next-line
const interactingWithSearchResultDataMiddleware: Middleware<{}, RootState, ThunkDispatch<any, any, any>> = ((storeAPI) => (next) => (action) => {
  /**
   * Resets all the filters based on the user search results being analyzed by the user.
   * This is used when:
   *  - A new search is made
   *  - The user navigates to their favorites in the dashboard
   *  - The user updates what favorites to view form specific saved searches
  */
  if (resetAllSearchResultFilters.match(action)) {
    const typeInput = storeAPI.getState().typeInput.value;
    const homeCriteriaInput = storeAPI.getState().homeCriteriaInput.value;
    const pricePayload = { homeCriteriaKey: HomeCriteriaKeys.PRICE, filterValue: homeCriteriaInput[HomeCriteriaKeys.PRICE] };
    const bathroomPayload = { homeCriteriaKey: HomeCriteriaKeys.BATHROOMS, filterValue: homeCriteriaInput[HomeCriteriaKeys.BATHROOMS] };
    const bedroomsPayload = { homeCriteriaKey: HomeCriteriaKeys.BEDROOMS, filterValue: homeCriteriaInput[HomeCriteriaKeys.BEDROOMS] };

    storeAPI.dispatch(updatingHomeCriteriaResultsFilter(pricePayload));
    storeAPI.dispatch(updatingHomeCriteriaResultsFilter(bathroomPayload));
    storeAPI.dispatch(updatingHomeCriteriaResultsFilter(bedroomsPayload));

    storeAPI.dispatch(resetMoreFilters(typeInput));

    // Initialize the user locations & more filter
    const { userLocationFilterData } = storeAPI.getState().searchResultSession;
    if (userLocationFilterData) { storeAPI.dispatch(setUserLocationsResultsFilter(userLocationFilterData)); }
  }
  /**
     * It the user changed the listing selection, update the session state
     * and also update which user locations should be displayed (if any)
     */
  if (selectedListingIsChanging.match(action)) {
    next(action);
    const { listingSelected } = storeAPI.getState().searchResultSession;
    return storeAPI.dispatch(updatingUserLocationsToDisplay(listingSelected));
  }

  /**
     * When the user filters the listings returned by the search:
     * 1. Update the filter values in state
     * 2. Then filter out the listings accordingly
     */
  if (updatingHomeCriteriaResultsFilter.match(action)
     || updatingUserLocationsResultsFilter.match(action)
     || updatingMoreResultsFiltersForHomeCriteria.match(action)
     || updatingMoreResultsFiltersForStyles.match(action)
     || updatingMoreResultsFiltersForFeatures.match(action)
  ) {
    const { filterChipSelected: currentFilter, userSearchResults } = storeAPI.getState().searchResultSession;

    // Only filter the listings after the user closed out of the filter
    if (!currentFilter && userSearchResults) {
      // @deprecated for now - Since users can update the search value manually from the filters, we need to make sure the values they entered
      // stay within the bounds of the original search input.
      // If not, it may make it seem to the user that we will re-fetch data for that price range when its not the case
      // if (updatingHomeCriteriaResultsFilter.match(action)) {
      //   const { payload } = action;
      //   const { homeCriteriaKey, filterValue } = payload;
      //   const userSearchInput = storeAPI.getState().homeCriteriaInput.value[homeCriteriaKey];

      //   const minUserValue = filterValue[0];
      //   const maxUserValue = filterValue[1];
      //   const minSearchValue = userSearchInput[0];
      //   const maxSearchValue = userSearchInput[1];

      //   if ((minUserValue >= minSearchValue) && (maxUserValue <= maxSearchValue)) {
      //     next(action);
      //   }
      // } else {
      //   // If its a different action than the home criteria filter, then allow the user to change it
      //   next(action);
      // }
      next(action);

      let dataToFilter: Df = userSearchResults;
      const { displayFavoritesOnly, sortBy } = storeAPI.getState().searchResultSession;

      if (displayFavoritesOnly) {
        const { favoriteListings } = storeAPI.getState().searchResultFavorites;
        dataToFilter = pick(userSearchResults, favoriteListings);
      }

      // Filter the listings
      storeAPI.dispatch(filteringListingsToDisplay(dataToFilter));
      const { filteredListings } = storeAPI.getState().searchResultFilters;

      // Keep the same sorting
      storeAPI.dispatch(sortingListingsToDisplay({ sortByKey: sortBy, listings: pick(userSearchResults, filteredListings) }));
      const { sortedListings } = storeAPI.getState().searchResultSortBy;
      return storeAPI.dispatch(updatingListingsToDisplay(sortedListings));
    }
  }

  /**
     * Update listings to display to only the favorites
     */
  if (userRequestingToDisplayFavoritesOnly.match(action)) {
    next(action);
    const { sortBy, userSearchResults } = storeAPI.getState().searchResultSession;

    if (userSearchResults) {
      const { favoriteListings } = storeAPI.getState().searchResultFavorites;
      storeAPI.dispatch(sortingListingsToDisplay({ sortByKey: sortBy, listings: pick(userSearchResults, favoriteListings) }));

      // Make sure to keep the save filtering
      const { sortedListings: sortedFavorites } = storeAPI.getState().searchResultSortBy;

      return storeAPI.dispatch(updatingListingsToDisplay(sortedFavorites));
    }
  }

  /**
     * If the user wants to display all listings,
     * filter through and display them.
     */
  if (userRequestingToDisplayAllListings.match(action)) {
    next(action);
    const { sortBy, userSearchResults } = storeAPI.getState().searchResultSession;

    if (userSearchResults) {
      storeAPI.dispatch(filteringListingsToDisplay(userSearchResults));
      const { filteredListings } = storeAPI.getState().searchResultFilters;

      storeAPI.dispatch(sortingListingsToDisplay({ sortByKey: sortBy, listings: pick(userSearchResults, filteredListings) }));
      const { sortedListings } = storeAPI.getState().searchResultSortBy;

      return storeAPI.dispatch(updatingListingsToDisplay(sortedListings));
    }
  }

  /**
     * If the user removes a favorite listing while viewing favorites only,
     * Update the listings to display the the list is updated correctly
     */
  if (removingListingFromFavorites.match(action)) {
    const { displayFavoritesOnly } = storeAPI.getState().searchResultSession;

    next(action);

    if (displayFavoritesOnly) {
      const { favoriteListings } = storeAPI.getState().searchResultFavorites;

      // If the listing being removed is the last one in the list
      // Set the selected state to null in case the user clicked on it before removing it
      if (favoriteListings.length === 0) {
        storeAPI.dispatch(selectedListingIsChanging(null));
      }

      storeAPI.dispatch(updatingSavedSearchFromSearchResultSession());
      return storeAPI.dispatch(updatingListingsToDisplay(favoriteListings));
    }

    // If in the normal view, just updated the search result
    if (!displayFavoritesOnly) {
      return storeAPI.dispatch(updatingSavedSearchFromSearchResultSession());
    }
  }

  /**
   * If the user is added or removing favorites for a saved search result, update the saved search with the updated favorite selections
   */
  if (addingListingToFavorites.match(action)) {
    next(action);
    return storeAPI.dispatch(updatingSavedSearchFromSearchResultSession());
  }

  if (sortByIsChanging.match(action)) {
    next(action);

    const { sortBy, userSearchResults, listingsToDisplay } = storeAPI.getState().searchResultSession;

    if (userSearchResults && listingsToDisplay) {
      const listings = pick(userSearchResults, listingsToDisplay);

      storeAPI.dispatch(sortingListingsToDisplay({ sortByKey: sortBy, listings }));

      const { sortedListings } = storeAPI.getState().searchResultSortBy;
      return storeAPI.dispatch(updatingListingsToDisplay(sortedListings));
    }
  }

  /**
     * When the user expands a listing, update the state with the listings data
     * and also dispatch an action to get more listing photos
     */
  if (userExpandingListing.match(action)) {
    const { payload: listingID } = action;

    return storeAPI.dispatch(updatingDataForListingExpanded(listingID));
  }

  if (userScrollingThroughPhotos.match(action)) {
    const { payload: listingID } = action;

    if (typeof storeAPI.getState().searchResultSession.userSearchResults === 'object'
      // @ts-ignore already making sure its an object
      && storeAPI.getState().searchResultSession.userSearchResults[listingID]) {
      // @ts-ignore Already checking to make sure its an object in in the if statement
      const { listing } = storeAPI.getState().searchResultSession.userSearchResults[listingID];
      const { propertyId, photos } = listing;

      // Only fetch results if there are a few photos
      if (photos && photos.length < 5) { return storeAPI.dispatch(getMoreListingPhotos({ listingID, propertyId })); }

      return next(action);
    }

    next(action);
  }

  /** Dispatches action to update state when the additional photos are retrieved */
  if (getMoreListingPhotos.fulfilled.match(action)) {
    const { listingID, photos } = unwrapResult(action);
    if (photos.length > 1) { storeAPI.dispatch(addMorePhotosToListing({ listingID, photos })); }
  }

  /**
   * When the user closes an expanded listing, clear out the data
   */
  if (userCollapsingListing.match(action)) {
    return storeAPI.dispatch(updatingDataForListingExpanded(null));
  }

  /**
    * This handles the case where the user hovered over a listing, moved away,
    * and the hovered over the same listing again.
    * The state when the user will end the over will be equal to the previous
    * so in other words previousListingHovered will be the same value
    * so no action will fire off to correctly re-render the marker.
    * This will null the state so the action fires off correctly
    */
  if (listingBeingHovered.match(action) || listingNoLongerBeingHovered.match(action)) {
    const { payload: listingID } = action;

    if (listingID === storeAPI.getState().searchResultSession.previousListingHovered) {
      storeAPI.dispatch(listingNoLongerBeingHovered(null));
    }

    return next(action);
  }

  /**
     * If the user wants to update their search, clear our the session data
     * in prep for new results
     */
  if (userWantsToUpdateSearch.match(action)) {
    const { payload: navigate } = action;

    // Hide all the filters again
    storeAPI.dispatch(selectedFilterChipIsChanging({ filterChipID: null }));

    // Un-select if a listing is selected
    storeAPI.dispatch(selectedListingIsChanging(null));

    // Push the user to the search page
    return navigate(Routes.SEARCH);
  }

  return next(action);
});

export default interactingWithSearchResultDataMiddleware;
