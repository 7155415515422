import { FunctionComponent } from 'react';
import Chip from '@material-ui/core/Chip';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useAppDispatch, useAppSelector } from '@hooks';
import { userLocationRemoved, selectUserLocationsInput } from '@stores';
import { UserLocation } from '@types';
import useStyles from './Chips.styles';

/**
 * Chip that represents a location the user entered
 * The user can remove the chip as well
 * @component
 */
const Chips: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const userLocations = useAppSelector(selectUserLocationsInput);

  /** userLocations
   * Removes the location from the list if the chip is deleted
   * @function
   * @param userLocation The location thats being removed
   */
  const handleOnDelete = (userLocation: UserLocation) => {
    dispatch(userLocationRemoved(userLocation));
  };

  /**
   * Generates a list of chip components to be rendered based on the number of user locations
   * @function
   */
  const chipComponents = userLocations.map((userLocationData) => {
    const { userLocation, userAlias } = userLocationData;
    return (
      <Chip
        variant="outlined"
        clickable={false}
        key={userLocation}
        label={userAlias}
        onDelete={() => handleOnDelete(userLocationData)}
        deleteIcon={<HighlightOffIcon />}
        classes={{
          root: classes.chip,
          deleteIcon: classes.deleteIcon,
        }}
      />
    );
  });

  return (
    <div className={classes.container}>
      { chipComponents }
    </div>
  );
};

export default Chips;
