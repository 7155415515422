import relocating from '../assets/relocating.png';
import innerCity from '../assets/inner-city-move.png';
import investment from '../assets/investment-search.png';

export const relocatingUseCase = {
  title: 'Relocating To A New Area',
  description: 'Moving the family to a new state where you need to be close to a school, day care, and have a manageable commute to work.',
  icon: relocating,
};

export const innerCityUseCase = {
  title: 'Inner City Move',
  description: 'Wanting to keep the same amenities nearby like a gym when moving to a home rental or different apartment.',
  icon: innerCity,
};

export const investmentUseCase = {
  title: 'Investment Property',
  description: 'Spot under valued homes in areas with great surrounding amenities and shops.',
  icon: investment,
};
