import { StepLinesToHide, StepKeys } from '@types';

/** Logic on which lines should be hidden when the user hovers or clicks a specific step */
const whichLinesToHide = (selectedStep: string | null, hoveredStep: string | null): StepLinesToHide => {
  let hideLineOne = false;
  let hideLineTwo = false;
  let hideLineThree = false;

  if (selectedStep === StepKeys.LOCATION || hoveredStep === StepKeys.LOCATION) {
    hideLineOne = true;
  }

  if (selectedStep === StepKeys.HOME_CRITERIA || hoveredStep === StepKeys.HOME_CRITERIA) {
    hideLineOne = true;
    hideLineTwo = true;
  }

  if (selectedStep === StepKeys.USER_LOCATIONS || hoveredStep === StepKeys.USER_LOCATIONS) {
    hideLineTwo = true;
    hideLineThree = true;
  }

  return { hideLineOne, hideLineTwo, hideLineThree };
};

export default whichLinesToHide;
