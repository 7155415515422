import { ListingDetails, SavedSearch } from 'beiytak_sdk';
import {
  UserLocationFilterData,
  Row, Df, LngLat, BoundingBoxResults, FilterValue,
  UserLocationTravelModes,
} from '@types';
import { getBoundingBox } from '@utils';

/**
 * This processes the results from the backend and extracts key information.
 *  1. Formats the user search results so the data can be easily filtered.
 *     The data structure can be viewed as a table like format.
 *  2. Create the bounding box for each listing so that it can be used on the map
 *     when the user selects a listing
 *  3. Create the user location commute filter data by determining the min and max commute time for each location
 * @function
 */
const formatUserSearchResults = (
  /** The results returned for the user search */
  userSearchResults?: ListingDetails[],
  /** saved search associated to grab the users favorites and append them to the results if need be */
  savedSearchFavorites?: ListingDetails[],
): [Df, BoundingBoxResults, UserLocationFilterData, UserLocationTravelModes] => {
  let listings: ListingDetails[] = [];
  let boundingBoxResults: BoundingBoxResults;

  const df: Df = {};
  const boundingBoxData: LngLat[] = [];
  const userLocationFilterData: UserLocationFilterData = {};
  const userLocationTravelModes: UserLocationTravelModes = {};
  const allUserLocations: FilterValue = [0, 0];

  /** if a saved search is supplied, append the listing details to the results */
  if (userSearchResults) { listings = [...listings, ...userSearchResults]; }
  if (savedSearchFavorites) { listings = [...listings, ...savedSearchFavorites]; }

  // Loop through each of the results returned by the backend
  listings.forEach((listingDetails, index) => {
    const { listing, userLocations } = listingDetails;
    const {
      coordinates, address: id,
    } = listing;

    /** Check to make sure the listing has not been evaluated yet */
    if (!df[id]) {
      // Save out the coordinates of the listing for later use
      boundingBoxData.push([coordinates.lng, coordinates.lat]);

      // Acts as a row in a data frame that holds the data in a table like format
      const row: Row = {
        listing: {
          id,
          ...listing,
        },
        userLocations: {
        },
      };

      /**
      * Loop through each user location and add the alias as key
      * This will make it easy to filter locations based on commute times
      * The range of default values for each user location will be the overall range
      * of all locations. This is done so that the filters display with the same range and labels.
      */
      if (listing && userLocations) {
        for (let i = 0; i < userLocations.length; i += 1) {
          const userLocation = userLocations[i];
          const { userAlias, commute, travelMode } = userLocation;

          // Add the travel mode for the user location
          if (!userLocationTravelModes[userAlias]) { userLocationTravelModes[userAlias] = travelMode; }

          if (commute) {
            const commutedRoundedCeiling = Math.ceil(commute);

            // Add the user location as a row
            row.userLocations[userAlias] = userLocations[i];

            const allMax = allUserLocations[1];
            const newMax = commutedRoundedCeiling > allMax ? commutedRoundedCeiling : allMax;

            // Update the max value if the max evaluated is greater
            allUserLocations[1] = commutedRoundedCeiling > allMax ? commutedRoundedCeiling : allMax;
            userLocationFilterData[userAlias] = allUserLocations;
          }
        }
      }

      df[id] = row;
    }
  });

  // Get the bounding box from all the coordinates
  // If there is only one listing in the bounding box data, duplicate the data so there is enough data for the bounding box
  if (boundingBoxData.length === 0) {
    boundingBoxResults = { boundingBox: [0, 0, 0, 0], center: [0, 0] };
  } else {
    boundingBoxResults = boundingBoxData.length > 1 ? getBoundingBox(boundingBoxData) : getBoundingBox([...boundingBoxData, ...boundingBoxData]);
  }

  return [df, boundingBoxResults, userLocationFilterData, userLocationTravelModes];
};

export default formatUserSearchResults;
