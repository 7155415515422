import { FunctionComponent, useEffect, useState } from 'react';
import { useAppDispatch } from '@hooks';
import clsx from 'clsx';
import { Outlet, useNavigate, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { Routes } from '@types';
import { userNavigatingToDashboard } from '@stores';
import {
  ListingCardSideView,
  ResultsFilters,
  TopNavBar,
  Tint,
} from '@components';
import useStyles from './DashboardScreen.styles';

const DashboardScreen: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const currentLocation = location.pathname.includes(Routes.FAVORITES) ? Routes.FAVORITES : Routes.SAVED_SEARCHES;
  const [selection, setSelection] = useState<Routes>(currentLocation);

  /** Request user searches when the screen loads */
  useEffect(() => {
    dispatch(userNavigatingToDashboard());
  }, []);

  const handleNewSubNavBarSelection = (newSelection: Routes) => setSelection(newSelection);

  return (
    <>
      <Tint />

      <ListingCardSideView />

      <div className={classes.container} >

        <div className={classes['top-nav-bar']}>

          <TopNavBar variant='white'>

            <div className={classes['tabs-container']}>

              <Link
                to="saved-searches"
                className={clsx({
                  [classes.tab]: true,
                  selected: selection === Routes.SAVED_SEARCHES,
                })}
                onClick={() => handleNewSubNavBarSelection(Routes.SAVED_SEARCHES)}>
               Saved Searches
              </Link>

              <Link
                to="favorites"
                className={clsx({
                  [classes.tab]: true,
                  selected: selection === Routes.FAVORITES,
                })}
                onClick={() => handleNewSubNavBarSelection(Routes.FAVORITES)}>
                Favorites
              </Link>

              { selection === Routes.FAVORITES
                ? <ResultsFilters />
                : <></>
              }

            </div>

          </TopNavBar>

        </div>

        <Outlet />

      </div>
    </>

  );
};

export default DashboardScreen;
