import clsx from 'clsx';
import CheckIcon from '@material-ui/icons/Check';
import { FunctionComponent } from 'react';

import { useAppSelector } from '../../../../../hooks';
import { StepKeys } from '../../../../../types';

import useStyles from './StepNumber.styles';

interface StepNumberProps {
    /** The number of the step */
  stepNumber: number,

  /** The id of the step */
  id: StepKeys,
}

/**
 * Component that stores the number associated with the step and
 * changes to a colored icon once the input is entered and successfully validated.
 * @component
 */
const StepNumber: FunctionComponent<StepNumberProps> = ({ stepNumber, id }) => {
  const classes = useStyles();

  // Obtains whether the data for the step has been validated or not
  const completed = useAppSelector((state) => {
    if (id === StepKeys.LOCATION) { return state.locationInput.validation; }
    if (id === StepKeys.HOME_CRITERIA) { return state.homeCriteriaInput.validation; }
    if (id === StepKeys.USER_LOCATIONS) { return state.userLocationsInput.validation; }
    return false;
  });

  return (

    <div className={clsx({
      [classes.circle]: true,
      [classes.complete]: completed,
    })}
    >
      <>
        {completed
          ? <CheckIcon className={classes.check} />
          : stepNumber}
      </>
    </div>

  );
};

export default StepNumber;
