import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  button: {
    borderRadius: '0px',
    border: 'none',

    minWidth: '33%',
    maxWidth: '33%',
    color: 'black',

    '&.left': {
      borderRadius: '10px 0px 0px 10px',
      border: `1px solid ${theme.palette.neutral.dark}`,
    },

    '&.middle': {
      borderRadius: '0px',
      border: `1px solid ${theme.palette.neutral.dark}`,
      borderLeft: 'none',
    },

    '&.right': {
      borderRadius: '0px 10px 10px 0px',
      border: `1px solid ${theme.palette.neutral.dark}`,
      borderLeft: 'none',
    },

    '&.selected': {
      backgroundColor: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      color: 'white',
    },
  },

  content: {

    ...theme.typography.subtitle2,

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    marginRight: '0.5rem',
    marginLeft: '0.5rem',

  },

  icon: {
    width: '1.3rem',
    height: '1.1em',
    padding: '0rem',
  },

  text: {
    fontSize: '0.6rem',
  },

}));

export default useStyles;
